import { TextTableHeaderCell } from "../tables";

export const PriceListTableHeader = () => {
    return (
        <thead>
            <tr>
                {/* <TextTableHeaderCell text=""/> */}
                <TextTableHeaderCell text="Reference" />
                <TextTableHeaderCell text="Created"/>
                <TextTableHeaderCell text="Last Saved"/>
            </tr>
        </thead>
    )
}