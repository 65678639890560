import React, { useContext } from "react";
import { ContentHeader } from "../contentContainer/ContentHeader";
import { InvoiceList } from "./InvoiceList";
import { NothingSelectedMessage } from "../contentContainer/NothingSelectedMsg";
import { Dates } from "../../../../../../utils/dates";
import { DateSelectorButton, MonthRangePicker } from "../../../../../dates";
import styles from "./InvoiceHistory.module.css";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";

/**
 * Component shows contains the Invoice Date Range and Invoice List components.
 */
const Content = () => {

    // default the date range to 180 days ago to now
    const [fromDateMillis, setFromDateMillis] = React.useState<number>(Dates.today().subtractDays(180).millis());
    const [toDateMillis, setToDateMillis] = React.useState<number>(Dates.today().endOfDay().millis());
    const [showCalendar, setShowCalendar] = React.useState(false);
    const anchorRef = React.useRef<null | HTMLDivElement>(null);

    const handleMonthRangeOk = (from: {year: number, month: number}, to: {year: number, month: number}) => {
        // set the date range to be the start of the from month and end of the to month
        const f = Dates.fromValues(from.year, from.month, 1).millis();
        const t = Dates.fromValues(to.year, to.month, 1).endOfMonth().millis();

        //console.log("handle month range OK", from, to, f, t);

        setFromDateMillis(f);
        setToDateMillis(t);
        setShowCalendar(false);
    }

    return (
        <>        
            <div className={styles.dateRangeContainer}>
                <div>Showing Invoices for</div>
                <div ref={anchorRef}>
                    <DateSelectorButton
                        title="Invoice Date Range"
                        onClick={() => setShowCalendar(true)}
                        className={styles.dateSelectorButton}
                    >
                        {Dates.fromMillis(fromDateMillis).toString(Dates.DATE_FORMAT_YEAR_MONTH)}
                        {' to '}
                        {Dates.fromMillis(toDateMillis).toString(Dates.DATE_FORMAT_YEAR_MONTH)}                    
                    </DateSelectorButton>
                </div>
                {showCalendar &&
                    <MonthRangePicker 
                        fromDate={{year:Dates.fromMillis(fromDateMillis).year(), month: Dates.fromMillis(fromDateMillis).month()}}
                        toDate={{year:Dates.fromMillis(toDateMillis).year(), month: Dates.fromMillis(toDateMillis).month()}}
                        minDate={{year:2018, month: 1}}
                        maxDate={{year:Dates.now().year(), month: Dates.now().month()}}
                        anchorRef={anchorRef}
                        onOk={handleMonthRangeOk} 
                        onCancel={() => setShowCalendar(false)} 
                    />
                }
            </div>
            <InvoiceList fromDateMillis={fromDateMillis} toDateMillis={toDateMillis}/>
        </>
    )
}

/**
 * Component is a container for the Invoice History List and download functionality.
 */
const InvoiceHistory = () => {

    const {ship} = useContext(SelectedShipContext);

    return (
        <div>
            <ContentHeader title="Invoice History" />
            {
                ship ? <Content /> : <NothingSelectedMessage />
            }
        </div>    
    );
}

export default InvoiceHistory;