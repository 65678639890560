import React, {ReactElement} from "react";
import { Dates } from "../../../../utils/dates";
import { Day } from "./Day";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import classNames from "classnames";
import styles from "./Month.module.css";

/**
 * Function builds the month including a header and days
 */
const build = (
    baseDateMillis: number, 
    todayMillis: number,
    onClick: (dateMillis: number) => void,
    onMouseEnter: (dateMillis: number) => void,
    selectedFromDateMillis: number | null, 
    selectedToDateMillis: number | null,
    potentialToDateMillis: number | null,
    minimumDateMillis: number | null,
    maximumDateMillis: number | null,
    ) =>{

    const content: ReactElement[] = [];

    // first day of the month in which the 'start' date is located
    let day = Dates.fromMillis(baseDateMillis).startOfMonth();

    // month in which the 'start' date is located
    const thisMonth = Dates.fromMillis(baseDateMillis).month();

    // walk backwards if required to find the Monday prior to the first day
    // 1 = Monday
    while (day.weekDay() !== 1){
        day = day.subtractDays(1);
    }

    // build a header row
    const dayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

    content.push(<div key="header" className={styles.dayNames}>{
        dayNames.map((day, index) => {
            return <span key={`header-${index}`} className={styles.dayName}>{day}</span>
        })}</div>
    );

    // build rows and columns for each 'week' in the month
    for (let row = 0; row < 6; row++){
        const rowContent = []
        for (let col = 0; col < 7; col++){
            rowContent.push(
                <Day 
                    key={day.millis()}
                    dateMillis={day.millis()} 
                    isVisible={day.month() === thisMonth}
                    isToday={day.millis() === todayMillis}
                    isSelectable={(minimumDateMillis ? day.millis() >= minimumDateMillis : true) && (maximumDateMillis ? day.millis() <= maximumDateMillis : true)}
                    isSelected={(selectedFromDateMillis ? day.millis() === selectedFromDateMillis : false) || (selectedToDateMillis ? day.millis() === selectedToDateMillis : false) }
                    // in range is used to highlight days that are inbetween the currently selected from and to dates
                    // or where the from date is selected but the to date is not selected, days that are between the from date
                    // and the day currently under the mouse, as indicated by the potentialToDate value
                    isInRange={(selectedFromDateMillis && selectedToDateMillis 
                                ? day.millis() > selectedFromDateMillis && day.millis() < selectedToDateMillis 
                                : selectedFromDateMillis && potentialToDateMillis 
                                    ? day.millis() > selectedFromDateMillis && day.millis() <= potentialToDateMillis 
                                    : false
                                )}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                />
            )        
            day = day.addDays(1);
        }
        content.push(<div key={row} className={styles.row}>{rowContent}</div>)
    }

    return content;
}

interface IMonthProps {
    firstDateOfMonthMillis: number;
    todayMillis: number;
    selectedFromDateMillis: number | null;
    selectedToDateMillis: number | null;
    potentialToDateMillis: number | null;
    minimumDateMillis: number | null;
    maximumDateMillis: number | null;
    showMonthDecrease: boolean,
    showMonthIncrease: boolean,
    onDecrease: () => void,
    onIncrease: () => void,
    onDayClick: (dateMillis: number) => void,
    onDayMouseEnter: (dateMillis: number) => void,
}

/**
 * 
 * @param firstDateOfMonthMillis DateTime which dictates which month will be shown.
 * @param todayMillis The current system date, used to highlight the current day.
 * @param selectedFromDateMillis The currently selected From Date (readonly) used to initialise the component
 * @param selectedToDateMillis The currently selected To Date (readonly) used to initialise the component
 * @param potentialToDateMillis A datetime which is a potential To Date used to highligt the potential date range
 * @param minimumDateMillis A minium date time that can be selected
 * @param maximumDateMillis A maximum date time that can be selected
 * @param showMonthDecreaseMillis True if the calendar should show the icon used to decrease the month
 * @param showMonthIncreaseMillis True if the calendar should show the icon used to increase the month
 * @param onDecrease Raised when a request to decrease the month has been made
 * @param onIncrease Raised when a request to increase the month has been made
 * @returns 
 */
export const Month : React.FC<IMonthProps> = ({
    firstDateOfMonthMillis,
    todayMillis,
    selectedFromDateMillis,
    selectedToDateMillis,
    potentialToDateMillis,
    minimumDateMillis,
    maximumDateMillis,
    showMonthDecrease,
    showMonthIncrease,
    onDecrease,
    onIncrease,
    onDayClick,
    onDayMouseEnter,
}) => {

    const firstDateOfMonth = Dates.fromMillis(firstDateOfMonthMillis);

    return (
        <div>            
            <div className={styles.root}>
                <div className={styles.monthSelectorContainer}>
                    <FontAwesomeIcon 
                        className={classNames(styles.monthSelectorButton, !showMonthDecrease && styles.hidden)}
                        icon={faAngleLeft} 
                        size="1x" 
                        onClick={onDecrease} 
                        title="Previous Month"
                    />

                    <div className={styles.monthSelectorMonthName}>{firstDateOfMonth.monthNameLong()} {firstDateOfMonth.year()}</div>

                    <FontAwesomeIcon 
                        className={classNames(styles.monthSelectorButton, !showMonthIncrease && styles.hidden)}
                        icon={faAngleRight} 
                        size="1x" 
                        onClick={onIncrease} 
                        title="Next Month"
                    />
                </div>
                <div className={styles.column}>
                    {build(
                        firstDateOfMonth.millis(), 
                        todayMillis, 
                        onDayClick, 
                        onDayMouseEnter,
                        selectedFromDateMillis, 
                        selectedToDateMillis, 
                        potentialToDateMillis, 
                        minimumDateMillis, 
                        maximumDateMillis
                        )}
                </div>
            </div>
        </div>
    )
}