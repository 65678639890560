import { Month } from "./Month";
import { IYearAndMonth, yearAndMonthAsNumber } from "./YearAndMonth";
import styles from "./Months.module.css";

interface IMonthsProps {
    year: number;
    minDate: IYearAndMonth | null;
    maxDate: IYearAndMonth | null;
    fromDate: IYearAndMonth | null;
    toDate: IYearAndMonth | null; 
    potentialToDate: IYearAndMonth | null;
    onMonthSelected: (yearAndMonth: IYearAndMonth) => void;
    onMonthMouseEnter: (yearAndMonth: IYearAndMonth) => void;
}

const IsMonthSelected = (currentFrom: number | null, currentTo: number | null, year: number, month: number) : boolean => {

    const date = yearAndMonthAsNumber({year, month});

    // only a lower date selected
    if (currentFrom !== null && currentTo === null) 
    {
        // month is selected if the supplied year and month 
        // match the selected lower values
        return currentFrom === date;
    }

    // lower and upper date selected
    if (currentFrom !== null && currentTo !== null){
        // month is selected if the month is between the selected
        // dates inclusive
        const selected = (currentFrom <= date) && (date <= currentTo);

        //console.log(`${selectedLower} ${date} ${selectedUpper} ${selected}`);

        return selected;
    }

    return false;
}


export const Months : React.FC<IMonthsProps> = ({
    year,
    minDate,
    maxDate,
    fromDate,
    toDate,
    potentialToDate,
    onMonthSelected,
    onMonthMouseEnter,
}) => {

    const handleOnMonthSelected = (monthNumber: number) => {
        onMonthSelected({year, month: monthNumber});
    }

    const handleOnMonthMouseEnter = (monthNumber: number) => {
        onMonthMouseEnter({year, month: monthNumber});
    }

    // convert current boundary values to composite numbers
    const selectedLowerAsNum = fromDate !== null ? yearAndMonthAsNumber(fromDate) : null;
    const selectedUpperAsNum = toDate !== null ? yearAndMonthAsNumber(toDate) : null;
    const potentialToDateAsNum = potentialToDate !== null ? yearAndMonthAsNumber(potentialToDate) : null;

    // build a collection of rows of months,
    const rows : React.ReactNode[] = [];
    const minDateAsNum = minDate !== null ? yearAndMonthAsNumber(minDate) : null;
    const maxDateAsNum = maxDate !== null ? yearAndMonthAsNumber(maxDate) : null;

    for (let row = 0; row < 3; row++){
        const months : React.ReactNode[] = [];
        for (let col = 0; col < 4; col++){
            const monthNumber = (row * 4) + col + 1;
            // determine if the month should be shown as selected
            const isSelected = IsMonthSelected(selectedLowerAsNum, selectedUpperAsNum, year, monthNumber);
            // determine if the month should be disabled because of the min / max date values
            const yearAndMonthNum = yearAndMonthAsNumber({year, month: monthNumber});
            const isDisabledByMinDate = minDateAsNum !== null ? yearAndMonthNum < minDateAsNum : false;
            const isDisabledByMaxDate = maxDateAsNum !== null ? yearAndMonthNum > maxDateAsNum : false;
            // determine if the month should be shown as in the range of the selected or potentially selected dates
            const isInRange = 
                (selectedLowerAsNum !== null && selectedUpperAsNum !== null) 
                ? (yearAndMonthNum > selectedLowerAsNum) && (yearAndMonthNum < selectedUpperAsNum)
                : selectedLowerAsNum !== null && potentialToDateAsNum !== null
                    ? (yearAndMonthNum > selectedLowerAsNum) && (yearAndMonthNum <= potentialToDateAsNum)
                    : false;

            months.push(
                <Month 
                    key={monthNumber} 
                    monthNumber={monthNumber} 
                    isSelected={isSelected}
                    isInRange={isInRange}
                    isDisabled={isDisabledByMinDate || isDisabledByMaxDate}
                    onSelected={handleOnMonthSelected} 
                    onMouseEnter={handleOnMonthMouseEnter}
                    border={{top: true, right: (col === 3), bottom: (row === 2), left: true}}
                />
            );
        }
        rows.push(
            <div key={row} className={styles.row}>{months}</div>
        );
    }

    return (
        <div>
            {rows}
        </div>
    )
}