import styles from "./Content.module.css";

interface IContentProps {
    children: React.ReactNode;
}

export const Content : React.FC<IContentProps> = ({
    children
}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}