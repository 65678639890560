import { application } from "../../../utils/environment";
import { AuthForm, AuthLayout, FieldSpacer, NavLink, SectionHeader } from "./components";

export default function ResetPasswordConfirmationPage() {

    return (
        <AuthLayout>
            <AuthForm onSubmit={() => {}}>
                <SectionHeader text="Your password has been reset"/>
                <FieldSpacer />
                <NavLink to={"/"} text={`Click here to Sign In to ${application.name}`}/>
            </AuthForm>
        </AuthLayout>
    )
}