import React from "react";
import { VerticalBarsProps } from "./VerticalBarsProps";
import { VerticalBarProps } from "./VerticalBarProps";
import { VerticalBar } from "./VerticalBar";

export const VerticalBars: React.FC<VerticalBarsProps> = ({ height, xScale, yScale, color, data }) => {

    const bars =

        data.map((d) => {
            const lowerDate = new Date(d.tsLowerMillis);
            const upperDate = new Date(d.tsUpperMillis);

         const barProps: VerticalBarProps = {
             id: d.tsLowerMillis,
             x: xScale(lowerDate) || 0,
             y: d.value ? yScale(d.value + d.offset) : 0,
             width: (xScale(upperDate) - xScale(lowerDate)) || 0,
             height: d.value ? (height - yScale(d.value)) : 0,
             color
         };

         return <VerticalBar key={barProps.id} {...barProps} />;

        });

    return <g>{bars}</g>
};
