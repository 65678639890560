import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./Header.module.css";

interface IHeaderProps {
    text: string;
    canDrop?: boolean;
    isOver?: boolean;
    isExpandable?: boolean;
    isExpanded?: boolean;
    onToggleExpand?: () => void;
}

export const Header : React.FC<IHeaderProps> = ({
    text,
    canDrop,
    isOver,
    isExpandable = true,
    isExpanded,
    onToggleExpand,
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                {isExpandable && 
                    <div className={styles.buttons}>
                        <div className={styles.buttonContainer}>
                            <button className={styles.button} 
                                type="button" 
                                onClick={onToggleExpand}
                                title="Expand/collapse Users"
                            >
                                <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
                            </button>
                        </div>
                    </div>
                }
                <div className={`${classNames(styles.text, canDrop && styles.canDrop, (isOver && canDrop) && styles.isOver)}`}>
                    {text}
                </div>
            </div>
        </div>
    )
}