import React from "react";
import UserSettings from "../../user/components/userSettings/UserSettings";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import { NavBarButton } from "./NavBarButton";

const ShowUserSettings = () => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    }

    // NOTE: don't be tempted to use a click away handler on this
    // because it's easy for the user to accidentally click away
    // and for the dialog to close as a result
    return (
        <div>
            <NavBarButton
                icon={faGear}
                onClick={handleClick}
                title="User Settings"
            />
            {open ? (
                <UserSettings onClose={() => setOpen(false)}/>
            ) : null}
        </div>
    );
}

export default ShowUserSettings;