import { Tree } from "../../common/tree/Tree";
import { IApiData } from "../data/Models";
import { ActionTypes } from "../data/State";
import { ClientGroup } from "./components/ClientGroup";

interface IClientGroupsProps {
    data: IApiData;
    dispatch: React.Dispatch<ActionTypes>;
}

export const ClientGroups : React.FC<IClientGroupsProps> = ({
    data,
    dispatch,
}) => {

    return (
        <Tree>
            {data.clientGroups.map(
                clientGroup => 
                    <ClientGroup
                        key={clientGroup.id}
                        clientGroup={clientGroup}
                        dispatch={dispatch}
                    />
            )}
        </Tree>
    )
}