import { useContext } from "react";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { AuthContext } from "../../../contexts/components/AuthContext";
import { logger } from "../../../debug/consoleLogger";

export const VnoAdminLayout = () => {

    const {user} = useContext(AuthContext);
    const outlet = useOutlet();
    const location = useLocation();

    if (!user || !user.canPerformVnoAdmin) {
        logger.log(`vnoAdminLayout exiting ${user?.userName}`);
        return <Navigate to="/" replace state={{path: location.pathname}} />
    }

    // don't be tempted to change the fragment to a div
    // unless you set height 100% because it may well
    // screw the css lower down
    return (
        <>
            {outlet}
        </>
    )
}