import React from "react";
import { IRatePlanModel } from "../../../../../../../../../dataServices/dataPackages/editScheduledChanges";
import { Dates } from "../../../../../../../../../utils/dates";
import { Button } from "../../../../../../../../buttons";
import { Dialog } from "../../../../../../../../dialog";

import { RatePlanSelectionList } from "../../ratePlanSelectionList/RatePlanSelectionList";
import { EndDate } from "./EndDate";
import { StartDate } from "./StartDate";
import { getRatePlanFromId } from "./Utils";

import styles from "./AddOrEditDialog.module.css";

type DialogType = "edit" | "add";

export const AddOrEditDialog =({
    dialogType,
    itemId,
    isInProgress,
    startDateMillis,
    endDateMillis,
    ratePlanId,
    minStartDateMillis,
    maxStartDateMillis,
    minEndDateMillis,
    maxEndDateMillis,
    ratePlans,
    hidePricing,
    onCancel,
    onAddAccepted,
    onEditAccepted
}: {
    dialogType: DialogType,
    itemId: number | null,
    isInProgress: boolean,
    startDateMillis: number,
    endDateMillis: number | null,
    ratePlanId: number | null,
    minStartDateMillis: number,
    maxStartDateMillis: number | null,
    minEndDateMillis: number,
    maxEndDateMillis: number | null,
    ratePlans: IRatePlanModel[],
    hidePricing: boolean,
    onCancel: () => void,
    onAddAccepted: (startDateMillis: number, endDateMillis: number, ratePlanId: number) => void,
    onEditAccepted: (id: number, startDateMillis: number, endDateMillis: number, ratePlanId: number) => void,
}) => {

    const [selectedRatePlanId, setSelectedRatePlanId] = React.useState<number | null>(ratePlanId);
    const [selectedStartDateMillis, setSelectedStartDateMillis] = React.useState<number>(startDateMillis);
    const [selectedEndDateMillis, setSelectedEndDateMillis] = React.useState<number>(endDateMillis === null ? startDateMillis : endDateMillis);

    function handleAcceptClick(){
        // need to validate here
        if (selectedRatePlanId === null) return;

        // if all OK, need to pass data back
        if (dialogType === "add"){
            onAddAccepted(selectedStartDateMillis, selectedEndDateMillis, selectedRatePlanId);
        } else {
            if (itemId !== null){
                onEditAccepted(itemId, selectedStartDateMillis, selectedEndDateMillis, selectedRatePlanId);
            }
        }
    }

    function handleCancelClick(){
        onCancel();
    }

    function handleRatePlanSelection(id: number){
        setSelectedRatePlanId(id);
    }

    function handleStartDateChange(value: number) {
        setSelectedStartDateMillis(value);
        if (value > selectedEndDateMillis) {
            const endDate = Dates.fromMillis(value).endOfDay().millis();
            setSelectedEndDateMillis(endDate);
        }
    }

    function handleEndDateChange(value: number){
        setSelectedEndDateMillis(value);
    }

    function formatDate(dateMillis: number | null) {
        if (dateMillis === null) return "null";
        return Dates.fromMillis(dateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS);
    }

    const title = dialogType === "add" ? "Add a Package Change" : "Edit a Package Change";

    // if things have not been configured correctly, prevent the user from getting into problems.
    if (ratePlans.length === 0) {
        return (
            <Overlay>
                <Dialog title={title}>
                    <Container>
                        <Section>
                            <div>Sorry, but things don't seem to have been configured correctly</div>
                        </Section>
                        <Buttons>
                            <Button
                                text="Ok"
                                color="success"
                                onClick={handleCancelClick}
                            />
                        </Buttons>
                    </Container>
                </Dialog>
            </Overlay>
        )
    }

    return (
        <Overlay>
            <Dialog title={title}>
                <Container>                    
                    {!isInProgress &&
                        <Section>
                            <SectionTitle text="Package will Change to"/>
                            <RatePlanSelectionList
                                ratePlans={ratePlans}
                                ratePlanId={selectedRatePlanId}
                                hidePricing={hidePricing}
                                onChange={handleRatePlanSelection}
                            />
                        </Section>
                    }
                    
                    {isInProgress && 
                        <Section>
                            <div>This Package Change has already started.</div>
                            <div>You cannot amend the Package or the Start Date.</div>
                        </Section>
                    }
                    
                    {isInProgress && 
                        <Section>
                            <SectionTitle text="The Package is"/>
                            <Highlight>{getRatePlanFromId(ratePlans, ratePlanId)?.name}</Highlight>
                        </Section>
                    }

                    {isInProgress && 
                        <Section>
                            <SectionTitle text="The Change started at"/>
                            <Highlight>{Dates.fromMillis(selectedStartDateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</Highlight>
                        </Section>
                    }
                    {!isInProgress &&
                        <Section>
                            <SectionTitle text="The Change will start at"/>
                            <StartDate
                                startDateMillis={selectedStartDateMillis}
                                minStartDateMillis={minStartDateMillis}
                                maxStartDateMillis={maxStartDateMillis}
                                onChange={handleStartDateChange}                        
                            />
                        </Section>
                    }

                    <Section>
                        <SectionTitle text="The Change will end at"/>
                        <EndDate
                            endDateMillis={selectedEndDateMillis}
                            minEndDateMillis={minEndDateMillis}
                            maxEndDateMillis={maxEndDateMillis}
                            onChange={handleEndDateChange}
                        />
                    </Section>

                    <Buttons>
                        <Button 
                            text="Ok"
                            color="success"
                            onClick={handleAcceptClick}
                            disabled={selectedRatePlanId === null}
                        />
                        <Button
                            text="Cancel"
                            color="secondary"
                            onClick={handleCancelClick}
                         />
                    </Buttons>

                </Container>


                {import.meta.env.DEV && false &&
                    <div style={{ fontFamily: 'Courier New' }}>
                        <div>item id -------------: {itemId ? itemId : "null"}</div>
                        <div>is in progress ------: {isInProgress ? "yes" : "no"}</div>
                        <div>rate plan count -----: {ratePlans.length}</div>
                        <div>rate plan id --------: {ratePlanId ? ratePlanId : "null"}</div>
                        <div>supplied start date -: {formatDate(startDateMillis)}</div>
                        <div>supplied end date ---: {formatDate(endDateMillis)}</div>
                        <div>min start date ------: {formatDate(minStartDateMillis)}</div>
                        <div>max start date ------: {formatDate(maxStartDateMillis)}</div>
                        <div>min end date --------: {formatDate(minEndDateMillis)}</div>
                        <div>max end date --------: {formatDate(maxEndDateMillis)}</div>
                        <div>selected start date -: {formatDate(selectedStartDateMillis)}</div>
                        <div>selected end date ---: {formatDate(selectedEndDateMillis)}</div>
                        <div>error messages</div>
                    </div>
                }
            </Dialog>
        </Overlay>
    );
}

const Overlay = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <div className={styles.overlay}>
            {children}
        </div>
    )
}
const Container = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

const Section = ({
    children
}: {
    children: React.ReactNode | React.ReactNode[];
}) => {
    return (
        <div className={styles.section}>
            {children}
        </div>
    )
}

const SectionTitle = ({
    text
}: {
    text: string;
}) => {
    return (
        <div className={styles.sectionTitle}>
            {text}
        </div>
    )
}

const Highlight = ({
    children
}: {
    children: React.ReactNode | React.ReactNode[];
}) => {
    return (
        <div className={styles.highlight}>
            {children}
        </div>
    )
}

const Buttons = ({
    children
}: {
    children: React.ReactNode | React.ReactNode[];
}) => {
    return (
        <div className={styles.buttons}>
            {children}
        </div>
    )
}