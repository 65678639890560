import React from "react";
import { ViewState } from "react-map-gl";
import { MapViewStateContext } from "./MapViewStateContext";

export const MapViewStateProvider = ({children} : {children:React.ReactNode}) => {

    const MIN_ZOOM = 0;
    const MAX_ZOOM = 22;

    const defaultViewState: ViewState = {        
        latitude: 0, 
        longitude: 0, 
        bearing: 0, 
        pitch: 0, 
        zoom: MIN_ZOOM, 
        padding: {
            left: 0, 
            right: 0, 
            top: 0, 
            bottom: 0
        }};

    const [viewState, setViewState] = React.useState<ViewState>(defaultViewState);

    const value = {viewState, setViewState, minZoom:MIN_ZOOM, maxZoom:MAX_ZOOM};

    return (
        <MapViewStateContext.Provider value={value}>
            {children}
        </MapViewStateContext.Provider>
    )
}
