import React from "react";
import { api, fetchApiPost, fetchHandler } from "../../../../dataServices/components";
import { Button } from "../../../buttons";
import { ErrorMessage } from "./ErrorMessage";
import styles from "./NewEmail.module.css";

/**
 * Validates an input value to determine if it
 * has a valid email format
 */
function EmailIsValid (email:string) : boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

/**
 * Represents the email value returned by the
 * API when a new email has been successfully added
 */
interface IAddedNotificationEmail{
    id: number, 
    email: string
}

/**
 * A component that allows a new Email Address 
 * to be added for a given client.
 */
export const NewEmail = (props: {clientId: number, onAdd: (id: number, email: string) => void}) => {

    const {clientId, onAdd} = props;
    const [newEmail, setNewEmail] = React.useState<string>('');
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const postUrl = api.urls.packages.post.notificationEmails();

    /**
     * Handle the request to add the email address.
     */
    const handleOnAdd = () => {

        // belt and braces - should not happen
        if (!EmailIsValid(newEmail)) return;

        setIsProcessing(true);
        setErrorMessage(null);

        const data = {
            clientId: clientId,
            emailAddress: newEmail 
        };

        fetchApiPost(postUrl, data, {includeCredentials: true})
        .then((postResponse) => {
            setIsProcessing(false);
            void fetchHandler(postResponse)
            .then((handlerResult) => {
                if (handlerResult.succeeded){
                    setNewEmail('');
                    setErrorMessage('');
                    const added = handlerResult.json as IAddedNotificationEmail;
                    onAdd(added.id, added.email);
                } else {
                    setErrorMessage("Unable to add email.")
                }
            })
        })
        .catch(() => {
            setIsProcessing(false);
            setErrorMessage("Sorry - something went wrong");
        })
    }

    /**
     * Respond to the email input value being changed.
     */
    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>){
        const value = event.target.value;
        // record the new value as the email address
        setNewEmail(value);
    }

    return (
        <div className={styles.root}>
            <div className={styles.inputContainer}>
                <input 
                    name={`new-email-client-${clientId}`} 
                    type="text" 
                    placeholder="New email address" 
                    onChange={handleOnChange} 
                    disabled={isProcessing}
                    value={newEmail}
                    />
            </div>
            <div className={styles.buttonContainer}>
                <Button 
                    text="Add Email" 
                    color="success"
                    size="small"
                    onClick={handleOnAdd} 
                    disabled={isProcessing || !EmailIsValid(newEmail)}
                />
            </div>
            {errorMessage && <ErrorMessage text={errorMessage}/>}
        </div>
    )
}
