import styles from "./Header.module.css";

interface IHeaderProps {
    title: string
}

export const Header : React.FC<IHeaderProps> = ({
    title
}) => {
    return (
        <header className={styles.root}>
            <h2>{title}</h2>
        </header>
    )
}