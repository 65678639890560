import { useContext } from "react";
import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";
import { MapDateRangeContext } from "../../../../contexts/components/MapDateRangeContext";
import { Dates } from "../../../../utils/dates";

const DateDisplay = ({ dateMillis }: {dateMillis: number}) => {

    return (
        <span>{Dates.fromMillis(dateMillis).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}</span>
    )
}

export const MapDatePointDisplay = ({ showUpperValue }: {showUpperValue: boolean}) => {

    const { dateMillis } = useContext(MapDatePointContext);
    const { intervalMillis } = useContext(MapDateRangeContext);

    return (
        <span>
            <DateDisplay dateMillis={dateMillis} />
            {showUpperValue ? " to " : null}
            {showUpperValue ? <DateDisplay dateMillis={dateMillis + intervalMillis} /> : null}
        </span>
    )
}