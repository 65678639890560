import React from "react";
import { useSearchParams } from "react-router-dom";
import { AuthForm, AuthLayout, FieldSpacer, Input, IsPasswordValid, SectionHeader } from "./components";
import {useNavigate} from "react-router-dom";
import { api, fetchHandler, fetchApiPost } from "../../../dataServices/components";
import { Button } from "../../buttons";
import { toast } from "react-toastify";

function ResetPasswordPage() {
    const navigate = useNavigate();

    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState<string>('');
    const [processing, setProcessing] = React.useState<boolean>(false);
    
    // pick up the token from the query string
    const [searchParams] = useSearchParams();

    const code = searchParams.get('c');

    const redirectPage = "/resetpasswordconfirmation";

    /**
     * Handle the user pressing the submit button
     */
     function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        setEmailErrorMessage('');
        setPasswordErrorMessage('');
        setConfirmPasswordErrorMessage('');

        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;
        const password = formData.get("password") as string;
        const confirmPassword = formData.get("confirmPassword") as string;

        let errors = false;

        if (email === null || typeof email === "undefined" || email.trim() === '') {
            setEmailErrorMessage("Email is required");
            errors = true;
        }

        if (password === null || typeof password === "undefined" || password.trim() === '') {
            setPasswordErrorMessage("Password is required");
            errors = true;
        }
        
        if (!IsPasswordValid(password)) {
            setPasswordErrorMessage("Password is not valid");
            errors = true;
        }

        if (confirmPassword === null || typeof confirmPassword === "undefined" || confirmPassword.trim() === '') {
            setConfirmPasswordErrorMessage("Confirm Password is required");
            errors = true;
        }

        if (!IsPasswordValid(confirmPassword)) {
            setConfirmPasswordErrorMessage("Confirm Password is not valid");
            errors = true;
        }
        
        if (password !== confirmPassword) {
            setConfirmPasswordErrorMessage("Password and Confirm Password do not match");
            errors = true;
        }

        if (errors){
            return;
        }

        setProcessing(true);

        const data = {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            code: code
        };

        fetchApiPost(api.urls.auth.post.resetPassword(), data, {includeCredentials: false})
        .then((response) => {
            setProcessing(false);
            void fetchHandler(response)
            .then((result) => {
                if (result.succeeded){
                    navigate(redirectPage, {replace: true});
                } else {
                    toast.error("Oops.  Something went wrong processing your request");
                }
            })
        })
        .catch(() => {
            setProcessing(false);
            toast.error("Oops.  Something went wrong processing your request");
        })
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit}>
                <SectionHeader text="Reset your password" />
                <Input name="email" placeholder="Email" type="text" maxLength={50} disabled={processing} required={true} errorMessage={emailErrorMessage}/>
                <Input name="password" placeholder="Password" type="password" maxLength={30} disabled={processing} required={true} errorMessage={passwordErrorMessage}/>
                <Input name="confirmPassword" placeholder="Confirm Password" type="password" maxLength={30} disabled={processing} required={true} errorMessage={confirmPasswordErrorMessage}/>
                <Button text="Submit" disabled={processing}/>
                <FieldSpacer/>
            </AuthForm>
        </AuthLayout>
    )
}

export default ResetPasswordPage;