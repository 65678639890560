import React from "react";
import { Dates } from "../../../../utils/dates";
import classNames from "classnames";
import styles from "./Day.module.css";

interface IDayProps {
    // DateTime value that this day represents (star of day)
    dateMillis: number;     
    // True if the Day represents the current date
    isToday: boolean;                 
    // True if the Day should be visible
    isVisible: boolean;
    // True if the Day should be selectable (via mouse click)
    isSelectable: boolean;
    // True if the Day represents a Selected day
    isSelected: boolean;
    // True if the Day represents a day between two selected dates
    isInRange: boolean;
    // Raised when the Day is clicked
    onClick: (dateMillis: number) => void;

    onMouseEnter: (dateMillis: number) => void;
}

export const Day : React.FC<IDayProps> = ({
    dateMillis,
    isToday,
    isVisible,
    isSelectable,
    isSelected,
    isInRange,
    onClick,
    onMouseEnter,
}) => {

    const handleOnClick :React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        onClick(dateMillis);
    }

    const handleOnMouseEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        onMouseEnter(dateMillis);
    }

    let className = styles.root;

    if (isToday) className = classNames(styles.root, styles.today);

    if (isSelected) className = classNames(styles.root, styles.selected);

    if (isInRange) className = classNames(styles.root, styles.inRange);

    if (!isVisible) className = classNames(styles.root, styles.hidden);

    if (!isSelectable) className = classNames(styles.root, styles.notSelectable);

    return (
        <div
            className={className}
            onClick={isVisible && isSelectable ? handleOnClick : () => {}}
            onMouseEnter={isVisible && isSelectable ? handleOnMouseEnter : () => {}}
        >
            {isVisible ? Dates.fromMillis(dateMillis).day()  : ' '}
        </div>
    );
}