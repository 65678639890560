import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

import styles from "./WeatherSelector.module.css";

interface IIconProps{
    className?: string,
    title?: string,
}

const IconClouds : React.FC<IIconProps> = ({className, title}) => {
    return (
        <div title={title}>
            <svg className={className} viewBox="0 0 24 24">
                <path d="M19,18H6A4,4 0 0,1 2,14A4,4 0 0,1 6,10H6.71C7.37,7.69 9.5,6 12,6A5.5,5.5 0 0,1 17.5,11.5V12H19A3,3 0 0,1 22,15A3,3 0 0,1 19,18M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
            </svg>
        </div>
    )
}

const IconRain : React.FC<IIconProps> = ({className, title}) => {
    return (
        <div title={title}>
            <svg className={className} viewBox="0 0 24 24">
                <path d="M9,12C9.53,12.14 9.85,12.69 9.71,13.22L8.41,18.05C8.27,18.59 7.72,18.9 7.19,18.76C6.65,18.62 6.34,18.07 6.5,17.54L7.78,12.71C7.92,12.17 8.47,11.86 9,12M13,12C13.53,12.14 13.85,12.69 13.71,13.22L11.64,20.95C11.5,21.5 10.95,21.8 10.41,21.66C9.88,21.5 9.56,20.97 9.7,20.43L11.78,12.71C11.92,12.17 12.47,11.86 13,12M17,12C17.53,12.14 17.85,12.69 17.71,13.22L16.41,18.05C16.27,18.59 15.72,18.9 15.19,18.76C14.65,18.62 14.34,18.07 14.5,17.54L15.78,12.71C15.92,12.17 16.47,11.86 17,12M17,10V9A5,5 0 0,0 12,4C9.5,4 7.45,5.82 7.06,8.19C6.73,8.07 6.37,8 6,8A3,3 0 0,0 3,11C3,12.11 3.6,13.08 4.5,13.6V13.59C5,13.87 5.14,14.5 4.87,14.96C4.59,15.43 4,15.6 3.5,15.32V15.33C2,14.47 1,12.85 1,11A5,5 0 0,1 6,6C7,3.65 9.3,2 12,2C15.43,2 18.24,4.66 18.5,8.03L19,8A4,4 0 0,1 23,12C23,13.5 22.2,14.77 21,15.46V15.46C20.5,15.73 19.91,15.57 19.63,15.09C19.36,14.61 19.5,14 20,13.72V13.73C20.6,13.39 21,12.74 21,12A2,2 0 0,0 19,10H17Z" />
            </svg>
        </div>
    )
}

const IconWaves : React.FC<IIconProps> = ({className, title}) => {
    return (
        <div title={title}>
            <svg className={className} viewBox="0 0 24 24">
                <path d="M20,12H22V14H20C18.62,14 17.26,13.65 16,13C13.5,14.3 10.5,14.3 8,13C6.74,13.65 5.37,14 4,14H2V12H4C5.39,12 6.78,11.53 8,10.67C10.44,12.38 13.56,12.38 16,10.67C17.22,11.53 18.61,12 20,12M20,6H22V8H20C18.62,8 17.26,7.65 16,7C13.5,8.3 10.5,8.3 8,7C6.74,7.65 5.37,8 4,8H2V6H4C5.39,6 6.78,5.53 8,4.67C10.44,6.38 13.56,6.38 16,4.67C17.22,5.53 18.61,6 20,6M20,18H22V20H20C18.62,20 17.26,19.65 16,19C13.5,20.3 10.5,20.3 8,19C6.74,19.65 5.37,20 4,20H2V18H4C5.39,18 6.78,17.53 8,16.67C10.44,18.38 13.56,18.38 16,16.67C17.22,17.53 18.61,18 20,18Z" />
            </svg>
        </div>
    )
}

type ButtonProps = {
    title: string,
    isSelected: boolean,
    disabled: boolean,
    onClick: () => void,
    children?: React.ReactNode
};

const Button: React.FC<ButtonProps> = ({
    title, 
    isSelected, 
    disabled, 
    onClick, 
    children
}) => {
    
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClick();
   }

   return (
        <button
            type="button"
            disabled={disabled}
            title={title}
            className={`${classnames(styles.button, isSelected && styles.selected, disabled && styles.disabled)}`}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};

type WeatherSelectorProps = {
    cloudSelected: boolean,
    cloudEnabled: boolean,
    onCloudsClick: () => void,
    rainSelected: boolean,
    rainEnabled: boolean,
    onRainClick: () => void,
    wavesSelected: boolean,
    wavesEnabled: boolean,
    onWavesClick: () => void,
    legendSelected: boolean,
    onLegendClick: () => void,
}

export const WeatherSelector: React.FC<WeatherSelectorProps> = ({
    cloudSelected,
    cloudEnabled,
    onCloudsClick,
    rainSelected,
    rainEnabled,
    onRainClick,
    wavesSelected,
    wavesEnabled,
    onWavesClick,
    legendSelected,
    onLegendClick,
}) => {

    function handleCloudsClick(){
        onCloudsClick();
    }

    function handleRainClick(){
        onRainClick();
    }

    function handleWavesClick(){
        onWavesClick();
    }

    function handleLegendClick(){
        onLegendClick();
    }
    
    return (
        <div className={styles.root}>
            <Button 
                onClick={handleCloudsClick} 
                title="Cloud Cover (last 7 days only)" 
                isSelected={cloudSelected} 
                disabled={!cloudEnabled}>
                <IconClouds className={styles.icon}/>
            </Button>
            <Button 
                onClick={handleRainClick} 
                title="Precipitation (last 30 days only)" 
                isSelected={rainSelected} 
                disabled={!rainEnabled}>
                <IconRain className={styles.icon}/>
            </Button>
            <Button 
                onClick={handleWavesClick} 
                title="Wave Height (last 7 days only)" 
                isSelected={wavesSelected} 
                disabled={!wavesEnabled}>
                <IconWaves className={styles.icon}/>
            </Button>
            <Button 
                onClick={handleLegendClick} 
                title="Weather Information" 
                isSelected={legendSelected}
                disabled={false}
                >
                    <FontAwesomeIcon icon={faQuestion}/>
            </Button>
        </div>
    );
}