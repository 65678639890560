import React from "react";
import styles from "./AuthForm.module.css";

export function AuthForm(props: {onSubmit: (event: React.FormEvent<HTMLFormElement>) => void, children: Array<JSX.Element> | JSX.Element}){

    return (
        <form className={styles.authorisationForm} onSubmit={props.onSubmit}>
            {props.children}
        </form>
    );
}