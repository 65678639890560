import { RatePlanModel } from "../../../../../../../../../dataServices/dataPackages/ratePlans";
import { RatePlanSelectionList } from "../../ratePlanSelectionList/RatePlanSelectionList";

import styles from "./RatePlanList.module.css";

interface IRatePlanListProps {
    ratePlans: RatePlanModel[];
    ratePlanId?: number;
    hidePricing: boolean;
    onChange: (id: number) => void;
}

export const RatePlanList : React.FC<IRatePlanListProps> = ({
    ratePlans,
    ratePlanId,
    hidePricing,
    onChange
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.title}>Package to Upgrade to</div>
            <RatePlanSelectionList 
                ratePlans={ratePlans} 
                ratePlanId={ratePlanId}
                hidePricing={hidePricing}
                onChange={onChange}
            />
        </div>

    )
}