import { passwordRules } from "../../../../utils/environment";
/**
 * Inspects a string to determine if it represents a valid password.
 * Returns true if valid, false otherwise.
 */
export function IsPasswordValid(password: string): boolean {

    const digitRegEx = /^(?=.*[0-9]).+$/
    const lowerCaseRegEx = /^(?=.*[a-z]).+$/
    const upperCaseRegEx = /^(?=.*[A-Z]).+$/
    const nonAlphaRegEx = /^(?=.*[-+_!@#$%^&*., ?]).+$/

    // must be an actual string with a minimum length
    if (password === null || typeof password === "undefined" || password.trim() === '' || password.length < passwordRules.minimumLength) return false;
    
    if (passwordRules.digitRequired && (digitRegEx.exec(password) === null)) return false;

    if (passwordRules.lowerCaseRequired && (lowerCaseRegEx.exec(password) === null)) return false;

    if (passwordRules.upperCaseRequired && (upperCaseRegEx.exec(password) === null)) return false;

    if (passwordRules.nonAlphaRequired && (nonAlphaRegEx.exec(password) === null)) return false;

    return true;
}