import styles from "./Tab.module.css";

interface ITabProps {
    text: string,
    children: JSX.Element | JSX.Element[] | undefined
}

export const Tab : React.FC<ITabProps> = ({
    text,
    children
}) => {

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.text}>{text}</div>
            </div>
             <div className={styles.content}>
                {children}
             </div>
        </div>
    )
}