import React from "react";
import styles from "./ChartContainer.module.css";

interface ChartContainerProps {
    children?: React.ReactNode
}
export const ChartContainer: React.FC<ChartContainerProps> = ({ children }) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    );
};
