import { Dates } from "../../../../utils/dates";
import styles from "./SelectedDateDisplay.module.css";

/**
 * Component displays a date value
 */
export const SelectedDateDisplay = ({
    dateMillis
}: {
    dateMillis?: number | null
}) => {

    return (
        <div className={styles.root}>
            <div>Selected Date : {dateMillis ? Dates.fromMillis(dateMillis).toString(Dates.DATE_FORMAT_SHORT) : '-'}</div>
        </div>
    )
}