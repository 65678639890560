/**
 * Hook to retrieve the Locations and Routes for a given Ship
 * over a given date range using a given date interval.
 */
import React from "react";
import { ShipLocationsAndRoutesModel } from "./Models";
import { api, useGetData } from "../../components";

/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel {
    installationId: number,
    fromMillis: number,
    toMillis: number,
    intervalMillis: number,
    timeStampsMillis: Array<number>,
    locations: Array<Array<number | null>>,
    routes: Array<Array<Array<number>>>,
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel): ShipLocationsAndRoutesModel{

    return {
        shipId: apiData.installationId,
        fromMillis: apiData.fromMillis,
        toMillis: apiData.toMillis,
        intervalMillis: apiData.intervalMillis,
        timeStampsMillis: apiData.timeStampsMillis,
        locations: apiData.locations.map((value) => {
            return {
                lat: value[0],
                lng: value[1],
            }
        }),
        routes: apiData.routes.map((value) => {
            return {
                locations: value.map((inner) => {
                    return {
                        lat: inner[0],
                        lng: inner[1]
                    }
                })
            }
        })
    }
}

/**
 * Hook for retrieving Ship Location data
 */
function useFetchShipLocations(installationId: number | undefined, fromMillis: number, toMillis: number, intervalMillis: number){

    const [url, setUrl] = React.useState<string>('');
    const {state} = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.metrics.get.installationLocations(installationId, fromMillis, toMillis, intervalMillis);

        setUrl(url);

    }, [installationId, fromMillis, toMillis, intervalMillis]);

    return {state};
}

export { useFetchShipLocations };