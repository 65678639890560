import React from "react";
import { api, fetchApiDelete, fetchHandler } from "../../../../dataServices/components";
import { Button } from "../../../buttons";
import styles from "./Email.module.css";
import { ErrorMessage } from "./ErrorMessage";

/**
 * A component which displays an Email address associated
 * with a client and allows the email address to be deleted
 */
export const Email = (props: {clientId: number, emailId: number, emailAddress: string, onDelete: (id: number) => void}) => {
    
    const {clientId, emailId, emailAddress, onDelete} = props;

    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const deleteUrl = api.urls.packages.delete.notificationEmails();

    function handleOnDelete(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        setErrorMessage('')
        setShowConfirm(true);
    }

    function handleCancel(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        setShowConfirm(false);
    }

    function handleConfirmDelete(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();

        setIsProcessing(true);
        setErrorMessage('');

        const data = {
            clientId: clientId,
            emailId: emailId,
            emailAddress: emailAddress
        }

        fetchApiDelete(deleteUrl, data)
        .then((deleteResponse) => {
            setIsProcessing(false);
            setShowConfirm(false);
            void fetchHandler(deleteResponse)
            .then((handlerResult) => {
                if (handlerResult.succeeded){
                    setErrorMessage('');
                    onDelete(emailId);
                } else {
                    setErrorMessage("Unable to delete email");
                }
            })
        })
        .catch(() => {
            setIsProcessing(false);
            setShowConfirm(false);
            setErrorMessage("Sorry - something went wrong")
        });
    }

    return (
        <div className={styles.root}>
            <div className={styles.addressContainer}>
                <div className={styles.address}>{emailAddress}</div>
            </div>
            <div className={styles.buttonContainer}>
                {!showConfirm &&
                    <Button onClick={handleOnDelete} disabled={showConfirm} text="Delete" color="danger" size="small" />
                }
                {showConfirm && 
                    <Button onClick={handleConfirmDelete} disabled={isProcessing} text="Confirm" size="small" color="success" />
                }
                {showConfirm && 
                    <Button onClick={handleCancel} disabled={isProcessing} text="Cancel" size="small" color="secondary" />
                }
            </div>
            {errorMessage && <ErrorMessage text={errorMessage}/>}
        </div>
    )
}
