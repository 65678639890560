import { Fragment, useContext } from "react";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import { ShipModel } from "../../../../../../dataServices/ships";
import Card from "../card/Card";
import { Client } from "../client/Client";

import styles from "./Content.module.css";

type Props = {
    isLoading: boolean,
    installations: Array<ShipModel> | undefined,
};

const Content : React.FC<Props> = ({
    isLoading,
    installations
}) => {

    const selectedShip = useContext(SelectedShipContext);

    function handleOnShipSelected(ship: ShipModel){
        selectedShip.setShip(ship);
    }

    function MakeShipList(installations: ShipModel[]) {

        // determine if we should show the client
        // details before the list of vessels for the
        // client. This relies on the data retrieved
        // from the API being ordered by Client Name
        // then by Installation Name
        let lastClientId: number | null = null;
        let showClient = false;

        return installations.map((installation) => {

            if (installation.clientId !== lastClientId) {
                lastClientId = installation.clientId;
                showClient = true;
            }
            else {
                showClient = false;
            }

            return(
                <Fragment key={`client-container-id-${installation.clientId}-${installation.installationId}`}>
                    {showClient &&
                        <Client
                            key={`client-id-${installation.clientId}`}
                            clientName={installation.clientName }
                        />
                    }
                    <Card
                        key={`ship-index-${installation.clientId}-${installation.installationId}`}
                        installation={installation}
                        isSelected={(selectedShip && selectedShip.ship) ? selectedShip.ship.installationId === installation.installationId : false}
                        onSelected={handleOnShipSelected}
                    />
                </Fragment>
            )
        })
    }

    return (
        <div className={styles.root}>

            {isLoading &&
                <div className={styles.loading}>Loading Ships...</div>
            }
            {!isLoading && installations !== undefined &&
                MakeShipList(installations)
            }
        </div>
    );
}

export default Content;