import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useEscapeKey } from "../../../../../../hooks";
import { Button } from "../../../../../buttons";
import styles from "./ClientActionMenu.module.css";

interface IClientActionMenuProps {
    onMove: () => void;
    onCopy: () => void;
    onCancel: () => void;
}

export const ClientActionMenu : React.FC<IClientActionMenuProps> = ({
    onMove,
    onCopy,
    onCancel,
}) => {
    // a bit belt and braces and will probably never be used as the rest
    // of the user management functionality isn't really keyboard
    // accessible - but interesting to use!
    useEscapeKey(onCancel);

    return (
        <ClickAwayListener onClickAway={onCancel} >
            <div className={styles.root} role="dialog">
                <div className={styles.buttonContainer}>
                    <Button
                        size="small"
                        color="secondary"
                        text="Move User"
                        onClick={onMove}
                    />
                </div>
                <div className={styles.buttonContainer} onClick={onCopy}>
                    <Button
                        size="small"
                        color="secondary"
                        text="Copy User"
                        onClick={onCopy}
                    />
                </div>
            </div>
        </ClickAwayListener>
    )
}
