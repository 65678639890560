import React from "react";

import {
    ChartContainer,
    ChartContent,
    ChartTitle,
    ErrorIndicator,
    LoadingIndicator,
    ContentAndKeyContainer,
} from "../components";
import { WanStatusesChartProps } from "./WanStatusesChartProps";
import { NoIps4Message } from "./NoIps4Message";
import { WanStatusesChartContent } from "./WanStatusesChartContent";

export const WanStatusesChart : React.FC<WanStatusesChartProps> = ({overallWidth, overallHeight, margins, showContent, chartState}) => {
    
    return (
        <ChartContainer>
            <ChartTitle title="WAN Statuses" uom="" />
            <ContentAndKeyContainer>
                {showContent ? 
                <>
                    <ChartContent overallHeight={overallHeight}>
                        {
                            chartState.isLoading ?
                                <LoadingIndicator/> : 
                                    chartState.isError ?
                                        <ErrorIndicator/> :
                                            <WanStatusesChartContent 
                                                chartData={chartState.data}
                                                overallWidth={overallWidth}
                                                overallHeight={overallHeight}
                                                margins={margins}
                                                />
                        }
                    </ChartContent>
                </>
                : 
                <NoIps4Message />
                }
            </ContentAndKeyContainer>
        </ChartContainer>
    )
}