import React from "react";
import { scaleUtc as d3scaleUtc, scaleLinear as d3scaleLinear } from "d3-scale";
import { max as d3max } from "d3-array";
import {
    BottomAxis, 
    VerticalBars,
    LeftAxis,
    TimeLine
} from "../components";
import { UploadSpeedsChartContentProps } from "./UploadSpeedsChartContentProps";

export const UploadSpeedsChartContent: React.FC<UploadSpeedsChartContentProps> = ({ chartData, margins, overallWidth, overallHeight}) => {

    if (chartData === null || typeof chartData === "undefined" || !chartData.series.length) return null;

    // the lowest value of the lower Time Stamp in the collection of data
    const minTimeStamp = chartData.fromMillis;

    // the highest value of the upper Time Stamp in the collection of data
    const maxTimeStamp = chartData.toMillis + chartData.intervalMillis;

    const maxValue = chartData.series.length ? d3max(chartData.series[0].chartItems.map(d => { return d.value; })) ?? 0 : 0;

    // a time based scale for the x axis
    const xScale = d3scaleUtc().domain([minTimeStamp, maxTimeStamp]).range([margins.marginLeft, overallWidth - margins.marginRight]);

    const timeLineWidth = xScale(new Date(chartData.fromMillis + chartData.intervalMillis)) - xScale(new Date(chartData.fromMillis));

    const contentHeight = overallHeight - margins.xAxisHeight;

    // a number scale for the y axis
    const yScale = d3scaleLinear().domain([0, maxValue]).rangeRound([contentHeight, 5]).nice();

    return (
        <>
            <VerticalBars
                height={contentHeight}
                color={chartData.series[0].color}
                xScale={xScale}
                yScale={yScale}
                data={chartData.series[0].chartItems.map(item => {return {tsLowerMillis: item.tsLowerMillis, tsUpperMillis: item.tsUpperMillis, offset: 0, value: item.value}})} />
            <LeftAxis
                width={margins.marginLeft}
                scale={yScale} 
                showTicks={true} />
            <BottomAxis
                width={overallWidth - margins.marginLeft - margins.marginRight}
                top={overallHeight - margins.xAxisHeight}
                scale={xScale} />
            <TimeLine
                height={contentHeight}
                width={timeLineWidth}
                scale={xScale}
                />
        </>
    );
};
