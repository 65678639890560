/**
 * TODO: should we use useCallback and useMemo to wrap our setters and context
 * value as per this article: https://devtrium.com/posts/how-use-react-context-pro
 */
import React from "react";
import { Dates } from "../../utils/dates";
import { MapDatePointContext } from "./MapDatePointContext";



const MapDatePointProvider = ({children} : {children:React.ReactNode}) => {

    const [dateMillis, setDateMillis] = React.useState<number>(Dates.today().millis());

    const value = {dateMillis, setDateMillis};

    return (
        <MapDatePointContext.Provider value={value}>
            {children}
        </MapDatePointContext.Provider>
    );
}

export {MapDatePointProvider};