import { useContext } from "react";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { logger } from "../../debug/consoleLogger";
import { NavBar } from "../navBar";

import { AuthContext } from "../../contexts/components/AuthContext";
import styles from "./AuthenticatedLayout.module.css";

export const AuthenticatedLayout = () => {
    const {user} = useContext(AuthContext);
    const outlet = useOutlet();
    const location = useLocation();

    if (user === null) {
        logger.log(`navigating to /`);
        return <Navigate to="/" replace state={{path: location.pathname}} />
    }

    return (
        <div className={styles.root}>
            <NavBar />
            {outlet}
            <ToastContainer />
        </div>
    )    
}
