import { useEffect, useState } from "react";
import { api, useGetData } from "../../components";
import { IScheduledChangeModel } from "./Models";

/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel{
    dailyAdjustment?: number,
    createdByEmail: string,
    endDateMillis: number,
    startDateMillis: number,
    ratePlanName: string
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel[]) : IScheduledChangeModel[] {
    return apiData.map((change) => {
            return {
                createdByEmail: change.createdByEmail,
                endDateMillis: change.endDateMillis,
                ratePlanName: change.ratePlanName,
                startDateMillis: change.startDateMillis,
                dailyAdjustment: change.dailyAdjustment    
            }
        })
}

/**
 * Hook to retrieve Historic Package Scheduled Changes data for a given Installation
 * and SABA Equipment Id and WAN Interface ID
 * 
 * @returns An object which defines the state of the data fetch.
 */
 export const useFetchHistoricScheduledChanges = (
    wanInterfaceId: number | undefined,
    requestId: number,
) => {

    const [url, setUrl] = useState<string>('');
    const {state} = useGetData(url, mapper, requestId);

    useEffect(() => {

        if (typeof wanInterfaceId === "undefined") return;

        const url = api.urls.packages.get.historicScheduledChanges(wanInterfaceId);

        setUrl(url);
        
    }, [wanInterfaceId]);

    return {state};
}