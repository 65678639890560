/**
 * Utility to display a formatted lat/lng value
 */

type Location = {
    position?: string,
    hemiSphere?: string,        
}

class Provider {
    constructor(lat?: number, lng?: number){
        this.lat = lat;
        this.lng = lng;
    }
    protected lat? = 0;
    protected lng? = 0;

    toString(){

        function format(value: number, decimalPlaces: number) : string{
            return new Intl.NumberFormat(
                navigator.language,
                {
                    minimumFractionDigits: decimalPlaces,
                    maximumFractionDigits: decimalPlaces,
                }
            ).format(value);        
        }

        function latToLocation(value?: number) : Location {
            return {
                position: value ? format(value, 3) : undefined,
                hemiSphere: value ? value >= 0 ? 'N' : 'S' : undefined
            };
        }

        function lngToLocation(value?: number) : Location {
            return {
                position: value ? format(value, 3) : undefined,
                hemiSphere: value ? value >= 0 ? 'E' : 'W' : undefined
            };
        }

        const latLoc = latToLocation(this.lat);
        const lngLoc = lngToLocation(this.lng);

        if (typeof latLoc.position === "undefined" || typeof lngLoc.position === "undefined") return "Unknown";

        return `${latLoc.position}${latLoc.hemiSphere}, ${lngLoc.position}${lngLoc.hemiSphere}`;
    }
}

class LatLng {
    static fromPoints(lat?: number, lng?: number ){
        return new Provider(lat, lng);
    }
}

export { LatLng };