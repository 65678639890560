import { useState } from "react";
import { Button } from "../../../../../buttons";
import { FormContainer } from "./FormContainer";
import styles from "./Form.module.css";

interface IAddEditFormProps {
    title: string;
    label: string;
    defaultValue?: string | undefined;
    placeHolder?: string | undefined;
    isProcessing: boolean;
    onOk: (text: string) => void;
    onCancel: () => void;
}

export const AddEditForm : React.FC<IAddEditFormProps> = ({
    title,
    label,
    defaultValue,
    placeHolder,
    isProcessing,
    onOk,
    onCancel
}) => {

    const [value, setValue] = useState<string | undefined>(defaultValue);

    // respond to changes of the input text box
    // by updating our value state
    const handleOnValueChange = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
    }

    // respond to user clicking the ok button
    const handleOkClick = () => {
        // TODO: need to have some validation checks here
        // and before
        if (value) {
            onOk(value);
        }
    }

    return (
        <FormContainer isProcessing={isProcessing}>
            <div className={styles.title}>{title}</div>
            <div className={styles.label}>{label}</div>
            <div>
                <input 
                    className={styles.input} 
                    type="text" 
                    placeholder={placeHolder} 
                    defaultValue={defaultValue}
                    onChange={handleOnValueChange}
                />
            </div>
            <div className={styles.buttons}>
                <Button 
                    size="small" 
                    onClick={handleOkClick} 
                    color="success" 
                    text="Ok" 
                    className={styles.button}
                />
                <Button 
                    size="small" 
                    onClick={onCancel} 
                    color="secondary" 
                    text="Cancel" 
                    className={styles.button}
                />
            </div>
        </FormContainer>
    )
}