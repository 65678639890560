import { useContext } from "react";
import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";
import { MapLocationsContext } from "../../../../contexts/components/MapLocationsContext";
import { LatLng } from "../../../../utils/latLng";

export const MapLocationDisplay = () => {

    const mapDatePoint = useContext(MapDatePointContext);
    const {locations, isLoading} = useContext(MapLocationsContext);

    return (
            <span>{isLoading ? "loading" : LatLng.fromPoints(locations.get(mapDatePoint?.dateMillis)?.lat??0, locations.get(mapDatePoint?.dateMillis)?.lng??0).toString()}</span>
    )
}