import { Column, Row, usePagination, useSortBy, useTable } from "react-table";
import { Pager } from "./Pager";

import styles from "./PagedTable.module.css";

interface IPagedTableProps<T extends object> {
    columns: readonly Column<T>[];
    data: readonly T[];
    getCustomRowProps?: (row: Row<T>) => ({});
    hiddenColumns?: string[];
}

export function PagedTable<T extends object>(props: IPagedTableProps<T>) {
    
    const {columns, data, getCustomRowProps, hiddenColumns} = props;

    const {
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        prepareRow, 
        page, 
        canPreviousPage, 
        canNextPage, 
        pageOptions, 
        pageCount, 
        gotoPage, 
        nextPage, 
        previousPage, 
        setPageSize, 
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5, hiddenColumns: hiddenColumns ? hiddenColumns : [] }
        },
        useSortBy,
        usePagination
    );    
    
    return (
        <div>
            <Pager
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                previousPage={previousPage}
                setPageSize={setPageSize}
            />
            <div className={styles.tableWrap}>
                <table {...getTableProps()} className={styles.table}>
                    <thead className={styles.thead}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th 
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={styles.th}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr 
                                    {...row.getRowProps([
                                        getCustomRowProps ? getCustomRowProps(row) : {}
                                ])}
                                >
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={styles.td}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}