import { useState } from "react";
import { ClientGroupViewModel, ClientViewModel, PriceListCollectionViewModel, PriceListViewModel } from "../ViewModels";
import { TreeItem } from "./TreeItem";
import { TreeRoot } from "./TreeRoot";
import styles from "./TreeView.module.css";

export const TreeView = ({
    clientGroups,
    clients,
    priceListCollections,
    priceLists,
    onClientGroupToggleExpand,
    onClientToggleExpand,
    onPriceListCollectionToggleExpand,
    onClientGroupSelected,
    onClientSelected,
    onPriceListCollectionSelected,
    onPriceListSelected,
} : {
    clientGroups: Array<ClientGroupViewModel>,
    clients: Array<ClientViewModel>,
    priceListCollections : Array<PriceListCollectionViewModel>,
    priceLists: Array<PriceListViewModel>,
    onClientGroupToggleExpand: (clientGroupId: number) => void,
    onClientToggleExpand: (clientId: number) => void,
    onPriceListCollectionToggleExpand: (clientId: number) => void,
    onClientGroupSelected: (clientGroupId: number) => void,
    onClientSelected: (clientId: number) => void,
    onPriceListCollectionSelected: (clientId: number) => void,
    onPriceListSelected: (priceListId: number) => void,
}) => {

    // this may need to be taken down a layer if changes cause
    // the clientGroup map to be repeated on change
    const [showContent, setShowContent] = useState<boolean>(true);
    
    const treeItems : React.ReactElement[] = clientGroups.map(clientGroup => {
        return (
            <TreeItem
                key={clientGroup.id}
                label={clientGroup.name}
                expanded={clientGroup.expanded}
                selected={clientGroup.selected}
                onToggleExpanded={() => onClientGroupToggleExpand(clientGroup.id)}
                onSelected={() => onClientGroupSelected(clientGroup.id)}
            >
                {clients
                    .filter(client => client.clientGroupId === clientGroup.id)
                    .map(client => {
                    return (
                        <TreeItem
                            key={client.id}
                            label={client.name}
                            expanded={client.expanded}
                            selected={client.selected}
                            onToggleExpanded={() => onClientToggleExpand(client.id)}
                            onSelected={() => onClientSelected(client.id)}
                        >
                            {priceListCollections
                                .filter(priceListCollection => priceListCollection.clientId === client.id)
                                .map(priceListCollection => {
                                return (
                                    <TreeItem
                                        key={priceListCollection.clientId}
                                        label="Price Lists"
                                        expanded={priceListCollection.expanded}
                                        selected={priceListCollection.selected}
                                        onToggleExpanded={() => onPriceListCollectionToggleExpand(priceListCollection.clientId)}
                                        onSelected={() => onPriceListCollectionSelected(priceListCollection.clientId)}
                                    >
                                        {priceLists
                                            .filter(priceList => priceList.clientId === priceListCollection.clientId)
                                            .map(priceList => {
                                            return (
                                                <TreeItem
                                                    key={priceList.id}
                                                    label={priceList.reference}
                                                    selected={priceList.selected}
                                                    onSelected={() => onPriceListSelected(priceList.id)}
                                                >
                                                <></>
                                                </TreeItem>
                                            )
                                        })}
                                    </TreeItem>
                                )
                            })}
                        </TreeItem>
                    )    
                })}
            </TreeItem>
        )
    })

    return (
        <div className={styles.root}>     
            {!showContent && 
                <div className={styles.header}>
                    <p>Price Lists</p>
                    <button onClick={() => setShowContent(prev => !prev)}>show</button>
                </div>
            }
            {showContent &&       
                <div className={styles.header}>
                    <h2 className={styles.heading}>Price Lists</h2>
                    <button onClick={() => setShowContent(prev => !prev)}>hide</button>
                </div>
            }
            {showContent && 
                <TreeRoot>
                    {treeItems}
                </TreeRoot>
            }
        </div>
    )        
}

