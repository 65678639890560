import React from "react";
import { api, useGetData } from "../components";
import { StarlinkPerformanceModel } from "./Models";

/**
 * Shape of the data that is returned by the remote API call
 */
interface IApiModel {
    installationId: number;
    fromMillis: number;
    toMillis: number;
    stateName: string[];
    statePercentage: number[];
    timeStampsMillis: number[];
    maxPopPingLatency: number[];
    minPopPingLatency: number[];
    averagePopPingLatency: number[];
    maxDownlinkRateMbps: number[];
    minDownlinkRateMbps: number[];
    averageDownlinkRateMbps: number[];
    maxUplinkRateMbps: number[];
    minUplinkRateMbps: number[];
    averageUplinkRateMbps: number[];
    maxPopPingDropRate: number[];
    minPopPingDropRate: number[];
    averagePopPingDropRate: number[];
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData
 * @returns
 */
function mapper(apiData: IApiModel): StarlinkPerformanceModel {
    return {
        shipId: apiData.installationId,
        fromMillis: apiData.fromMillis,
        toMillis: apiData.toMillis,
        stateName: apiData.stateName,
        statePercentage: apiData.statePercentage,
        timeStampsMillis: apiData.timeStampsMillis,
        maxPopPingLatency: apiData.maxPopPingLatency,
        minPopPingLatency: apiData.minPopPingLatency,
        averagePopPingLatency: apiData.averagePopPingLatency,
        maxDownlinkRateMbps: apiData.maxDownlinkRateMbps,
        minDownlinkRateMbps: apiData.minDownlinkRateMbps,
        averageDownlinkRateMbps: apiData.averageDownlinkRateMbps,
        maxUplinkRateMbps: apiData.maxUplinkRateMbps,
        minUplinkRateMbps: apiData.minUplinkRateMbps,
        averageUplinkRateMbps: apiData.averageUplinkRateMbps,
        maxPopPingDropRate: apiData.maxPopPingDropRate,
        minPopPingDropRate: apiData.minPopPingDropRate,
        averagePopPingDropRate: apiData.averagePopPingDropRate,
    }
}

/**
 * Hook to retrieve Starlink Performance data for a given ship over a given time range
 * @param installationId Id of the Installation whose data is to be retrieved
 * @param fromMillis Lower date range limit in Unix Epoch seconds.
 * @param toMillis Upper date range limit in Unix Epoch seconds.
 * @returns
 */
const useFetchStarlinkPerformance = (installationId: number | undefined, fromMillis: number, toMillis: number) => {

    const [url, setUrl] = React.useState<string>('');
    const { state } = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.starlink.get.performance(installationId, fromMillis, toMillis);

        setUrl(url);

    }, [installationId, fromMillis, toMillis]);

    return { state };
}

export { useFetchStarlinkPerformance }