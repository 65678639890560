import { useContext } from "react";
import { AuthContext } from "../../../../contexts/components/AuthContext";
import { useFetchClientNotificationEmails } from "../../../../dataServices/user/notificationEmails/useFetchClientNotificationEmails";
import SectionHeader from "../userSettings/SectionHeader";
import { ClientList } from "./ClientList";
import styles from "./NotificationEmails.module.css";

/**
 * A component which displays the additional Notification Emails that will
 * be sent when a Package Change Event Occurs.
 */
const NotificationEmails = () => {

    const { user } = useContext(AuthContext);

    if (user?.canSchedulePackageChanges) {
        return <Inner />
    }

    return null;
}

const Inner = () => {

    const { state } = useFetchClientNotificationEmails();

    return (
        <div className={styles.root}>
            <SectionHeader text="Package Change Notification Emails" />
            <div>The following email addresses will be notified when a Package Change event occurs</div>
            <ClientList state={state} />
        </div>
    )
}

export default NotificationEmails;