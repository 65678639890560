import { useState } from "react";
import { useDrop } from "react-dnd";
import { IClient, IInstallationGroup } from "../../data/Models";
import { ActionTypes } from "../../data/State";
import { Header } from "../common/Header";
import { HeaderIcon } from "../common/HeaderIcon";
import { IInstallationDnd } from "../../dnd/Installation";
import { ItemTypes } from "../../dnd/ItemTypes";
import { Installation } from "../installation/Installation";
import { DeleteInstallationGroup } from "./DeleteInstallationGroup";
import { RenameInstallationGroup } from "./RenameInstallationGroup";
import { moveInstallation } from "../../data/UpdateActions";

interface IInstallationGroupProps {
    clientGroupId: number;
    clientId: number;
    installationGroup: IInstallationGroup;
    holdingClient: IClient | undefined;
    dispatch: React.Dispatch<ActionTypes>;
}
export const InstallationGroup : React.FC<IInstallationGroupProps> = ({
    clientGroupId,
    clientId,
    installationGroup,
    holdingClient,
    dispatch,
}) => {

    const [isRenamingInstallationGroup, setIsRenamingInstallationGroup] = useState<boolean>(false);
    const [isDeletingInstallationGroup, setIsDeletingInstallationGroup] = useState<boolean>(false);
    const [showInstallations, setShowInstallations] = useState<boolean>(true);

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: ItemTypes.INSTALLATION,
            canDrop: (item) => item.installationGroupId !== installationGroup.id,
            drop: (item: IInstallationDnd) => 
                moveInstallation({
                    source: item,
                    target: {
                        clientGroupId,
                        clientId,
                        installationGroupId: installationGroup.id
                    },
                    dispatch
                }),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    )

    return (
        <li>
            {!isRenamingInstallationGroup && !isDeletingInstallationGroup &&
                <div ref={drop}>
                    <Header 
                        text={installationGroup.name} 
                        isExpanded={showInstallations}
                        onToggleExpanded={() => setShowInstallations(prev => !prev)}
                        canDrop={canDrop} 
                        isOver={isOver}
                    >
                        <HeaderIcon 
                            type="edit" 
                            onClick={() => setIsRenamingInstallationGroup(true)} 
                            title="Rename Fleet"
                        />
                        {installationGroup.installations.length === 0 &&
                            <HeaderIcon 
                                type="delete" 
                                onClick={() => setIsDeletingInstallationGroup(true)} 
                                title="Delete Fleet"
                            />
                        }
                    </Header>
                </div>
            }
            {isDeletingInstallationGroup &&
                <DeleteInstallationGroup
                    clientGroupId={clientGroupId}
                    clientId={clientId}
                    installationGroupId={installationGroup.id}
                    name={installationGroup.name}
                    onClose={() => setIsDeletingInstallationGroup(false)}
                    dispatch={dispatch}
                />
            }
            {isRenamingInstallationGroup &&
                <RenameInstallationGroup
                    clientGroupId={clientGroupId}
                    clientId={clientId}
                    installationGroupId={installationGroup.id}
                    name={installationGroup.name}
                    onClose={() => setIsRenamingInstallationGroup(false)}
                    dispatch={dispatch}
                />
            }
            <ul>
                {showInstallations && installationGroup.installations.map(
                    installation => 
                        <Installation 
                            key={installation.id}
                            clientGroupId={clientGroupId}
                            clientId={clientId}
                            installationGroupId={installationGroup.id}
                            installation={installation} 
                            holdingClient={holdingClient}
                            dispatch={dispatch}
                        />
                )}
            </ul>
        </li>
    )
}
