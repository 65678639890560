import { useState } from "react";
import { IClientGroup } from "../../data/Models";
import { ActionTypes } from "../../data/State";
import { Client } from "../client/Client";
import { Header } from "../common/Header";
import { HeaderIcon } from "../common/HeaderIcon";
import { AddClient } from "./AddClient";

interface IClientGroupProps {
    clientGroup: IClientGroup;
    dispatch: React.Dispatch<ActionTypes>;
}
export const ClientGroup : React.FC<IClientGroupProps> = ({
    clientGroup,
    dispatch,
}) => {
    
    const [isAddingClient, setIsAddingClient] = useState<boolean>(false);

    const holdingClient = clientGroup.clients.find(cg => cg.isVnoHoldingClient);

    return (
        <ul>
            <Header 
                text={clientGroup.name}
                isExpandable={false}
            >
                <HeaderIcon 
                    type="add" 
                    onClick={() => setIsAddingClient(true)} 
                    title="Add a Customer"
                />
            </Header>
            <ul>
                {isAddingClient &&
                    <AddClient 
                        clientGroupId={clientGroup.id}
                        onClose={() => setIsAddingClient(false)}
                        dispatch={dispatch}
                    />
                }
                {clientGroup.clients.filter(client => !client.isVnoHoldingClient).map(
                    client => 
                        <Client 
                            key={client.id} 
                            clientGroupId={clientGroup.id}
                            client={client} 
                            holdingClient={holdingClient}
                            dispatch={dispatch} 
                        />
                )}
            </ul>
        </ul>
    )
}
