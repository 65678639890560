import { notifyError } from "../../common/errors/Errors";
import { DataApi } from "./DataApi";
import { IInstallationDnd } from "../dnd/Installation";
import { Actions, ActionTypes } from "./State";

interface ImoveInstallationProps {
    source: IInstallationDnd;
    target: {
        clientGroupId: number;
        clientId: number;
        installationGroupId: number;    
    }
    dispatch: React.Dispatch<ActionTypes>;
}

export const moveInstallation = async (props: ImoveInstallationProps) => {

    const {source, target, dispatch} = props;

    let ok = false;

    try {
        const response = await DataApi.moveInstallation(source.installationId, target.installationGroupId);

        ok = response.ok;
    }
    catch (error) {
        console.error('There was an error', error);
    }

    if (ok) {
        dispatch({
            type: Actions.INSTALLATION_MOVED,
            data: {
                installationId: source.installationId,
                from: {                        
                    clientGroupId: source.clientGroupId,
                    clientId: source.clientId,
                    installationGroupId: source.installationGroupId,
                },
                to: {
                    clientGroupId: target.clientGroupId,
                    clientId: target.clientId,
                    installationGroupId: target.installationGroupId,
                }
            }
        })
    } else {
        notifyError();
    }
}
