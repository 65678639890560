import { Link } from "react-router-dom"

// use inline css for the logo so we can use environment variables to pick up the branding
const logoStyle = {
    backgroundImage: `url(/assets/${import.meta.env.VITE_BRANDING_NAV_BAR_LOGO_NAME})`,
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: import.meta.env.VITE_BRANDING_NAV_BAR_LOGO_HEIGHT as string,
    width: import.meta.env.VITE_BRANDING_NAV_BAR_LOGO_WIDTH as string,
}

export const LogoLink = () => {
    return (
        <Link to="/">
            <div style={logoStyle}/>
        </Link>
    )
}