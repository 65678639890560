import { useContext } from "react";
import { useNavigate } from "react-router";
import { UserAvatar } from "./UserAvatar";
import { Button } from "../../../buttons";
import { useEscapeKey } from "../../../../hooks";
import styles from "./UserAccount.module.css";
import { AuthContext } from "../../../../contexts/components/AuthContext";

const UserAccount = (
    {
        onCancel
    }:
    {
        onCancel: () => void
    }
) => {

    const {user, signOut} = useContext(AuthContext);
    const navigate = useNavigate();

    useEscapeKey(onCancel);

//    console.log("User ", user);

    return (
        <div className={styles.root}>
            <div><UserAvatar size="large"/></div>
            <div className={styles.userName}>{user?.userName}</div>                    
            <Button 
                text="Sign Out"
                className={styles.signOutButton}
                onClick={() => {
                    signOut(() => navigate("/"));
                }}
            />
        </div>
    );
}

export default UserAccount;