﻿///**
// * Extracts an environment variable value as an integer.
// * @param key Key of the environment variable to extract.
// * @returns The environment variable as an integer.
// * @throws Error if the value is not a valid integer or the value does not exist
// */
//function extractIntegerEnvVar(key: keyof NodeJS.ProcessEnv): number {
//    const stringValue = extractStringEnvVar(key);
//    const numberValue = parseInt(stringValue, 10);
//    if (Number.isNaN(numberValue)){
//        const message = `The environment variable "${key}" has to hold a stringified integer value - not ${stringValue}.`;
//        throw new Error(message);
//    }
//    return numberValue;
//}
export function ConvertToInteger(value: string): number {
    const numberValue = parseInt(value, 10);

    if (Number.isNaN(numberValue)) {
        const message = `The environment variable does not hold a stringified integer value`;
        throw new Error(message);
    }

    return numberValue;
}
