import styles from "./ChartContent.module.css";

interface ChartContentProps {
    overallHeight: number;
    children: React.ReactNode;
}

export const ChartContent = ({ overallHeight, children }: ChartContentProps) => {
    return (
        <svg height={overallHeight} className={styles.root}>
            {children}
        </svg>
    )
}