import classNames from "classnames";
import styles from "./TreeItem.module.css";
import { TreeRoot } from "./TreeRoot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export const TreeItem = ({
    label,
    expanded,
    selected,
    onToggleExpanded,
    onSelected,
    children,
} : {
    label: string,
    expanded?: boolean,
    selected: boolean,
    onToggleExpanded?: () => void | undefined,
    onSelected: () => void,
    children: React.ReactNode,
}) => {
    return (
        <li className={styles.treeItem}>
            <div className={styles.content}>
                {children &&
                    <button onClick={onToggleExpanded} className={styles.toggler}>
                        <FontAwesomeIcon icon={expanded ? faMinus : faPlus} />
                    </button>
                }
                <button onClick={onSelected} className={classNames(styles.label, selected ? styles.selected : '')}>
                    {label}
                </button>
            </div>
            {expanded && children &&
                <TreeRoot>
                    {children}
                </TreeRoot>
            }
        </li>
    )
}
