import React from "react";
import { api, useGetData } from "../components";
import { StarlinkStatusModel } from "./Models";

/**
 * Shape of the data that is returned by the remote API call
 */
interface IApiModel {
    timeMillis: number,
    performance: {
        popPingDropRateAverage?: number,
        popPingDropRateMaximum?: number,
        popPingDropRateMinimum?: number,
        popPingLatencyAverage?: number,
        popPingLatencyMaximum?: number,
        popPingLatencyMinimum?: number,
        downLinkAverage?: number,
        downLinkMaximum?: number,
        downLinkMinimum?: number,
        upLinkAverage?: number,
        upLinkMaximum?: number,
        upLinkMinimum?: number,
        snrAverage?: number,
        snrMaximum?: number,
        snrMinimum?: number,
    },
    alerts: {
        alertMotorsStuck?: boolean,
        alertThermalThrottle?: boolean,
        alertThermalShutdown?: boolean,
        alertMastNotNearVertical?: boolean,
        alertUnexpectedLocation?: boolean,
        alertSlowEthernetSpeeds?: boolean,
        alertRoaming?: boolean,
        alertInstallPending?: boolean,
        alertIsHeating?: boolean,
        alertPowerSupplyThermalThrottle?: boolean,
        alertIsPowerSaveIdle?: boolean,
        alertMovingWhileNotMobile?: boolean,
        alertMovingTooFastForPolicy?: boolean,
        alertDbfTelemStale?: boolean,
        alertLowMotorCurrent?: boolean,
    },
    status: {
        id?: string,
        hardwareVersion?: string,
        softwareVersion?: string,
        state?: string,
        uptime?: number,
        secFirstNonEmptySlot?: number,
        popPingDropRate?: number,
        downlink?: number,
        uplink?: number,
        popPingLatency?: number,
        alerts?: number,
        fractionObstructed?: number,
        currentlyObstructed?: boolean,
        obstructionDuration?: number,
        obstructionInterval?: number,
        azimuth?: number,
        elevation?: number,
        snrAboveNoise?: boolean,
    }
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData
 * @returns
 */
function mapper(apiData: IApiModel): StarlinkStatusModel {
    return { ...apiData };
}

/**
 * Hook to retrieve Starlink Performance data for a given ship
 * @param installationId Id of the Installation whose data is to be retrieved
 * @returns
 */
const useFetchStarlinkStatus = (installationId: number | undefined) => {

    const [url, setUrl] = React.useState<string>('');
    const { state } = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.starlink.get.status(installationId);

        setUrl(url);

    }, [installationId]);

    return { state };
}

export { useFetchStarlinkStatus };