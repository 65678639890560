import styles from "./LoadingIndicator.module.css";

export const LoadingIndicator = ({
    visible,
    vesselName
}: {
        visible: boolean,
    vesselName?: string
}) => {

    if (!visible) return null;

    return (
        <div className={styles.root}>
            <div className={styles.text}>
                Loading location information for {vesselName}
            </div>
        </div>
    )
}