/**
 * Rounds a number to a given number of decimal places.
 * @param n Number to round
 * @param dp Number of decimal places (default of 2)
 * @returns A number
 */
export function froundToNumber(n: number,dp=2) : number {
    return Math.round(Math.round(n*10**(dp+1))/10)/10**dp
}

/**
 * Rounds a number to a given number of decimal places.
 * @param n Number to round
 * @param dp Number of decimal places (default of 2)
 * @returns A string representing the rounded number displayed using the browser's locale.
 */
export function froundToIntlString(n: number, dp = 2) : string {
    return new Intl.NumberFormat(navigator.language, {minimumFractionDigits: +dp, maximumFractionDigits: +dp}).format(n);
}
