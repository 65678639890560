import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ReactElement } from "react";
import { Day } from "./Day";
import { Dates } from "../../../../utils/dates";
import styles from "./Month.module.css";

/**
 * Function builds the month including a header and days
 */
 const build = (
    baseDateMillis: number, 
    todayMillis: number,
    onClick: (dateMillis: number) => void,
    onMouseEnter: (dateMillis: number) => void,
    onMouseLeave: () => void,
    selectedDateMillis?: number | null, 
    minimumDateMillis?: number | null,
    maximumDateMillis?: number | null,
    ) =>{

    const content: ReactElement[] = [];

    // first day of the month in which the 'start' date is located
    let day = Dates.fromMillis(baseDateMillis).startOfMonth();

    // month in which the 'start' date is located
    const thisMonth = Dates.fromMillis(baseDateMillis).month();

    // walk backwards if required to find the Monday prior to the first day
    // 1 = Monday
    while (day.weekDay() !== 1){
        day = day.subtractDays(1);
    }

    // build a header row
    const dayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

    content.push(<div key="header" className={styles.dayNames}>{
        dayNames.map((day, index) => {
            return <span key={`header-${index}`} className={styles.dayName}>{day}</span>
        })}</div>
    );

    // build rows and columns for each 'week' in the month
    for (let row = 0; row < 6; row++){
        const rowContent = []
        for (let col = 0; col < 7; col++){
            rowContent.push(
                <Day 
                    key={day.millis()}
                    dateMillis={day.millis()} 
                    isVisible={day.month() === thisMonth}
                    isToday={day.millis() === todayMillis}
                    isSelectable={(minimumDateMillis ? day.millis() >= minimumDateMillis : true) && (maximumDateMillis ? day.millis() <= maximumDateMillis : true)}
                    isSelected={selectedDateMillis ? day.millis() === selectedDateMillis : false }
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            )        
            day = day.addDays(1);
        }
        content.push(<div key={row} className={styles.row}>{rowContent}</div>)
    }

    return content;
}

/**
 * Component displays a month of days together with mechanisms for signalling that 
 * the month should be increased or decreased and that a day has been selected.
 */
export const Month  = ({
    firstDateOfMonthMillis,
    todayMillis,
    selectedDateMillis,
    minimumDateMillis,
    maximumDateMillis,
    showMonthDecrease,
    showMonthIncrease,
    onDecrease,
    onIncrease,
    onDayClick,
    onDayMouseEnter,
    onDayMouseLeave,
}: {
    firstDateOfMonthMillis: number,
    todayMillis: number,
    selectedDateMillis: number,
    minimumDateMillis: number | null,
    maximumDateMillis: number | null,
    showMonthDecrease: boolean,
    showMonthIncrease: boolean,
    onDecrease: () => void,
    onIncrease: () => void,
    onDayClick: (dateMillis: number) => void,
    onDayMouseEnter: (dateMillis: number) => void,
    onDayMouseLeave: () => void,
}) => {

    const firstDateOfMonth = Dates.fromMillis(firstDateOfMonthMillis);

    return (
        <div className={styles.root}>
            <div className={styles.monthSelectorContainer}>
                <FontAwesomeIcon 
                    className={classNames(styles.monthSelectorButton, !showMonthDecrease && styles.hidden)}
                    icon={faAngleLeft} 
                    size="1x" 
                    onClick={onDecrease} 
                    title="Previous Month"
                />

                <label className={styles.monthSelectorMonthName}>{firstDateOfMonth.monthNameLong()} {firstDateOfMonth.year()}</label>

                <FontAwesomeIcon 
                    className={classNames(styles.monthSelectorButton, !showMonthIncrease && styles.hidden)}
                    icon={faAngleRight} 
                    size="1x" 
                    onClick={onIncrease} 
                    title="Next Month"
                />
            </div>
            <div className={styles.column}>
                {build(
                    firstDateOfMonth.millis(),
                    todayMillis,
                    onDayClick,
                    onDayMouseEnter,
                    onDayMouseLeave,
                    // strip off any time component to allow the Day comparison to work correctly
                    Dates.fromMillis(selectedDateMillis).startOfDay().millis(),
                    minimumDateMillis,
                    maximumDateMillis
                )}
            </div>
        </div>
    );
}