import { PriceListViewModel } from "../ViewModels";
import { DateTableDataCell, TextTableDataCell } from "../tables";
import styles from "./PriceListTableRow.module.css";

export const PriceListTableRow = ({
    priceList,
    onSelected
} : {
    priceList: PriceListViewModel,
    onSelected: (id: number) => void,
}) => {

    const handleRowDoubleClick = () => {
        onSelected(priceList.id);
    }

    return (
        <tr key={priceList.id} onDoubleClick={handleRowDoubleClick} className={styles.row}>
            {/* <TextTableDataCell text="view" title="view"/> */}
            <TextTableDataCell text={priceList.reference} title="price list"/>
            <DateTableDataCell value={priceList.createdDate} title="created date"/>
            <DateTableDataCell value={priceList.savedDate} title="saved date"/>
        </tr>
    )
}
