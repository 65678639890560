import classNames from "classnames";
import { useDrop } from "react-dnd";
import { IInstallationDnd } from "../../dnd/Installation";
import { ItemTypes } from "../../dnd/ItemTypes";
import { ActionTypes } from "../../data/State";
import { moveInstallation } from "../../data/UpdateActions";
import styles from "./Header.module.css";

interface IHeaderProps {
    clientGroupId: number;
    clientId: number;
    installationGroupId: number;
    dispatch: React.Dispatch<ActionTypes>;
}


export const Header : React.FC<IHeaderProps> = ({
    clientGroupId,
    clientId,
    installationGroupId,
    dispatch,
}) => {

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: ItemTypes.INSTALLATION,
            canDrop: (item) => item.installationGroupId !== installationGroupId,
            drop: (item: IInstallationDnd) => 
                moveInstallation({
                    source: item, 
                    target: {
                        clientGroupId, 
                        clientId, 
                        installationGroupId
                    },
                    dispatch
                }),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    )

    return (
        <li className={styles.li} ref={drop}>
            <div className={styles.root}>
                <div className={`${classNames(styles.inner, canDrop && styles.canDrop, (isOver && canDrop) && styles.isOver )}`}>
                    <div>Unassigned Vessels</div>
                </div>
            </div>
        </li>
    )
}