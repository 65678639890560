import React from "react";
import { INotificationEmail } from "../../../../dataServices/user/notificationEmails/Models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import styles from "./Client.module.css";
import { EmailList } from "./EmailList";

/**
 * Sorts an array of INotificationEmail 
 * items by ascending email address
 */
function SortEmails(emails: INotificationEmail[]){
    if (emails === null || emails.length < 1) return [];
    return emails.sort((a, b) => {
        const emailA = a.email.toUpperCase();
        const emailB = b.email.toUpperCase();
        if (emailA < emailB) return -1;
        if (emailA > emailB) return 1;
        return 0;
    });
}

/**
 * A component that displays information about a Client
 * and its associated Notification Emails
 */
export const Client = (
    { id, name, emails }
        :
    {
        id: number,
        name: string,
        emails: INotificationEmail[]
        }
) => {

    const [data, setData] = React.useState<INotificationEmail[]>(SortEmails(emails));
    const [show, setShow] = React.useState<boolean>(false);

    const handleOnAdd = (id: number, email: string) => {
        setData(prev => {return SortEmails(prev.concat([{id, email}]))});
    }

    const handleOnDelete = (id: number) => {
        setData(prev => {return SortEmails(prev.filter(item => item.id !== id))});
    }

    const handleShowHide = () => {
        setShow(prev => !prev);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.name}>{name}</div>
                <button onClick={handleShowHide} className={styles.showHideButton}>
                    <FontAwesomeIcon icon={show? faAngleUp : faAngleDown}/>
                    <span className={styles.count}>{data.length}</span>
                </button>
            </div>
            <EmailList visible={show} emails={data} clientId={id} onAdd={handleOnAdd} onDelete={handleOnDelete} />
       </div>
    )
}

