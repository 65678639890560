import { useContext, useMemo } from "react";
import { Layer, Source } from "react-map-gl";
import { BeamContoursContext } from "../../../../contexts/components/BeamContoursContext";

export const BeamContours = (
    {
        showSatelliteBeams,
        showServiceAreas,
    }:
    {
            showSatelliteBeams: boolean,
            showServiceAreas: boolean,
    }
) => {

    const { beamContours } = useContext(BeamContoursContext);

    const content = useMemo(() => 
        beamContours
            .map((beamContour, index) => {

                const visible =
                    beamContour.contourType === "satellite_beam"
                        ? showSatelliteBeams
                        : beamContour.contourType === "service_area"
                            ? showServiceAreas
                            : false;

                return (
                    <Source 
                        key={`beam-contour-${index}`} 
                        type="geojson" 
                        data={{
                            type: "Feature",
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    beamContour.coordinates
                                ]
                            },
                            properties: {}
                        }} >
                        <Layer
                            key={`beam-fill-layer-${index}`}
                            beforeId="ship-route-layer-id"
                            type="fill"
                            paint={{
                                "fill-opacity": beamContour.fillOpacity,
                                "fill-color": beamContour.fillColour
                            }}
                            layout={{
                                visibility: visible ? "visible" : "none"
                            }}
                        />
                        <Layer 
                            key={`beam-outline-layer-${index}`}
                            beforeId="ship-route-layer-id"
                            type="line"
                            paint={{
                                "line-color": beamContour.colour,
                                "line-width": beamContour.outlineWidth,
                                "line-opacity": beamContour.opacity
                            }}
                            layout={{
                                visibility: visible ? "visible" : "none"
                            }}
                        />
                    </Source>)
            }), [beamContours, showSatelliteBeams, showServiceAreas]);  

    return (<>{content}</>);

}