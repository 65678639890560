import { DateTime, Info } from "luxon";

class Provider {

    dt: DateTime;
    
    constructor(millis: number) {
        this.dt = DateTime.fromMillis(millis).toUTC();
    }

    toString(dateFormat: string) {
        return this.dt.toUTC().toFormat(dateFormat);
    }

    startOfDay() {
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, 0, 0, 0);
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    endOfDay() {
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, 23, 59, 59);
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    nextDay() {
        const d = this.dt.plus({ days: 1 }).toUTC();
        const s = DateTime.utc(d.year, d.month, d.day);
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    previousDay() {
        const d = this.dt.minus({ days: 1 }).toUTC();
        const s = DateTime.utc(d.year, d.month, d.day);
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    addDays(value: number) {
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, this.dt.hour, this.dt.minute, this.dt.second, this.dt.millisecond).plus({ days: value });
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    subtractDays(value: number) {
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, this.dt.hour, this.dt.minute, this.dt.second, this.dt.millisecond).minus({ days: value });
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;        
    }

    startOfMonth() {
        const s = DateTime.utc(this.dt.year, this.dt.month, 1, 0, 0, 0);
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    endOfMonth() {
        const s = DateTime.utc(this.dt.year, this.dt.month, 1, 0, 0, 0).endOf('month');
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    addMonths(value: number){
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, this.dt.hour, this.dt.minute, this.dt.second, this.dt.millisecond).plus({months: value});
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    subtractMonths(value: number){
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, this.dt.hour, this.dt.minute, this.dt.second, this.dt.millisecond).minus({months: value});
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    subtractMillis(value: number) {
        const s = DateTime.utc(this.dt.year, this.dt.month, this.dt.day, this.dt.hour, this.dt.minute, this.dt.second, this.dt.millisecond).minus({milliseconds: value});
        this.dt = DateTime.fromMillis(s.toMillis()).toUTC();
        return this;
    }

    diffInDays(otherDateMillis: number){
        return this.dt.toUTC().startOf('day').diff(DateTime.fromMillis(otherDateMillis).toUTC().startOf('day'), 'days').days;
    }

    millis() {
        return this.dt.toUTC().toMillis();
    }

    year() {
        return this.dt.toUTC().year;
    }

    month() {
        return this.dt.toUTC().month;
    }

    monthNameLong() {
        return this.dt.toUTC().monthLong;
    }

    day() {
        return this.dt.toUTC().day;
    }

    weekDay() {
        return this.dt.toUTC().weekday;
    }

    isValid() {
        return this.dt.isValid;
    }
}

class Dates {
    /**
     * Creates a new Date based on Unix Epoch milliseconds
     * @param {number} millis Unix Epoch milliseconds value
     */
    static fromMillis(millis: number) {
        return new Provider(millis);
    }

    /**
     * Creates a new Date based on the supplied date string 
     * in the form YYYY-MM-DD HH:mm:ss.zzz 
     * */
    static fromString(value: string) {
        const year = +(value.substring(0, 4));
        const month = +(value.substring(5, 7));
        const day = +(value.substring(8, 10));
        const hour = +(value.substring(11, 13));
        const minute = +(value.substring(14, 16));
        const second = +(value.substring(17, 19));
        const millis = +(value.substring(20));

        const s = DateTime.utc(year, month, day, hour, minute, second, millis);

        return new Provider(s.toMillis());
    }

    /**
     * Creates a new Date based on the supplied date values.
     * Months should be 1 based i.e. 1 = January
     */
    static fromValues(year: number, month: number, day: number) {
        const s = DateTime.utc(year, month, day);
        
        return new Provider(s.toMillis());
    }

    /**
     * Creates a new Date with the current date and time.
     * */
    static now() {
        return new Provider(DateTime.utc().toMillis());
    }

    /**
     * Creates a new Date which is the start of Today (UTC)
     * */
    static today() {
        const dt = DateTime.utc();
        const s = DateTime.utc(dt.year, dt.month, dt.day, 0, 0, 0);
        return new Provider(s.toMillis());
    }

    /**
     * Creates a new Date which is the start of Tomorrow (UTC)
     * */
    static tomorrow() {
        const dt = DateTime.utc().plus({ days: 1 });
        const s = DateTime.utc(dt.year, dt.month, dt.day, 0, 0, 0);
        return new Provider(s.toMillis());
    }

    /**
     * Creates a new Date which is the start of Yesterday (UTC)
     * */
    static yesterday() {
        const dt = DateTime.utc().minus({ days: 1 });
        const s = DateTime.utc(dt.year, dt.month, dt.day, 0, 0, 0);
        return new Provider(s.toMillis());
    }

    /**
     * Returns the milliseconds that represent the time value 23:59:59
     * */
    static endTimeMillis() {
        /* (23 * 60 * 60 * 1000) + (59 * 60 * 1000) + (59 * 1000) */
        return 86399000
    }

    static monthNameShort(month: number): string{
        return (DateTime.utc(1970, month, 1)).toFormat("MMM");
    }

    static monthNameLong(month: number): string {
        return Info.months('long')[month];
    }

    static DATE_FORMAT_LONG_WITH_MILLISECONDS = "yyyy-LLL-dd HH:mm:ss.SSS 'UTC'";
    static DATE_FORMAT_LONG_WITHOUT_MILLISECONDS = "yyyy-LLL-dd HH:mm:ss 'UTC'";
    static DATE_FORMAT_SHORT_WITHOUT_SECONDS = "yyyy-LLL-dd HH:mm 'UTC'";
    static DATE_FORMAT_SHORT = "yyyy-LLL-dd";
    static DATE_FORMAT_TIME = "HH:mm:ss 'UTC'";
    static DATE_FORMAT_AERIS_WEATHER_TILES = "yyyyMMddHHmmss";
    static DATE_FORMAT_YEAR_MONTH = "yyyy-LLL";
    
    static MILLIS_FOR_MINUTES_1 = 60000;
    static MILLIS_FOR_MINUTES_5 = 300000;
    static MILLIS_FOR_MINUTES_20 = 1200000;
    static MILLIS_FOR_MINUTES_30 = 1800000;
    static MILLIS_FOR_MINUTES_60 = 3600000;
    static MILLIS_FOR_HOURS_1 = Dates.MILLIS_FOR_MINUTES_60;
    static MILLIS_FOR_HOURS_24 = 86400000;
    static MILLIS_FOR_DAYS_1 = Dates.MILLIS_FOR_HOURS_24;
    static MILLIS_FOR_DAYS_7 = 604800000;
    static MILLIS_FOR_DAYS_30 = 2592000000;
}

export { Dates };