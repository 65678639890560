import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ContainerProvider } from 'brandi-react';
import { createContainer} from "brandi";
import { TOKENS } from "./ui/pages/vno/prices/data/Repository";
import { PriceListRepository } from './ui/pages/vno/prices/data/Repository';

const container = createContainer();

container.bind(TOKENS.priceListRepository).toInstance(PriceListRepository).inTransientScope();

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <ContainerProvider container={container}>
      <DndProvider backend={HTML5Backend}>
        <App/>
      </DndProvider>
    </ContainerProvider>
  </React.StrictMode>,
);