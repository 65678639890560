import { useState } from "react";
import { DataApi } from "../../data/DataApi";
import { Actions, ActionTypes } from "../../data/State";
import { DeleteForm } from "../common/DeleteForm"
import { notifyError, notifyErrorMessage } from "../../../common/errors/Errors";

interface IDeleteClientProps {
    clientGroupId: number;
    clientId: number;
    name: string;
    onClose: () => void;
    dispatch: React.Dispatch<ActionTypes>;
}

export const DeleteClient : React.FC<IDeleteClientProps> = ({
    clientGroupId,
    clientId,
    name,
    onClose,
    dispatch,
}) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleOk = async () => {

        setIsSaving(true);

        let ok = false;
        let usersExist = false;

        try {
            const response = await DataApi.deleteClient(clientId);

            ok = response.ok;

            const text = await response.text();

            usersExist = !ok && response.status === 409 && text === "\"Client has associated Users\"";
        }
        catch (error){
            console.error("There was an error", error);
        }

        if (ok) {
            dispatch({
                type: Actions.CLIENT_DELETED,
                data: {
                    clientGroupId: clientGroupId,
                    clientId: clientId
                }
            });
            onClose();
        } else {
            if (usersExist) {
                notifyErrorMessage("The Client cannot be deleted because it has Users associated with it");
            } else {
                notifyError();
            }
        }

        setIsSaving(false);
    }

    return (
        <DeleteForm 
            label={name}
            isProcessing={isSaving}
            onOk={handleOk}
            onClose={onClose} 
        />
    )
}