import { ContentAndKeyContainer } from "./ContentAndKeyContainer"
import { ChartContainer } from "./ChartContainer"
import { ChartTitle } from "./ChartTitle"
import { ChartContent } from "./ChartContent"
import { ChartMargins } from "./ChartMargins";
import { LoadingIndicator } from "./LoadingIndicator";
import { ErrorIndicator } from "./ErrorIndicator";
import { Fragment } from "react";
import { BottomAxis } from "./BottomAxis";
import { LeftAxis } from "./LeftAxis";

import { scaleUtc as d3scaleUtc, scaleLinear as d3scaleLinear } from "d3-scale";
import { max as d3max } from "d3-array";
import { VerticalBars } from "./VerticalBars";
import { RightAxis } from "./RightAxis";
import { Area } from "./Area";

interface ChartData {
    timeStampMillis: number;
    value: number;
}

interface DataVolumesChartState {
    isLoading: boolean;
    isError: boolean;
    data: ChartData[];
}
interface DataVolumesChartProps {
    overallHeight: number;
    overallWidth: number;
    chartMargins: ChartMargins;
    chartState: DataVolumesChartState;
}

export const DataVolumesChart = ({ overallHeight, overallWidth, chartMargins, chartState }: DataVolumesChartProps) => {
    return (
        <ChartContainer>
            <ChartTitle title="Traffic Data" uom="(GB)" />
            <ContentAndKeyContainer>
                <ChartContent overallHeight={overallHeight}>{
                    chartState.isLoading
                        ? <LoadingIndicator />
                        : chartState.isError
                            ? <ErrorIndicator />
                            : <DataVolumesChartContent
                                overallHeight={overallHeight}
                                overallWidth={overallWidth}
                                chartMargins={chartMargins}
                                chartData={chartState.data}
                            />
                }
                </ChartContent>
            </ContentAndKeyContainer>
        </ChartContainer>
    )
}

interface DataVolumesChartContentProps {
    overallHeight: number;
    overallWidth: number;
    chartMargins: ChartMargins;
    chartData: ChartData[];
}

const DataVolumesChartContent = ({ overallHeight, overallWidth, chartMargins, chartData }: DataVolumesChartContentProps) => {

    if (chartData === null || chartData === undefined) return null;
    if (chartData.length === 0) return null;

    const minTimeStamp = chartData[0].timeStampMillis;
    const maxTimeStamp = chartData[chartData.length - 1].timeStampMillis;
    const maxValue = d3max(chartData, d => d.value) ?? 0;
    const valueSum = chartData.reduce((a, c) => a + c.value, 0);

    const chartAreaWidth = overallWidth - chartMargins.marginLeft - chartMargins.marginRight;

    // a time based scale for the x axis
    const xScale = d3scaleUtc()
        .domain([minTimeStamp, maxTimeStamp])
        .range([chartMargins.marginLeft + 20, overallWidth - chartMargins.marginRight - 20]);

    const contentHeight = overallHeight - chartMargins.xAxisHeight;

    // a number scale for the left y axis
    const leftYScale = d3scaleLinear()
        .domain([0, maxValue])
        .range([contentHeight, 5])
        .nice();

    const rightYScale = d3scaleLinear()
        .domain([0, valueSum])
        .range([contentHeight, 5])
        .nice();

    function createRunningTotalValues(data: ChartData[]): ChartData[] {
        let sum = 0;
        return data.map((d) => ({
            timeStampMillis: d.timeStampMillis,
            value: (sum += d.value)
        }));
    }

    return (
        <Fragment>
            <LeftAxis
                x={chartMargins.marginLeft}
                showTicks={true}
                scale={leftYScale}
            />
            <BottomAxis
                width={chartAreaWidth}
                y={overallHeight - chartMargins.xAxisHeight}
                scale={xScale}
            />
            <RightAxis
                x={overallWidth - chartMargins.marginRight}
                showTicks={true}
                scale={rightYScale}
            />
            <Area
                color='pink'
                data={createRunningTotalValues(chartData)}
                xScale={xScale}
                yScale={rightYScale}
            />
            <VerticalBars
                color='purple'
                data={chartData}
                barWidth={20}
                height={contentHeight}
                xScale={xScale}
                yScale={leftYScale}
            />
        </Fragment>
    )
}
