import React from "react";
import styles from "./Navigation.module.css";

interface NavigationProps {
    children?: React.ReactNode
}
const Navigation : React.FC<NavigationProps> = ({children}) => {
    return (
        <div className={styles.root}>
            <ul className={styles.list}>
                {children}
            </ul>
        </div>
    )
}

export { Navigation };