﻿import styles from "./ChartContainer.module.css";

interface ChartContainerProps {
    children: React.ReactNode;
}

export const ChartContainer = ({ children }: ChartContainerProps) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    );
};
