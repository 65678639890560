import { useContext } from "react";
import { MapDatePointDisplay } from "../../../mapDatePointDisplay/MapDatePointDisplay";
import { MapLocationDisplay } from "../../../mapLocationDisplay/MapLocationDisplay";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import styles from "./FeedbackBar.module.css";

const FeedbackBar = () => {

    const { ship } = useContext(SelectedShipContext);

    return (
        <div className={styles.root}>
            <div className={styles.shipName}>
                {ship ? ship.name : "NOTHING SELECTED"}
            </div>
            {ship &&
                <div className={styles.location}>
                    <span>Location @ <MapDatePointDisplay showUpperValue={false}/> : <MapLocationDisplay /></span>
                </div>
            }
        </div>
    );
}

export default FeedbackBar;