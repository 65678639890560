import styles from "./Input.module.css";

interface IInputProps {
    name: string; 
    placeholder: string; 
    type: string; 
    maxLength?: number; 
    disabled: boolean; 
    required?: boolean; 
    errorMessage?: string;
    autoFocus?: boolean;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
}
export const Input : React.FC<IInputProps> = ({  
    name,
    placeholder,
    type,
    maxLength,
    disabled,
    required = false,
    errorMessage,
    autoFocus,
    onChange,
}) => {

    return (
        <div className={styles.inputOuterContainer}>
            <div className={styles.inputInnerContainer}>
                <div className={styles.inputInputContainer}>
                    <input
                        className={styles.input}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        type={type}
                        maxLength={maxLength} 
                        autoFocus={autoFocus}
                        onChange={onChange}
                        />
                </div>
                {/* <div className={styles.inputRequiredContainer}>
                    {required 
                        ? <div className={styles.inputRequiredIndicator} title="Please fill in this field">&#42;</div> 
                        : <div>&#160;</div>
                    }
                </div> */}
            </div>
            <div className={styles.inputErrorMessage}>
                {errorMessage}
            </div>
        </div>
    );
}
