import React from "react";
import classnames from "classnames";

import { ShipModel } from "../../../../../../dataServices/ships";
import { options } from "../../../../../../utils/environment";
import { Statuses } from "./Statuses";
import { StatusIndicator } from "./StatusIndicator";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { api } from "../../../../../../dataServices/components";

import { useInterval } from "../../../../../../hooks";
import { authenticatedGetOptions } from "../../../../../../dataServices/components/fetchOptions";

import styles from "./Card.module.css";

/**
 * Card Selector
 */
const CardSelector = ({ 
    installationId,
    installationName, 
    isSelected,
    onClick
}: {
    installationId: number,
    installationName: string,
    isSelected: boolean,
    onClick: (id: number) => void,
}) => {

    const className = `${classnames(styles.selectorCheckMark, isSelected && styles.isSelected)}`;

    function handleOnClick(){
        onClick(installationId);
    }

    return (
        <label className={styles.selectorRoot}>
            <input 
                type="checkbox" 
                name="card_selector" 
                checked={isSelected}
                className={className}
                onChange={handleOnClick}
            />
            <span>
                {installationName}{ options.shouldShowInstallationSelectorDebug &&<span> - {installationId}</span>}
            </span>
        </label>

    );
}

interface IStatusMessage {
    installationId: number,
    statusCheckTimeMillis: number,
    wanStatus: string,
    wanStatusObservationTimeMillis: number | null,
    locationLat: number | null,
    locationLng: number | null,
    locationObservationTimeMillis: number | null,
}

/**
 * Card Header
 */
const CardHeader = ({
    installation, 
    isSelected, 
    onSelectorClick,
}: {
    installation: ShipModel,
    isSelected: boolean,
    onSelectorClick: (id: number) => void,
}) => {
    
    const [refresh, setRefresh] = React.useState<number>(0);

    // true if the status panel is visible
    const [statusIsExpanded, setStatusIsExpanded] = React.useState<boolean>(false);

    // status information, defaulted to the initial values provided with the installion
    const [statusCheckedAtMillis, setStatusCheckedAtMillis] = React.useState<number | null>(installation.statusCheckMillis);
    const [wanStatus, setWanStatus] = React.useState<string | null>(installation.wanStatus);
    const [wanStatusObservationMillis, setWanStatusObservationMillis] = React.useState<number | null>(installation.wanStatusObservationMillis);
    const [locationLat, setLocationLat] = React.useState<number | null>(installation.locationLat);
    const [locationLng, setLocationLng] = React.useState<number | null>(installation.locationLng);
    const [locationObservationMillis, setLocationObservationMillis] = React.useState<number | null>(installation.locationObservationMillis);

    useInterval(() => {setRefresh(prev => prev + 1)}, options.installationStatusRefreshIntervalMillis);

    // effect will update the status of the installation
    React.useEffect(() => {
        if (!options.installationStatusRefreshEnabled) return;

        const foo = async () => {
            try {
                const response = await fetch(api.urls.installations.get.latestStatus(installation.installationId), authenticatedGetOptions);

                if (response.ok) {
                    const status: IStatusMessage = await response.json() as IStatusMessage;

                    setStatusCheckedAtMillis(status.statusCheckTimeMillis);
                    setWanStatus(status.wanStatus);
                    setWanStatusObservationMillis(status.wanStatusObservationTimeMillis);
                    setLocationLat(status.locationLat);
                    setLocationLng(status.locationLng);
                    setLocationObservationMillis(status.locationObservationTimeMillis);
                }
            } catch {
                setStatusCheckedAtMillis(null);
                setWanStatus(null);
                setWanStatusObservationMillis(null);
                setLocationLat(null);
                setLocationLng(null);
                setLocationObservationMillis(null);
            }
        }

        void foo();

    }, [refresh, installation.installationId])


    // respond to a request to select / deselect 
    // this installation as the current installation of interest
    function handleOnSelectorClick(id: number){
        onSelectorClick(id);
    }

    // respond to a request to hide / show
    // the status detail
    function handleExpanderClick(){
        setStatusIsExpanded(prev => !prev);
    }

    return (
        <div>
            <div className={styles.header}>
                <CardSelector 
                    installationId={installation.installationId}
                    installationName={installation.name}
                    isSelected={isSelected}
                    onClick={handleOnSelectorClick}
                />
                <StatusIndicator status={installation.wanStatus} />
                <button onClick={handleExpanderClick} className={styles.expanderContainer}>
                    <FontAwesomeIcon icon={statusIsExpanded ? faChevronUp : faChevronDown} title="Details"/>
                </button>
            </div>
            <Statuses 
                visible={statusIsExpanded}
                statusCheckedTimeMillis={statusCheckedAtMillis}
                wanStatus={wanStatus}
                wanStatusTimeMillis={wanStatusObservationMillis}
                locationLat={locationLat}
                locationLng={locationLng}
                locationTimeMillis={locationObservationMillis}
            />
        </div>
    );
};


/**
 * Card
 */
const Card = ({ 
    installation,
    isSelected,
    onSelected,
}: {
    installation: ShipModel,
    isSelected: boolean,
    onSelected: (installation: ShipModel) => void,
}) => {

    function handleSelectorClick(){
        onSelected(installation);
    }

    return (
        <div className={styles.root}>
            <CardHeader 
                installation={installation} 
                isSelected={isSelected} 
                onSelectorClick={handleSelectorClick}
            />
        </div>
    );
}

export default Card;