import { useFetchEditScheduledChanges } from "../../../../../../../../../dataServices/dataPackages/editScheduledChanges";
import { Spinner } from "../../../../../../../../spinners/Spinner";
import { EditChangesDialog } from "./EditChangesDialog";

import styles from "./EditChanges.module.css";

export const EditChanges = ({
    wanInterfaceId,
    basePackageName,
    hidePricing,
    onClose,
}: {
    wanInterfaceId: number,
    basePackageName: string,
    hidePricing: boolean,
    onClose: (updated: boolean) => void
}) => {

    // get the data that will be displayed to show the existing editable scheduled changes
    const {state} = useFetchEditScheduledChanges(wanInterfaceId);

    if (state.status === "error") {
        return null;
    }

    return (
        <div className={styles.overlay}>
            {(state.status === "idle" || state.status === "in_progress") &&
                <div style={{marginTop: '40vh'} }>
                    <Spinner />
                </div>
            }
            {state.status === "completed" &&
                <EditChangesDialog
                    wanInterfaceId={wanInterfaceId}
                    basePackageName={basePackageName}
                    ratePlans={state.data?.ratePlans ?? []}
                    scheduledChanges={state.data?.scheduledChanges ?? []}
                    newChangeDefaultStartDateMillis={state.data?.newChangeDefaultStartDateMillis ?? 0}
                    currentServerDateMillis={state.data?.currentServerDateMillis ?? 0}
                    endOfTodayMillis={state.data?.endOfTodayMillis ?? 0}
                    hidePricing={hidePricing}
                    onClose={onClose}
                />        
            }
        </div>
    )
}