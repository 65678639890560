import React, { useContext } from "react";

import {
    ChartContainer,
    ChartContent,
    ChartTitle,
    ErrorIndicator,
    LoadingIndicator,
    ContentAndKeyContainer,
} from "../components";

import { DataVolumesChartProps } from "./DataVolumesChartProps";
import { DataVolumesChartContent } from "./DataVolumesChartContent";
import { froundToIntlString } from "../../../../../../../../../utils/rounding";
import { DataVolumesChartSeries } from "./DataVolumesChartSeries";
import styles from "../ChartKey.module.css";
import { MapDatePointContext } from "../../../../../../../../../contexts/components/MapDatePointContext";

type KeyAreaProps = {
    upSeries: DataVolumesChartSeries,
    downSeries: DataVolumesChartSeries,
}

const KeyArea : React.FC<KeyAreaProps> = ({upSeries, downSeries}) => {

    const {dateMillis } = useContext(MapDatePointContext);
    
    return (
        <div className={styles.root}>
            <table>
                <tbody>
                    <tr>
                        <td></td>
                        <td className={styles.right}>Total</td>
                        <td className={styles.right} style={{paddingLeft: "10px"}}>At date point</td>
                    </tr>
                    <tr>
                        <td><div className={styles.itemIndicator} style={{backgroundColor: upSeries.color}} /> Data Up</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(upSeries.total, 2)}</span>MB</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(upSeries.keyItems?.get(dateMillis) ?? 0, 2)}</span>MB</td>
                    </tr>
                    <tr>
                        <td><div className={styles.itemIndicator} style={{backgroundColor: downSeries.color}} /> Data Down</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(downSeries.total, 2)}</span>MB</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(downSeries.keyItems?.get(dateMillis) ?? 0, 2)}</span>MB</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export const DataVolumesChart: React.FC<DataVolumesChartProps> = ({chartState, overallHeight, overallWidth, margins}) => {

    return (
         <ChartContainer>
            <ChartTitle title="Data Volumes" uom="(Megabytes)" />
            <ContentAndKeyContainer>
                <ChartContent overallHeight={overallHeight}>
                    {
                        chartState.isLoading ? 
                        <LoadingIndicator/> : 
                            chartState.isError ? 
                                <ErrorIndicator/> : 
                                    <DataVolumesChartContent
                                        chartData={chartState.data} 
                                        overallHeight={overallHeight} 
                                        overallWidth={overallWidth} 
                                        margins={margins} 
                                /> 
                    }
                </ChartContent>
                {chartState.data.series.length 
                    ? <KeyArea upSeries={chartState.data.series[0]} downSeries={chartState.data.series[1]} /> 
                    : null 
                }
            </ContentAndKeyContainer>
         </ChartContainer>
    );
};
