import { faDeleteLeft, faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./HeaderIcon.module.css";

interface IHeaderIconProps {
    type: "add" | "edit" | "delete";
    title: string;
    onClick: () => void;
}

export const HeaderIcon : React.FC<IHeaderIconProps> = ({
    type,
    title,
    onClick
}) =>{

    const icon = type === "add" ? faPlusCircle : type === "delete" ? faDeleteLeft : faEdit;

    return (
        <div className={styles.buttonContainer}>
            <button
                className={styles.button}
                type="button"
                onClick={onClick}
                title={title}
            >
                <FontAwesomeIcon icon={icon} />
            </button>
        </div>
    )
}