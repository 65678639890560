import styles from "./ContentAndKeyContainer.module.css";

interface ContentAndKeyContainerProps {
    children?: React.ReactNode
}

export const ContentAndKeyContainer : React.FC<ContentAndKeyContainerProps> = ({children}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}