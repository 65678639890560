import { useState } from "react";
import { useDrop } from "react-dnd";
import { notifyError } from "../../../common/errors/Errors";
import { DataApi } from "../../data/DataApi";
import { IClient } from "../../data/Models";
import { Actions, ActionTypes } from "../../data/State";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";
import { ClientActionMenu } from "./ClientActionMenu";
import { ClientUser } from "./ClientUser";
import { Header } from "./Header";

interface IClientProps {
    clientGroupId: number;
    client: IClient;
    dispatch: React.Dispatch<ActionTypes>;
}

interface ISourceTypeIsClientCallbackProps {
    userId: number;
    userName: string;
    sourceClientId: number;
    targetClientId: number;
}

export const Client : React.FC<IClientProps> = ({
    clientGroupId,
    client,
    dispatch,
}) => {

    const [showUsers, setShowUsers] = useState<boolean>(true);
    const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
    const [clientActionProps, setClientActionProps] = useState<ISourceTypeIsClientCallbackProps | null>(null);

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: [ItemTypes.USER],
            canDrop: (item) => item.clientGroupId === clientGroupId && item.clientId !== client.id,
            drop: (item: IUserDnd) => handleDrop({
                source: item,
                target: {
                    clientGroupId: clientGroupId,
                    clientId: client.id
                },
                dispatch: dispatch,
                sourceTypeIsClientCallback: 
                    () => handleSourceTypeIsClient({
                        userId: item.userId, 
                        userName: item.userName,
                        sourceClientId: item.clientId,
                        targetClientId: client.id
                    })
            }),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    )

    const handleSourceTypeIsClient = (props : ISourceTypeIsClientCallbackProps) : void => {
        setClientActionProps(props)
        setShowActionMenu(true);
    } 

    const moveClient = async () => {
        if (clientActionProps === null) return;

        let ok = false;

        try {
            const response = await DataApi.moveUserToClient(clientActionProps.userId, clientActionProps.sourceClientId, clientActionProps.targetClientId);

            ok = response.ok;
        }
        catch (error) {
            console.error('There was an error', error);
        }

        if (ok){
            dispatch({
                type: Actions.CLIENT_USER_MOVED_TO_CLIENT,
                data: {
                    userId: clientActionProps.userId,
                    userName: clientActionProps.userName,
                    sourceClientId: clientActionProps.sourceClientId,
                    targetClientId: clientActionProps.targetClientId,
                    clientGroupId: clientGroupId,
                }
            })
        } else {
            notifyError();
        }

        setShowActionMenu(false);
    }

    const copyClient = async () => {

        if (clientActionProps === null) return;

        let ok = false;

        try {
            const response = await DataApi.addUserToClient(clientActionProps.userId, clientActionProps.targetClientId);
            
            ok = response.ok;
        }
        catch (error) {
            console.error('There was an error', error);
        }

        if (ok){
            dispatch({
                type: Actions.CLIENT_USER_COPIED_TO_CLIENT,
                data: {
                    userId: clientActionProps.userId,
                    userName: clientActionProps.userName,
                    clientId: clientActionProps.targetClientId,
                    clientGroupId: clientGroupId
                }
            })
        }
        else {
            notifyError();
        }
        
        setShowActionMenu(false);
    }

    return (
        <li>
            <div ref={drop}>
                <Header 
                    text={`${client.name} Users`} 
                    isExpanded={showUsers}
                    onToggleExpand={() => setShowUsers(prev => !prev)}
                    canDrop={canDrop}
                    isOver={isOver}
                />
            </div>
            <ul>
                {showActionMenu && 
                    <ClientActionMenu
                        onMove={moveClient}
                        onCopy={copyClient}
                        onCancel={() => setShowActionMenu(false)}
                    />
                }
                {showUsers &&
                    client.users.map(
                        user => 
                            <ClientUser
                                key={user.id}
                                clientGroupId={clientGroupId}
                                clientId={client.id}
                                user={user}
                                dispatch={dispatch}
                            />
                )}            
            </ul>
        </li>
    )
}

interface IhandleDropProps {
    source: IUserDnd,
    target: {
        clientGroupId: number;
        clientId: number;
    },
    dispatch: React.Dispatch<ActionTypes>;
    sourceTypeIsClientCallback: (props: ISourceTypeIsClientCallbackProps) => void;
}

const handleDrop = async (props: IhandleDropProps) => {

    const {source, target, dispatch, sourceTypeIsClientCallback} = props;

    let ok = false;

    try {
        const response = await DataApi.addUserToClient(source.userId, target.clientId);
        
        ok = response.ok;
    }
    catch (error) {
        console.error('There was an error', error);
    }

    if (ok){
        switch (source.type){
            case "client":
                sourceTypeIsClientCallback({
                    userId: source.userId, 
                    userName: source.userName,
                    sourceClientId: source.clientId,
                    targetClientId: target.clientId
                });
                break;
            case "client-group":
                dispatch({
                    type: Actions.CLIENT_GROUP_USER_MOVED_TO_CLIENT,
                    data: {
                        userId: source.userId,
                        userName: source.userName,
                        clientId: target.clientId,
                        clientGroupId: target.clientGroupId
                    }
                })
                break;
            case "unassigned":
                dispatch({
                    type: Actions.UNASSIGNED_USER_ADDED_TO_CLIENT,
                    data: {
                        userId: source.userId,
                        userName: source.userName,
                        clientId: target.clientId,
                        clientGroupId: target.clientGroupId
                    }
                })
                break;
        } 
    } else {
        notifyError();
    }
}
