import { NoActionsView } from "../actions"
import { ContentContainer, Content, Heading, SubHeading, TextValue } from "../common"

export const ClientView = ({
    name,
} : {
    name: string,
}) => {

    return (
        <ContentContainer>
            <Content>
                <Heading text="Customer"/>
                <SubHeading text="Name"/>
                <TextValue text={name} />
            </Content>
            <NoActionsView/>
        </ContentContainer>
    )
}

