﻿import { INotificationEmail } from "../../../../dataServices/user/notificationEmails/Models";
import { Email } from "./Email";
import { NewEmail } from "./NewEmail";

export const EmailList = (
    { visible, emails, clientId, onAdd, onDelete }: {
        visible: boolean;
        emails: INotificationEmail[];
        clientId: number;
        onAdd: (id: number, email: string) => void;
        onDelete: (id: number) => void;
    }
) => {

    if (!visible) return null;

    return (
        <div>
            {emails.map(value => {
                return <Email
                    key={value.id}
                    clientId={clientId}
                    emailId={value.id}
                    emailAddress={value.email}
                    onDelete={onDelete} />;
            })}
            <NewEmail clientId={clientId} onAdd={onAdd} />
        </div>
    );
};
