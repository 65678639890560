import { useState } from "react";
import { DataApi } from "../../data/DataApi";
import { Actions, ActionTypes } from "../../data/State";
import { AddEditForm } from "../common/AddEditForm";
import { notifyError } from "../../../common/errors/Errors";

interface IAddClientProps {
    clientGroupId: number;
    onClose: () => void;
    dispatch: React.Dispatch<ActionTypes>;
}

export const AddClient : React.FC<IAddClientProps> = ({
    clientGroupId,
    onClose,
    dispatch,
}) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleOk = async (name: string) => {

        setIsSaving(true);

        let json;

        try {
            const response = await DataApi.createClient(clientGroupId, name);

            if (response.ok){
                json = await response.json();
            }
        }
        catch (error) 
        {
            console.error('There was an error', error);
        }

        if (json){
            dispatch({
                type: Actions.CLIENT_ADDED,
                data: {
                    clientGroupId: clientGroupId,
                    id: json.id,
                    name: name
                }
            });
            onClose();
        } else {
            notifyError();
        }

        setIsSaving(false);
    }

    return (
        <li>
            <AddEditForm
                    title="Add a Customer"
                    label="Customer Name:"
                    placeHolder="Customer name"
                    isProcessing={isSaving}
                    onOk={handleOk}
                    onCancel={onClose}
                />
        </li>
    )
}
