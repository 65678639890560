import { application } from "../../../utils/environment";
import { AuthForm, AuthLayout, FieldSpacer, NavLink, SectionHeader } from "./components";

export default function ConfirmEmailConfirmationPage() {
    
    return (
        <AuthLayout>
            <AuthForm onSubmit={()=> {}}>
                <SectionHeader text="Thank your for confirming your Email"/>
                <FieldSpacer />
                <NavLink to={"/"} text={`Click here to Sign In to ${application.name}`}/>                
            </AuthForm>
        </AuthLayout>
    )
}