import { useContext, useMemo } from "react";

import { IGetDataState, api } from "../../../../../../dataServices/components";
import { IInvoiceModel, useFetchInvoiceHistory } from "../../../../../../dataServices/invoiceHistory";
import { Dates } from "../../../../../../utils/dates";
import { froundToIntlString } from "../../../../../../utils/rounding";

import { Column } from "react-table";
import { AlignEnum, HeaderContent, PagedTable } from "../../../../../tables";
import { InvoiceListModel } from "./InvoiceListModel";

import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import styles from "./InvoiceList.module.css";

/**
 * Component which displays a list of Invoices related to 
 * the currently selected vessel, over a specified date range.
 * @param fromDateMillis Unix Epoch milliseconds of the lower date range boundary, inclusive.
 * @param toDateMillis Unix Epoch milliseconds of the upper date range boundary, inclusive.
 * @returns 
 */
export const InvoiceList = (
    {
        fromDateMillis,
        toDateMillis
    }:
    {
        fromDateMillis: number;
        toDateMillis: number;
    }
) => {

    // respond to changes in the selected vessel
    const {ship} = useContext(SelectedShipContext);

    // get the invoice history for the selected vessel and date range
    const {state} = useFetchInvoiceHistory(ship?.installationId, fromDateMillis, toDateMillis);

    // memoized data for the react-table
    const tableData = useMemo(
        () => makeTableData(state),
        [state]
    );

    // memoized column definitions for the react-table
    const columns = useMemo(
        () => makeColumns(),
        []
    );

    return (
        <div>
            <div>
                {state.status === "in_progress" && 
                    (
                        <div>Loading...</div>
                    )
                }
                
                {state.status === "error" && 
                    (
                        <div>Ooops.  Sorry, something went wrong...</div>
                    )
                }

                {(state.status === "completed" || state.status === "idle") &&  

                    (
                        <div>
                            {tableData && tableData.length > 0 &&
                                <PagedTable 
                                    columns={columns}
                                    data={tableData}
                                />                                    
                            }
                            {tableData && tableData.length === 0 &&
                                <div className={styles.noItemsMessage}>
                                    There are no invoices for the selected date range
                                </div>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

// function creates react-table column definitions
function makeColumns() : Column<InvoiceListModel>[] {
    return [
        {
            Header: ({column}) => { 
                return <HeaderContent text="Invoice Number" column={column} align={AlignEnum.Left} />
            },
            accessor: "invoiceNumber",
            Cell: ({cell: {row, value}}) => {
                // construct a link to download the invoice pdf
                const href = api.urls.billing.get.invoiceDownload(row.original.installationId, row.original.invoiceId);
                return <div><a href={href} rel="noreferrer" download={value}>{value}</a></div> 
            }
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="Invoice Date" column={column} align={AlignEnum.Left} />
            },
            accessor: "invoiceDateMillis",
            Cell: ({cell: {value}}) => {return <div>{Dates.fromMillis(value).toString(Dates.DATE_FORMAT_SHORT)}</div>}
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="Total $" column={column} align={AlignEnum.Right} />
            },
            accessor: "total",
            Cell: ({cell: {value}}) => {return <div className={styles.colRightAlign}>{froundToIntlString(value ?? 0, 2)}</div>}
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="Tax $" column={column} align={AlignEnum.Right} />
            },
            accessor: "tax",
            Cell: ({cell: {value}}) => {return <div className={styles.colRightAlign}>{froundToIntlString(value ?? 0, 2)}</div>}
        },
    ]
}

function makeTableData(state: IGetDataState<IInvoiceModel[]>): InvoiceListModel[]{

    if (state.status === "error" || state.status === "in_progress") return [];
    if (state.data && state.data.length > 0){
        // map the fetched data to our extended model
        return state.data.map(invoice => {
            return {
                installationId: invoice.installationId,
                invoiceId: invoice.invoiceId,
                invoiceDateMillis: invoice.invoiceDateMillis,
                invoiceNumber: invoice.invoiceNumber,
                tax: invoice.tax,
                total: invoice.total
            }
        })
    }
    else {
        return [];
    }
}