import { useContext } from "react";
import { useFetchBeamUsages, BeamUsageModel } from "../../../../dataServices/metrics/beamUsage";
import { IGetDataState } from "../../../../dataServices/components";
import { IMapBeamUsageDisplayData } from "./IMapBeamUsageDisplayData";
import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";

function mapData(fetch: {state: IGetDataState<BeamUsageModel>; }): IMapBeamUsageDisplayData
{
    const data: IMapBeamUsageDisplayData = {
        isLoading: fetch.state.status === "in_progress",
        isError: fetch.state.status === "error",
        items: new Map<number, string>(), 
    };

    if (fetch.state.data){
        fetch.state.data.timeStampsMillis.forEach((value, index) => {
            data.items.set(value, fetch.state.data?.values[index]??'');
        })
    }

    return data;
}

export const MapBeamUsageDisplay = (
    { shipId, fromDate, toDate, interval }:
    { shipId: number | undefined, fromDate: number, toDate: number, interval: number }
) => {

    const data = mapData(useFetchBeamUsages(shipId, fromDate, toDate, interval));

    const { dateMillis } = useContext(MapDatePointContext);

    return (
        <span>{data.items?.get(dateMillis)}</span>
    )
}
