import React, { useContext, useEffect, useMemo } from "react";
import { Layer, LineLayer, Source } from "react-map-gl";
import { MapLocationsContext } from "../../../../contexts/components/MapLocationsContext";

function makeRouteGeoJson(coordinates: number[][][]) : GeoJSON.Feature {
    return {
        type: "Feature",
        geometry: {
            type: "MultiLineString",
            coordinates: coordinates,
        },
        properties: null
  };
}

const routeLayer : LineLayer = {
    id: 'ship-route-layer-id',
    type: "line",    
    paint: {
        "line-color": "#000000",
        "line-width": 3
    }
}

export const ShipRoute : React.FC = () => {

    const [geoJson, setGeoJson] = React.useState<GeoJSON.Feature>(makeRouteGeoJson([]));

    const {isLoading, routes} = useContext(MapLocationsContext);

    const memoizedMakeGeoJson = useMemo(() => {
        return makeRouteGeoJson(routes)
    }, [routes]);

    const memoizedEmptyRoutes = useMemo(() => {
        const emptyRoute : [][][] = [];
        return makeRouteGeoJson(emptyRoute)
    },[]);

    useEffect(() => {
        if (isLoading){
            setGeoJson(memoizedEmptyRoutes);
        } else {
            setGeoJson(memoizedMakeGeoJson);
        }
    }, [isLoading, routes, setGeoJson, memoizedEmptyRoutes, memoizedMakeGeoJson
    ])
      
    return (
        <Source id={"ship-routes-source"} type='geojson' data={geoJson}>
            <Layer beforeId="ship-point-layer-id" {...routeLayer} />
        </Source>
    );

}
