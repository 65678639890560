import styles from "./BasePackageDisplay.module.css"

/**
 * A component which displays the supplied Base Package Name
 * 
 * @param packageName Name of the base package to display
 * @returns
 */
export const BasePackageDisplay = ({
    packageName
}: {
    packageName: string
}) => {

    return (
        <div className={styles.root}>Base Package : <span className={styles.packageName}>{packageName}</span></div>
    )
}