import { useContext } from "react";
import { AuthContext } from "../../../../contexts/components/AuthContext";
import styles from "./Header.module.css";

const Header = () => {

    const auth = useContext(AuthContext);

    return (
        <div className={styles.root}>
            <div className={styles.title}>User Settings</div>
            <div className={styles.subTitle}>[ {auth.user?.userName} ]</div>
        </div>
    )
}

export default Header;