import styles from "./Content.module.css";

export const Content = ({
    children,
} : {
    children: React.ReactNode
}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}