import { AuthLayout, AuthForm, SectionHeader } from "./components";

function RegisterConfirmationPage() {
    return (
        <AuthLayout>
            <AuthForm onSubmit={()=> {}}>
                <SectionHeader text="We have sent you an email with a link to verify your email address." />
            </AuthForm>
        </AuthLayout>    )
}

export default RegisterConfirmationPage;