import styles from "./ContentContainer.module.css";

export const ContentContainer = ({
    children
} : {
    children: React.ReactNode
}) => {

    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}