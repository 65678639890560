import { useEffect, useState } from "react";
import { api, useGetData } from "../components";
import { IInvoiceModel } from "./Models";

/**
* Shape of the data that is returned from the remote API call
*/
 interface IApiModel {
    installationId: number,
    invoiceId: number,
    invoiceNumber: string,
    invoiceDateMillis: number,
    invoiceTotal: number,
    invoiceTax: number,
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel[]): IInvoiceModel[]{

    return apiData.map((value) => {
            return {
                installationId: value.installationId,
                invoiceId: value.invoiceId,
                invoiceNumber: value.invoiceNumber,
                invoiceDateMillis: value.invoiceDateMillis,
                total: value.invoiceTotal,
                tax: value.invoiceTax,
            }
        });
}

/**
 * Hook to retrieve Invoice History data for a given ship over a given time range
 * @param installationId Id of the Ship whose data is to be retrieved
 * @param fromMillis Lower date range limit in Unix Epoch seconds.
 * @param toMillis Upper date range limit in Unix Epoch seconds.
 * @returns An array of ShipModel items.
 */
const useFetchInvoiceHistory = (installationId: number | undefined, fromMillis: number, toMillis: number) => {

    const [url, setUrl] = useState<string>('');
    const {state} = useGetData(url, mapper);

    useEffect(() => {

        if (typeof installationId === "undefined") return;

        setUrl(api.urls.billing.get.invoiceHistory(installationId, fromMillis, toMillis));
        
    }, [installationId, fromMillis, toMillis]);

    return {state};
}

export {useFetchInvoiceHistory};