import React from "react";
import classNames from "classnames";
import { ContentType } from "../../ContentType";
import styles from "./NavigationButton.module.css";

interface INavigationButtonProps{
    id: ContentType,
    icon: React.ReactNode,
    isCurrent: boolean,
    onClick: (id: ContentType) => void,
}

const NavigationButton : React.FC<INavigationButtonProps> = ({id, icon, isCurrent, onClick}) => {
    
    function handleOnClick(){
        onClick(id);
    }

    return (
        <li onClick={handleOnClick}>
            <div className={`${classNames(styles.root, isCurrent && styles.isCurrent)}`}>
                <button>
                    {icon}
                </button>
            </div>
        </li>
    )
}

export {NavigationButton};