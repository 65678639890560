import { Link } from "react-router-dom";
import styles from "./AuthLinks.module.css";

function NavLink(props : {to: string, text: string}){
    
    const {to, text} = props;

    return (
        <div className={styles.linkContainer}>
            <Link 
                to={to}
                className={styles.navLink}
            >
                {text}
            </Link>
        </div>
    )
}

function AuthLinks(props : {register?: boolean, forgotPassword?: boolean, signIn?: boolean}){
    return (
        <div className={styles.container}>
            {props.register && 
                <NavLink to="/register" text="I would like to register"/>
            }
            {props.forgotPassword &&
                <NavLink to="/forgotpassword" text="I've forgotten my password"/>
            }
            {props.signIn && 
                <NavLink to="/" text="I would like to Sign In"/>
            }
        </div>
    );
}

export {AuthLinks, NavLink};
