import { useEffect, useRef } from "react";
import { RatePlanModel } from "../../../../../../../../dataServices/dataPackages/ratePlans";
import { froundToIntlString } from "../../../../../../../../utils/rounding";
import styles from "./RatePlanSelectionList.module.css";
import classnames from "classnames";

const RatePlanItem = ({
    id,
    name,
    adjustment,
    isSelected,
    hidePricing,
    onClick
}: {
    id: number;
    name: string;
    adjustment: number;
    isSelected: boolean;
    hidePricing: boolean;
    onClick: (id: number) => void;
}) => {

    // ref used to enable scrolling the item into view on load
    const ref = useRef<HTMLDivElement>(null);

    // function to scroll the item into view
    const scrollToElement = () => {
        ref.current?.scrollIntoView({ behavior: 'instant' })
    };

    // scroll the item into view when the component loads
    useEffect(() => {
        scrollToElement();
    }, []);

    function handleOnClick(){
        onClick(id);
    }

    return (
        <div
            className={`${classnames(styles.ratePlanItem, isSelected && styles.checked)}`}
            onClick={handleOnClick}
            // if this item is selected, set the ref that controls scrolling into view
            ref={isSelected ? ref : null}
        >
            <div className={styles.selector}>
                <input
                    type="radio"
                    id={`${id}`}
                    value={id}
                    onChange={handleOnClick}
                    checked={isSelected}
                />
            </div>
            <label 
                onClick={handleOnClick}
                className={styles.packageName}
            >{name}</label>
            {!hidePricing &&
                <label 
                    onClick={handleOnClick}
                    className={styles.adjustment}
                >{froundToIntlString(adjustment, 2)}</label>
            }
        </div>
    )
}

export const RatePlanSelectionList = ({
    className = undefined,
    ratePlans,
    ratePlanId,
    hidePricing,
    onChange
}: {
    className?: string | undefined,
    ratePlans: RatePlanModel[],
    ratePlanId?: number | null,
    hidePricing: boolean,
    onChange: (id: number) => void,
}) => {

    return (
        <div className={`${className}`}>
            <div className={styles.thead}>
                <div className={styles.selector}></div>
                <div className={styles.packageName}>Package Name</div>
                {!hidePricing &&
                    <div className={styles.adjustment}>$ Daily Adjustment</div>
                }
            </div>
            <div className={styles.tbody}>
                {ratePlans.map((ratePlan) => {
                        const isSelected = ratePlan.id === ratePlanId;

                        return (
                            <RatePlanItem 
                                key={`rate-plan-id-${ratePlan.id}`}
                                id={ratePlan.id}
                                name={ratePlan.name}
                                adjustment={ratePlan.dailyAdjustment}
                                isSelected={isSelected}
                                hidePricing={hidePricing}
                                onClick={(id) => onChange(id)}
                            />
                        )
                    })}
            </div>
            <div className={styles.tfooter}></div>
        </div>
    )
}