import React from "react";
import { ShipModel } from "../../../../../../dataServices/ships";
import Header from "../header/Header";
import Content from "../content/Content";

import styles from "./FilteredList.module.css";

interface IFilteredListProps {
    isLoading: boolean;
    installations: ShipModel[];
}

export const FilteredList : React.FC<IFilteredListProps> = ({
    isLoading,
    installations
}) => {

    const initialFilter: string = '';

    // a user supplied filter on the list of installations
    const [filter, setFilter] = React.useState<string>(initialFilter);

    // the filtered list of installations
    const filteredList = installations.filter((value) => {
        return filter
            ? value.name.toUpperCase().includes(filter.toUpperCase())
            : value
    });

    function handleOnFilterChange(filterValue: string){
        setFilter(filterValue);
    }

    return (
        <nav className={styles.root}>
            <Header initialFilter={initialFilter} onFilterChange={handleOnFilterChange}/>            
            <Content installations={filteredList} isLoading={isLoading}/>
        </nav>
    );
}