import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import UserAccount from "../../user/components/userAccount/UserAccount";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { NavBarButton } from "./NavBarButton";

const ShowUserAccount = () => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    }

    const closeMenu = () => {
        // don't prevent default on this, because it prevents other
        // links etc on the UI from being actioned.
        setOpen(false);        
    }

    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <div>
                <NavBarButton
                    icon={faUser}
                    onClick={handleClick}
                    title="User Account"
                />
                {open ? ( <UserAccount onCancel={closeMenu}/> ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default ShowUserAccount;