import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Header.module.css";

/**
 * A component which implements a draggable header bar.
 */
export const Header = (
    {
        title,
        inactive,
        onDrag,
    }:
    {
        title: string,
        inactive: boolean,
        onDrag: (movementX: number, movementY: number) => void,
    }
) => {

    const [mouseDown, setMouseDown] = useState(false);
    
    useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);

        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };

    }, []);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => onDrag(e.movementX, e.movementY);

        if (mouseDown){
            window.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        }

    }, [mouseDown, onDrag]);

    const handleMouseDown = () => setMouseDown(true);

    return (
        <div className={`${classNames(styles.root, inactive && styles.inactive)}`} onMouseDown={handleMouseDown}>
            {title}
        </div>
    )
}