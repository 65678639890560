import { Dates } from "../../../../utils/dates";
import classNames from "classnames";
import styles from "./Month.module.css";

interface IBorder {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
}

interface IMonthProps {
    // number of the month. 1 = January
    monthNumber: number;
    // true if the month represents a month selected either at then start or end of a range
    isSelected: boolean;
    // true if the month cannot be selected by a user
    isDisabled: boolean;
    // true if the month is in the range of selected months (but not the start or end month)
    isInRange: boolean;
    // raised when the month is selected
    onSelected: (monthNumber: number) => void;
    // raised when the mouse enters the month
    onMouseEnter: (monthNumber: number) => void;
    // borders to show
    border: IBorder;
}

export const Month : React.FC<IMonthProps> = ({
    monthNumber,
    isSelected,
    isDisabled,
    isInRange,
    onSelected,
    onMouseEnter,
    border,
}) => {

    const handleOnClick : React.MouseEventHandler<HTMLButtonElement> = (e) =>{
        e.preventDefault();
        onSelected(monthNumber);
    }

    const handleOnMouseEnter : React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        onMouseEnter(monthNumber);
    }

    // build the styles for the borders
    const borders : string[] = [];

    if (border.top) borders.push(styles.borderTop);
    if (border.right) borders.push(styles.borderRight);
    if (border.bottom) borders.push(styles.borderBottom);
    if (border.left) borders.push(styles.borderLeft);

    // build the styles for the selection status
    let className = classNames(styles.root, borders);

    if (isSelected) className = classNames(styles.root, styles.selected, borders);

    if (isInRange) className = classNames(styles.root, styles.inRange, borders);

    if (isDisabled) className = classNames(styles.root, styles.disabled, borders);


    return (
        <button 
            className={className} 
            disabled={isDisabled}
            onClick={handleOnClick}
            onMouseEnter={!isDisabled ? handleOnMouseEnter : () => {}}
        >
            {Dates.monthNameShort(monthNumber)}
        </button>
    )
}