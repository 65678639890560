import { useMemo } from "react";
import { useFetchHistoricScheduledChanges } from "../../../../../../../../dataServices/dataPackages/scheduledChanges/useFetchHistoricScheduledChanges";
import { PagedTable } from "../../../../../../../tables";
import { makeColumns, makeTableData, sortScheduledChangesDesc } from "./common";

import styles from "./ScheduledChangesList.module.css";

//
// Component displays the list of completed scheduled changes 
// for a supplied installation and equipment
//
export const HistoricScheduledChangesList = ({
    wanInterfaceId,
    lastRefresh,
    hidePricing,
}: {
    wanInterfaceId?: number;
    lastRefresh: number;
    hidePricing: boolean;
}) => {

    const {state} = useFetchHistoricScheduledChanges(wanInterfaceId, lastRefresh);

    // memoize the data from the database so we can use it in a react-table instance
    const tableData = useMemo(
        () => makeTableData(state, sortScheduledChangesDesc), 
        [state]
    );

    // memoize the column definitions
    const columns = useMemo(
        () => makeColumns(),
        []
    );

    if (wanInterfaceId){

        return (            
            <div className={styles.root}>
                <div className={styles.header}>
                    <div className={styles.title}>Completed Package Changes</div>
                </div>

                <div className={styles.content}>

                    {state.status === "in_progress" && 
                        (
                            <div>Loading...</div>
                        )
                    }
                    
                    {state.status === "error" && 
                        (
                            <div>Ooops.  Sorry, something went wrong...</div>
                        )
                    }

                    {(state.status === "completed" || state.status === "idle") &&  
                        (
                            <div>

                                {(tableData === null || (tableData.length === 0)) &&
                                    <div className={styles.noItemsMessage}>
                                        You do not have any Completed Package Changes
                                    </div>
                                }

                                {tableData !== null && tableData.length > 0 &&
                                    <PagedTable
                                        columns={columns}
                                        data={tableData}
                                        hiddenColumns={hidePricing ? ["dailyAdjustment"] : []}
                                    />
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    } 
    else 
    {
        return null;
    }
}