import { LatLng } from "../../../../../../utils/latLng";
import { Dates } from "../../../../../../utils/dates";

import styles from "./Statuses.module.css";

export const Statuses = ({
    visible,
    statusCheckedTimeMillis,
    wanStatus,
    wanStatusTimeMillis,
    locationLat,
    locationLng,
    locationTimeMillis,

}: {
    visible: boolean;
    statusCheckedTimeMillis: number | null;
    wanStatus: string | null;
    wanStatusTimeMillis: number | null;
    locationLat: number | null;
    locationLng: number | null;
    locationTimeMillis: number | null;
}) => {

    if (!visible) return null;

    return (
        <div className={styles.root}>
            <WanStatus 
                status={wanStatus}
                timeMillis={wanStatusTimeMillis}
            />
            <Location 
                lat={locationLat}
                lng={locationLng}
                timeMillis={locationTimeMillis}
            />
            <Info 
                timeMillis={statusCheckedTimeMillis}
            />
        </div>
    );
}

/**
 * Component which displays WAN Status information
 */
const WanStatus = ({
    status,
    timeMillis,
}: {
    status: string | null;
    timeMillis: number | null;
}) => {

    return (
        <div className={styles.wanStatusContainer}>
            <div className={styles.header}>Last observed WAN Status</div>
            <div className={styles.status}>{status}</div>
            <div>{timeMillis === null ? '' : 'observed : ' + Dates.fromMillis(timeMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
        </div>
    )
}

/**
 * Component which displays Location Status information
 */
const Location = ({
    lat,
    lng,
    timeMillis,
}: {
    lat: number | null;
    lng: number | null;
    timeMillis: number | null;
}) => {
    return (
        <div className={styles.locationContainer}>
            <div className={styles.header}>Last observed Location</div>
            <div className={styles.location}>{LatLng.fromPoints(lat ? lat : undefined, lng ? lng : undefined).toString()}</div>
            <div>{timeMillis === null ? '' : 'observed : ' + Dates.fromMillis(timeMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}</div>
        </div>
    )
}

/**
 * Component which shows information about when 
 * the status was last checked
 */
const Info = ({
    timeMillis
}: {
    timeMillis: number | null;
}) => {
    return (
        <div className={styles.infoContainer}>
            <div className={styles.info}>Last updated : {timeMillis === null ? '' : Dates.fromMillis(timeMillis).toString(Dates.DATE_FORMAT_TIME)}</div>
        </div>
    )
}