import { useState } from "react";
import { IClient } from "../../data/Models";
import { ActionTypes } from "../../data/State";
import { Header } from "../common/Header";
import { HeaderIcon } from "../common/HeaderIcon";
import { InstallationGroup } from "../installationGroup/InstallationGroup";
import { AddInstallationGroup } from "./AddInstallationGroup";
import { DeleteClient } from "./DeleteClient";
import { RenameClient } from "./RenameClient";

interface IClientProps {
    clientGroupId: number;
    client: IClient;
    holdingClient: IClient | undefined;
    dispatch: React.Dispatch<ActionTypes>
}

export const Client : React.FC<IClientProps> = ({
    clientGroupId,
    client,
    holdingClient,
    dispatch
}) => {

    const [isRenamingClient, setIsRenamingClient] = useState<boolean>(false);
    const [isDeletingClient, setIsDeletingClient] = useState<boolean>(false);
    const [isAddingInstallationGroup, setIsAddingInstallationGroup] = useState<boolean>(false);
    const [showFleetHeader, setShowFleetHeader] = useState<boolean>(true);
    const [showFleets, setShowFleets] = useState<boolean>(true);

    return (
        <li>
            {!isRenamingClient && !isDeletingClient &&
                <Header 
                    text={client.name}
                    isExpanded={showFleetHeader}
                    onToggleExpanded={() => setShowFleetHeader(prev => !prev)}
                >
                    <HeaderIcon 
                        type="edit" 
                        onClick={() => setIsRenamingClient(true)} 
                        title="Rename Customer"
                    />
                    {client.installationGroups.length === 0 &&
                        <HeaderIcon
                            type="delete"
                            onClick={() => setIsDeletingClient(true)}
                            title="Delete Customer"
                        />
                    }
                </Header>
            }
            {isRenamingClient && 
                <RenameClient
                    clientGroupId={clientGroupId}
                    clientId={client.id}
                    name={client.name}
                    onClose={() => setIsRenamingClient(false)} 
                    dispatch={dispatch}
                />
            }
            {isDeletingClient && 
                <DeleteClient
                    clientGroupId={clientGroupId}
                    clientId={client.id}
                    name={client.name}
                    onClose={() => setIsDeletingClient(false)}
                    dispatch={dispatch}
                />
            }
            {showFleetHeader &&
            <ul>
                <li>
                    <Header 
                        text="Fleets"
                        isExpanded={showFleets}
                        onToggleExpanded={() => setShowFleets(prev => !prev)}
                    >
                        <HeaderIcon 
                            type="add" 
                            onClick={() => setIsAddingInstallationGroup(true)} 
                            title="Add a Fleet"
                        />
                    </Header>
                    <ul>
                        {isAddingInstallationGroup &&
                            <AddInstallationGroup
                                clientGroupId={clientGroupId}
                                clientId={client.id}
                                onClose={() => setIsAddingInstallationGroup(false)}
                                dispatch={dispatch}
                            />
                        }
                        {showFleets && client.installationGroups.map(
                            installationGroup => 
                                <InstallationGroup 
                                    key={installationGroup.id} 
                                    clientGroupId={clientGroupId}
                                    clientId={client.id}
                                    installationGroup={installationGroup}
                                    holdingClient={holdingClient}
                                    dispatch={dispatch}
                                />
                        )}
                    </ul>
                </li>
            </ul>
            }
        </li>
    )
}