import styles from "./Content.module.css";

const Content = (props: {children: JSX.Element[]}) => {
    return (
        <div className={styles.root}>
            {props.children}
        </div>
    )
}

export default Content;