import { IGetDataState } from "../../../../dataServices/components";
import { IClientNotificationEmails } from "../../../../dataServices/user/notificationEmails/Models";
import { LoadingIndicator } from "./LoadingIndicator";
import { Client } from "./Client";
import { LoadingError } from "./LoadingError";
import { NoClients } from "./NoClients";

/**
 * A component which shows a list of Clients and associated Notification Email Addresses.
 */
export const ClientList = (props: { state: IGetDataState<IClientNotificationEmails[]> }) => {

    if (props.state.status === "in_progress") {
        return <LoadingIndicator />;
    }

    if (props.state.status === "error") {
        return <LoadingError />;
    }

    if ((props.state.status === "completed" || props.state.status === "idle")) {

        if ((typeof props.state.data === "undefined") || (props.state.data.length === 0)) {            

            return <NoClients />

        } else {

            const content = props.state.data.map(value => {
                return (
                    <Client
                        key={value.clientId}
                        id={value.clientId}
                        name={value.clientName}
                        emails={value.emailAddresses}
                    />
                );
            })

            return (
                <div>
                    {content}
                </div>
            )

        }
    }

    return null;
}
