import React, {useState} from "react";
import { RatePlanModel } from "../../../../../../../../../dataServices/dataPackages/ratePlans";
import { Dialog } from "../../../../../../../../dialog";
import { ConfirmationChecks } from "./ConfirmationChecks";
import { EndDate } from "./EndDate";
import { RatePlanList } from "./RatePlanList";
import { DialogButtons } from "./DialogButtons";

import styles from "./ImmediateUpgradeDialog.module.css";
import { api, fetchApiPost, fetchHandler } from "../../../../../../../../../dataServices/components";

const postUrl = api.urls.packages.post.immediateUpgrade();

interface IImmediateUpgradeDialogProps {
    wanInterfaceId: number;
    earliestEndDateMillis: number;
    availableRatePlans: RatePlanModel[];
    hidePricing: boolean;
    onClose: (updated: boolean) => void;
}

export const ImmediateUpgradeDialog : React.FC<IImmediateUpgradeDialogProps> = ({
    wanInterfaceId,
    earliestEndDateMillis,
    availableRatePlans,
    hidePricing,
    onClose
}) => {

    const [ratePlanId, setRatePlanId] = useState<number>(null!);
    const [checksAccepted, setChecksAccepted] = useState(false);
    const [endDateMillis, setEndDateMillis] = useState(earliestEndDateMillis);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    /**
     * Handles the request to save the immediate upgrade
     */
    function handleOkClick(){

        // belt and braces - should not happen
        if (!checksAccepted) return;

        setIsProcessing(true);
        setErrorMessage(null);

        const data = {
            wanInterfaceId: wanInterfaceId,
            ratePlanId: ratePlanId,
            endDateMillis: endDateMillis,
        }

        fetchApiPost(postUrl, data, {includeCredentials: true})
        .then((postResponse) => {
            setIsProcessing(false);
            fetchHandler(postResponse)
                .then((handlerResult) => {
                    if (handlerResult.succeeded) {
                        onClose(true);
                    } else {
                        setErrorMessage("Failed to save the Immediate Upgrade");
                    }
                })
                .catch(() => setErrorMessage("Failed to save the Immediate Upgrade"))
        })
        .catch(() => {
            setIsProcessing(false);
            setErrorMessage("Sorry - something went wrong");
        })
    }

    function handleCancelClick(){
        onClose(false);
    }

    function handleConfirmationChecksChange(accepted: boolean){
        setChecksAccepted(accepted);
    }

    function handleEndDateChange(endDateMillis: number){
        setEndDateMillis(endDateMillis);
    }

    function handleRatePlanChange(value: number){
        setRatePlanId(value);        
    }

    return (
        <Dialog title="Immediate Package Upgrade" disabled={isProcessing}>
            <div className={styles.root}>
                <RatePlanList
                    ratePlans={availableRatePlans} 
                    onChange={handleRatePlanChange}
                    ratePlanId={ratePlanId}
                    hidePricing={hidePricing}
                />
                <EndDate 
                    earliestEndDateMillis={earliestEndDateMillis}
                    endDateMillis={endDateMillis}
                    onChange={handleEndDateChange}
                />
                <ConfirmationChecks 
                    onChange={handleConfirmationChecksChange} 
                />
                {errorMessage && 
                    <div className={styles.errorMessage}>{errorMessage}</div>
                }
                <DialogButtons
                    okDisabled={!checksAccepted}
                    onOkClick={handleOkClick}
                    onCancelClick={handleCancelClick}
                />
            </div>
        </Dialog>
    )
}