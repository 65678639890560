import ClickAwayListener from "@mui/material/ClickAwayListener";
import React, { useContext } from "react";
import { Dates } from "../../../../utils/dates";
import { DateRangePicker, DateSelectorButton } from "../../../dates";

import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";
import { MapDateRangeContext } from "../../../../contexts/components/MapDateRangeContext";
import styles from "./MapDateRange.module.css";

const BUTTON_TITLE = "Map and Vessel Connectivity Dates";

const MapDateRange = ({ disabled = false }: {disabled?: boolean}) => {

    const {
        fromMillis: currentMapFromMillis, 
        toMillis: currentMapToMillis, 
        intervalMillis: currentMapIntervalMillis,
        setDates: setMapDates,
        calculateIntervalMillis
    } = useContext(MapDateRangeContext);

    const {
        setDateMillis: setMapDatePoint,
        dateMillis: mapDatePoint
    } = useContext(MapDatePointContext);

    const [showCalendar, setShowCalendar] = React.useState<boolean>(false);

    const anchorRef = React.useRef<null | HTMLDivElement>(null);

    function handleShowCalendar(){
        setShowCalendar(true);
    }

    function handleOnSelected(fromDateMillis: number, toDateMillis: number){
        setShowCalendar(false);
        // set the map dates, converting the toDate to the end of the day, before applying the dates
        const interval = calculateIntervalMillis(fromDateMillis, toDateMillis);

        const toDateToUse = Dates.fromMillis(toDateMillis).addDays(1).subtractMillis(interval).millis();

        setMapDates(fromDateMillis, toDateToUse);

        // reset the current map date point to the new from date
        setMapDatePoint(fromDateMillis);
    }

    
    const handleOnDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currDate = +(event.target.value);
        // update the context which drives the map and chart current date
        setMapDatePoint(currDate);
    }

    return (
        <div className={styles.root}>
            <div ref={anchorRef}>
                <DateSelectorButton 
                    title={BUTTON_TITLE}
                    onClick={handleShowCalendar}
                    className={styles.dateSelectorButton}
                    >
                    {Dates.fromMillis(currentMapFromMillis).toString(Dates.DATE_FORMAT_SHORT)} to {Dates.fromMillis(currentMapToMillis).toString(Dates.DATE_FORMAT_SHORT)}
                </DateSelectorButton>
            </div>
            <input 
                type="range"
                title="Date slider"
                min={currentMapFromMillis}
                max={currentMapToMillis}
                step={currentMapIntervalMillis}
                disabled={disabled}
                value={mapDatePoint}
                onChange={handleOnDateRangeChange}
            />

            {showCalendar &&
                <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
                    <div>
                        <DateRangePicker 
                            minimumDateMillis={Dates.now().subtractDays(180).millis()}
                            maximumDateMillis={Dates.now().millis()}
                            suppliedStartDateMillis={Dates.fromMillis(currentMapFromMillis).startOfDay().millis()}
                            suppliedEndDateMillis={Dates.fromMillis(currentMapToMillis).startOfDay().millis()}
                            anchorRef={anchorRef} 
                            onSelected={handleOnSelected}
                        />
                    </div>
                </ClickAwayListener>
            }

        </div>
    );
}

export { MapDateRange };
