import React from "react";
import { api, useGetData } from "../components";
import { StarlinkDataVolumesModel } from "./Models";

/**
 * Shape of the data that is returned by the remote API call
 */
interface IApiModel {
    timeStampsMillis: number[];
    dataVolumeValues: number[];
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData
 * @returns
 */
function mapper(apiData: IApiModel): StarlinkDataVolumesModel {
    return {
        timeStampsMillis: apiData.timeStampsMillis,
        dataVolumes: apiData.dataVolumeValues,
    }
}

/**
 * Hook to retrieve Starlink Data Volumes data for a given ship over a given time range
 * @param installationId Id of the Installation whose data is to be retrieved
 * @param fromMillis Lower date range limit in Unix Epoch seconds.
 * @param toMillis Upper date range limit in Unix Epoch seconds.
 * @returns
 */
const useFetchStarlinkDataVolumes = (installationId: number | undefined, fromMillis: number, toMillis: number) => {

    const [url, setUrl] = React.useState<string>('');
    const { state } = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.starlink.get.dataVolumes(installationId, fromMillis, toMillis);

        setUrl(url);

    }, [installationId, fromMillis, toMillis]);

    return { state };

}

export { useFetchStarlinkDataVolumes };