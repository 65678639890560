import React from "react";
import { api, useGetData } from "../../components";
import { UploadSpeedsModel } from "./Models";

 /**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel{
    installationId: number,
    fromMillis: number,
    toMillis: number,
    intervalMillis: number,
    timeStampsMillis: number[],
    values: number[]
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel): UploadSpeedsModel{

    return {
        shipId: apiData.installationId,
        fromMillis: apiData.fromMillis,
        toMillis: apiData.toMillis,
        intervalMillis: apiData.intervalMillis,
        timeStampsMillis: apiData.timeStampsMillis,
        values: apiData.values,
    }
}

/**
 * Hook to retrieve Upload Speed data for a given ship over a given time range
 * @param installationId Id of the Ship whose data is to be retrieved
 * @param fromMillis Lower date range limit in Unix Epoch seconds.
 * @param toMillis Upper date range limit in Unix Epoch seconds.
 * @param intervalMillis Interval between time slots expressed in seconds.
 * @returns An array of UploadSpeedModel items.
 */
 const useFetchUploadSpeeds = (installationId: number | undefined, fromMillis: number, toMillis: number, intervalMillis: number) => {

    const [url, setUrl] = React.useState<string>('');
    const {state} = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.metrics.get.uploadSpeeds(installationId, fromMillis, toMillis, intervalMillis);

        setUrl(url);
        
    }, [installationId, fromMillis, toMillis, intervalMillis]);

    return {state};
}

export { useFetchUploadSpeeds};