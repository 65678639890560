import React from "react";
import Avatar from "@mui/material/Avatar";

type AvatarSize = "small" | "large";

interface IUserAvatarProps {
    size: AvatarSize,
    className?: string,
    onClick?: () => void,
}

const UserAvatar : React.FC<IUserAvatarProps> = ({size, className, onClick}) => {

    return (
        <Avatar 
            className={className}
            onClick={onClick}
            sx={size === "small" ? {height: "2.8rem", width: "2.8rem"} : {height: "5.6rem", width: "5.6rem"}}
            title="User account"
        />
    );
}

export { UserAvatar };