import {token} from "brandi";

type ClientGroupModel = {
    id: number;
    name: string;
}

type ClientModel = {
    id: number;
    name: string;
    clientGroupId: number;
}

type SabaQuoteModel = {
    id: number;
    description: string;
    clientId: number;
    clientGroupId: number;
}

type PriceListModel = {
    id: number;
    reference: string;
    savedDate: number;
    createdDate: number;
    sabaQuoteId: number;
    clientId: number;
    clientGroupId: number;
}

export type GetResultModel = PriceListModel & {
    prices: Array<{
        selected: boolean;
        priceListId: number;
        sabaPlanId: number;
        sabaPlanName: string;
        costDaily: number;
        costMonthly: number;
        saleDaily: number;
        saleMonthly: number;
    }>
}

type SavePriceModel = {
    sabaPlanId: number;
    saleMonthly: number;
    saleDaily: number;
}

export type GetAllResultModel = {
    clientGroups: Array<ClientGroupModel>;
    clients: Array<ClientModel>;
    sabaQuotes: Array<SabaQuoteModel>;
    priceLists: Array<PriceListModel>;
}

/**
 * @interface IPriceListRepository Provides access to the VNO Price List related
 * endpoint on the backend API
 */
export interface IPriceListRepository {
    /**
     * Get all client groups, clients, saba quotes and price list headers
     * for the logged in user
     */
    getAll() : Promise<GetAllResultModel>;

    /**
     * Get a specific price list with its prices
     * @param priceListId Id of the Price List for which details are to be retrieved.
     */
    get(priceListId: number): Promise<GetResultModel>;

    /**
     * Create a new price list and returns information about the newly created price list.
     * Note - creates default prices based on the supplied SABA Quote ID.
     * @param clientId Id of the Client with which the Price List is associated.
     * @param sabaQuoteId Id of the SABA Quote that drives the plans and cost prices for the price list.
     * @param reference Human readable reference for the price list.
     * @returns Details of the created price list.
     */
    create(clientId: number, sabaQuoteId: number, reference: string): Promise<PriceListModel>;

    /**
     * Save a Price List and its associated Prices.
     * @param priceListId Id of the Price list whose details are being saved.
     * @param reference The Price List reference value.
     * @param prices A collection of the Sale Prices which have been selected for inclusion in the Price List.
     * @returns Details of the updated Price List.
     */
    save(priceListId: number, reference: string, prices: Array<SavePriceModel>) : Promise<PriceListModel>;

    /**
     * Deletes a Price List (and its associated Prices)
     * @param priceListId Id of the Price List to delete.
     * @returns True if the deletion was successful, false otherwise.
     */
    delete(priceListId: number): Promise<void>;    
}

export const TOKENS = {
    priceListRepository: token<IPriceListRepository>('priceListRepository')
}

type RequestTypeEnum = 'GET' | 'POST' | 'PUT' | 'DELETE';

function MakeRequestInit(requestType: RequestTypeEnum, body: null | object | undefined = null): RequestInit {
    return {
        method: requestType,
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: body ? JSON.stringify(body) : null
    }
}

const baseAddress = `/vno/pricing`;

export class PriceListRepository implements IPriceListRepository {
    
    private _className = "priceListRepository";

    /**
     * Get all client groups, clients, saba quotes and price list headers
     * for the logged in user
     */
    async getAll(): Promise<GetAllResultModel> 
    {
        const methodName = `${this._className}->getAll`;

        const url = `${baseAddress}/priceLists`;

        const response = await fetch(url, MakeRequestInit("GET"));

        if (response.ok){

            // shape of the data returned from the remote API
            type JSONResponse = {
                clientGroups: Array<{
                    id: number, 
                    name: string
                }>,
                clients: Array<{
                    id: number, 
                    name: string, 
                    clientGroupId: number
                }>,
                sabaQuotes: Array<{
                    id: number, 
                    description: string, 
                    clientId: number, 
                    clientGroupId: number
                }>,
                priceLists: Array<{
                    id: number, 
                    reference: string, 
                    createdDateMillis: number, 
                    savedDateMillis: number, 
                    sabaQuoteId: number, 
                    clientId: number, 
                    clientGroupId: number
                }>,
            }

            const data: JSONResponse = await response.json();

            //console.log(`${methodName} api data`, data);

            if (data){
                const result: GetAllResultModel = {
                    clientGroups: data.clientGroups.map(clientGroup => {
                        return {...clientGroup}
                    }),
                    clients: data.clients.map(client => {
                        return {...client}
                    }),
                    sabaQuotes: data.sabaQuotes.map(sabaQuote => {
                        return {...sabaQuote}
                    }),
                    priceLists: data.priceLists.map(priceList => {
                        return {
                            ...priceList, 
                            createdDate: priceList.createdDateMillis, 
                            savedDate: priceList.savedDateMillis
                        }
                    })
                }

                //console.log(`${methodName} result`, result);

                return result;
            }

            return Promise.reject(`${methodName} response was null`);

        } else {
            return Promise.reject(`${methodName} failed ${response.statusText}`);
        }
    }

    /**
     * Get a specific price list with its prices
     * @param priceListId Id of the Price List for which details are to be retrieved.
     */
    async get(priceListId: number) : Promise<GetResultModel>
    {
        const methodName = `${this._className}->get`;

        const url = `${baseAddress}/priceLists/${priceListId}`;

        const response = await fetch(url, MakeRequestInit("GET"));

        if (response.ok){

            // shape of the data returned from the remote API
            type JSONResponse = {
                priceList: {
                    id: number,
                    clientId: number,
                    clientGroupId: number,
                    reference: string,
                    createdDateMillis: number,
                    savedDateMillis: number,
                    sabaQuoteId: number,
                },
                packagePrices: Array<{
                    clientGroupId: number,
                    clientId: number,
                    selected: boolean,
                    priceListId: number,
                    sabaPlanId: number,
                    sabaPlanName: string,
                    costMonthly: number,
                    costDaily: number,
                    saleMonthly: number,
                    saleDaily: number,
                }>
            }

            const data: JSONResponse = await response.json();

            //console.log(`${methodName} api data`, data);

            if (data){
                const result: GetResultModel = {
                    id: data.priceList.id,
                    clientGroupId: data.priceList.clientGroupId,
                    clientId: data.priceList.clientId,
                    createdDate: data.priceList.createdDateMillis,
                    savedDate: data.priceList.savedDateMillis,
                    reference: data.priceList.reference,
                    sabaQuoteId: 0, // TODO: do we need this?
                    prices: data.packagePrices.map(price => {
                        return {
                            costDaily: price.costDaily,
                            costMonthly: price.costMonthly,
                            priceListId: price.priceListId,
                            sabaPlanId: price.sabaPlanId,
                            sabaPlanName: price.sabaPlanName,
                            saleDaily: price.saleDaily,
                            saleMonthly: price.saleMonthly,
                            selected: price.selected
                        }
                    })
                }

                //console.log(`${methodName} result`, result);

                return result;
            }

            return Promise.reject(`${methodName} response was null`);
        } else {
            return Promise.reject(`${methodName} failed ${response.statusText}`);
        }
    }

    /**
     * Create a new price list and returns information about the newly created price list.
     * Note - creates default prices based on the supplied SABA Quote ID.
     * @param clientId Id of the Client with which the Price List is associated.
     * @param sabaQuoteId Id of the SABA Quote that drives the plans and cost prices for the price list.
     * @param reference Human readable reference for the price list.
     * @returns Details of the created price list.
     */
    async create(clientId: number, sabaQuoteId: number, reference: string): Promise<PriceListModel>
    {
        const methodName = `${this._className}->create`;

        const url = `${baseAddress}/priceLists`;

        const postBody = {
            clientId,
            sabaQuoteId,
            reference
        }

        // if (clientId === 37){
        //     return Promise.reject("error");
        // }

        const response = await fetch(url, MakeRequestInit("POST", postBody));

        if (response.ok){

            // shape of the data returned from the remote API
            type JSONResponse = {
                priceList: {
                    id: number,
                    reference: string,
                    createdDateMillis: number,
                    savedDateMillis: number,
                    sabaQuoteId: number,
                    clientGroupId: number,
                    clientId: number,
                }
            }

            const data: JSONResponse = await response.json();

            //console.log(`${methodName} api data`, data);

            if (data){
                const result: PriceListModel = {
                    id: data.priceList.id,
                    clientId: data.priceList.clientId,
                    clientGroupId: data.priceList.clientGroupId,
                    reference: data.priceList.reference,
                    createdDate: data.priceList.createdDateMillis,
                    savedDate: data.priceList.savedDateMillis,
                    sabaQuoteId: data.priceList.sabaQuoteId,
                }

                //console.log(`${methodName} result`, result);

                return result;
            }

            return Promise.reject(`${methodName} response was null`);
        } else {
            return Promise.reject(`${methodName} failed ${response.statusText}`);
        }
    }

    /**
     * Save a Price List and its associated Prices.
     * @param priceListId Id of the Price list whose details are being saved.
     * @param reference The Price List reference value.
     * @param prices A collection of the Sale Prices which have been selected for inclusion in the Price List.
     * @returns Details of the updated Price List.
     */
    async save(priceListId: number, reference: string, prices: Array<SavePriceModel>) : Promise<PriceListModel>
    {
        const methodName = `${this._className}->save`;

        const url = `${baseAddress}/priceLists/${priceListId}`;

        const putBody = {
            reference,
            selectedSalePrices : prices.map(price => {
                return {
                    sabaPlanId: price.sabaPlanId,
                    saleMonthly: price.saleMonthly,
                    saleDaily: price.saleDaily
                }
            })
        }

        const response = await fetch(url, MakeRequestInit("PUT", putBody));

        if (response.ok){

            // shape of the data returned from the remote API
            type JSONResponse = {
                priceList: {
                    id: number,
                    reference: string,
                    createdDateMillis: number,
                    savedDateMillis: number,
                    sabaQuoteId: number,
                    clientGroupId: number,
                    clientId: number,                    
                }
            }

            const data: JSONResponse = await response.json();

            //console.log(`${methodName} api data`, data);

            if (data){
                const result: PriceListModel = {
                    id: data.priceList.id,
                    clientId: data.priceList.clientId,
                    clientGroupId: data.priceList.clientGroupId,
                    reference: data.priceList.reference,
                    createdDate: data.priceList.createdDateMillis,
                    savedDate: data.priceList.savedDateMillis,
                    sabaQuoteId: data.priceList.sabaQuoteId,                
                }

                //console.log(`${methodName} result`, result);

                return result;
            }

            return Promise.reject(`${methodName} response was null`);
        } else {
            return Promise.reject(`${methodName} failed ${response.statusText}`);
        }
    }

    /**
     * Deletes a Price List (and its associated Prices)
     * @param priceListId Id of the Price List to delete.
     * @returns True if the deletion was successful, false otherwise.
     */
    async delete(priceListId: number): Promise<void> 
    {
        const methodName = `${this._className}->delete`;

        const url = `${baseAddress}/priceLists/${priceListId}`;

        const response = await fetch(url, MakeRequestInit("DELETE"));

        if (response.ok){
            return Promise.resolve();
        } else {
            return Promise.reject(`${methodName} failed ${response.statusText}`);
        }
    }
}