import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./Header.module.css";

interface IHeaderProps {
    text: string;
    canDrop?: boolean;
    isOver?: boolean;
    isExpandable?: boolean;
    isExpanded?: boolean; 
    onToggleExpanded?: () => void;
    children?: React.ReactNode;
}

export const Header : React.FC<IHeaderProps> = ({
    text, 
    canDrop,
    isOver,
    isExpandable = true,
    isExpanded,
    onToggleExpanded,
    children
}) => {

    return (
        <div className={styles.root}>
            <div className={styles.content}> 
                {isExpandable &&
                    <div className={styles.expansion}>
                        <div className={styles.expansionButtonContainer}>
                            <button className={styles.expansionButton} 
                                onClick={onToggleExpanded} 
                                title="Expand/collapse"
                            >
                                <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown}/>
                            </button>
                        </div>
                    </div>
                }
                <div className={`${classNames(styles.text, canDrop && styles.canDrop, (isOver && canDrop) && styles.isOver )}`}>
                    {text}
                </div>
                <div className={styles.buttons}>
                    {children}
                </div>
            </div>
        </div>
    )
}
