import React, { useContext, useEffect } from "react";
import { useMap} from 'react-map-gl';
import { MapLocationType, MapLocationsContext } from "../../../../contexts/components/MapLocationsContext";

export const FlyTo : React.FC = () => {

    const {current: shipMapGl} = useMap();

    const { locations, isLoading } = useContext(MapLocationsContext);

    useEffect(() => {
        // fly to the first location when the locations
        // change (i.e. when the ship or date range has changed)
        if (!isLoading)
        {
            // get the iterator for the locations javascript map structure
            const entries = locations.values();
            // get the first location
            const first = entries.next().value as MapLocationType;

            if (first && first.lat && first.lng && shipMapGl) {
                // get the actual value from the structure
                shipMapGl.flyTo({ center: [first.lng, first.lat], zoom: 1 })
            }
            else{
                shipMapGl?.flyTo({center: [0,0], zoom: 1});
            }
        }
    }, [isLoading, locations, shipMapGl])


    return null;
}
