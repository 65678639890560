import React from "react";
import { toast } from "react-toastify";
import { api, fetchApiPut, fetchHandler } from "../../../../dataServices/components";
import { Button } from "../../../buttons";
import { IsPasswordValid } from "../../../pages/auth/components";
import SectionHeader from "../userSettings/SectionHeader";
import styles from "./ChangePassword.module.css";
import { Password } from "./Password";

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = React.useState<string>('');

    const [newPassword, setNewPassword] = React.useState<string>('');
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = React.useState<string>('');

    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState<string>('');

    const [isProcessing, setIsProcessing] = React.useState(false);

    function handleOnOldPasswordChange(value: string){
        setOldPassword(value);
        setOldPasswordErrorMessage('');
    }

    function handleOnNewPasswordChange(value: string){
        setNewPassword(value);
        setNewPasswordErrorMessage('');
    }

    function handleOnConfirmPasswordChange(value: string){
        setConfirmPassword(value);
        setConfirmPasswordErrorMessage('');
    }

    /**
     * Handle the user pressing the submit button
     */
    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        
        event.preventDefault();

        setOldPasswordErrorMessage('');
        setNewPasswordErrorMessage('');
        setConfirmPasswordErrorMessage('');

        let errors = false;

        if (oldPassword.trim() === ''){
            setOldPasswordErrorMessage("Old Password is required");
            errors = true;
        }

        if (newPassword.trim() === ''){
            setNewPasswordErrorMessage("New Password is required");
            errors = true;
        }

        if (!IsPasswordValid(newPassword)){
            setNewPasswordErrorMessage("New Password is not valid");
            errors = true;
        }

        if (confirmPassword.trim() === ''){
            setConfirmPasswordErrorMessage("Confirm Password is required");
            errors = true;
        }

        if (!IsPasswordValid(confirmPassword)){
            setConfirmPasswordErrorMessage("Confirm Password is not valid");
            errors = true;
        }

        if (confirmPassword !== newPassword){
            setConfirmPasswordErrorMessage("New Password and Confirm New Password do not match");
            errors = true;
        }

        if (errors){
            return;
        }

        setIsProcessing(true);

        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        };

        fetchApiPut(api.urls.auth.put.changePassword(), data, {includeCredentials: true})
        .then((putResponse) => {
            setIsProcessing(false);
            void fetchHandler(putResponse)
            .then((handlerResult) => {
                if (handlerResult.succeeded){
                    toast.success("Your password has been updated");
                } else {
                    const serverErrors : string[] = [];

                    handlerResult.errors.forEach(err => {
                        if (err.startsWith("Old")){
                            setOldPasswordErrorMessage(err);
                        } else if (err.startsWith("New")){
                            setNewPasswordErrorMessage(err);
                        } else if (err.startsWith("Confirm")){
                            setConfirmPasswordErrorMessage(err);
                        } else if (err === "Incorrect password."){
                            setOldPasswordErrorMessage("Password is incorrect");
                        } else {
                            serverErrors.push(err);
                        }
                    })
                    if (serverErrors.length > 0) {
                        toast.error("Ooops. Something went wrong updating your password");
                    }
                }
            })
        })
        .catch(() => {
            setIsProcessing(false);
            toast.error("Ooops. Something went wrong updating your password");
        })

    }

    return (
        <form className={styles.root} onSubmit={handleSubmit} name="changePasswordForm">
            <SectionHeader text="Update Your Password"/>
            <div className={styles.form}>
                <Password 
                    name="oldPassword" 
                    placeholder="Old Password" 
                    disabled={isProcessing} 
                    errorMessage={oldPasswordErrorMessage} 
                    onChange={(value) => handleOnOldPasswordChange(value)}
                    autofocus={true}
                    />
                <Password
                    name="newPassword"
                    placeholder="New Password"
                    disabled={isProcessing}
                    errorMessage={newPasswordErrorMessage}
                    onChange={(value) => handleOnNewPasswordChange(value)}
                    />
                <Password
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    disabled={isProcessing}
                    errorMessage={confirmPasswordErrorMessage}
                    onChange={(value) => handleOnConfirmPasswordChange(value)}
                    />
            </div>
            <Button text="Update" />
        </form>
    )
}

export { ChangePassword };
