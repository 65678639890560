import styles from "./Tree.module.css";

interface ITreeProps {
    children: React.ReactNode;
}

export const Tree : React.FC<ITreeProps> = ({
    children,
}) => {

    return (
        <div className={styles.tree}>
            {children}
        </div>
    )
}