import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useEscapeKey } from "../../../../../../hooks";
import { Button } from "../../../../../buttons";
import styles from "./ClientGroupUsersActionMenu.module.css";

interface IClientGroupUsersActonMenuProps {
    onOk: () => void;
    onCancel: () => void;
}

export const ClientGroupUsersActionMenu : React.FC<IClientGroupUsersActonMenuProps> = ({
    onOk,
    onCancel,
}) => {
    // a bit belt and braces and will probably never be used as the rest
    // of the user management functionality isn't really keyboard
    // accessible - but interesting to use!
    useEscapeKey(onCancel);

    return (
        <ClickAwayListener onClickAway={onCancel} >
            <div className={styles.root} role="dialog">
                <h3>Confirmation Required</h3>
                <p>Are you sure you want to make this User an Administrator?</p>
                <p>Administrators are able to view and manage all Customers <br/>and Vessels, and manage Users.</p>
                <div className={styles.buttonContainer}>
                    <Button
                        size="small"
                        text="Ok"
                        color="success"
                        onClick={onOk}
                    />
                    <Button 
                        size="small"
                        text="Cancel"
                        color="secondary"
                        onClick={onCancel}
                    />
                </div>
            </div>
        </ClickAwayListener>

    )
}