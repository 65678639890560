import React, { RefObject, useState, useEffect, createRef } from "react";
import { DownloadSpeedsChart } from "./downloadSpeed/DownloadSpeedsChart";
import { UploadSpeedsChart } from "./uploadSpeed/UploadSpeedsChart";
import { RfPerformanceChart } from "./rfPerformance/RfPerformanceChart";
import { DataVolumesChart } from "./dataVolumes/DataVolumesChart";
import { WanStatusesChart } from "./wanStatuses/WanStatusesChart";
import { ChartsProps } from "./ChartsProps";
import { ChartMargins } from "./components/chartMargins/ChartMargins";

import styles from "./Charts.module.css";

const margins : ChartMargins = {
    // margin between the left side of the chart container and the left axis
    marginLeft: 50,
    // margin between the right side of the SVG and the right edge of the xAxis
    marginRight: 50,
    // height of the area in which the xAxis will be displayed (effectively a bottom margin for the chart)
    xAxisHeight: 20,
}


export const Charts: React.FC<ChartsProps> = ({
    showWanStatuses, 
    dataVolumesChartState, 
    downloadSpeedsChartState,
    rfPerformanceChartState, 
    uploadSpeedsChartState, 
    wanStatusesChartState
}) => {

    const divRef: RefObject<HTMLDivElement> = createRef();
    const [chartWidth, setChartWidth] = useState<number>(0);
    const chartHeight = 150;

    // watch for window resize events and set the chart width
    // based on the new width of the div that contains the charts
    // NOTE: it's possible we could use the useWindowSize hook for this
    // but this direct approach seems to provide better screen response
    // with smoother updates
    useEffect(() => {
        function handleResize() {
            if (divRef.current) {
                setChartWidth(divRef.current.clientWidth - 2);
            }
        }

        // watch for window resize events
        window.addEventListener("resize", handleResize);

        // get initial size
        handleResize();

        // remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [divRef]);

    return (
        <div ref={divRef} className={styles.root}>
            <WanStatusesChart 
                overallWidth={chartWidth} 
                overallHeight={chartHeight} 
                chartState={wanStatusesChartState} 
                margins={margins} 
                showContent={showWanStatuses}/>
            <DataVolumesChart 
                overallWidth={chartWidth} 
                overallHeight={chartHeight} 
                chartState={dataVolumesChartState} 
                margins={margins} />
            <DownloadSpeedsChart 
                overallWidth={chartWidth} 
                overallHeight={chartHeight} 
                chartState={downloadSpeedsChartState} 
                margins={margins} />
            <UploadSpeedsChart 
                overallWidth={chartWidth} 
                overallHeight={chartHeight} 
                chartState={uploadSpeedsChartState} 
                margins={margins} />
            <RfPerformanceChart 
                overallWidth={chartWidth} 
                overallHeight={chartHeight} 
                chartState={rfPerformanceChartState} 
                margins={margins} />
        </div>
    );
};
