import { useContext } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { AuthContext } from "../../contexts/components/AuthContext";
import { logger } from "../../debug/consoleLogger";
import styles from "./NotAuthenticatedLayout.module.css";

export const NotAuthenticatedLayout = () => {
    const {user} = useContext(AuthContext);
    const outlet = useOutlet();

    if (user){
        logger.log("returning to /")
        return <Navigate to="/home" replace />
    }

    return (
        <div className={styles.root}>
            {outlet}
        </div>
    )    
}