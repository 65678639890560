import { toDateAndTime } from "../utils/Utils"

export const DateTableDataCell = ({
    value,
    title,
} : {
    value: number,
    title: string,
}) => {
    return (
        <td title={title}>
            <div>
                {toDateAndTime(value)}
            </div>
        </td>
    )
}