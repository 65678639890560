import { useContext } from "react";
import { useFetchScheduledChangesStatus } from "../../../../../../../../dataServices/dataPackages/scheduledChanges/useFetchScheduledChangesStatus";
import { Dates } from "../../../../../../../../utils/dates";
import { Button } from "../../../../../../../buttons";

import { AuthContext } from "../../../../../../../../contexts/components/AuthContext";
import styles from "./PackageChangeButtons.module.css";

export const PackageChangeButtons = (
    {
        wanInterfaceId,
        lastRefresh,
        onEditChanges,
        onImmediateUpgrade,
    }:
    {
        wanInterfaceId?: number,
        lastRefresh: number,
        onEditChanges: () => void,
        onImmediateUpgrade: () => void,
    }
) => {

    const {state} = useFetchScheduledChangesStatus(wanInterfaceId, lastRefresh);

    const {user} = useContext(AuthContext);

    if (wanInterfaceId){

        if (user?.canSchedulePackageChanges) {
            if (state.status === "error") {
                return <div>Ooops...</div>;
            }
            else if (state.status === "in_progress") {
                return <div>Loading...</div>;
            }
            else {
                return (
                    <div>
                        <div className={styles.buttonContainer}>
                            {/* Always show the button to allow package changes */}
                            <div>
                                <Button
                                    text="Schedule Package Changes..."
                                    color="primary"
                                    onClick={onEditChanges}
                                />
                            </div>
                            {/* Only show the Immediate upgrade button if it's allowed */}
                            {state.data?.immediateUpgradeIsAllowed &&
                                <div>
                                    <Button
                                        text="Perform an Immediate Upgrade..."
                                        color="primary"
                                        onClick={onImmediateUpgrade}
                                    />   
                                </div>
                            }
                        </div>
                        {/* Show a message if an Immediate upgrade is not allowed */}
                        {!state.data?.immediateUpgradeIsAllowed && state.data?.earliestImmediateUpgradeStartDateMillis &&
                            <div className={styles.immediateMessageContainer}>
                                <p>
                                    Please note, you cannot perform an Immediate Package Upgrade.
                                </p>
                                <p>
                                    The earliest you can perform an Immediate Package Upgrade is {Dates.fromMillis(state.data?.earliestImmediateUpgradeStartDateMillis).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}
                                </p>
                            </div>
                        }
                    </div>
                )
            }
        }
    } 
    else 
    {
        return null;
    }
}