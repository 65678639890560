import type { Point } from "geojson";
import React, { useContext, useEffect } from "react";
import { Layer, Source } from "react-map-gl";
import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";
import { MapLocationType, MapLocationsContext } from "../../../../contexts/components/MapLocationsContext";

function makeShipPoint(location: MapLocationType | undefined): Point | undefined {

    return {
        type: "Point",
        coordinates: (location && location.lng && location.lat) ? [location.lng, location.lat] : [0,0]
    };
}

export const ShipPoint = ({ visible } : { visible: boolean }) => {

    const [geoJson, setGeoJson] = React.useState<Point | undefined>(undefined);

    const {locations} = useContext(MapLocationsContext);
    const {dateMillis} = useContext(MapDatePointContext);

    useEffect(() => {
        setGeoJson(makeShipPoint(locations.get(dateMillis)));
    }, [dateMillis, locations])

    return (
        <Source id={"ship-point"} type="geojson" data={geoJson}>
            <Layer 
                id="ship-point-layer-id"
                type="circle"
                paint={{
                    "circle-radius": 6, 
                    "circle-color": "#ffffff",
                    "circle-stroke-width": 3,
                    "circle-stroke-color": "#000000"
                }}
                layout={{
                    visibility: visible ? "visible" : "none"
                }} 
            />
        </Source>
    )
}
