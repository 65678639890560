import React from "react";

import styles from './DateSelectorButton.module.css';

interface Props{
    title?:string,
    onClick?: ()=> void,
    disabled?:boolean,
    className?: string,
    children?: React.ReactNode
}

const DateSelectorButton : React.FC<Props> = ({
    title, 
    disabled=false, 
    onClick, 
    className,
    children
}) => {
    
    return (
        <button 
            className={`${styles.button} ${className}`}
            title={title}
            onClick={onClick}
            disabled={disabled}
        >
                <CalendarIcon title={title ? title : "calendar"} />
            {children}
        </button>
    )
}

const CalendarIcon = (props: { title: string }) => {

    const { title } = props;

    return (
        <svg
            id="68549F8E-A173-419F-998B-F5AA4A25BD1E"
            x="0"
            y="0"
            viewBox="0 0 24 24"
            width="24"
            height="24"
        >
            <title>{title}</title>
            <g>
                <path d="M2.252 24a2.252 2.252 0 0 1-2.25-2.25V5.25A2.252 2.252 0 0 1 2.252 3h3.75V.75a.75.75 0 0 1 1.5 0V3h9V.75a.75.75 0 0 1 1.5 0V3h3.75a2.252 2.252 0 0 1 2.25 2.25v16.5a2.252 2.252 0 0 1-2.25 2.25h-19.5zm-.75-2.25c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75V10.5h-21v11.25zm21-12.75V5.25a.75.75 0 0 0-.75-.75h-3.75V6a.75.75 0 0 1-1.5 0V4.5h-9V6a.75.75 0 0 1-1.5 0V4.5h-3.75a.75.75 0 0 0-.75.75V9h21z" />
            </g>
        </svg>
    );
}
export { DateSelectorButton };