import styles from "./SelectList.module.css";

export const SelectList = ({
    options,
    defaultValue,
    onChange,
} : {
    options: Array<{value: number, text: string}>,
    defaultValue: number | undefined,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
}) =>{
    return (
        <select onChange={onChange} value={defaultValue} className={styles.root}>
            {options.map(options => {
                return <option key={options.value} value={options.value}>{options.text}</option>
            })}
        </select>
    )
}