import { useContext } from "react";
import { SelectedShipContext } from "../../../../../../../../../contexts/components/SelectedShipContext";
import { ContentHeader } from "../../../../contentContainer/ContentHeader";
import { NothingSelectedMessage } from "../../../../contentContainer/NothingSelectedMsg";
import { PackageItemsList } from "./PackageItemsList";


/**
 * Component is a container for the Data Packages and Change Package functionality
 */
export const DataPackages = () => {

    const {ship} = useContext(SelectedShipContext);

    // don't be tempted to enclose the returned content in a div because is screws the overflow-y
    return (
        <>
            <ContentHeader title="Subscriptions and Data Packages" />
            { ship ? <PackageItemsList /> : <NothingSelectedMessage /> }
        </>
    );
}