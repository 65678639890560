import React, { useContext, useEffect, useState } from "react";
import { ShipModel } from "../../../../dataServices/ships";
import { api, fetchApiGet, fetchHandler } from "../../../../dataServices/components";
import { FilteredList } from "./components/filteredList/FilteredList";
import { logger } from "../../../../debug/consoleLogger";
import { AuthContext } from "../../../../contexts/components/AuthContext";

// get the installations for the current user
export const Installations : React.FC = () => {

    const [installations, setInstallations] = useState<ShipModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadedRef = React.useRef<boolean>(false);

    const {user, signOut} = useContext(AuthContext);

    useEffect(() => {
        if (loadedRef.current === true) return;
        if (user === null) return;

        interface IApiModel {
            id: number;
            name: string;
            clientId: number;
            clientName: string;
            usesMarpoint: boolean;
            status : {
                statusCheckTimeMillis: number;
                wanStatus: string | null;
                wanStatusObservationTimeMillis: number | null;
                locationLat: number | null;
                locationLng: number | null;
                locationObservationTimeMillis: number | null;
            }
        }        
        function mapper(apiData: IApiModel[]) : ShipModel[]
        {
            return apiData.map((value) => {
                return {
                    installationId: value.id,
                    name: value.name,
                    clientId: value.clientId,
                    clientName: value.clientName,
                    usesMarpoint: value.usesMarpoint,
                    statusCheckMillis: value.status.statusCheckTimeMillis,
                    locationLat: value.status.locationLat,
                    locationLng: value.status.locationLng,
                    locationObservationMillis: value.status.locationObservationTimeMillis,
                    wanStatus: value.status.wanStatus,
                    wanStatusObservationMillis: value.status.wanStatusObservationTimeMillis
                }
            })
        }

        // TODO: this all looks very messy
        async function loadInstallations(){
            
            setIsLoading(true);
                        
            await fetchApiGet(api.urls.installations.get.installations(), {includeCredentials: true})
            .then((response) => {
                fetchHandler(response)
                .then((result) => {
                    if (result.response.status === 401){
                        setIsLoading(false);
                        signOut(()=> {});
                    }
                    else if (result.succeeded){
                        const items = mapper(result.json as IApiModel[]);
                        setInstallations(items);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                })
                    .catch(() => {logger.error("an error occurred") })
            })
            .catch((error) => {
                logger.error("Error", [error]);
                setIsLoading(false);
            });
        }
    
        loadedRef.current = true;
        loadInstallations().then(() => { }).catch(() => { });
    },[user, signOut])

    return (
        <FilteredList isLoading={isLoading} installations={installations} />
    )
}
