export const authenticatedGetOptions : RequestInit = {
    credentials: "include"
}

export const unAuthenticatedGetOptions : RequestInit = {
    credentials: "omit"
}

export const postOptions: RequestInit = {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    credentials: "include",
}

export const putOptions : RequestInit = {
    method: 'PUT',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    credentials: "include",
}

export const deleteOptions: RequestInit = {
    method: 'DELETE',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    credentials: 'include', 
}
