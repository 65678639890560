import { ContentContainer, Content, Heading, SubHeading, TextValue } from "../common";
import { NoActionsView } from "../actions";

export const ClientGroupView = ({
    name,
} : {
    name: string,
}) => {
    return (
        <ContentContainer>
            <Content>
                <Heading text="Customer Group" />
                <SubHeading text="Name" />
                <TextValue text={name} />
            </Content>
            <NoActionsView/>
        </ContentContainer>
    )
}