import { useState } from "react";
import { useDrop } from "react-dnd";
import { IUser } from "../../data/Models";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";
import { Header } from "./Header";
import { ClientGroupUser } from "./ClientGroupUser";
import { Actions, ActionTypes } from "../../data/State";
import { DataApi } from "../../data/DataApi";
import { notifyError } from "../../../common/errors/Errors";
import { ClientGroupUsersActionMenu } from "./ClientGroupUsersActionMenu";

interface IClientGroupUsersProps {
    clientGroupId: number;
    users: IUser[];
    dispatch: React.Dispatch<ActionTypes>;
}

export const ClientGroupUsers : React.FC<IClientGroupUsersProps> = ({
    clientGroupId,
    users,
    dispatch,
}) => {
    const [showUsers, setShowUsers] = useState<boolean>(true);
    const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
    const [dropProps, setDropProps] = useState<IDropProps | null>(null);

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: ItemTypes.USER,
            canDrop: (item) => item.type === "unassigned" ? true : item.type === "client-group" ? item.clientGroupId !== clientGroupId : item.clientGroupId === clientGroupId,
            drop: (item: IUserDnd) => handleDrop({
                userId: item.userId,
                userName: item.userName,
            }),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    )

    const handleDrop = async (props: IDropProps) => {
        setShowActionMenu(true);
        setDropProps(props);
    }
    
    const addUser = async () => {
        if (dropProps === null) return;

        //console.log(`adding admin user ${dropProps.userId} ${dropProps.userName}`);

        let ok = false;

        try {
            const response = await DataApi.addUserToClientGroup(dropProps.userId, clientGroupId);
    
            ok = response.ok;
        }
        catch (error) {
            console.error('There was an error', error);
        }
    
        if (ok){
            dispatch({
                type: Actions.USER_ADDED_TO_CLIENT_GROUP,
                data: {
                    userId: dropProps.userId,
                    userName: dropProps.userName,
                    clientGroupId: clientGroupId,
                }
            })
        } else {
            notifyError();
        }
    
        setShowActionMenu(false);
    }

    return (
        <li>
            <div ref={drop}>
                <Header 
                    text="Admin Users" 
                    isExpanded={showUsers}
                    onToggleExpand={()=> setShowUsers(prev => !prev)}
                    canDrop={canDrop}
                    isOver={isOver}
                />
            </div>
            <ul>
                {showActionMenu &&
                    <ClientGroupUsersActionMenu
                        onOk={addUser}
                        onCancel={() => setShowActionMenu(false)}
                    />
                }
                {showUsers && users.map(
                    user => 
                        <ClientGroupUser
                            key={user.id}
                            clientGroupId={clientGroupId}
                            user={user}
                            dispatch={dispatch}
                        />
                )}
            </ul>
        </li>
    )
}

interface IDropProps {
    userId: number;
    userName: string;
}

// interface IhandleDropProps {
//     source: IUserDnd;
//     target: {
//         clientGroupId: number,
//     };
//     dispatch: React.Dispatch<ActionTypes>;
// }


// const actualHandleDrop = async (props: IhandleDropProps) => {
    
//     const {source, target, dispatch} = props;

//     let ok = false;

//     try {
//         const response = await DataApi.addUserToClientGroup(source.userId, target.clientGroupId);

//         ok = response.ok;
//     }
//     catch (error) {
//         console.error('There was an error', error);
//     }

//     if (ok){
//         dispatch({
//             type: Actions.USER_ADDED_TO_CLIENT_GROUP,
//             data: {
//                 userId: source.userId,
//                 userName: source.userName,
//                 clientGroupId: target.clientGroupId,
//             }
//         })
//     } else {
//         notifyError();
//     }
// }