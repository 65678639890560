import styles from "./FormContainer.module.css";

interface IFormContainerProps {
    isProcessing: boolean;
    children: React.ReactNode;
}
export const FormContainer : React.FC<IFormContainerProps> = ({
    isProcessing,
    children
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                {children}
                {isProcessing &&
                    <div className={styles.processing}></div>
                }
            </div>
        </div>
    )
}