import styles from "./NoRoutesIndicator.module.css";

export const NoRoutesIndicator = ({
    visible,
    vesselName,
}: {
    visible: boolean,
    vesselName?: string
}) => {

    if (!visible) return null;

    return (
        <div className={styles.root}>
            <div className={styles.text}>
                {vesselName ? `There is no location information for ${vesselName}` : "Nothing selected"}
            </div>
        </div>
    )
}