import styles from "./TreeRoot.module.css";

export const TreeRoot = ({
    children
} : {
    children: React.ReactNode
}) => {

    return (
        <ul className={styles.root}>
            {children}
        </ul>
    )
}