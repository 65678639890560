import React from "react";
import { api, useGetData } from "../components";
import { StarlinkObstructionImageModel } from "./Models";

/**
 * Shape of the data that is returned by the remote API call
 */
interface IApiModel {
    imageData: string;
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData
 * @returns
 */
function mapper(apiData: IApiModel): StarlinkObstructionImageModel {
    return { ...apiData };
}

/**
 * Hook to retrieve Starlink Obstruction Image data for a given ship over a given time range
 * @param installationId Id of the Installation whose data is to be retrieved
 * @returns
 */
const useFetchStarlinkObstructionImage = (installationId: number | undefined) => {

    const [url, setUrl] = React.useState<string>('');
    const { state } = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.starlink.get.obstructionImage(installationId);

        setUrl(url);

    }, [installationId]);

    return { state };
}

export { useFetchStarlinkObstructionImage };