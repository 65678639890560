import { NoActionsView } from "../actions"
import { Content, ContentContainer, SubHeading, TextValue } from "../common"

export const ErrorView = ({
    message,
} : {
    message?: string
}) => {
    return (
        <ContentContainer>
            <Content>
                <SubHeading text="Ooops" />
                <TextValue text={message ? message : "Sorry, a problem occurred"} />
            </Content>
            <NoActionsView/>
        </ContentContainer>
    )
}