import { ContentAndKeyContainer } from "./ContentAndKeyContainer"
import { ChartContainer } from "./ChartContainer"
import { ChartTitle } from "./ChartTitle"
import { ChartContent } from "./ChartContent"
import { ChartMargins } from "./ChartMargins";
import { LoadingIndicator } from "./LoadingIndicator";
import { ErrorIndicator } from "./ErrorIndicator";

interface ChartData {
    data: string; // change this
}

interface PerformanceChartState {
    isLoading: boolean;
    isError: boolean;
    data: ChartData;
}

interface PerformanceChartProps {
    overallHeight: number;
    overallWidth: number;
    chartMargins: ChartMargins;
    chartState: PerformanceChartState;
}

export const PerformanceChart = ({ overallHeight, overallWidth, chartMargins, chartState }: PerformanceChartProps) => {
    return (
        <ChartContainer>
            <ChartTitle title="Performance Over Time" />
            <ContentAndKeyContainer>
                <ChartContent overallHeight={overallHeight}>{
                    chartState.isLoading
                        ? <LoadingIndicator />
                        : chartState.isError
                            ? <ErrorIndicator />
                            : <PerformanceChartContent
                                overallHeight={overallHeight}
                                overallWidth={overallWidth}
                                chartMargins={chartMargins}
                                chartData={chartState.data}
                            />
                }
                </ChartContent>
            </ContentAndKeyContainer>
        </ChartContainer>
    )
}

interface PerformanceChartContentProps {
    overallHeight: number;
    overallWidth: number;
    chartMargins: ChartMargins;
    chartData: ChartData;
}

const PerformanceChartContent = ({ overallHeight, overallWidth, chartMargins, chartData }: PerformanceChartContentProps) => {
    return (
        <div>
            chart content displayed here
            <div>{overallHeight}</div>
            <div>{overallWidth}</div>
            <div>{JSON.stringify(chartMargins, null, 2)}</div>
            <div>{JSON.stringify(chartData, null, 2)}</div>
        </div>
    )
}