import { DataVolumesModel } from "../../../../../../../../dataServices/metrics/dataVolumes";
import { DownloadSpeedsModel } from "../../../../../../../../dataServices/metrics/downloadSpeeds";
import { RfPerformanceModel } from "../../../../../../../../dataServices/metrics/rfPerformance";
import { UploadSpeedsModel } from "../../../../../../../../dataServices/metrics/uploadSpeeds";
import { WanStatusesModel } from "../../../../../../../../dataServices/metrics/wanStatuses";

import { UploadSpeedsChartSeries } from "./uploadSpeed/UploadSpeedsChartSeries";
import { DownloadSpeedsChartSeries } from "./downloadSpeed/DownloadSpeedsChartSeries";
import { RfPerformanceChartSeries } from "./rfPerformance/RfPerformanceChartSeries";
import { DataVolumesChartSeries } from "./dataVolumes/DataVolumesChartSeries";
import { WanStatusesChartSeries } from "./wanStatuses/WanStatusesChartSeries";

import { WanStatusesChartData } from "./wanStatuses/WanStatusesChartData";
import { DownloadSpeedsChartData } from "./downloadSpeed/DownloadSpeedsChartData";
import { UploadSpeedsChartData } from "./uploadSpeed/UploadSpeedsChartData";


import { schemeCategory10, interpolateReds, interpolateBlues, interpolateGreens } from "d3-scale-chromatic";
import { DataVolumesChartData } from "./dataVolumes/DataVolumesChartData";
import { RfPerformanceChartData } from "./rfPerformance/RfPerformanceChartData";

export function mapWanStatusesData(serverData: WanStatusesModel): WanStatusesChartData {
    
    type WanInterface = {
        name: string, 
        sort: number,
        color: string,
        isOffline: boolean,
        isAura: boolean,
    }

    const seriesItems = (data: WanStatusesModel) => {
        const chartSeries: WanStatusesChartSeries[] = [];

        try {

            // create list of unique interface
            const interfaces: WanInterface[] =
                data.values.filter((interfaceName, index, interfaceNames) => interfaceNames.indexOf(interfaceName) === index)
                    .map((name) => {
                        return {
                            name: name,
                            sort: 0,
                            color: '',
                            isOffline: name.toLowerCase() === "offline",
                            isAura: (name.toLowerCase().indexOf("aura") > -1) || (name.toLowerCase().indexOf("vsat") > -1)
                        }
                    });

            // assign usefule properties to each interface
            let auraColor = 0.7;
            let otherColor = 0.7;

            // assign useful properties to each interface
            interfaces.forEach((item) => {
                if (item.isOffline) {
                    item.sort = 0;
                    item.color = interpolateReds(0.8);
                } else if (item.isAura) {
                    item.sort = 2;
                    item.color = interpolateGreens(auraColor);
                    auraColor -= 0.1;
                } else {
                    item.sort = 1;
                    item.color = interpolateBlues(otherColor);
                    otherColor -= 0.1;
                }
            })

            // sort the interfaces
            interfaces.sort((a, b) => {
                if (a.sort < b.sort) return -1;
                if (a.sort > b.sort) return 1;
                return 0;
            })

            // create series for each interface
            interfaces.forEach((interfaceItem, interfaceIndex) => {
                chartSeries[interfaceIndex] = {
                    color: interfaceItem.color,
                    label: interfaceItem.name,
                    index: interfaceIndex,
                    chartItems: data?.lowerTimeStampsMillis.map((timeStamp, timeStampIndex) => {
                        return {
                            tsLowerMillis: timeStamp,
                            tsUpperMillis: data.upperTimeStampsMillis[timeStampIndex],
                            show: data.values[timeStampIndex] === interfaceItem.name ? true : false
                        }
                    }),
                }
            })
        }
        catch (error) {
            console.log("error ", error);
        }

        return chartSeries;
    }

    const result = {
        fromMillis: serverData.fromMillis,
        toMillis: serverData.toMillis,
        intervalMillis: serverData.intervalMillis,
        series: seriesItems(serverData)
    };

    return result;
}

export function mapDataVolumesData(serverData: DataVolumesModel): DataVolumesChartData {

    const upKeyItems = (data: DataVolumesModel) => {
        const keyMap = new Map<number, number>();
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.upValues[index])});
        return keyMap;
    }

    const downKeyItems = (data: DataVolumesModel) => {
        const keyMap = new Map<number,number>();
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.downValues[index])});
        return keyMap;
    }

    const seriesItems = (data: DataVolumesModel) => {
        const series: DataVolumesChartSeries[] = [];

        try {
            series[0] = {
                color: schemeCategory10[0],
                total: data.upValues.reduce((a, b) => a + b, 0),
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        offset: 0,
                        value: data.upValues[index]
                    }
                }),
                keyItems: upKeyItems(data)
            };

            series[1] = {
                color: schemeCategory10[1],
                total: data.downValues.reduce((a, b) => a + b, 0),
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        offset: data.upValues[index],
                        value: data.upValues[index] + data.downValues[index]    // creating data for a stacked bar chart
                    }
                }),
                keyItems: downKeyItems(data)
            };
        }
        catch (error) {
            console.log("error", error);
        }

        return series;
    }

    const result = {
        fromMillis: serverData.fromMillis,
        toMillis: serverData.toMillis,
        intervalMillis: serverData.intervalMillis,
        series: seriesItems(serverData)
    };

    return result;
}

export function mapRfPerformanceData (serverData: RfPerformanceModel) : RfPerformanceChartData {

    const cnoKeyItems = (data : RfPerformanceModel) => { 
        const keyMap = new Map<number, number>(); 
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.cnoValues[index])}); 
        return keyMap; 
    };
    
    const snrKeyItems = (data : RfPerformanceModel) => {
        const keyMap = new Map<number, number>(); 
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.snrValues[index])}); 
        return keyMap; 
    };

    const seriesItems = (data: RfPerformanceModel) => {
        const series: RfPerformanceChartSeries[] = [];

        try {
            series[0] = {
                color: schemeCategory10[8],
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        value: data.cnoValues[index]
                    }
                }),
                keyItems: cnoKeyItems(data)
            };

            series[1] = {
                color: schemeCategory10[9],
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        value: data.snrValues[index]
                    }
                }),
                keyItems: snrKeyItems(data)
            };
        }
        catch (error) {
            console.log("error", error);
        }

        return series;
    }

    const result = {
        fromMillis: serverData.fromMillis,
        toMillis: serverData.toMillis,
        intervalMillis: serverData.intervalMillis,
        series: seriesItems(serverData)
    };

    return result;
}

export function mapDownloadSpeedsData (serverData: DownloadSpeedsModel) : DownloadSpeedsChartData {
 
    const keyItems = (data : DownloadSpeedsModel) => { 
        const keyMap = new Map<number, number>(); 
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.values[index])}); 
        return keyMap; 
    };

    const seriesItems = (data: DownloadSpeedsModel) => {
        const series: DownloadSpeedsChartSeries[] = [];

        try {
            series[0] = {
                color: schemeCategory10[6],
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        value: data.values[index]
                    }
                }),
                keyItems: keyItems(data)
            };
        }
        catch (error) {
            console.log("error ", error);
        }

        return series;
    };

    const result = {
        fromMillis: serverData.fromMillis,
        toMillis: serverData.toMillis,
        intervalMillis: serverData.intervalMillis,
        series: seriesItems(serverData)
    };

    return result;
}

export function mapUploadSpeedsData (serverData: UploadSpeedsModel) : UploadSpeedsChartData {

    const keyItems = (data : DownloadSpeedsModel) => { 
        const keyMap = new Map<number, number>(); 
        data.timeStampsMillis.forEach((value, index) => {keyMap.set(value, data.values[index])}); 
        return keyMap; 
    };
    
    const seriesItems = (data: UploadSpeedsModel) => {
        const series: UploadSpeedsChartSeries[] = [];

        try {
            series[0] = {
                color: schemeCategory10[7],
                chartItems: data.timeStampsMillis.map((value, index) => {
                    return {
                        tsLowerMillis: value,
                        tsUpperMillis: value + data.intervalMillis,
                        value: data.values[index]
                    }
                }),
                keyItems: keyItems(data)
            };
        }
        catch (error) {
            console.log("error ", error);
        }

        return series;
    };

    const result = {
        fromMillis: serverData.fromMillis,
        toMillis: serverData.toMillis,
        intervalMillis: serverData.intervalMillis,
        series: seriesItems(serverData)
    };

    return result;}