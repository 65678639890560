/**
 * TODO: should we use useCallback and useMemo to wrap our setters and context
 * value as per this article: https://devtrium.com/posts/how-use-react-context-pro
 */

import React from "react";
import { Dates } from "../../utils/dates";
import { MapDateRangeContext } from "./MapDateRangeContext";

/**
 * Calculates number of seconds that should be used
 * when determining the interval between dates in a date range 
 * @param {number} fromMillis start of the date range in UNIX Epoch milliseconds
 * @param {number} toMillis end of the date range in UNIX Epoch milliseconds
 */
function calculateIntervalMillis(fromMillis:number, toMillis:number){
    const duration = toMillis - fromMillis;

    if (duration > Dates.MILLIS_FOR_DAYS_30) {
        return Dates.MILLIS_FOR_MINUTES_60;

    } else if (duration > Dates.MILLIS_FOR_DAYS_7) {
        return Dates.MILLIS_FOR_MINUTES_30;

    } else if (duration > Dates.MILLIS_FOR_DAYS_1) {
        return Dates.MILLIS_FOR_MINUTES_20;

    } else {
        return Dates.MILLIS_FOR_MINUTES_5;
    }
}

const MapDateRangeProvider  = ({children}: {children: React.ReactNode}) => {

    const defaultFromMillis = Dates.today().millis();
    const defaultToMillis = Dates.tomorrow().millis();
    const defaultIntervalMillis = 300000;

    const [fromMillis, setFromMillis] = React.useState<number>(defaultFromMillis);
    const [toMillis, setToMillis] = React.useState<number>(defaultToMillis - defaultIntervalMillis);
    const [intervalMillis, setIntervalMillis] = React.useState<number>(defaultIntervalMillis);

    const setDates = (fromMillis: number, toMillis: number) =>{
        setFromMillis(fromMillis);
        setToMillis(toMillis);
        setIntervalMillis(calculateIntervalMillis(fromMillis, toMillis));
    }
    const value = {fromMillis, toMillis, intervalMillis, setDates, calculateIntervalMillis};

    return (
        <MapDateRangeContext.Provider value={value}>
            {children}
        </MapDateRangeContext.Provider>
    );
}

export { MapDateRangeProvider };