import React, { useContext } from "react";

import { ContentHeader } from "../contentContainer/ContentHeader";

import { MapBeamUsageDisplay } from "../../../mapBeamUsageDisplay/MapBeamUsageDisplay";
import { MapDatePointDisplay } from "../../../mapDatePointDisplay/MapDatePointDisplay";
import { MapLocationDisplay } from "../../../mapLocationDisplay/MapLocationDisplay";

import { MapDateRangeContext } from "../../../../../../contexts/components/MapDateRangeContext";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import { NothingSelectedMessage } from "../contentContainer/NothingSelectedMsg";
import { ChartsDataContainer } from "./components/charts/ChartsDataContainer";

const ChartsHeader : React.FC = () => {

    const {ship} = useContext(SelectedShipContext);
    const {fromMillis, toMillis, intervalMillis} = useContext(MapDateRangeContext);

    return (
        <>
            {/* <MapDateRange /> */}
            <div>
                <table>
                    <tbody>
                        <tr><td>Date</td><td>: <MapDatePointDisplay showUpperValue={true} /></td></tr>
                        <tr><td>Location</td><td>: <MapLocationDisplay /></td></tr>
                        <tr><td>Beam</td><td>: <MapBeamUsageDisplay shipId={ship.installationId} fromDate={fromMillis} toDate={toMillis} interval={intervalMillis}/></td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
/**
 * Overall container component for the Vessel Connectivity content.
 * Retrieves data about connectivity and passes it to a component
 * which displays the collection of Connectivity Charts.
 * @returns 
 */
const VesselConnectivity : React.FC = () => {

    const {ship} = useContext(SelectedShipContext);

    return (        
        <>
            <ContentHeader title="Vessel Connectivity" />
            {/* <DebugInfo /> */}
            {
                ship 
                    ?
                    // Don't be tempted to change the following to a div because it screws the CSS y scroll behaviour
                    // We should probably fix that to make this component more self contained.
                    <>      
                        <ChartsHeader />                  
                        <ChartsDataContainer />
                    </>
                    : 
                    <NothingSelectedMessage />
            }
        </>     
    )
}

export default VesselConnectivity;
