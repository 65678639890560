import { useContext, useEffect, useReducer } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContext } from "../../../../contexts/components/AuthContext";
import { Content, ErrorMessage, Footer, Header, LoadingIndicator, Main } from "../common/page";
import { ClientGroups } from "./assigned/ClientGroups";
import { DataApi } from "./data/DataApi";
import { IApiData } from "./data/Models";
import { Actions, initialState, reducer } from "./data/State";
import { HoldingGroups } from "./unassigned/HoldingGroups";

// TODO: tidy this data fetch

/**
 * Get the Users tree data from the database
 * @param preFetch A function to call prior to getting the data
 * @param postFetch A function to call when data has been retrieved
 */
const fetchData = async (
    preFetch: () => void, 
    postFetch: (data: IApiData | undefined) => void,
    notAuthenticated: () => void,
) => {
    
    let data: IApiData | undefined;

    preFetch();

    try {
        const response = await DataApi.getHierarchy();

        if (response.ok){
            data = (await response.json()) as IApiData;
        } else {
            if (response.status === 401){
                notAuthenticated();
            }
        }
    }
    catch (error){
        console.log("An error occurred");
    }

    postFetch(data);
}

/**
 * Page component to display the Users Tree
 */
export const VnoAdminUsersPage : React.FC = () => {

    // state holds the users tree data accessible via a reducer
    const [state, dispatch] = useReducer(reducer, initialState);
    const {signOut} = useContext(AuthContext);

    function preFetch(){
        // signal that the data is loading
        dispatch({type: Actions.LOADING});
    }

    function postFetch(data: IApiData | undefined){
        // add a small UI experience delay
        // before handling the retrieved data
        setTimeout(() => {
            if (data){
                dispatch({
                    type: Actions.LOADED, 
                    data: data
                });    
            } else {
                dispatch({
                    type: Actions.LOADING_FAILED,
                    data: "An error occurred"
                })
            }        
        }, 750);
    }


    // load user tree data when the component loads
    useEffect(() => {  

        function notAuthenticated() {
            signOut(() => {});
        }    
        
        fetchData(preFetch, postFetch, notAuthenticated).then(() => { }).catch(() => { signOut(() => { }) });
    },[signOut])

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div> }>
            <Main>
                <Header title="Manage your Users" />
                <LoadingIndicator isLoading={state.loading} />
                <ErrorMessage isError={!!state.error} />
                <Content>
                {!state.loading && state.data &&
                    <ClientGroups data={state.data} dispatch={dispatch} />
                }
                {!state.loading && state.data &&
                    <HoldingGroups data={state.data} dispatch={dispatch} />
                }
                </Content>
                <Footer/>
            </Main>
        </ErrorBoundary>        
    )
}