import { RefObject, createRef, useEffect } from "react";
import { select as d3select } from "d3-selection";
import { axisLeft as d3axisLeft, AxisScale as d3AxisScale } from "d3-axis";

interface LeftAxisProps {
    x: number;
    scale: d3AxisScale<number>;
    showTicks: boolean;
}

export const LeftAxis = ({x, scale, showTicks } : LeftAxisProps) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            const axis = d3axisLeft(scale);
            if (!showTicks) {
                axis.ticks(0);
            }
            d3select(elRef.current).call(axis);
        }
    }, [elRef, scale, showTicks])

    return (
        <g ref={elRef} transform={`translate(${x}, 0)`}></g>
    )
}