import { ColumnInstance } from "react-table"
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./HeaderContent.module.css";
import { AlignEnum } from "./AlignEnum";

export const HeaderContent = <T extends object>(props: {
    column: ColumnInstance<T>,
    text: string,
    align: AlignEnum
}) => {

    const {column, text, align} = props;

    return (<div style={{textAlign:  align === AlignEnum.Left ? "left" : "right"}}>
        <div>
            <span>{column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown}/> : <FontAwesomeIcon icon={faSortUp}/> : <FontAwesomeIcon icon={faSort}/>}</span>
            <span className={styles.text}>{text}</span>
            </div>
        </div>
    );

}