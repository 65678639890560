import React, { useLayoutEffect, useState } from "react";
import { Dates } from "../../../../utils/dates";
import { SelectedDatesDisplay } from "./SelectedDatesDisplay";
import { Month } from "./Month";

import styles from "./DateRangePicker.module.css";

type LocationType = {
    top: number,
    left: number,
}

interface IDateRangePickerProps {
    minimumDateMillis: number | null;
    maximumDateMillis: number | null;
    suppliedStartDateMillis: number | null;
    suppliedEndDateMillis: number | null;
    anchorRef: React.MutableRefObject<null | HTMLDivElement>,
    onSelected: (fromDateMillis: number, toDateMillis: number) => void,
}

/**
 * This components display two 'calendar' months side by side.
 * The right hand calendar defaults to showing the month
 * in which today's date is located.  The left hand calendar
 * shows the month prior to that shown in the left hand calendar.
 * The months can be incremented and decremented, within limits.
 */

export const DateRangePicker : React.FC<IDateRangePickerProps> = ({
    minimumDateMillis,
    maximumDateMillis,
    suppliedStartDateMillis,
    suppliedEndDateMillis,
    anchorRef,
    onSelected
}) => {

    // screen location of the date picker
    const [componentPosition, setComponentPosition] = React.useState<LocationType>({top: 0, left: 0});

    // Effect that will track window resizing and allow us to 
    // position the date picker relative to the anchor element that
    // has been passed to us
    useLayoutEffect(() => {
        function updatePosition(){
            if (anchorRef.current){
                // determine the location where we will display the date picker
                const DIALOG_HEIGHT = 360;
                const DIALOG_WIDTH = 590;
                let pos: LocationType = {top: 0, left: 0};

                const windowVP = window.visualViewport;
                const anchorCr = anchorRef.current.getBoundingClientRect();
                
                if (windowVP && ((windowVP.height - anchorCr.bottom) < DIALOG_HEIGHT)) 
                { 
                    pos.top = anchorCr.top - DIALOG_HEIGHT; // - 5
                } else {
                    pos.top = anchorCr.bottom + 5;
                };

                if (windowVP && ((anchorCr.left + DIALOG_WIDTH) > windowVP.width)){
                    pos.left = windowVP.width - DIALOG_WIDTH;
                } else {
                    pos.left = anchorCr.left;
                }

                setComponentPosition(pos);
            }
        }

        window.addEventListener('resize', updatePosition);

        updatePosition();

        return () => window.removeEventListener('resize', updatePosition);

    },[anchorRef])

    const todayMillis = Dates.today().millis();

    // Date used to determine what month is shown on the right hand side of the picker
    // and consequently also the left side of the picker.
    // Defaults to the month of the current date
    const [rightHandDateMillis, setRightHandDateMillis] = useState<number>(suppliedEndDateMillis ? Dates.fromMillis(suppliedEndDateMillis).startOfMonth().millis() : Dates.today().startOfMonth().millis());

    // the currently selected from and to dates defaulted to the values supplied to the component
    const [fromDateMillis, setFromDateMillis] = useState<number | null>(suppliedStartDateMillis);
    const [toDateMillis, setToDateMillis] = useState<number | null>(suppliedEndDateMillis);
    const [potentialToDateMillis, setPotentialToDateMillis] = useState<number | null>(null);

    function handleDecreaseMonth(){
        setRightHandDateMillis(prev => Dates.fromMillis(prev).subtractMonths(1).millis());
    }

    function handleIncreaseMonth(){
        setRightHandDateMillis(prev => Dates.fromMillis(prev).addMonths(1).millis());
    }

    function handleDayClick(dateMillis: number) {
        if (fromDateMillis !== null && toDateMillis !== null){
            // from date and to date already set, so unset toDate and use the supplied date as the new from date
            setFromDateMillis(dateMillis);
            setToDateMillis(null);
        } 
        else if (fromDateMillis !== null && toDateMillis === null)
        {
            if (dateMillis < fromDateMillis)
            {
                // from date is set, to date is not set, but the supplied date is earlier
                // than the from date, so change the from date to be the supplied date
                setFromDateMillis(dateMillis);
            } else {
                // from date is set, but to date is not set, so use the supplied date as the to date
                onSelected(fromDateMillis, dateMillis);
            }
        }
        setPotentialToDateMillis(null);
    }

    function handleDayMouseEnter(dateMillis: number){
        if (fromDateMillis !== null && toDateMillis === null && dateMillis >= fromDateMillis){
            setPotentialToDateMillis(dateMillis);
        } else {
            setPotentialToDateMillis(null);
        }
    }

    return (
        <div className={styles.root} style={{top: componentPosition.top, left: componentPosition.left}}>
            <div className={styles.content}>
                <div className={styles.monthContainer}>                    
                    <Month 
                        firstDateOfMonthMillis={Dates.fromMillis(rightHandDateMillis).subtractMonths(1).millis()} 
                        todayMillis={todayMillis}
                        minimumDateMillis={minimumDateMillis}
                        maximumDateMillis={maximumDateMillis}
                        selectedFromDateMillis={fromDateMillis}
                        selectedToDateMillis={toDateMillis}
                        potentialToDateMillis={potentialToDateMillis}
                        // don't allow the user to move to a month that is earler than any supplied minimum date
                        showMonthDecrease={
                            minimumDateMillis 
                                ? Dates.fromMillis(rightHandDateMillis).year() > Dates.fromMillis(minimumDateMillis).year()
                                    ? true
                                    : Dates.fromMillis(rightHandDateMillis).month() > Dates.fromMillis(minimumDateMillis).month() 
                                        ? true
                                        : false
                                : true
                            }
                        onDecrease={handleDecreaseMonth}
                        // doesn't make sense to show the increase month button on the left hand calendar
                        showMonthIncrease={false}
                        onIncrease={() => {}}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseEnter}
                    />
                    <Month 
                        firstDateOfMonthMillis={rightHandDateMillis} 
                        todayMillis={todayMillis}
                        minimumDateMillis={minimumDateMillis}
                        maximumDateMillis={maximumDateMillis}
                        selectedFromDateMillis={fromDateMillis}
                        selectedToDateMillis={toDateMillis}
                        potentialToDateMillis={potentialToDateMillis}
                        // don't allow a user to move to a month that is later than any supplied maximum date
                        showMonthIncrease={
                            maximumDateMillis 
                                ? Dates.fromMillis(rightHandDateMillis).year() < Dates.fromMillis(maximumDateMillis).year() 
                                    ? true 
                                    : Dates.fromMillis(rightHandDateMillis).month() < Dates.fromMillis(maximumDateMillis).month() 
                                        ? true 
                                        : false 
                                : true
                            }
                        onIncrease={handleIncreaseMonth}
                        // doesn't make sense to show the decrease month button on the right hand calendar
                        showMonthDecrease={false}
                        onDecrease={()=>{}}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseEnter}
                    />
                </div>
                <SelectedDatesDisplay 
                    fromDateMillis={fromDateMillis} 
                    toDateMillis={
                        toDateMillis 
                            ? toDateMillis 
                            : potentialToDateMillis 
                                ? potentialToDateMillis 
                                : null
                        }
                />
            </div>
        </div>
    )
}