import React from "react";

import styles from "./WeatherLegends.module.css";

import classNames from "classnames";

import waveHeightsLegend from "../../../../assets/legend-wave-heights.png";
import iceLegend from "../../../../assets/legend-radar-ice.png";
import rainLegend from "../../../../assets/legend-radar-rain.png";
import snowLegend from "../../../../assets/legend-radar-snow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface WeatherLegendsProps{
    visible: boolean,
    onClose: () => void,
}

export const WeatherLegends : React.FC<WeatherLegendsProps> = ({
    visible,
    onClose
}) => {

    return (
        <div className={classNames(styles.root, !visible && styles.hidden)}>
            <div className={styles.header}>
                <button type="button" onClick={onClose} title="Close" className={styles.closeButton}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </div>
            <div className={styles.section}>
                <h3>
                    Weather Information
                </h3>
                <p>
                    Cloud data is available for the past 7 days.<br/>
                    Precipitation data is available for the past 30 days.<br/>
                    Wave Height data is available for the past 7 days.
                </p>
            </div>
            <div className={styles.section}>
                <h3>
                    Legend
                </h3>
                <div>
                    <div>Wave Height (m)</div>
                    <img src={waveHeightsLegend} alt="Wave Heights (m)" title="Wave Heights (m)"/>                
                </div>
                <div>
                    <div>Rain</div>
                    <img src={rainLegend} alt="Rain" title="Rain"/>
                </div>
                <div>
                    <div>Ice</div>
                    <img src={iceLegend} alt="Ice" title="Ice"/>
                </div>
                <div>
                    <div>Snow</div>
                    <img src={snowLegend} alt="Snow" title="Snow"/>
                </div>                
            </div>
        </div>
    )
}