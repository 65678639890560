import styles from "./LoadingIndicator.module.css";

interface ILoadingIndicatorProps {
    isLoading: boolean;
}

export const LoadingIndicator : React.FC<ILoadingIndicatorProps> = ({
    isLoading
}) => {

    if (!isLoading) return null;

    return (
        <div className={styles.root}>
            <div>Loading.  Please wait...</div>
        </div>
    )
}