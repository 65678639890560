import { LogLevel } from "../debug/consoleLogger";
import { ConvertToInteger } from "./ConvertToInteger";
import { ConvertToBoolean } from "./ConvertToBoolean";

// object which exposes various application related properties
export const application = {
    get name() : string {
        return import.meta.env.VITE_BRANDING_APP_NAME as string;
    },
    get version(): string {
        return import.meta.env.VITE_APP_VERSION as string;
    }
    ,
    get environment(): string {
        return import.meta.env.MODE;
    },
    get LOG_LEVEL(): LogLevel {
        return import.meta.env.VITE_LOG_LEVEL as LogLevel;
    }
}

// object which exposes various Debug settings
export const options = {
    get shouldShowInstallationSelectorDebug(): boolean {
        return application.environment === "development" && ConvertToBoolean(import.meta.env.VITE_SHIP_SELECTOR_DEBUG as string);
    },
    get installationStatusRefreshIntervalMillis(): number {
        return ConvertToInteger(import.meta.env.VITE_STATUS_REFRESH_INTERVAL as string);
    },
    get installationStatusRefreshEnabled(): boolean {
        return application.environment === "development" ? ConvertToBoolean(import.meta.env.VITE_STATUS_REFRESH_ENABLED as string) : true;
    }
}

// object which exposes the various Aries Map keys 
export const ariesKeys = {
    get clientId(): string {

        return import.meta.env.VITE_AERIS_CLIENT_ID as string;
    },
    get secret(): string {
        return import.meta.env.VITE_AERIS_SECRET as string;
    }
}

// object which exposes the various password rules
export const passwordRules = {
    get digitRequired(): boolean {
        return ConvertToBoolean(import.meta.env.VITE_PWD_REQUIRES_DIGIT as string);
    },
    get lowerCaseRequired() : boolean {
        return ConvertToBoolean(import.meta.env.VITE_PWD_REQUIRES_LOWER_CASE as string);
    },
    get upperCaseRequired() : boolean {
        return ConvertToBoolean(import.meta.env.VITE_PWD_REQUIRES_UPPER_CASE as string);
    },
    get nonAlphaRequired() : boolean {
        return ConvertToBoolean(import.meta.env.VITE_PWD_REQUIRES_NON_ALPHA as string);
    },
    get minimumLength() : number {
        return ConvertToInteger(import.meta.env.VITE_PWD_MIN_LENGTH as string);
    },
}
