import { Button } from "../../../../../../../../buttons";
import styles from "./DialogButtons.module.css";

interface IDialogButtonsProps{
    // true if the ok button should be disabled
    okDisabled: boolean;
    onOkClick: () => void;
    onCancelClick: () => void;
}


export const DialogButtons : React.FC<IDialogButtonsProps> = ({
    okDisabled,
    onOkClick,
    onCancelClick,
}) => {

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        onCancelClick();
    }

    function handleOkClick(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
        onOkClick();
    }

    return (
        <div className={styles.root}>
            <Button 
                disabled={okDisabled} 
                onClick={handleOkClick} 
                text="Ok" 
                className={styles.okButton}
                color="success"
            />
            <Button 
                onClick={handleCancelClick} 
                text="Cancel"
                color="secondary"
            />
        </div>        
    )
}