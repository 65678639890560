import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { application, options } from "../../../utils/environment";
import {
    AuthForm,
    AuthLayout,
    AuthLinks,
    FieldSpacer,
    Input,
    SectionHeader,
} from "./components";

import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/components/AuthContext";
import { Button } from "../../buttons";

function SignInPage() {
    
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [showSettings, setShowSettings] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const redirectPage = '/home';

    /**
     * Handle the user pressing the submit button
     */
     function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        setEmailErrorMessage('');
        
        // a debug / useful easter egg to show app settings 
        setShowSettings(false);

        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;
        const password = formData.get("password") as string;

        if (email === "showthe" && password === "settings")
        {
            setShowSettings(true);
            return;
        }

        let errors = false;

        if (email === null || typeof email === "undefined" || email.trim() === '') {
            setEmailErrorMessage("Email is required");
            errors = true;
        }

        if (password === null || typeof password === "undefined" || password.trim() === ''){
            setPasswordErrorMessage("Password is required");
            errors = true;
        }

        if (errors){
            return;
        }

        setProcessing(true);

        auth.signIn(
            email, 
            password, 
            () => {
                setProcessing(false);
                navigate(redirectPage, {replace: true})
            },
            (message) => {
                setProcessing(false);
                if (message === "Invalid login"){
                    toast.error("Sorry.  The email or password is not valid");
                } else {
                    toast.error("Ooops.  Something went wrong while trying to sign you in");
                }
            })
    }

    const handleOnEmailChange = (e: React.FormEvent<HTMLInputElement>) =>{
        const value = e.currentTarget.value;

        setShowSettings(value === '???');
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit}>
                <SectionHeader text="Sign In"/>
                <Input 
                    name="email" 
                    placeholder="Email" 
                    type="text" 
                    disabled={processing} 
                    required={true} 
                    errorMessage={emailErrorMessage}
                    autoFocus={true}
                    onChange={handleOnEmailChange}
                />
                <Input 
                    name="password" 
                    placeholder="Password" 
                    type="password" 
                    disabled={processing} 
                    required={true} 
                    errorMessage={passwordErrorMessage}
                />
                <Button text="Sign me in" disabled={processing}/>
                <FieldSpacer/>
            </AuthForm>
            <AuthLinks forgotPassword={true} register={true}/>
            <div>
                { showSettings && <Settings /> }
            </div>
        </AuthLayout>        
    )

}

const Settings = () => {
    return (
        <div>
            <div>ENVIRONMENT : {application.environment}</div>
            <div>INSTALLATION REFRESH INTERVAL : {options.installationStatusRefreshIntervalMillis}ms</div>
            <div>INSTALLATION REFRESH ENABLED : {options.installationStatusRefreshEnabled}</div>
            <div>PWD MIN LENGTH : {import.meta.env.VITE_PWD_MIN_LENGTH}</div>
            <div>MAP TOKEN : {import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string}</div>
        </div>
    )
}

export default SignInPage;
