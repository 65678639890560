import { ConvertToInteger } from "./ConvertToInteger";

///**
// * Extracts an environment variable value as a boolean.
// * @param key Key of the environment variable to extract.
// * @returns The environment variable as a boolean.
// * @throws Error if the value is not a valid boolean recorded as 0 = false, 1 = true, or the value does not exist
// */
//function extractBooleanEnvVar(key: keyof NodeJS.ProcessEnv): boolean {
//    const value = extractIntegerEnvVar(key);
//    return Boolean(value);
//}

export function ConvertToBoolean(value: string): boolean {
    return Boolean(ConvertToInteger(value));
}