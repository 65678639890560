// ---------------------------------------------------------------------
// TODO: this needs looking at in detail because it doesn't seem correct
// ---------------------------------------------------------------------
import { useEffect, useState } from "react";
import { GetAllResultModel } from "../../data/Repository"
import { ClientGroupViewModel, ClientViewModel, PriceListCollectionViewModel, PriceListDetailViewModel, PriceListViewModel, SabaQuoteViewModel, SavePriceRowModel } from "../ViewModels";
import { TreeView } from "../treeView/TreeView";
import { NothingSelectedView } from "../nothingSelected/NothingSelectedView";
import { ClientGroupView } from "../clientGroup/ClientGroupView";
import { ErrorView } from "../error/ErrorView";
import { ClientView } from "../client/ClientView";
import { PriceListCollectionView } from "../priceLists";
import { DeleteSuccessView } from "../deleteSuccess/DeleteSuccessView";
import { LoadingView } from "../loading/LoadingView";
import { PriceListView } from "../priceList";
import { AddPriceListView } from "../addPriceList/AddPriceListView";
import { ToastContainer, toast } from "react-toastify";

import styles from "./PageContent.module.css";

export const PageContent = (props: {
    data: GetAllResultModel | null,
    onAddPriceList: (clientId: number, reference: string, sabaQuoteId: number) => Promise<PriceListViewModel>,
    onDeletePriceList: (priceListId: number) => Promise<void>,
    onGetPriceList: (priceListId: number) => Promise<PriceListDetailViewModel>,
    onSavePriceList: (priceListId: number, reference: string, prices: Array<SavePriceRowModel>) => Promise<PriceListViewModel>,
}) => {

    const {data, onAddPriceList, onDeletePriceList, onGetPriceList, onSavePriceList} = props;

    const [detailView, setDetailView] = useState<React.ReactElement>(<NothingSelectedView />);

    const [clientGroups, setClientGroups] = useState<Array<ClientGroupViewModel>>([]);
    const [clients, setClients] = useState<Array<ClientViewModel>>([]);
    const [sabaQuotes, setSabaQuotes] = useState<Array<SabaQuoteViewModel>>([]);
    const [priceListCollections, setPriceListCollections] = useState<Array<PriceListCollectionViewModel>>([]);
    const [priceLists, setPriceLists] = useState<Array<PriceListViewModel>>([]);

    useEffect(() => {

        if (data === null) return;

        setClientGroups(data.clientGroups.map(item=> {return {...item, expanded: true, selected: false}}));
        setClients(data.clients.map(item => {return{...item, expanded: true, selected: false}}));
        setPriceListCollections(data.clients.map(item => {return {clientId: item.id, expanded: true, selected: false}}));
        setPriceLists(data.priceLists.map(item => {return {...item, selected: false}}));
        setSabaQuotes(data.sabaQuotes.map(item => {return {...item}}));

    }, [data]);

    const showClientGroupDetailView = (clientGroupId: number) => {
        // find the associated client group
        // and if found, set the content appropriately
        const index = clientGroups.findIndex(x => x.id === clientGroupId);
        if (index >= 0){

            // set tree item selections
            setClientGroups(prev => prev.map(item => {return {...item, selected: item.id === clientGroupId}}));
            setClients(prev => prev.map(item => {return {...item, selected: false}}));
            setPriceListCollections(prev => prev.map(item => {return {...item, selected: false}}));
            setPriceLists(prev => prev.map(item => {return {...item, selected: false}}));

            setDetailView(
                <ClientGroupView 
                    name={clientGroups[index].name}
                />
            )
        } else {
            setDetailView(<ErrorView />);
        }
    }

    const showClientDetailView = (clientId: number) => {
        // find the associated client
        // and if found, set the content appropriately
        const index = clients.findIndex(x => x.id === clientId);
        if (index >= 0){

            // set tree item selections
            setClientGroups(prev => prev.map(item => {return {...item, selected: false}}));
            setClients(prev => prev.map(item => {return {...item, selected: item.id === clientId}}));
            setPriceListCollections(prev => prev.map(item => {return {...item, selected: false}}));
            setPriceLists(prev => prev.map(item => {return {...item, selected: false}}));

            setDetailView(
                <ClientView 
                    name={clients[index].name} 
                />
            )
        } else {
            setDetailView(<ErrorView />);
        }
    }

    const showPriceListsDetailView = (clientId: number) => {

        const index = priceListCollections.findIndex(x => x.clientId === clientId);

        if (index >= 0){

            // set tree item selections
            setClientGroups(prev => prev.map(item => {return {...item, selected: false}}));
            setClients(prev => prev.map(item => {return {...item, selected: false}}));
            setPriceListCollections(prev => prev.map(item => {return {...item, selected: item.clientId === clientId}}));
            setPriceLists(prev => prev.map(item => {return {...item, selected: false}}));

            // find the price lists associated with the 
            // identified client
            const models = priceLists.filter(priceList => priceList.clientId === clientId).slice(0); 

            setDetailView(
                <PriceListCollectionView
                    clientId={clientId}
                    priceLists={models}
                    onSelected={showPriceListDetailView}
                    onAdd={showAddPriceListDetailView}
                />
            )
        } else {
            setDetailView(<ErrorView />);
        }
    }

    const showDeleteSuccessDetailView = () => {
        // set tree item selections
        setClientGroups(prev => prev.map(item => {return {...item, selected: false}}));
        setClients(prev => prev.map(item => {return {...item, selected: false}}));
        setPriceListCollections(prev => prev.map(item => {return {...item, selected: false}}));
        setPriceLists(prev => prev.map(item => {return {...item, selected: false}}));
        
        setDetailView(<DeleteSuccessView />)
    }

    // TODO: fix the error handling so it works like the other methods
    // TODO: need to validate the reference
    const handleOnSaveEdit = async (priceListId: number, reference: string, prices: Array<SavePriceRowModel>) : Promise<boolean> => {
        try {
            //console.log("should be saving", {priceListId, reference, prices})

            const result = await onSavePriceList(priceListId, reference, prices);

            const updated: Array<PriceListViewModel> = priceLists.map(priceList => {
                if (priceList.id === priceListId){
                    return {
                        ...priceList,
                        reference: result.reference,
                        savedDate: result.savedDate,
                    }
                } else {
                    return {
                        ...priceList,
                    }
                }
            });

            setPriceLists(updated);

            return true;
        }
        catch {
            return false;
        }
    }

    const showPriceListDetailView = async (priceListId: number) => {
        
        setDetailView(<LoadingView headingText="Price List"/>)

        // set tree item selections
        setClientGroups(prev => prev.map(item => {return {...item, selected: false}}));
        setClients(prev => prev.map(item => {return {...item, selected: false}}));
        setPriceListCollections(prev => prev.map(item => {return {...item, selected: false}}));
        setPriceLists(prev => prev.map(item => {return {...item, selected: item.id === priceListId}}));

        throw Error("not implemented");

        // need an error handler here somewhere
        try {
            const priceList = await onGetPriceList(priceListId);

            setDetailView(
                <PriceListView 
                    priceList={priceList} 
                    onChange={handleOnSaveEdit}
                    onChanged={showPriceListDetailView} 
                    onDelete={handleDeletePriceList}
                    onDeleted={showDeleteSuccessDetailView}
                />
            )
        }
        catch {
            setDetailView(<ErrorView />)
        }

    }

    // TODO: need to handle a case where there are no quotes available
    // TODO: should prevent the save button from being pressed if the reference and quote are not valid
    const handleAddPriceList = async (clientId: number, reference: string, sabaQuoteId: number) : Promise<PriceListViewModel | null> => {
        
        if (!reference || reference.length < 10){
            toast.error("Please provide a Reference");
            return null;
        }

        try {
            const result = await onAddPriceList(clientId, reference, sabaQuoteId);

            const updated: Array<PriceListViewModel> = [...priceLists, result];

            setPriceLists(updated);

            return result;
        }
        catch {
            toast.error("Ooops.  A problem occurred while adding the Price List");
            return null;
        }
    }

    const handleDeletePriceList = async (priceListId: number) : Promise<boolean> => {

        try {
            await onDeletePriceList(priceListId);

            const updated: Array<PriceListViewModel> = priceLists.filter(priceList => priceList.id !== priceListId).slice(0);

            setPriceLists(updated);

            return true;
        }
        catch {
            toast.error("Ooops.  A problem occurred while deleting the Price List");
            return false;
        }
    }

    const showAddPriceListDetailView = (clientId: number) => {

        // find the saba quotes associated with the client
        const clientSabaQuotes = sabaQuotes.filter(sabaQuote => sabaQuote.clientId === clientId).slice(0);

        setDetailView(
            <AddPriceListView
                clientId={clientId}
                sabaQuotes={clientSabaQuotes}
                onCancel={showPriceListsDetailView}
                onSave={handleAddPriceList}
                onSaved={showPriceListDetailView}
            />
        )
    }

    const toggleClientGroupExpanded = (clientGroupId: number) => {
        const updated = clientGroups.map(item => {return {...item, expanded: item.id === clientGroupId ? !item.expanded : item.expanded}});

        setClientGroups(updated);
    }

    const toggleClientExpanded = (clientId: number) => {
        const updated = clients.map(item => {return {...item, expanded: item.id === clientId ? !item.expanded : item.expanded}});

        setClients(updated);
    }

    const togglePriceListCollectionExpanded = (clientId: number) => {
        const updated = priceListCollections.map(item => {return {...item, expanded: item.clientId === clientId ? !item.expanded : item.expanded}});

        setPriceListCollections(updated);
    }

    return (
        <div className={styles.root}>
            <section className={styles.tree}>
                <TreeView 
                    clientGroups={clientGroups}
                    clients={clients}
                    priceListCollections={priceListCollections}
                    priceLists={priceLists}
                    onClientGroupToggleExpand={toggleClientGroupExpanded}
                    onClientToggleExpand={toggleClientExpanded}
                    onPriceListCollectionToggleExpand={togglePriceListCollectionExpanded}
                    onClientGroupSelected={(clientGroupId) => {showClientGroupDetailView(clientGroupId)}}
                    onClientSelected={(clientId) => showClientDetailView(clientId)}
                    onPriceListCollectionSelected={(clientId) => showPriceListsDetailView(clientId)}
                    onPriceListSelected={(priceListId) => showPriceListDetailView(priceListId)}
                />
            </section>
            <section className={styles.detail}>
                {detailView}
            </section>
            <ToastContainer />
        </div>
    )
}