import { AuthProvider } from './contexts';
import {AuthenticatedLayout, NotAuthenticatedLayout} from "./ui/layout";
import 'mapbox-gl/dist/mapbox-gl.css';

import {
    ConfirmEmailPage,
    ConfirmEmailConfirmationPage,
    ForgotPasswordPage,
    ForgotPasswordConfirmationPage,
    RegisterPage,
    RegisterConfirmationPage,
    ResetPasswordPage,
    ResetPasswordConfirmationPage,
    SignInPage,
    ShipViewPage,
} from "./ui/pages";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { VnoAdminLayout } from './ui/pages/vno/VnoAdminLayout';
import { VnoAdminUsersPage } from './ui/pages/vno/users/VnoAdminUsersPage';
import { VnoAdminClientsPage } from './ui/pages/vno/clients/VnoAdminClientsPage';

import styles from "./App.module.css";
import { VnoAdminPricesPage } from './ui/pages/vno/prices/VnoAdminPricesPage';
import { useInjection } from 'brandi-react';
import { TOKENS } from './ui/pages/vno/prices/data/Repository';

const NotFoundPage = () => {
    return (
        <div className={styles.notFoundRoot}>
            <h1>Ooops!</h1>
            <p>Sorry, we couldn't find what you were looking for</p>
        </div>
    )
}

function App() {
    
    const priceListRepository = useInjection(TOKENS.priceListRepository);
    
    // things seem to work quite nicely if the Auth useEffect is commented out
    // (once a user has been authenticated obvs).  My suspicion is the navigate in the useEffect is the issue
    // of course - the F5 functionality doesn't work (we get a full page refresh and the user is kicked out effectively)
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<NotAuthenticatedLayout/>}>
                        <Route path="confirmemail/:userId" element={<ConfirmEmailPage/>}/>
                        <Route path='confirmemailconfirmation' element={<ConfirmEmailConfirmationPage/>}/>
                        <Route path="forgotpassword" element={<ForgotPasswordPage/>}/>
                        <Route path="forgotpasswordconfirmation" element={<ForgotPasswordConfirmationPage/>}/>
                        <Route path="register" element={<RegisterPage/>} />
                        <Route path="registerconfirmation" element={<RegisterConfirmationPage/>}/> 
                        <Route path="resetpassword" element={<ResetPasswordPage/>} />
                        <Route path="resetpasswordconfirmation" element={<ResetPasswordConfirmationPage/>}/>
                        <Route path="" element={<SignInPage/>} />
                        <Route path="*" element={<NotFoundPage/>}/>
                    </Route>
                    <Route element={<AuthenticatedLayout />}>
                        <Route path="home" element={<ShipViewPage/>}/>
                        <Route path="" element={<VnoAdminLayout />}>
                            <Route path="users" element={<VnoAdminUsersPage />}/>
                            <Route path="clients" element={<VnoAdminClientsPage />}/>
                            <Route path="prices" element={<VnoAdminPricesPage priceListRepository={priceListRepository}/>} />
                        </Route>
                    </Route>
                </Routes> 
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
