// console logger extension!
// very much based on https://meticulous.ai/blog/getting-started-with-react-logging/
import {application} from "../utils/environment";

export interface ILogFn {
    (message?: string, ...optionalParams: object[]): void;
}

export interface ILogger {
    log: ILogFn;
    warn: ILogFn;
    error: ILogFn;
}

export type LogLevel = 'log' | 'warn' | 'error';

const NO_OP: ILogFn = () => {};

export class ConsoleLogger implements ILogger {
    
    readonly log: ILogFn;
    readonly warn: ILogFn;
    readonly error: ILogFn;

    constructor(options?: {level?: LogLevel}) {

        const {level} = options || {};

        this.error = console.error.bind(console);

        if (level === 'error'){
            this.warn = NO_OP;
            this.log = NO_OP;
            return;
        }

        this.warn = console.warn.bind(console);

        if (level === 'warn'){
            this.log = NO_OP;
            return;
        }

        this.log = console.log.bind(console);
    }
}

export const logger = new ConsoleLogger({level: application.LOG_LEVEL});