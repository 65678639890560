export const TextTableDataCell = ({
    text,
    title,
} : {
    text: string,
    title: string,
}) => {
    return (
        <td title={title}>
            <div>
                {text}
            </div>
        </td>
    )
}