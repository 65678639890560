import React from "react";
import { useNavigate } from "react-router";
import { 
    AuthLinks, 
    AuthLayout,
    AuthForm, 
    Input, 
    SectionHeader, 
    SubHeader, 
    FieldSpacer,
    IsPasswordValid
} from "./components";
import { api, fetchHandler, fetchApiPost} from "../../../dataServices/components";
import { application } from "../../../utils/environment";
import { Button } from "../../buttons";
import { toast } from "react-toastify";

function RegisterPage() {
    const navigate = useNavigate();
    
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState<string>('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = React.useState<string>('');
    const [phoneErrorMessage, setPhoneErrorMessage] = React.useState<string>('');
    const [companyErrorMessage, setCompanyErrorMessage] = React.useState<string>('');

    const [processing, setProcessing] = React.useState<boolean>(false);

    const redirectPage = "/registerconfirmation";
    
    /**
     * Handle the user pressing the submit button
     */
    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        setEmailErrorMessage('');
        setPasswordErrorMessage('');
        setConfirmPasswordErrorMessage('');
        setPhoneErrorMessage('');
        setCompanyErrorMessage('');

        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;
        const password = formData.get("password") as string;        
        const confirmPassword = formData.get("confirmPassword") as string;
        const phone = formData.get("phone") as string;
        const company = formData.get("companyName") as string;
        const accountNumber = formData.get("accountNumber") as string;
        const accountManager = formData.get("accountManager") as string;

        let errors = false;

        if (email === null || typeof email === "undefined" || email.trim() === '') {
            setEmailErrorMessage("Email is required");
            errors = true;
        }

        if (password === null || typeof email === "undefined" || password.trim() === '') {
            setPasswordErrorMessage("Password is required");
            errors = true;
        }
        
        if (!IsPasswordValid(password)) {
            setPasswordErrorMessage("Password is not valid");
            errors = true;
        }

        if (confirmPassword === null || typeof confirmPassword === "undefined" || confirmPassword.trim() === '') {
            setConfirmPasswordErrorMessage("Confirm Password is required");
            errors = true;
        }

        if (!IsPasswordValid(confirmPassword)) {
            setConfirmPasswordErrorMessage("Confirm Password is not valid");
            errors = true;
        }
        
        if (password !== confirmPassword) {
            setConfirmPasswordErrorMessage("Password and Confirm Password do not match");
            errors = true;
        }

        if (phone === null || typeof phone === "undefined" || phone.trim() === '') {
            setPhoneErrorMessage("Phone is required");
            errors = true;
        }
        
        if (company === null || typeof company === "undefined" || company.trim() === '') {
            setCompanyErrorMessage("Company is required");
            errors = true;
        }

        if (errors){
            return;
        }

        setProcessing(true);

        const data = {
            email: email,
            applicationName: application.name,
            password: password,
            confirmPassword: confirmPassword,
            companyName: company,
            phoneNumber: phone,
            accountNumber: accountNumber,
            accountManager: accountManager,
        }

        fetchApiPost(api.urls.auth.post.register(), data, {includeCredentials: false})
        .then((response) => {
            setProcessing(false);
            void fetchHandler(response)
            .then((result) => {
                if (result.succeeded){
                    navigate(redirectPage, {replace: true});
                } else {
                    toast.error("Oops.  Something went wrong processing your request");
                }
            })
        })
        .catch(() => {
            setProcessing(false);
            toast.error("Oops.  Something went wrong processing your request");
        })
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit}>
                <SectionHeader text="Register" />
                <Input 
                    name="email" 
                    placeholder="Email" 
                    type="text" 
                    maxLength={50} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={emailErrorMessage}
                    autoFocus={true}
                />
                <Input 
                    name="password" 
                    placeholder="Password" 
                    type="password" 
                    maxLength={30} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={passwordErrorMessage}
                />
                <Input 
                    name="confirmPassword" 
                    placeholder="Confirm Password" 
                    type="password" 
                    maxLength={30} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={confirmPasswordErrorMessage}
                />
                <FieldSpacer />
                <SubHeader text="The following information will help us to correctly configure your account:"/>
                <Input 
                    name="companyName" 
                    placeholder="Company Name" 
                    type="text" 
                    maxLength={30} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={companyErrorMessage}
                />
                <Input 
                    name="phone" 
                    placeholder="Phone" 
                    type="text" 
                    maxLength={20} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={phoneErrorMessage}
                />
                <Input 
                    name="accountNumber" 
                    placeholder="Account Number" 
                    type="text" 
                    maxLength={20} 
                    disabled={processing}
                />
                <Input 
                    name="accountManager" 
                    placeholder="Account Manager" 
                    type="text" 
                    maxLength={30} 
                    disabled={processing} 
                />
                <FieldSpacer />
                <Button text="Register" disabled={processing}/>
            </AuthForm>
            <AuthLinks signIn={true} forgotPassword={true}/>
        </AuthLayout>
    );
}

export default RegisterPage;