import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

import styles from "./StatusIndicator.module.css";

interface IStatusIndicatorProps {
    status: string | null;
}

export const StatusIndicator : React.FC<IStatusIndicatorProps> = ({
    status
}) => {

    const statusIcon = status ? status.toLowerCase() === "offline" ? faCircleExclamation : status.toLowerCase() === "unknown" ? faCircleQuestion : faCircleCheck : faCircleQuestion;
    const statusIconColor = status ? status.toLowerCase() === "offline" ? "red" : status.toLowerCase() === "unknown" ? "white" : "green" : "white";

    return(                 
        <FontAwesomeIcon icon={statusIcon} className={styles.statusIndicatorIcon} style={{color: statusIconColor}}/>
    )
}