/**
 * Search box for filtering / searching for entries in the ShipList.
 */
import React from "react";
import debounce from "lodash/debounce";
import styles from "./SearchBox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface ISearchBoxProps{
    initialFilter: string,
    onFilterChange: (filter: string) => void,
}

/**
 * Search box for filtering / searching for entries in the ShipList.
 */
const SearchBox : React.FC<ISearchBoxProps> = ({initialFilter, onFilterChange}) => {

    const [filterValue, setFilterValue] = React.useState(initialFilter);

    // debounce the user's input
    const debounceFilterValue = React.useMemo(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        () => debounce(nextValue => onFilterChange(nextValue), 300),
        [onFilterChange]
    )

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterValue(value);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        debounceFilterValue(value);
    }

    const handleOnClear = () => {
        setFilterValue(initialFilter)
        onFilterChange(initialFilter);
    }

    return (
        <div className={styles.root}>
            <div className={styles.searchBox}>
                <input 
                    type="text" 
                    placeholder="Search for vessels"
                    value={filterValue}
                    onChange={handleOnChange}  
                />   
                { filterValue && 
                    <button type="button" title="Clear" onClick={handleOnClear}>
                        <FontAwesomeIcon
                            icon={faClose}
                            size="sm"
                        />
                    </button>
                }
                </div>
            <div className={styles.searchIcon}>
                <FontAwesomeIcon icon={faMagnifyingGlass} size="1x"/>
            </div>
        </div>
    );
}

export default SearchBox;