import { froundToIntlString } from "../../../../../../../../../utils/rounding";

import styles from "./MonthlyCostDisplay.module.css";

interface IMonthlyCostDisplayProps {
    cost: number;
}

export const MonthlyCostDisplay : React.FC<IMonthlyCostDisplayProps> = ({
    cost,
}) => {

    return (
        <div className={styles.root}>
            Monthly Cost : <span className={styles.cost}>${froundToIntlString(cost, 2)}</span>
        </div>
    )
}