import React from "react";
import { VerticalBarProps } from "./VerticalBarProps";

export const VerticalBar: React.FC<VerticalBarProps> = ({ id, x, y, width, height, color }) => {

    return (
        <rect 
            key={id} 
            x={x} 
            y={y} 
            width={width < 0 ? 0 : width} 
            height={height} 
            fill={color} 
        />
    )
};
