import styles from "./ActionsContainer.module.css";

export const ActionsContainer = ({
    children,
}:{
    children?: React.ReactElement | Array<React.ReactElement>
}) => {

    return (
        <div className={styles.root}>
            <h4 className={styles.heading}>Actions</h4>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}