import { ActiveScheduledChangesList } from "./ActiveScheduledChangesList";
import { HistoricScheduledChangesList } from "./HistoricScheduledChangesList";

/**
 * A container component which shows a list of any on-going and future dated scheduled changes
 * as well as a list of any historic scheduled changes
 * @param param0
 * @returns
 */
export const ScheduledChanges = ({
    wanInterfaceId,
    lastRefresh,
    hidePricing,
    onEditChanges,
    onImmediateUpgrade,
}: {
    wanInterfaceId?: number;
    lastRefresh: number;
    hidePricing: boolean;
    onEditChanges: () => void;
    onImmediateUpgrade: () => void;
}) => {

    return (
        <div>
            {/*
                Display a list of the on-going and upcoming scheduled changes
                and allow the user to initiate edits of scheduled changes
                and creation of an immediate upgrade
            */}
            <ActiveScheduledChangesList 
                wanInterfaceId={wanInterfaceId}
                lastRefresh={lastRefresh}
                hidePricing={hidePricing}
                onEditChanges={onEditChanges}
                onImmediateUpgrade={onImmediateUpgrade}
            />

            {/*
                Display a list of the historic scheduled changes
            */}
            <HistoricScheduledChangesList 
                wanInterfaceId={wanInterfaceId}
                lastRefresh={lastRefresh}
                hidePricing={hidePricing}
            />
        </div>            
    )
}