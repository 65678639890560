import { deleteOptions, authenticatedGetOptions, postOptions, putOptions } from "../../../../../dataServices/components/fetchOptions";

// See this for reasoning and possible implementation of an abort handler
// https://semaphoreci.com/blog/api-layer-react

export const DataApi = {
    getHierarchy: async function () : Promise<Response> {
        const url = `/api/vno/clients/hierarchy`;

        const options = {
            ...authenticatedGetOptions
        }
        
        return await fetch(url, options);
    },

    createClient: async function(clientGroupId: number, name: string) : Promise<Response> {
        const url = `/api/vno/clients/clients`;

        const options = {
            ...postOptions,
            body: JSON.stringify({
                clientGroupId: clientGroupId,
                name: name
            })
        }

        return await fetch(url, options);
    },

    deleteClient: async function(clientId: number) : Promise<Response> {
        const url = `/api/vno/clients/clients/${clientId}`;

        const options = {
            ...deleteOptions
        }

        return await fetch(url, options);
    },

    renameClient: async function(clientId: number, name: string) : Promise<Response> {
        const url = `/api/vno/clients/clients`;

        const options = {
            ...putOptions,
            body: JSON.stringify({
                id: clientId,
                name: name,
            })
        }

        return await fetch(url, options);
    },

    createInstallationGroup: async function(clientId: number, name: string) : Promise<Response> {
        const url = `/api/vno/clients/installationGroups`;

        const options = {
            ...postOptions,
            body: JSON.stringify({
                clientId: clientId,
                name: name
            })
        }

        return await fetch(url, options);
    },

    renameInstallationGroup: async function(installationGroupId: number, name: string) : Promise<Response> {
        const url = `/api/vno/clients/installationGroups`;

        const options = {
            ...putOptions,
            body: JSON.stringify({
                id: installationGroupId,
                name: name    
            })
        }

        return await fetch(url, options);
    },

    deleteInstallationGroup: async function(installationGroupId: number) : Promise<Response> {
        const url = `/api/vno/clients/installationGroups/${installationGroupId}`;

        const options = {
            ...deleteOptions,
        }
    
        return await fetch(url, options);
    },

    moveInstallation: async function(installationId: number, targetInstallationGroupId: number) : Promise<Response> {
        const url = `/api/vno/clients/installations`;

        const options = {
            ...putOptions,
            body: JSON.stringify({
                id: installationId,
                installationGroupId: targetInstallationGroupId
            }),
        }

        return await fetch(url, {...options});
    },
}