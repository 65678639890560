import { useState } from "react";
import styles from "./ConfirmationChecks.module.css";

export const ConfirmationChecks = (
    {
        onChange,
    }:
    {
        onChange: (accepted: boolean) => void
    }
) => {
    const [checked, setCheck] = useState(false);

    const handleCheckboxChange = () => {
        const state = !checked;
        setCheck(state);
        onChange(state);
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                Important Information
            </div>
            <div className={styles.messages}>
                <ul>
                    <li>Please be aware that all scheduled future changes will be removed as part of the Immediate Upgrade process.</li>
                    <li>Please reschedule any future changes once this Immediate Upgrade has been processed.</li>
                    <li>Performing an Immediate Upgrade will incur a full 24 hour daily charge for today.</li>
                </ul>
            </div>
            <div>
                <label>
                    <input 
                        className={styles.checkbox}
                        type="checkbox" 
                        checked={checked}
                        onChange={handleCheckboxChange} 
                    />
                    I understand
                </label>
            </div>
        </div>
    )
}