import { ErrorBoundary } from "react-error-boundary";
import {
    MapDatePointProvider,
    MapDateRangeProvider,
    MapLocationsProvider,
    SelectedShipProvider,
} from "../../../contexts";
import styles from "./ShipViewPage.module.css";
import { Content } from "./content";
import { Installations } from "./installations";
import { MapAndControls } from "./map";

const ShipViewPage = () =>{

    return (
        <SelectedShipProvider>
            <MapDatePointProvider>
                <MapDateRangeProvider>
                    <MapLocationsProvider>
                        <ErrorBoundary fallback={<div>Something went wrong</div>}>
                            <main className={styles.root}>          
                                <Installations />
                                <MapAndControls />
                                <Content />
                            </main>
                        </ErrorBoundary>
                    </MapLocationsProvider>
                </MapDateRangeProvider>
            </MapDatePointProvider>
        </SelectedShipProvider>
    );
}

export default ShipViewPage;