import React from "react";
import { ShipModel } from "../../dataServices/ships";
import { SelectedShipContext } from "./SelectedShipContext";

function SelectedShipProvider({children}: {children: React.ReactNode}){

    const [ship, setShip] = React.useState<ShipModel>(null!);

    const value = {ship, setShip};

    return (
        <SelectedShipContext.Provider value={value}>
            {children}
        </SelectedShipContext.Provider>
    );
}

export {SelectedShipProvider};
