import { Column } from "react-table";
import { IGetDataState } from "../../../../../../../../dataServices/components";
import { IScheduledChangeModel } from "../../../../../../../../dataServices/dataPackages/scheduledChanges";
import { Dates } from "../../../../../../../../utils/dates";
import { froundToIntlString } from "../../../../../../../../utils/rounding";
import { AlignEnum, HeaderContent } from "../../../../../../../tables";

// sorts a set of Scheduled Changes in asecending Start Date order
// i.e. earlier dates first
export function sortScheduledChangesAsc(a: IScheduledChangeModel, b: IScheduledChangeModel){
    if (a.startDateMillis < b.startDateMillis) return -1;
    if (a.startDateMillis > b.startDateMillis) return 1;
    return 0;
}

// sorts a set of Scheduled Changes in descending Start Date order
// i.e. later dates first
export function sortScheduledChangesDesc(a: IScheduledChangeModel, b: IScheduledChangeModel) {
    if (a.startDateMillis > b.startDateMillis) return -1;
    if (a.startDateMillis < b.startDateMillis) return 1;
    return 0;
}

// creates a set of Scheduled Changes using state data returned from an API.
export function makeTableData (state: IGetDataState<IScheduledChangeModel[]>, sortFunction: (a: IScheduledChangeModel, b: IScheduledChangeModel) => number) : IScheduledChangeModel[] {

    if (state.status === "error") return [];
    
    if (state.data && state.data.length){
        return state.data.sort(sortFunction).map(change => {
            return {
                ...change
            }
        })
    }

    return [];
}

// function which creates the column definitions used by the react-table
// based table layout
export function makeColumns() : Column<IScheduledChangeModel>[] {
    return [
        {
            Header: ({column}) => { 
                return <HeaderContent text="Start Date" column={column} align={AlignEnum.Left} />
            },
            accessor: "startDateMillis",
            Cell: ({value}) => {return <>{Dates.fromMillis(value).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}</>}
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="End Date" column={column} align={AlignEnum.Left} />
            },
            accessor: "endDateMillis",
            Cell: ({value}) => {return <>{Dates.fromMillis(value).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}</>}
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="Package" column={column} align={AlignEnum.Left} />
            },
            accessor: "ratePlanName",
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="$ Daily Adjustment" column={column} align={AlignEnum.Right} />
            },        
            accessor: "dailyAdjustment",
            Cell: ({cell: {value}}) => {return <div style={{textAlign: "right"}}>{froundToIntlString(value ?? 0, 2)}</div>}
        },
        {
            Header: ({column}) => { 
                return <HeaderContent text="Created By" column={column} align={AlignEnum.Left} />
            },
            accessor: "createdByEmail",
        },
    ]
}
