import React, { useContext } from "react";
import { IRatePlanModel, IScheduledChangeModel } from "../../../../../../../../../dataServices/dataPackages/editScheduledChanges";
import { Dates } from "../../../../../../../../../utils/dates";
import { Button } from "../../../../../../../../buttons";

import { AddOrEditDialog } from "./AddOrEditDialog";
import { getRatePlanFromId } from "./Utils";

import { DebugRawDataDisplay } from "./DebugRawDataDisplay";

import type { EditableItem } from "./EditableItemsList";
import { EditableItemsList } from "./EditableItemsList";

import classNames from "classnames";
import { AuthContext } from "../../../../../../../../../contexts/components/AuthContext";
import { api, fetchApiPost, fetchHandler } from "../../../../../../../../../dataServices/components";

import { Spinner } from "../../../../../../../../spinners/Spinner";
import styles from "./EditChangesDialog.module.css";


// describes a Scheduled Change that is shaped for editing
interface IDataItem {
    id: number;
    ratePlanId: number;
    ratePlanName: string;
    dailyAdjustment: number;
    createdBy: string;
    startDateMillis: number;
    endDateMillis: number;
    equipmentBillingSchemeId: number | null;
    canBeRemoved: boolean;
    isInProgress: boolean;
    added: boolean;
    edited: boolean;
    deleted: boolean
}

// takes an array of scheduled changes as returned by the server
// and creates an array of extended data that allows edits to be tracked
function makeDataItems(existingChanges: IScheduledChangeModel[], currentDateMillis: number) : IDataItem[]{

    return existingChanges.map((change, index) => {
        return {
            id: index,
            startDateMillis: change.startDateMillis,
            endDateMillis: change.endDateMillis,
            ratePlanId: change.ratePlanId,
            ratePlanName: change.ratePlanName,
            dailyAdjustment: change.dailyAdjustment,
            createdBy: change.createdByEmail,
            equipmentBillingSchemeId: change.equipmentBillingSchemeId,
            canBeRemoved: change.startDateMillis > currentDateMillis,
            isInProgress: change.startDateMillis < currentDateMillis,
            deleted: false,
            edited: false,
            added: false,
        }        
    });
}

/**
 * Takes an IDataItem array of 'raw' changes and returns an EditableItem array which represents
 * a set of gaps and changes which can be modified by the user and will ultimately represent the 
 * superset of changes and unmodified entries prior to the changes being saved.
 * @param data holds ALL changes, tracking deletions, additions, edits etc
 * @param defaultMinDateMillis
 * @param basePackageName
 * @param currentServerDate
 * @returns
 */
function makeEditableItems(data: IDataItem[], defaultMinDateMillis: number, basePackageName: string, currentServerDate: number): EditableItem[] {

    // function to sort an array of IDataItems by ascending change start date
    function sort(input: IDataItem[]): IDataItem[] {
        if (input === null || typeof input === "undefined" || !input.length) return [];
        return input.sort((a, b) => {
            if (a.startDateMillis < b.startDateMillis) return -1;
            if (a.startDateMillis > b.startDateMillis) return 1;
            return 0;
        });
    }

    // create a copy of the data items
    const result: EditableItem[] = [];

    // sort the original items into ascending date order and remove any 'deleted' items
    const sortedItemsAndFilteredItems = sort(data.filter(item => !item.deleted));

    // work out if we need to show a gap that represents the base package
    // up to the first package change
    // add an entry if there are future dated changes
    if (sortedItemsAndFilteredItems.length > 0 && sortedItemsAndFilteredItems[0].startDateMillis > currentServerDate) {
        // one or more future changes so add a 'gap' for the base package up to the first change
        result.push({
            type: "gap",
            gap: {
                id: -1,
                startDateMillis: null,
                endDateMillis: sortedItemsAndFilteredItems[0].startDateMillis,
                minStartDateMillis: defaultMinDateMillis,
                maxEndDateMillis: sortedItemsAndFilteredItems[0].startDateMillis,
                ratePlanName: basePackageName
            },
            change: null,
        });
    } else if (sortedItemsAndFilteredItems.length == 0) {
        // no changes so we just have a 'gap' for the base package
        result.push({
            type: "gap",
            gap: {
                id: -1,
                startDateMillis: null,
                endDateMillis: null,
                minStartDateMillis: defaultMinDateMillis,
                maxEndDateMillis: null,
                ratePlanName: basePackageName
            },
            change: null,
        })
    }

    // iterate the displayable items adding entries to our results
    // array for changes and gaps between changes
    sortedItemsAndFilteredItems.forEach((item, index, array) => {

        // calculate the applicable min and max date boundaries for this item
        let minStartDateMillis: number = defaultMinDateMillis;
        const maxStartDateMillis: number = 0; // TODO: this may need to be calculated??
        let minEndDateMillis: number = 0;  // TODO: may need further refinement
        let maxEndDateMillis: number | null = null;
       
        if (index > 0) {
            // min start date is the start of the day after the end date of the preceding item
            minStartDateMillis = Dates.fromMillis(array[index - 1].endDateMillis).nextDay().startOfDay().millis();
        }

        if (index < (array.length - 1)) {
            // max end date is the end of the day before the start date of the next item
            maxEndDateMillis = Dates.fromMillis(array[index + 1].startDateMillis).previousDay().endOfDay().millis();
        }

        if (item.isInProgress) {
            // if the package change is in progress, we can't have end date earlier than today
            // (using the start of today will ensure the date dialog will allow today to be selected which will be converted to the end of today)
            minEndDateMillis = Dates.fromMillis(currentServerDate).startOfDay().millis();
        } else {
            // if the package change is not in progress, the minimum end date is the item start date (1 day of package change)
            minEndDateMillis = item.startDateMillis
        }

        // create a new 'change' entry
        result.push({
            type: "change",
            change: {
                id: item.id,
                ratePlanId: item.ratePlanId,
                ratePlanName: item.ratePlanName,
                startDateMillis: item.startDateMillis,
                endDateMillis: item.endDateMillis,
                minStartDateMillis: minStartDateMillis,
                maxStartDateMillis: maxStartDateMillis, 
                minEndDateMillis: minEndDateMillis, 
                maxEndDateMillis: maxEndDateMillis,
                createdBy: item.createdBy,
                dailyAdjustment: item.dailyAdjustment,
                isAdded: item.added,
                isDeleted: item.deleted,
                isEdited: item.edited,
                canBeRemoved: item.canBeRemoved,
                isInProgress: item.isInProgress,
            },
            gap: null,
        })

        // if the change has no relevant max date boundary or the max is later
        // than the end date for the change we need to create a new gap
        // which can be edited
        if (maxEndDateMillis === null || (maxEndDateMillis > item.endDateMillis)) {
            result.push({
                type: "gap",
                change: null,
                gap: {
                    id: item.id,
                    startDateMillis: Dates.fromMillis(item.endDateMillis).nextDay().startOfDay().millis(),
                    endDateMillis: maxEndDateMillis,
                    minStartDateMillis: Dates.fromMillis(item.endDateMillis).nextDay().startOfDay().millis(),
                    maxEndDateMillis: maxEndDateMillis,
                    ratePlanName: basePackageName
                }
            })
        }
    });

    return result;
}

export const EditChangesDialog = ({
    wanInterfaceId,
    ratePlans,
    scheduledChanges,
    currentServerDateMillis,
    newChangeDefaultStartDateMillis,
    endOfTodayMillis,
    basePackageName,
    hidePricing,
    onClose
}: {
    wanInterfaceId: number,
    ratePlans: IRatePlanModel[],
    scheduledChanges: IScheduledChangeModel[],
    currentServerDateMillis: number,
    newChangeDefaultStartDateMillis: number,
    endOfTodayMillis: number,
    basePackageName: string,
    hidePricing: boolean,
    onClose: (updated: boolean) => void,
}) => {

    type EditParams = {
        type: "edit" | "add",
        id: number | null,
        isInProgress: boolean,
        startDateMillis: number,
        endDateMillis: number,
        minStartDateMillis: number,
        maxStartDateMillis: number | null,
        minEndDateMillis: number,
        maxEndDateMillis: number | null,
        ratePlanId: number | null
    };

    // the actual data items which are being manipulated based on the supplied scheduled changes
    const [data, setData] = React.useState<IDataItem[]>(makeDataItems(scheduledChanges, currentServerDateMillis));

    // true if the component is saving data etc
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    // any error messages arising from saving data etc
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    // true if the Add / Edit change dialog should be shown 
    // (default to true if there are no existing scheduled changes to avoid having an unnecessary user action)
    const [showEdit, setShowEdit] = React.useState<boolean>(scheduledChanges.length === 0 ? true : false);

    // the parameters to pass to the Add / Edit dialog
    // (defaulted for the case where we are automatically showing the dialog when there are no existing scheduled changes)
    const [editParams, setEditParams] = React.useState<EditParams | null>(scheduledChanges.length === 0 ?
        {
            type: "add",
            id: null,
            isInProgress: false,
            startDateMillis: newChangeDefaultStartDateMillis,
            endDateMillis: Dates.fromMillis(newChangeDefaultStartDateMillis).endOfDay().millis(),
            minStartDateMillis: newChangeDefaultStartDateMillis,
            maxStartDateMillis: null,  // TODO: this needs to be calculated correctly
            minEndDateMillis: 0,  // TODO: this needs to be calculated correctly
            maxEndDateMillis: null,
            ratePlanId: null
        } : null);

    // used to obtain the name of the user making any changes
    const { user } = useContext(AuthContext);

    // true if the user has made any material changes
    const [changesMade, setChangesMade] = React.useState<boolean>(false);

    // url of the end point where changes will be saved
    const postUrl = api.urls.packages.post.editScheduledChanges();

    // keep a note of the last 'item' that was edited / added so we can let the list
    // know to allow the list to show a highlight after editing
    const [lastEditedItemId, setLastEditedItemId] = React.useState<number | null>(null);

    function handleOnEditChange(params: {
        id: number,
        isInProgress: boolean,
        startDateMillis: number,
        endDateMillis: number,
        minStartDateMillis: number,
        maxStartDateMillis: number | null,
        minEndDateMillis: number,
        maxEndDateMillis: number | null,
        ratePlanId: number
    }) {
        setLastEditedItemId(null);

        setEditParams({
            type: "edit",
            ...params
        })
        setShowEdit(true);
    }

    function handleOnEditGap(params: {
        id: number,
        startDateMillis: number | null,
        endDateMillis: number | null,
        minStartDateMillis: number,
        maxEndDateMillis: number | null,
    }) {
        setLastEditedItemId(null);

        setEditParams({
            type: "add",
            id: null,
            isInProgress: false,
            startDateMillis: params.startDateMillis ? params.startDateMillis : newChangeDefaultStartDateMillis,
            endDateMillis: params.endDateMillis ? params.endDateMillis : Dates.fromMillis(params.startDateMillis ? params.startDateMillis : newChangeDefaultStartDateMillis).endOfDay().millis(),
            minStartDateMillis: params.minStartDateMillis ? params.minStartDateMillis : newChangeDefaultStartDateMillis,
            maxStartDateMillis: 0, // the min start date and max end date make this irrelevant
            minEndDateMillis: 0, // the min start date and max end date make this irrelevant
            maxEndDateMillis: params.maxEndDateMillis ? params.maxEndDateMillis : null,
            ratePlanId: null
        });
        setShowEdit(true);
    }

    function handleOnDeleteChange(id: number) {

        // TODO: should we prompt first?
        // delete is done by marking the original change item as deleted
        const copied = [...data];

        copied[id].deleted = true;

        setData(copied);

        setChangesMade(true);
    }

    /**
     * Handle a request to apply the addition of a Scheduled Change
     */
    function handleOnAddAccepted(startDateMillis: number, endDateMillis: number, ratePlanId: number) {

        // get the rate plan identified by the supplied id
        const ratePlan = getRatePlanFromId(ratePlans, ratePlanId);

        if (ratePlan) {

            const id = data.length;

            // add a new item to the data, using the data array length for the id
            const addedItem: IDataItem[] = [{
                id: id,
                startDateMillis: startDateMillis,
                endDateMillis: endDateMillis,
                ratePlanId: ratePlan.id,
                ratePlanName: ratePlan.name,
                dailyAdjustment: ratePlan.dailyAdjustment,
                equipmentBillingSchemeId: null,
                isInProgress: false,
                canBeRemoved: true,
                createdBy: user?.userName ? user?.userName : "",
                added: true,
                deleted: false,
                edited: false,
            }];

            setData(prev => prev.concat(addedItem));

            setChangesMade(true);

            setLastEditedItemId(id);
        }

        setShowEdit(false);
    }

    /**
     * Handle a request to apply edits to a Scheduled Change
     */
    function handleOnEditAccepted(id: number, startDateMillis: number, endDateMillis: number, ratePlanId: number) {

        const ratePlan = getRatePlanFromId(ratePlans, ratePlanId);

        if (ratePlan) {
            // copy the existing data
            const copied = [...data];

            // set the properties of the relevant data item
            copied[id].edited = true;
            copied[id].ratePlanId = ratePlan?.id;
            copied[id].ratePlanName = ratePlan?.name;
            copied[id].endDateMillis = endDateMillis;
            copied[id].startDateMillis = startDateMillis;
            copied[id].dailyAdjustment = ratePlan.dailyAdjustment;

            // update the data items
            setData(copied);
        }

        setShowEdit(false);

        setChangesMade(true);

        setLastEditedItemId(id);
    }

    /**
     * Handle a request to Cancel the Scheduled Changes dialog without
     * persisting any changes.
     */
    function handleCancelClick() {
        onClose(false);
    }

    type PersistableState = "added" | "edited" | "deleted" | "unchanged" | "ignore";

    interface IPersistableChange {
        status: PersistableState
        startDateMillis: number;
        endDateMillis: number;
        ratePlanId: number;
        equipmentBillingSchemeId: number | null;
    }

    /**
     * Handle a request to Save all the Changes.
     */
    function handleOkClick() {

        setIsProcessing(true);
        setErrorMessage(null);

        // determine the state of each item in the data array
        // new (i.e. created in this edit session)
        // edited (i.e. existing item that has been edited in this session)
        // deleted (i.e. existing item that has been deleted in this session)
        // unchanged (i.e. existing item that has not been changed in this session)
        // ignore (i.e. an item created in this session which has also been deleted in this session)
        const items: IPersistableChange[] = data.map((value) => {

            let status: PersistableState = "ignore";

            if (value.added) {
                if (!value.deleted) {
                    status = "added";
                }
            } else {
                if (value.edited) {
                    status = "edited";
                } else if (value.deleted) {
                    status = "deleted";
                } else {
                    status = "unchanged"
                }
            }

            return {
                status: status,
                startDateMillis: value.startDateMillis,
                endDateMillis: value.endDateMillis,
                equipmentBillingSchemeId: value.equipmentBillingSchemeId,
                ratePlanId: value.ratePlanId
            }
        })

        // remove the items we are not interested in
        const persistableItems = items.filter((value) => value.status !== "ignore");

        // data to send to the backend api
        const apiData = {
            wanInterfaceId: wanInterfaceId,
            scheduledChanges: persistableItems
        }

        fetchApiPost(postUrl, apiData, { includeCredentials: true })
            .then((postResponse) => {
                setIsProcessing(false);
                fetchHandler(postResponse)
                    .then((handlerResult) => {
                        if (handlerResult.succeeded) {
                            onClose(persistableItems.length > 0);
                        } else {
                            setErrorMessage("Failed to save the Scheduled Changes");
                        }
                    }).catch(() => { setErrorMessage("Failed to save the Scheduled Changes"); })
            })
            .catch(() => {
                setIsProcessing(false);
                setErrorMessage("Sorry - something went wrong");
            })
    }

    // create an array of items that is based on the underlying set of changes
    // but which is augmented to allow edits to be made
    const editableItems = makeEditableItems(data, newChangeDefaultStartDateMillis, basePackageName, currentServerDateMillis);

    return (
        <div className={styles.root}>
            <div className={`${classNames(styles.header, showEdit && styles.inActive)}`}>Manage Package Changes</div>
            {isProcessing &&
                <div className={styles.disabledOverlay}><Spinner /></div>
            }
            <div className={styles.container}>

                <EditableItemsList
                    items={editableItems}
                    lastEditedItemId={lastEditedItemId}
                    hidePricing={hidePricing}
                    onEditGap={handleOnEditGap}
                    onEditChange={handleOnEditChange}
                    onDeleteChange={handleOnDeleteChange}
                />

                {errorMessage &&
                    <div className={styles.errorMessage}>{errorMessage}</div>
                }

                <div className={styles.buttons}>
                    <Button
                        text="Ok"
                        color="success"
                        onClick={handleOkClick}
                        disabled={!changesMade}
                    />
                    <Button
                        text="Cancel"
                        color="secondary"
                        onClick={handleCancelClick}
                    />
                </div>

            </div>

            {showEdit && editParams &&
                <AddOrEditDialog
                    dialogType={editParams.type}
                    itemId={editParams.id}
                    isInProgress={editParams.isInProgress}
                    startDateMillis={editParams.startDateMillis}
                    endDateMillis={editParams.endDateMillis}
                    minStartDateMillis={editParams.minStartDateMillis}
                    maxStartDateMillis={editParams.maxStartDateMillis}
                    minEndDateMillis={editParams.minEndDateMillis}
                    maxEndDateMillis={editParams.maxEndDateMillis}
                    ratePlanId={editParams.ratePlanId}
                    ratePlans={ratePlans}
                    hidePricing={hidePricing}
                    onCancel={() => { setShowEdit(false) }}
                    onAddAccepted={handleOnAddAccepted}
                    onEditAccepted={handleOnEditAccepted}
                />
            }

            {false &&
                <DebugRawDataDisplay
                    items={data}
                    ratePlanCount={ratePlans.length}
                    currentServerDateMillis={currentServerDateMillis}
                    endOfTodayMillis={endOfTodayMillis}
                    newChangeDefaultStartDateMillis={newChangeDefaultStartDateMillis}
                    basePackageName={basePackageName}
                />
            }
        </div>
    )
}