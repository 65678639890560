import styles from "./ErrorMessage.module.css";

/**
 * A component that can display an error message 
 */
export const ErrorMessage = (props: {text: string}) => {
    return (
        <div className={styles.root}>
            {props.text}
        </div>
    )
}
