import { IClientGroup } from "../../data/Models";
import { ClientGroupUsers } from "./ClientGroupUsers";
import { Header } from "./Header";
import { ActionTypes } from "../../data/State";
import { Client } from "./Client";

interface IClientGroupProps {
    clientGroup: IClientGroup;
    dispatch: React.Dispatch<ActionTypes>;
}

export const ClientGroup : React.FC<IClientGroupProps> = ({
    clientGroup,
    dispatch,
}) => {
    
    return (
        <ul>
            <Header 
                text={clientGroup.name} 
                isExpandable={false}
            />
            <ul>
                <ClientGroupUsers 
                    key={`users-${clientGroup.id}`}
                    clientGroupId={clientGroup.id}
                    users={clientGroup.users} 
                    dispatch={dispatch}
                />
                {clientGroup.clients.map(client => 
                    <Client
                        key={client.id}
                        client={client}
                        clientGroupId={clientGroup.id}
                        dispatch={dispatch}
                    />
                    )}
            </ul>
        </ul>
    )
}