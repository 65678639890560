import { IClientGroup } from "./Models"
import produce from "immer"

export interface IApiData {
    clientGroups: IClientGroup[]
}

interface IClientAdded {
    clientGroupId: number,
    id: number,
    name: string,
}

interface IClientDeleted {
    clientGroupId: number,
    clientId: number,
}

interface IClientUpdated {
    clientGroupId: number,
    clientId: number,
    name: string,
}

interface IInstallationGroupAdded {
    clientGroupId: number,
    clientId: number,
    id: number,
    name: string,
}

interface IInstallationGroupDeleted {
    clientGroupId: number,
    clientId: number,
    installationGroupId: number,
}

interface IInstallationGroupUpdated {
    clientGroupId: number,
    clientId: number,
    installationGroupId: number,
    name: string,
}

interface IInstallationMoved {
    installationId: number,
    from: {
        clientGroupId: number,
        clientId: number,
        installationGroupId: number,    
    },
    to: {
        clientGroupId: number,
        clientId: number,
        installationGroupId: number,    
    }
}

type State = {
    loading: boolean,
    data?: IApiData | null,
    error?: string | null
}

export enum Actions {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    LOADING_FAILED = 'LOADING_FAILED',
    CLIENT_ADDED = 'CLIENT_ADDED',
    CLIENT_DELETED = 'CLIENT_DELETED',
    CLIENT_UPDATED = 'CLIENT_UPDATED',
    INSTALLATION_GROUP_ADDED = 'INSTALLATION_GROUP_ADDED',
    INSTALLATION_GROUP_DELETED = 'INSTALLATION_GROUP_DELETED',
    INSTALLATION_GROUP_UPDATED = 'INSTALLATION_GROUP_UPDATED',
    INSTALLATION_MOVED = 'INSTALLATION_MOVED',
}

export const initialState: State = {
    loading: false,
}

type LoadingAction = {
    type: typeof Actions.LOADING
}

type LoadedAction = {
    type: typeof Actions.LOADED,
    data: IApiData
}

type LoadingFaileddAction = {
    type: typeof Actions.LOADING_FAILED,
    data: string
}

type ClientAddedAction = {
    type: typeof Actions.CLIENT_ADDED,
    data: IClientAdded
}

type ClientDeletedAction = {
    type: typeof Actions.CLIENT_DELETED,
    data: IClientDeleted
}

type ClientUpdatedAction = {
    type: typeof Actions.CLIENT_UPDATED,
    data: IClientUpdated
}

type InstallationGroupAddedAction = {
    type: typeof Actions.INSTALLATION_GROUP_ADDED,
    data: IInstallationGroupAdded
}

type InstallationGroupDeletedAction = {
    type: typeof Actions.INSTALLATION_GROUP_DELETED,
    data: IInstallationGroupDeleted
}

type InstallationGroupUpdatedAction = {
    type: typeof Actions.INSTALLATION_GROUP_UPDATED,
    data: IInstallationGroupUpdated
}

type InstallationMovedAction = {
    type: typeof Actions.INSTALLATION_MOVED,
    data: IInstallationMoved
}

export type ActionTypes = 
    LoadingAction | LoadedAction | LoadingFaileddAction |
    ClientAddedAction | ClientDeletedAction | ClientUpdatedAction |
    InstallationGroupAddedAction | InstallationGroupDeletedAction | InstallationGroupUpdatedAction |
    InstallationMovedAction;

interface IName {
    name: string
}

const sortByNameAsc = (a: IName, b: IName) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1; 
    if (nameA > nameB) return 1; 
    return 0;
}

const clientAdded = (draft: State, data: IClientAdded) => {
    // using immer to work directly on the clients
    // of the relevant client group
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);

    if (clientGroup) {
        // get the existing clients
        const sortedClients = clientGroup.clients.slice();
        // add the new entry
        sortedClients.push({
            id: data.id,
            name: data.name,
            isVnoHoldingClient: false,
            installationGroups: []
        });
        // sort the clients
        sortedClients.sort(sortByNameAsc);
        // and assign the sorted clients back to the client group
        clientGroup.clients = sortedClients;
    }
}

const clientDeleted = (draft: State, data: IClientDeleted) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (clientGroup) {
        clientGroup.clients = clientGroup.clients.filter(client => client.id !== data.clientId);
    }

}

const clientUpdated = (draft: State, data: IClientUpdated) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (clientGroup){
        // get the existing clients
        const sortedClients = clientGroup.clients.slice();
        // update the client
        const client = sortedClients.find(client => client.id === data.clientId);
        if (client){
            client.name = data.name;
            // sort the clients
            sortedClients.sort(sortByNameAsc);
            // and assign the sorted clients back to the client group
            clientGroup.clients = sortedClients;
        }
    }
}

const installationGroupAdded = (draft: State, data: IInstallationGroupAdded) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (clientGroup){
        const client = clientGroup.clients.find(c => c.id === data.clientId);
        if (client){
            const sortedInstallationGroups = client.installationGroups.slice();
            sortedInstallationGroups.push({
                id: data.id,
                name: data.name,
                installations: []
            });
            sortedInstallationGroups.sort(sortByNameAsc);
            client.installationGroups = sortedInstallationGroups;
        }
    }
}

const installationGroupDeleted = (draft: State, data: IInstallationGroupDeleted) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (clientGroup){
        const client = clientGroup.clients.find(c => c.id === data.clientId);
        if (client){
            client.installationGroups = client.installationGroups.filter(ig => ig.id !== data.installationGroupId);
        }
    }
}

const installationGroupUpdated = (draft: State, data: IInstallationGroupUpdated) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (clientGroup){
        const client = clientGroup.clients.find(c => c.id === data.clientId);
        if (client){
            const sortedInstallationGroups = client.installationGroups.slice();
            const installationGroup = sortedInstallationGroups.find(ig => ig.id === data.installationGroupId);
            if (installationGroup){
                installationGroup.name = data.name;
                sortedInstallationGroups.sort(sortByNameAsc);
                client.installationGroups = sortedInstallationGroups;
            }
        }
    }
}

const installationMoved = (draft: State, data: IInstallationMoved) => {
    const fromClientGroup = draft.data?.clientGroups.find(cg => cg.id === data.from.clientGroupId);
    const toClientGroup = draft.data?.clientGroups.find(cg => cg.id === data.to.clientGroupId);
    if (fromClientGroup && toClientGroup){
        const fromClient = fromClientGroup.clients.find(c => c.id === data.from.clientId);
        const toClient = toClientGroup.clients.find(c => c.id === data.to.clientId);
        if (fromClient && toClient){
            const fromInstallationGroup = fromClient.installationGroups.find(ig => ig.id === data.from.installationGroupId);
            const toInstallationGroup = toClient.installationGroups.find(ig => ig.id === data.to.installationGroupId);
            if (fromInstallationGroup && toInstallationGroup){
                const installation = fromInstallationGroup.installations.find(i => i.id === data.installationId);
                if (installation){
                    // remove the from item
                    fromInstallationGroup.installations = fromInstallationGroup.installations.filter(i => i.id !== data.installationId);
                    // add the to item
                    const sortedToInstallations = toInstallationGroup.installations.slice();
                    sortedToInstallations.push({
                        ...installation
                    });
                    sortedToInstallations.sort(sortByNameAsc);
                    toInstallationGroup.installations = sortedToInstallations;
                }
            }
        }
    }
}

// use immer to do a 'mutable' update of the state
export const reducer = produce((draft: State, action: ActionTypes) => {
    switch (action.type) {
        case Actions.LOADING:
            draft.loading = true;
            draft.error = null;
            break;
        case Actions.LOADED:
            draft.loading = false;
            draft.data = action.data;
            draft.error = null;
            break;
        case Actions.LOADING_FAILED:
            draft.loading = false;
            draft.data = null;
            draft.error = action.data;
            break;
        case Actions.CLIENT_ADDED:
            clientAdded(draft, action.data);
            break;
        case Actions.CLIENT_DELETED:
            clientDeleted(draft, action.data);
            break;
        case Actions.CLIENT_UPDATED:
            clientUpdated(draft, action.data);
            break;
        case Actions.INSTALLATION_GROUP_ADDED:
            installationGroupAdded(draft, action.data);
            break;
        case Actions.INSTALLATION_GROUP_DELETED:
            installationGroupDeleted(draft, action.data);
            break;
        case Actions.INSTALLATION_GROUP_UPDATED:
            installationGroupUpdated(draft, action.data);
            break;
        case Actions.INSTALLATION_MOVED:
            installationMoved(draft, action.data);
            break;
        default:
            break;
    }
});


