import { useFetchImmediateUpgradeMetaData } from "../../../../../../../../../dataServices/dataPackages/immediateChanges";
import { Spinner } from "../../../../../../../../spinners/Spinner";
import { ImmediateUpgradeDialog } from "./ImmediateUpgradeDialog";

import styles from "./ImmediateUpgrade.module.css";

interface IImmediateUpgradeProps {
    wanInterfaceId: number; 
    hidePricing: boolean;
    onClose: (updated:boolean)=> void
}

export const ImmediateUpgrade : React.FC<IImmediateUpgradeProps> = ({
    wanInterfaceId,
    hidePricing,
    onClose
}) => {

    const {state} = useFetchImmediateUpgradeMetaData(wanInterfaceId);

    let content = null;

    if (state.status === "idle" || state.status === "in_progress") 
    {
        content =  <Spinner/>
    } 
    else if (state.status === "error") 
    {
        return null;
    }
    else 
    {
        content =         
            <ImmediateUpgradeDialog
                wanInterfaceId={wanInterfaceId}
                availableRatePlans={state.data?.ratePlans??[]}
                earliestEndDateMillis={state.data?.earliestEndDateMillis??0}
                hidePricing={hidePricing}
                onClose={onClose}
            />
    }

    return (
        <div className={styles.overlay}>
            {content}
        </div>
    )
}