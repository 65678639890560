import { Button } from "../../../../../buttons";
import { FormContainer } from "./FormContainer";
import styles from "./Form.module.css";

interface IDeleteFormProps {
    label: string;
    isProcessing: boolean;
    onOk: () => void;
    onClose: () => void;
}

export const DeleteForm : React.FC<IDeleteFormProps> = ({
    label,
    isProcessing,
    onOk,
    onClose,
}) => {
    return (
        <FormContainer isProcessing={isProcessing}>
            <div className={styles.title}>Confirm delete</div>
            <div className={styles.label}>{label}</div>
            <div className={styles.buttons}>
                <Button
                    size="small"
                    color="danger"
                    text="Ok"
                    onClick={onOk}
                    className={styles.button}
                />
                <Button
                    size="small"
                    color="secondary"
                    text="Cancel"
                    onClick={onClose}
                    className={styles.button}
                />
            </div>
       </FormContainer>
    )
}