import React from "react";

export type MapLocationType = {
    lat: number | null,
    lng: number | null,
}

/**
 * Describes the set of geographical locations and routes associated with the 
 * currently selected ship over the currently selected time span.
 */
export type MapLocationsContextType = {
    // a Map of location TimeStamp to Location value
    locations: Map<number, MapLocationType>;
    // an array of routes built from the locations data
    routes: Array<Array<Array<number>>>;
    isLoading: boolean;
}

export const MapLocationsContext = React.createContext<MapLocationsContextType>(null!);