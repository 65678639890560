import { deleteOptions, authenticatedGetOptions, postOptions, putOptions } from "../../../../../dataServices/components/fetchOptions";

export const DataApi = {
    getHierarchy: async function() : Promise<Response> {
        const url = `/api/vno/users/hierarchy`;

        const options = {
            ...authenticatedGetOptions
        }
        
        return await fetch(url, options);
    },

    addUserToClient: async function(userId: number, clientId: number) : Promise<Response> {
        const url = `/api/vno/users/clients`;

        const options = {
            ...postOptions,
            body: JSON.stringify({
                userId: userId,
                clientId: clientId,
            })
        }

        return await fetch(url, options);
    },

    moveUserToClient: async function(userId: number, sourceClientId: number, targetClientId: number) : Promise<Response> {
        const url = `/api/vno/users/clients`;

        const options = {
            ...putOptions,
            body: JSON.stringify({
                userId: userId,
                sourceClientId: sourceClientId,
                targetClientId: targetClientId
            })
        }

        return await fetch(url, options);
    },

    addUserToClientGroup: async function(userId: number, clientGroupId: number) : Promise<Response> {
        const url = `/api/vno/users/clientGroups`;

        const options = {
            ...postOptions,
            body: JSON.stringify({
                userId: userId,
                clientGroupId: clientGroupId
            })
        }

        return await fetch(url, options);
    },

    removeUserFromClient: async function(userId: number, clientId: number) : Promise<Response> {
        const url = `/api/vno/users/clients`;

        const options = {
            ...deleteOptions,
            body: JSON.stringify({
                userId: userId,
                clientId: clientId
            })
        }

        return await fetch(url, options);
    },

    removeUserFromClientGroup: async function(userId: number, clientGroupId: number) : Promise<Response> {
        const url = `/api/vno/users/clientGroups`;

        const options = {
            ...deleteOptions,
            body: JSON.stringify({
                userId: userId,
                clientGroupId: clientGroupId
            })
        }

        return await fetch(url, options);
    }
}