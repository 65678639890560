import { useState } from "react";
import { DataApi } from "../../data/DataApi";
import { Actions, ActionTypes } from "../../data/State";
import { AddEditForm } from "../common/AddEditForm";
import { notifyError } from "../../../common/errors/Errors";

interface IRenameInstallationGroupProps {
    clientGroupId: number;
    clientId: number;
    installationGroupId: number;
    name: string;
    onClose: () => void;
    dispatch: React.Dispatch<ActionTypes>;
}

export const RenameInstallationGroup : React.FC<IRenameInstallationGroupProps> = ({
    clientGroupId,
    clientId,
    installationGroupId,
    name,
    onClose,
    dispatch,
}) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleOk = async (name: string) => {

        setIsSaving(true);

        let ok = false;

        try {
            const response = await DataApi.renameInstallationGroup(installationGroupId, name);

            ok = response.ok;
        }
        catch (error) {
            console.error("There was an error", error);
        }

        if (ok) {
            dispatch({
                type: Actions.INSTALLATION_GROUP_UPDATED,
                data: {
                    clientGroupId: clientGroupId,
                    clientId: clientId,
                    installationGroupId: installationGroupId,
                    name: name
                }
            });
            onClose();
        } else {
            notifyError();
        }
        
        setIsSaving(false);
    }

    return (
        <AddEditForm
            title="Rename Fleet"
            label="Fleet Name:"
            defaultValue={name}
            isProcessing={isSaving}
            onOk={handleOk}
            onCancel={onClose}
        />
    )
}