import React from "react";

/**
 * Hook which executes a callback at a given interval
 */
function useInterval(callback: () => void, delay: number){
    
    const savedCallback = React.useRef<()=>void>();

    // remember the last callback
    React.useEffect(() => {
        savedCallback.current = callback;
    },[callback])

    // set up the interval
    React.useEffect(() => {

        function tick() {
            if (savedCallback.current){
                savedCallback.current();
            }
        }

        if (delay){
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            }
        }

    }, [callback, delay]);
}

export {useInterval};
