import classNames from "classnames";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";
import styles from "./UnassignedUser.module.css";

interface IUnassignedUserProps {
    clientGroupId: number;
    userId: number;
    name: string;
    canBeMoved: boolean;
}

export const UnassignedUser : React.FC<IUnassignedUserProps> = ({
    clientGroupId,
    userId,
    name,
    canBeMoved,
}) => {

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.USER,
            item: {
                clientGroupId: clientGroupId,
                userId: userId,
                userName: name,
                type: "unassigned"
            } as IUserDnd,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }), 
        []
    );
    
    return (
        <li>
            <div className={styles.root}>
                <div className={`${classNames(styles.inner, canBeMoved && styles.canDrag, isDragging && styles.isDragging)}`} ref={canBeMoved ? drag : null}>
                    {name}
                </div>
            </div>
        </li>
    )
}
