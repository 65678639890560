import { AxisScale as d3AxisScale } from "d3-axis";
import { RefObject, createRef, useContext } from "react";
import { MapDatePointContext } from "../../../../../../../../../../contexts/components/MapDatePointContext";

export const TimeLine = (
    { height, scale, width }: { height: number, scale: d3AxisScale<Date | number>, width: number }
) => {

    const elRef: RefObject<SVGRectElement> = createRef();

    const datePoint = useContext(MapDatePointContext);

    const x1 = scale(datePoint.dateMillis) || 0;

    return (
        <rect ref={elRef} x={x1} width={width} height={height} style={{opacity: 0.3}}></rect>
    )
}