import { useState } from "react";
import { Dates } from "../../../../../../../../../utils/dates";
import { froundToIntlString } from "../../../../../../../../../utils/rounding";
import { Button } from "../../../../../../../../buttons";
import styles from "./EditableItemsList.module.css";
import classNames from "classnames";

function displayDate(dateInMillis: number | null): string {
    if (dateInMillis === null) return "";
    return Dates.fromMillis(dateInMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS);
}

type GapItem = {
    id: number,
    ratePlanName: string,
    startDateMillis: number | null,
    endDateMillis: number | null,
    minStartDateMillis: number,
    maxEndDateMillis: number | null
}

type ChangeItem = {
    id: number,
    startDateMillis: number,
    endDateMillis: number,
    ratePlanId: number,
    ratePlanName: string,
    minStartDateMillis: number,
    maxStartDateMillis: number | null,
    minEndDateMillis: number,
    maxEndDateMillis: number | null,
    createdBy: string,
    dailyAdjustment: number,
    isAdded: boolean,
    isDeleted: boolean,
    isEdited: boolean,
    canBeRemoved: boolean,
    isInProgress: boolean,
}

export type EditableItem = {
    type: "gap" | "change",
    gap: GapItem | null,
    change: ChangeItem | null,
}

export const EditableItemsList = (
    {
        items,
        hidePricing,
        lastEditedItemId,
        onEditGap,
        onEditChange,
        onDeleteChange,
    }:
        {
            items: EditableItem[],
            hidePricing: boolean,
            lastEditedItemId: number | null,
            onEditGap: (params: {
                id: number,
                startDateMillis: number | null,
                endDateMillis: number | null,
                minStartDateMillis: number,
                maxEndDateMillis: number | null,
            }) => void,
            onEditChange: (params: {
                id: number,
                isInProgress: boolean,
                startDateMillis: number,
                endDateMillis: number,
                minStartDateMillis: number,
                maxStartDateMillis: number | null,
                minEndDateMillis: number,
                maxEndDateMillis: number | null,
                ratePlanId: number
            }) => void,
            onDeleteChange: (id: number) => void,
        }
) => {

    // id of the item which the user has requested to delete
    const [deleteConfirmationId, setDeleteConfirmationId] = useState<number | null>(null);

    function confirmDelete(id: number) {
        setDeleteConfirmationId(id);
    }

    function cancelConfirmDelete() {
        setDeleteConfirmationId(null);
    }

    function acceptConfirmDelete(id: number) {
        setDeleteConfirmationId(null);
        onDeleteChange(id);
    }

    const makeGapItem = (value: GapItem) => {
        return (
            <tr key={`item-key-gap-${value.id}`}>
                <td>Base Plan</td>
                <td className={styles.packageName}>{value.ratePlanName}</td>
                <td>{value.startDateMillis ? displayDate(value.startDateMillis) : "---"}</td>
                <td>{value.endDateMillis ? displayDate(value.endDateMillis) : "---"}</td>
                {!hidePricing &&
                    <td className={styles.dailyAdjustment}>{froundToIntlString(0)}</td>
                }
                <td></td>
                <td>
                    <Button
                        color="primary"
                        text="Add a Change..."
                        size="small"
                        onClick={() => onEditGap({
                            id: value.id,
                            endDateMillis: value.endDateMillis,
                            maxEndDateMillis: value.maxEndDateMillis,
                            minStartDateMillis: value.minStartDateMillis,
                            startDateMillis: value.startDateMillis,
                        })}
                    />
                </td>
            </tr>
        )
    }

    const makeChangeItem = (value: ChangeItem) => {
        return (
            <tr key={`item-key-change-${value.id}`} className={`${classNames(value.id === lastEditedItemId && styles.highlight)}`}>
                <td>Changes to</td>
                <td className={styles.packageName}>{value.ratePlanName}</td>
                <td>{displayDate(value.startDateMillis)}</td>
                <td>{displayDate(value.endDateMillis)}</td>
                {!hidePricing &&
                    <td className={styles.dailyAdjustment}>{froundToIntlString(value.dailyAdjustment)}</td>
                }
                <td className={styles.createdBy}>{value.createdBy}</td>
                <td>
                    {(deleteConfirmationId !== value.id) &&
                        <>
                            <Button
                                color="primary"
                                text="Edit..."
                                size="small"
                                onClick={() => onEditChange({
                                    ...value
                                })}
                            />
                            <Button
                                color="danger"
                                text="Delete"
                                size="small"
                                disabled={!value.canBeRemoved}
                                onClick={() => confirmDelete(value.id) }
                            />
                        </>
                    }
                    {(deleteConfirmationId === value.id) &&
                        <div>
                            <div className={styles.confirmDeleteMsg }>Confirm delete</div>
                            <Button onClick={() => acceptConfirmDelete(value.id)} color="success" text="Ok" size="small" />
                            <Button onClick={() => cancelConfirmDelete()} color="secondary" text="Cancel" size="small" />
                        </div>    
                    }
                </td>
            </tr>
        )
    }

    const entries = items.map(x => {
        if (x.type == "gap" && x.gap) {
            return makeGapItem(x.gap);
        } else if (x.type == "change" && x.change) {
            return makeChangeItem(x.change);
        } else {
            return null;
        }
    });

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.packageActivity}>Package</th>
                        <th className={styles.packageName}></th>
                        <th className={styles.fromDate}>From</th>
                        <th className={styles.toDate}>Until</th>
                        {!hidePricing &&
                            <th className={styles.dailyAdjustment}>$ Daily Adjustment</th>
                        }
                        <th className={styles.createdBy}>Change Created By</th>
                        <th className={styles.buttons}></th>
                    </tr>
                </thead>
                <tbody>
                    {entries}
                </tbody>
            </table>
        </div>
    )
}