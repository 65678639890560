import React, { RefObject } from "react";
import { useEffect, createRef } from "react";
import { select as d3select } from "d3-selection";
import { axisBottom as d3axisBottom } from "d3-axis";

import { BottomAxisProps } from "./BottomAxisProps";

export const BottomAxis: React.FC<BottomAxisProps> = ({width, top, scale }) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            d3select(elRef.current).call(d3axisBottom(scale));
        }
    }, [width, elRef, scale]);

    return (
        <g ref={elRef} transform={`translate(0, ${top})`}></g>
    );
};
