import { useEffect, useState } from "react";
import { api, useGetData } from "../../components";
import { PackageItemModel } from "./Models";

/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel{
    installationId: number,
    wanInterfaceId?: number,
    description: string,
    total: number,
    sabaEquipmentId: number,
    ratePlanName?: string,
    planCanBeChanged: boolean
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
 function mapper(apiData: IApiModel[]) : PackageItemModel[] {
    return apiData.map((value) => {
        return {
            installationId: value.installationId,
            description: value.description,
            planCanBeChanged: value.planCanBeChanged,
            sabaEquipmentId: value.sabaEquipmentId,
            total: value.total,
            ratePlanName: value.ratePlanName,
            wanInterfaceId: value.wanInterfaceId
        }
    });
}

/**
 * Hook to retrieve Package Item data for a given ship
 * @param installationId Id of the Installations whose data is to be retrieved
 * @returns An object which defines the state of the data fetch.
 */
export const useFetchPackageItems = (installationId: number | undefined) => {

    const [url, setUrl] = useState<string>('');
    const {state} = useGetData(url, mapper);

    useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.packages.get.packageItems(installationId);

        setUrl(url);
        
    }, [installationId]);

    return {state};
}

