import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useRef, useState } from "react";
import { Dates } from "../../../../../../../../../utils/dates";
import { DatePicker, DateSelectorButton } from "../../../../../../../../dates";

import styles from "./EndDate.module.css";

export const EndDate = ({
    endDateMillis,
    minEndDateMillis,
    maxEndDateMillis,
    onChange
}: {
    endDateMillis: number,
    minEndDateMillis: number,
    maxEndDateMillis: number | null,
    onChange: (value: number) => void,
}) => {

    const [showDatePicker, setShowDatePicker] = useState(false);
    
    const anchorRef = useRef<HTMLDivElement | null>(null);

    function handleOnSelected(dateMillis: number){
        setShowDatePicker(false);

        // need to add time to the selected day to make it the end of the day
        const returnedDateMillis = Dates.fromMillis(dateMillis).endOfDay().millis();

        onChange(returnedDateMillis);
    }

    return (
        <div>
            <div ref={anchorRef}>
                <DateSelectorButton
                    onClick={() => setShowDatePicker(true)}
                    className={styles.dateSelectorButton}
                >
                    {Dates.fromMillis(endDateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}
                </DateSelectorButton>
            </div>
            {showDatePicker &&
                <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
                    <div>
                        <DatePicker 
                            minimumDateMillis={minEndDateMillis}
                            maximumDateMillis={maxEndDateMillis}
                            suppliedDateMillis={endDateMillis}
                            anchorRef={anchorRef}
                            onSelected={handleOnSelected}
                            onCancel={() => setShowDatePicker(false)}
                        />
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}
