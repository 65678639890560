import React, { useState } from "react";

import { Button } from "../ui/buttons";
import { application } from "../utils/environment";

import styles from "./DebugBar.module.css";

export const DebugBar : React.FC = () => {
    
    const [disabled, setDisabled] = useState(false);
    
    const debug = false;

    if (!debug) return null;

    return (
        <div className={styles.root}>
            <div>running in {application.environment}</div>
            <div>
                <label>
                    <input type="checkbox" onChange={() => {setDisabled(prev => !prev)}}/>
                    disabled
                </label>
                <Button text="Grey" color="secondary" disabled={disabled} onClick={() => {alert("grey button says hello")}}/>
                <Button text="Primary" color="primary" disabled={disabled} onClick={() => {alert("primary button says hello")}}/>
                <Button text="Success" color="success" disabled={disabled} onClick={() => {alert("success button says hello")}}/>
            </div>
        </div>
    )
}

