import { scaleUtc as d3scaleUtc, scaleLinear as d3scaleLinear, ScaleTime, ScaleLinear } from "d3-scale";
import { line as d3line, curveBasis as d3curveBasis} from "d3-shape";
import { BottomAxis, ChartLine, LeftAxis, TimeLine } from "../components"
import { WanStatusesChartContentProps } from "./WanStatusesChartContentProps"
import { WanStatusesChartItem } from "./WanStatusesChartItem";
import { VerticalBar } from "../components/verticalBars/VerticalBar";
import { VerticalBarProps } from "../components/verticalBars/VerticalBarProps";

type StatusBarsProps = {
    id: number,
    xScale: ScaleTime<number, number, never>,
    yScale: ScaleLinear<number, number, never>,
    color: string,
    interfaceIndex: number,
    chartHeight: number,
    data: WanStatusesChartItem[],
}
const StatusBars : React.FC<StatusBarsProps> = ({id, data, xScale, yScale, color, interfaceIndex, chartHeight}) => {

    const bars = data.map((dataItem, dataItemIndex) => {
        const ltsDate = new Date(dataItem.tsLowerMillis);
        const utsDate = new Date(dataItem.tsUpperMillis);

        const barProps : VerticalBarProps = {
            id: id,
            x: xScale(ltsDate) || 0,
            y: yScale(interfaceIndex + 0.5),
            width: (xScale(utsDate) - xScale(ltsDate)) || 0,
            height: dataItem.show ? (chartHeight - yScale(0.4)) || 0 : 0,
            color: color 
        }

        return <VerticalBar key={(id * 1000) + dataItemIndex} {...barProps} />
    })
    return (
        <g>{bars}</g>
    )
}

type Point = {
    x: number,
    y: number,
}

export const WanStatusesChartContent : React.FC<WanStatusesChartContentProps> = ({overallWidth, overallHeight, margins, chartData}) => {

    if (chartData === null || typeof chartData === "undefined") return null;
    
    const minTsLower = chartData.fromMillis;
    const maxTsUpper = chartData.toMillis + chartData.intervalMillis;

    // a time based scale for the x axis
    const xScale = d3scaleUtc().domain([minTsLower, maxTsUpper]).range([margins.marginLeft, overallWidth - margins.marginRight]);

    const timeLineWidth = xScale(new Date(minTsLower + chartData.intervalMillis)) - xScale(new Date(minTsLower));

    const contentHeight = overallHeight - margins.xAxisHeight;

            // a number scale for the y axis
    const yScale = d3scaleLinear().domain([0, chartData.series.length]).range([contentHeight, 5]).nice();

    // construct a set of data which can be used to calculate the postions of the horizontal lines shown on the chart,
    // one line per interface name, which go full width in the middle of the bar area
    const lineData : Point[][] = chartData.series.map((series) => {

        const yValue = series.index + 0.3;

        return [
            {
                x: chartData.fromMillis,
                y: yValue,
            },
            {
                x: chartData.toMillis + chartData.intervalMillis,
                y: yValue,
            }
        ]
    })

    const lineFn = d3line<Point>().curve(d3curveBasis).x((d:Point) => xScale(d.x)).y((d:Point) => yScale(d.y));

    return (
        // using separate maps for each element to save having to wrap 
        // with a separate null container which would require a key
        <>        
            {chartData.series.map((series) => {
                return (
                        <ChartLine 
                            key={`line-${series.index}`} 
                            color={series.color} 
                            fn={lineFn(lineData[series.index])} 
                            /> 
                )
            })}

            {chartData.series.map((series) =>{
                return (
                    <StatusBars 
                            key={`bar-${series.index}`} 
                            id={series.index} 
                            chartHeight={contentHeight} 
                            color={series.color} 
                            xScale={xScale} 
                            yScale={yScale} 
                            data={series.chartItems} 
                            interfaceIndex={series.index} 
                            />
                )
            })}

            {chartData.series.map((series) => {
                return (
                    <text  
                        key={`label-${series.index}`}                       
                        x={xScale(new Date(chartData.fromMillis + chartData.intervalMillis))} 
                        y={yScale(series.index + 0.6)}>{series.label}
                    </text> 
                )               
            })}

            <BottomAxis
                width={overallWidth - margins.marginLeft - margins.marginRight}
                top={overallHeight - margins.xAxisHeight}
                scale={xScale} 
                />
            <LeftAxis
                width={margins.marginLeft}
                scale={yScale}
                showTicks={false} 
                />            
            <TimeLine
                height={contentHeight}
                width={timeLineWidth}
                scale={xScale}
                />
        </>
    )
}