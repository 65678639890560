import classNames from "classnames";
import React from "react";
import { Dates } from "../../../../utils/dates";
import styles from "./Day.module.css";

export const Day = ({
    dateMillis,
    isToday,
    isVisible,
    isSelectable,
    isSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: {
    // DateTime value that this day represents (start of day)
    dateMillis: number,
    // True if the Day represents the current date
    isToday: boolean,
    // True if the Day should be visible
    isVisible: boolean,
    // True if the Day should be selectable (via mouse click)
    isSelectable: boolean,
    // True if the Day represents a Selected day
    isSelected: boolean,
    // Raised when the Day is clicked
    onClick: (dateMillis: number) => void,

    onMouseEnter: (dateMillis: number) => void,

    onMouseLeave: () => void,
}) => {

    const handleOnClick :React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        onClick(dateMillis);
    }

    const handleOnMouseEnter: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        onMouseEnter(dateMillis);
    }

    const handleOnMouseLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        onMouseLeave();
    }

    let className = styles.root;

    if (isToday) className = classNames(styles.root, styles.today);

    if (isSelected) className = classNames(styles.root, styles.selected);

    if (!isVisible) className = classNames(styles.root, styles.hidden);

    if (!isSelectable) className = classNames(styles.root, styles.notSelectable);

    return (
        <div
            className={className}
            onClick={isVisible && isSelectable ? handleOnClick : () => {}}
            onMouseEnter={isVisible && isSelectable ? handleOnMouseEnter : () => {}}
            onMouseLeave={isVisible  && isSelectable ? handleOnMouseLeave : () => {}}
        >
            {isVisible ? Dates.fromMillis(dateMillis).day()  : ' '}
        </div>
    );
}