import { Button } from "../../../buttons";
import styles from "./Buttons.module.css";

interface IButtonsProps {
    disabled: boolean;
    onCancel: () => void;
    onOk: () => void;
}


export const Buttons : React.FC<IButtonsProps> = ({
    disabled,
    onCancel,
    onOk,
}) => {
    return (
        <div className={styles.root}>
            <Button disabled={disabled} onClick={onOk} text="OK" size="small" color="success"/>
            <Button onClick={onCancel} text="Cancel" size="small" color="secondary" />
        </div>
    )
}