import { useContext } from "react";
import { AuthContext } from "../../contexts/components/AuthContext";
import styles from "./NavBar.module.css";
import { LogoLink } from "./components/LogoLink";
import ShowUserAccount from "./components/ShowUserAccount";
import ShowUserSettings from "./components/ShowUserSettings";
import { ShowVnoAdminMenu } from "./components/ShowVnoAdminMenu";

const NavBar = () => {

    const {user} = useContext(AuthContext);

    return (
        <nav className={styles.nav}>
            <LogoLink />
            <div className={styles.menusContainer}>
                <ShowUserSettings />
                <ShowUserAccount />
                {user && user.canPerformVnoAdmin && 
                    <ShowVnoAdminMenu />
                }
            </div>
        </nav>
    );
}

export default NavBar;