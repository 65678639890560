﻿import styles from "./ChartTitle.module.css";

interface ChartTitleProps {
    title: string;
    uom?: string;
}
export const ChartTitle = ({ title, uom }: ChartTitleProps) => {

    return (
        <div>
            <div className={styles.title}>
                <span>{title}</span><span className={styles.uom}>{uom}</span>
            </div>
        </div>
    );
};
