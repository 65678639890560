import styles from "./Main.module.css";

interface IMainProps {
    children: React.ReactNode;
}

export const Main : React.FC<IMainProps> = ({
    children
}) => {
    return (
        <main className={styles.root}>
            {children}
        </main>
    )
}