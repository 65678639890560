import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import { MapViewStateContext } from "../../../../../../contexts/components/MapViewStateContext";
import styles from "./MapZoom.module.css";

type ButtonProps = {
    title: string,
    onClick: () => void,
    children?: React.ReactNode
};

const Button: React.FC<ButtonProps> = ({title, onClick, children}) => {
    
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClick();
   }

   return (
        <button 
            title={title}
            className={styles.button}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};

const ZOOM_INCREMENT = 0.5;

const MapZoom: React.FC = () => {

    const { viewState, setViewState, minZoom, maxZoom } = useContext(MapViewStateContext);

    function handleZoomInClick(){
        setViewState(
            {
                ...viewState, 
                zoom: (viewState.zoom + ZOOM_INCREMENT) >= maxZoom ? maxZoom : (viewState.zoom + ZOOM_INCREMENT)
            });    
    }

    function handleZoomOutClick(){
        setViewState({
                ...viewState, 
                zoom: (viewState.zoom - ZOOM_INCREMENT) <= minZoom ? minZoom : (viewState.zoom - ZOOM_INCREMENT)
            });

    }

    return (
        <div className={styles.root}>
            <Button onClick={handleZoomInClick} title="Zoom In">
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </Button>
            <Button onClick={handleZoomOutClick} title="Zoom Out">
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
            </Button>
        </div>
    );
}

export default MapZoom;