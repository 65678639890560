import React from "react";
import styles from "./ContentContainer.module.css";

interface ContentContainerProps {
    children?: React.ReactNode
}
const ContentContainer : React.FC<ContentContainerProps> = ({children}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}

export { ContentContainer };