import { useEscapeKey } from "../../../../hooks";
import { CloseButton } from "../../../buttons";
import { ChangePassword } from "../changePassword/ChangePassword";
import NotificationEmails from "../notificationEmails/NotificationEmails";
import Content from "./Content";
import Header from "./Header";
import styles from "./UserSettings.module.css";

const UserSettings = (props: {onClose: () => void}) => {


    useEscapeKey(props.onClose);

    return (
        <div className={styles.root}>
            <Content>
                <Header />
                <div className={styles.innerContent}>
                    <ChangePassword />
                    <NotificationEmails />
                </div>
            </Content>
            <div className={styles.buttonContainer}>
                <CloseButton onClick={props.onClose} />
            </div>
        </div>
    );
}

export default UserSettings;
