import { ActionTypes } from "../data/State";
import { IHoldingGroupModel } from "./Models";
import { useCallback } from "react";
import { HoldingGroup } from "./components/HoldingGroup";
import { Tree } from "../../common/tree/Tree";
import { IApiData } from "../data/Models";

interface IHoldingGroupProps {
    data: IApiData;
    dispatch: React.Dispatch<ActionTypes>;
}

const getHoldingGroups = (data: IApiData) : IHoldingGroupModel[] => {

    let items : IHoldingGroupModel[] = [];

    data.clientGroups.forEach(clientGroup => {
        let hg: IHoldingGroupModel = {
            clientGroupId: clientGroup.id,
            clientGroupName: clientGroup.name,
            users: []
        }
        clientGroup.unassignedUsers.forEach(user => {
            hg.users.push(
                {
                    userId: user.id,
                    name: user.name,
                    canBeMoved: user.canBeMoved,
                }
            );
        });
        items.push(hg);
    });

    return items;
}

export const HoldingGroups : React.FC<IHoldingGroupProps> = ({
    data,
    dispatch,
}) =>
{
    const holdingGroups = useCallback(() => {return getHoldingGroups(data)}, [data]);

    return (
        <Tree>
            {holdingGroups().map(holdingGroup => 
                <HoldingGroup
                    key={holdingGroup.clientGroupId}
                    holdingGroup={holdingGroup}
                    dispatch={dispatch}
                />
            )}
        </Tree>
    )    
}