import { createRef, Fragment, useContext, useEffect, useState } from "react";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import { ContentHeader } from "../contentContainer/ContentHeader";
import { NothingSelectedMessage } from "../contentContainer/NothingSelectedMsg";
import { MapDateRangeContext } from "../../../../../../contexts/components/MapDateRangeContext";
import {
    useFetchStarlinkPerformance,
    useFetchStarlinkDataVolumes,
    useFetchStarlinkStatus,
    useFetchStarlinkObstructionImage,
} from "../../../../../../dataServices/starlink";
import {
    StarlinkObstructionImageModel,
    StarlinkPerformanceModel,
    StarlinkStatusModel,
    StarlinkDataVolumesModel,
} from "../../../../../../dataServices/starlink/Models";

import styles from "./Starlink.module.css";
import { froundToIntlString } from "../../../../../../utils/rounding";
import { Dates } from "../../../../../../utils/dates";
import { ChartMargins } from "./ChartMargins";
import { DataVolumesChart } from "./DataVolumesChart";
import { PerformanceChart } from "./PerformanceChart";

export const Starlink = () => {

    const { ship } = useContext(SelectedShipContext);

    // the enclosing fragment is important to leave as a fragment
    // because it allows the content to scroll
    return (
        <>
            <ContentHeader title="Starlink" />
            {
                ship
                    ? <Content />
                    : <NothingSelectedMessage />
            }
        </>
    )
}

const Content = () => {

    const { ship } = useContext(SelectedShipContext);
    const { fromMillis, toMillis } = useContext(MapDateRangeContext);

    const [performanceState, setPerformanceState] = useState<IDataState<null | StarlinkPerformanceModel>>(stateIsIdle);
    const [dataVolumesState, setDataVolumesState] = useState<IDataState<null | StarlinkDataVolumesModel>>(stateIsIdle);
    const [statusState, setStatusState] = useState<IDataState<null | StarlinkStatusModel>>(stateIsIdle);
    const [obstructionImageState, setObstructionImageState] = useState<IDataState<null | StarlinkObstructionImageModel>>(stateIsIdle);

    const fetchPerformanceState = useFetchStarlinkPerformance(ship?.installationId, fromMillis, toMillis);
    const fetchDataVolumesState = useFetchStarlinkDataVolumes(ship?.installationId, fromMillis, toMillis);
    const fetchStatusState = useFetchStarlinkStatus(ship?.installationId);
    const fetchObstructionImageState = useFetchStarlinkObstructionImage(ship?.installationId);

    // respond to changes in performance state
    useEffect(() => {
        switch (fetchPerformanceState.state.status) {
            case "in_progress":
                setPerformanceState(stateIsLoading);
                break;
            case "completed":
                setPerformanceState({
                    isLoading: false,
                    isError: false,
                    data: fetchPerformanceState.state.data
                        ? fetchPerformanceState.state.data
                        : null
                });
                console.log("performance data", fetchPerformanceState.state.data);
                break;
            case "error":
                setPerformanceState(stateIsError);
                break;
            default:
                setPerformanceState(stateIsIdle);
        }
    }, [fetchPerformanceState.state.status, fetchPerformanceState.state.data]);

    // respond to changes in data volumes
    useEffect(() => {
        switch (fetchDataVolumesState.state.status) {
            case "in_progress":
                setDataVolumesState(stateIsLoading);
                break;
            case "completed":
                setDataVolumesState({
                    isLoading: false,
                    isError: false,
                    data: fetchDataVolumesState.state.data
                        ? fetchDataVolumesState.state.data
                        : null
                });
                console.log("data volumes data", fetchDataVolumesState.state.data);
                break;
            case "error":
                setDataVolumesState(stateIsError);
                break;
            default:
                setDataVolumesState(stateIsIdle);
        }
    }, [fetchDataVolumesState.state.status, fetchDataVolumesState.state.data]);

    // respond to changes in status
    useEffect(() => {
        switch (fetchStatusState.state.status) {
            case "in_progress":
                setStatusState(stateIsIdle);
                break;
            case "completed":
                setStatusState({
                    isLoading: false,
                    isError: false,
                    data: fetchStatusState.state.data
                        ? fetchStatusState.state.data
                        : null
                });
                console.log("status data", fetchStatusState.state.data);
                break;
            case "error":
                setStatusState(stateIsError);
                break;
            default:
                setStatusState(stateIsIdle);
        }
    }, [fetchStatusState.state.status, fetchStatusState.state.data]);

    // respond to changes in obstruction image
    useEffect(() => {
        switch (fetchObstructionImageState.state.status) {
            case "in_progress":
                setObstructionImageState(stateIsIdle);
                break;
            case "completed":
                setObstructionImageState({
                    isLoading: false,
                    isError: false,
                    data: fetchObstructionImageState.state.data
                        ? fetchObstructionImageState.state.data
                        : null
                });
                console.log("obstruction image data", fetchObstructionImageState.state.data);
                break;
            case "error":
                setObstructionImageState(stateIsError);
                break;
            default:
                setObstructionImageState(stateIsIdle);
        }
    }, [fetchObstructionImageState.state.status, fetchObstructionImageState.state.data]);

    return (
        <div className={styles.root}>
            <DataVolumes state={dataVolumesState} />
            <Performance state={performanceState} />
            <ObstructionImage state={obstructionImageState} />
            <Status state={statusState }/>
            <DebugInfo 
                dataVolumesState={dataVolumesState}
                obstructionState={obstructionImageState}
                performanceState={performanceState}
                statusState={statusState }
            />
        </div>
    )
}

const chartMargins: ChartMargins = {
    marginLeft: 50,
    marginRight: 80,
    xAxisHeight: 20,
}

interface PerformanceProps {
    state: IDataState<StarlinkPerformanceModel | null>;
}

const Performance = ({state } : PerformanceProps) => {

    const divRef: React.RefObject<HTMLDivElement> = createRef();
    const [chartWidth, setChartWidth] = useState<number>(0);
    const chartHeight = 150;

    // watch for window resize events and set the chart width
    // based on the new width of the div that contains the charts
    // NOTE: it's possible we could use the useWindowSize hook for this
    // but this direct approach seems to provide better screen response
    // with smoother updates
    useEffect(() => {
        function handleResize() {
            if (divRef.current) {
                setChartWidth(divRef.current.clientWidth - 2);
            }
        }

        // watch for window resize events
        window.addEventListener("resize", handleResize);

        // get initial size
        handleResize();

        // remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [divRef]);

    return (
        // may have to have a separate styles.root for this because this is using one for the main container
        // although it's possible the overflow is unnecessary for both!!
        <div ref={divRef} className={styles.root}>
            <PerformanceChart
                overallHeight={chartHeight}
                overallWidth={chartWidth}
                chartMargins={chartMargins}
                chartState={
                    {
                        isLoading: state.isLoading,
                        isError: state.isError,
                        data: {
                            data: ""
                        }
                    }
                }
            />
        </div>
    )
}

interface DataVolumesProps {
    state: IDataState<StarlinkDataVolumesModel | null>;
}

const DataVolumes = ({ state }: DataVolumesProps) => {

    const divRef: React.RefObject<HTMLDivElement> = createRef();
    const [chartWidth, setChartWidth] = useState<number>(0);
    const chartHeight = 150;

    // watch for window resize events and set the chart width
    // based on the new width of the div that contains the charts
    // NOTE: it's possible we could use the useWindowSize hook for this
    // but this direct approach seems to provide better screen response
    // with smoother updates
    useEffect(() => {
        function handleResize() {
            if (divRef.current) {
                setChartWidth(divRef.current.clientWidth - 2);
            }
        }

        // watch for window resize events
        window.addEventListener("resize", handleResize);

        // get initial size
        handleResize();

        // remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [divRef]);

    return (
        // may have to have a separate styles.root for this because this is using one for the main container
        // although it's possible the overflow is unnecessary for both!!
        <div ref={divRef} className={styles.root}>
            <DataVolumesChart
                overallHeight={chartHeight}
                overallWidth={chartWidth}
                chartMargins={chartMargins}
                chartState={
                    {
                        isLoading: state.isLoading,
                        isError: state.isError,
                        data: state.data ? state.data.timeStampsMillis.map((d, i) => ({
                            timeStampMillis: d,
                            value: state.data ? state.data.dataVolumes[i] / 1000000000 : 0
                        })) : []
                    }
                }
            />
        </div>
    )

}

interface StatusProps {
    state: IDataState<StarlinkStatusModel | null>;
}

const Status = ({ state }: StatusProps) => {

    if (state.isLoading) return <div>Loading...</div>
    if (state.isError) return <div>Ooops.  Something went wrong</div>

    if (state.data) {

        const uptime = dhm(state.data.status.uptime ?? 0);
        const uptimeDays = uptime.split(':')[0];
        const uptimeHours = uptime.split(':')[1];
        const uptimeMinutes = uptime.split(':')[2];

        return (
            <Fragment>
                <h4>Obstruction Info</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Currently Obstructed:</td>
                            <td>{state.data.status.currentlyObstructed ? 'True' : 'False'}</td>
                        </tr>
                        <tr>
                            <td>Seconds Obstructed:</td>
                            <td>???</td>
                        </tr>
                        <tr>
                            <td>Obstruction Duration:</td>
                            <td>{froundToIntlString(state.data.status.obstructionDuration ?? 0, 2)}secs</td>
                        </tr>
                        <tr>
                            <td>Obstruction Interval:</td>
                            <td>{state.data.status.obstructionInterval}secs</td>
                        </tr>
                        <tr>
                            <td>Fraction Obstructed:</td>
                            <td>{froundToIntlString(state.data.status.fractionObstructed ?? 0, 2)}%</td>
                        </tr>
                        <tr>
                            <td>Azimuth:</td>
                            <td>{froundToIntlString(state.data.status.azimuth ?? 0, 4)}</td>
                        </tr>
                        <tr>
                            <td>Elevation:</td>
                            <td>{froundToIntlString(state.data.status.elevation ?? 0, 4)}</td>
                        </tr>
                        <tr>
                            <td>SNR:</td>
                            <td>???</td>
                        </tr>
                        <tr>
                            <td>SNR Above Noise:</td>
                            <td>{state.data.status.snrAboveNoise ? 'True' : 'False'}</td>
                        </tr>
                    </tbody>
                </table>
                <h4>Alerts</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Motors Stuck</td>
                            <td>{state.data.alerts.alertMotorsStuck ? 'cross' : 'tick'}</td>
                        </tr>
                        <tr>
                            <td>Thermal Throttle</td>
                            <td>{state.data.alerts.alertThermalThrottle ? 'cross' : 'tick'}</td>
                        </tr>
                        <tr><td>Thermal Shutdown</td><td>{state.data.alerts.alertThermalShutdown ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Mast Not Near Vertical</td><td>{state.data.alerts.alertMastNotNearVertical ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Unexpected Location</td><td>{state.data.alerts.alertUnexpectedLocation ? 'cross' : 'tick' }</td></tr>
                        <tr><td>Slow Ethernet Speeds</td><td>{state.data.alerts.alertSlowEthernetSpeeds ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Roaming</td><td>{state.data.alerts.alertRoaming ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Install Pending</td><td>{state.data.alerts.alertInstallPending ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Is Heating</td><td>{state.data.alerts.alertIsHeating ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Power Supply Thermal Throttle</td><td>{state.data.alerts.alertPowerSupplyThermalThrottle ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Is Power Save Idle</td><td>{state.data.alerts.alertIsPowerSaveIdle ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Moving While Not Mobile</td><td>{state.data.alerts.alertMovingWhileNotMobile ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Moving Too Fast Fo Policy</td><td>{state.data.alerts.alertMovingTooFastForPolicy ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Dbf Telem Stale</td><td>{state.data.alerts.alertDbfTelemStale ? 'cross' : 'tick'}</td></tr>
                        <tr><td>Low Motor Current</td><td>{state.data.alerts.alertLowMotorCurrent ? 'cross' : 'tick'}</td></tr>
                    </tbody>                    
                </table>
                <h4>Latest</h4>
                <table>
                    <tbody>
                        <tr><td>Date:</td><td>{Dates.fromMillis(state.data.timeMillis ?? 0).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}</td></tr>
                        <tr><td>POP Ping Drop Rate:</td><td>{froundToIntlString(state.data.status.popPingDropRate ?? 0, 2)}%</td></tr>
                        <tr><td>POP Ping Latency:</td><td>{froundToIntlString(state.data.status.popPingLatency ?? 0, 0)}ms</td></tr>
                        <tr><td>Downlink:</td><td>{froundToIntlString((state.data.status.downlink ?? 0) / 1000000, 2)}Mbps</td></tr>
                        <tr><td>Uplink:</td><td>{froundToIntlString((state.data.status.uplink ?? 0) / 1000000, 2)}Mbps</td></tr>
                    </tbody>
                </table>
                <h4>Current Info</h4>
                <table>
                    <tbody>
                        <tr><td>Hardware Version:</td><td>{state.data.status.hardwareVersion}</td></tr>
                        <tr><td>Software Version:</td><td>{state.data.status.softwareVersion}</td></tr>
                        <tr><td>State:</td><td>{state.data.status.state}</td></tr>
                        <tr><td>Alerts:</td><td>{state.data.status.alerts}</td></tr>
                    </tbody>
                </table>
                <h4>Uptime</h4>
                <div>
                    <div>
                        <div>
                            {uptimeDays}
                        </div>
                        <div>
                        DAYS
                        </div>
                    </div>
                    <div>
                        <div>
                            {uptimeHours }
                        </div>
                        <div>
                            HOURS
                        </div>
                    </div>
                    <div>
                        <div>
                            {uptimeMinutes }
                        </div>
                        <div>
                            MINUTES
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return null;
}

function dhm(t: number, multiplier: number = 1): string {
    const cd: number = 24 * 60 * 60 * multiplier;
    const ch: number = 60 * 60 * multiplier;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / (60 * multiplier));
    const pad = function (n: number) { return n < 10 ? '0' + n : n; };

    if (m === 60) {
        h++;
        m = 0;
    }
    if (h === 24) {
        d++;
        h = 0;
    }
    return [d, pad(h), pad(m)].join(':');
}

interface ObstructionImageProps {
    state: IDataState<StarlinkObstructionImageModel | null>;
}

const ObstructionImage = ({ state }: ObstructionImageProps) => {

    if (state.isLoading) return <div>Loading...</div>
    if (state.isError) return <div>Ooops, something went wrong</div>
    if (state.data) {
        return (
            <div>
                <h4>Obstruction</h4>
                <img src={`data:image/png;base64, ${state.data?.imageData}`} style={{width: '200px'}} />
            </div>
        )
    }

    return null;
}

interface DebugProps {
    performanceState: IDataState<StarlinkPerformanceModel | null>;
    dataVolumesState: IDataState<StarlinkDataVolumesModel | null>;
    statusState: IDataState<StarlinkStatusModel | null>;
    obstructionState: IDataState<StarlinkObstructionImageModel | null>;
}

const DebugInfo = ({ performanceState, dataVolumesState, statusState, obstructionState }: DebugProps) => {

    return (
        <div>
            <h3>Debug</h3>
            <h4>Performance</h4>
            <pre>{performanceState?.data ? JSON.stringify(performanceState.data, null, 2) : ''}</pre>
            <h4>Data Volumes</h4>
            <pre>{dataVolumesState?.data ? JSON.stringify(dataVolumesState.data, null, 2) : ''}</pre>
            <h4>Status</h4>
            <pre>{statusState?.data ? JSON.stringify(statusState.data, null, 2) : ''}</pre>
            <h4>Obstruction</h4>
            <div>
                imageData Length: {obstructionState.data?.imageData.length }
            </div>
        </div>
    )
}

interface IDataState<T> {
    isLoading: boolean;
    isError: boolean;
    data: T;
}

const stateIsLoading: IDataState<null> = {
    isLoading: true,
    isError: false,
    data: null,
}

const stateIsIdle: IDataState<null> = {
    isLoading: false,
    isError: false,
    data: null,
}

const stateIsError: IDataState<null> = {
    isLoading: false,
    isError: true,
    data: null,
}