import styles from "./Button.module.css";

export const Button = ({
    text,
    hidden,
    disabled,
    onClick,
} : {
    text: string,
    hidden?: boolean,
    disabled?: boolean,
    onClick: () => void,
}) => {

    if (hidden) return null;

    return (
        <button className={styles.button} onClick={onClick} disabled={disabled}>{text}</button>
    )
}