/**
* Hook to retrieve the Satellite Beam Contours that can be viewed by the currently logged in user.
*/
import React, { useContext } from "react";
import { BeamContourModel } from "./Models";
import { api, useGetData } from "../components";
import { AuthContext } from "../../contexts/components/AuthContext";

/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel {
    id: number;
    coordinates: Array<number[]>;
    colour: string;
    opacity: number;
    outlineWidth: number;
    fillColour: string;
    fillOpacity: number;
    contourType: string;
}

/**
 * Function maps data returned from the remote API to a UI model
 */
function mapper(apiData: IApiModel[]) : BeamContourModel[]
{
    return apiData.map((value) => {
        return {...value}
    })
}

/**
 * Hook which returns the collection of Satellite Beam Contours that can be viewed
 * by the currently logged in user.
 */
export function useFetchBeamContours() {

    const [url, setUrl] = React.useState<string>('');
    const {state} = useGetData(url, mapper);

    const auth = useContext(AuthContext);

    React.useEffect(() => {

        if (!auth.user) return;

        setUrl(api.urls.satellites.get.beamContours());

    }, [auth.user]);

    return {state};
}

