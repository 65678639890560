import React from "react";
import { ViewState } from "react-map-gl";

export type MapViewStateContextType = {
    viewState: ViewState,
    setViewState: (viewState: ViewState) => void,
    minZoom: number,
    maxZoom: number,
}

export const MapViewStateContext = React.createContext<MapViewStateContextType>(null!);