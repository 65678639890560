import styles from "./Buttons.module.css";

export const Buttons = ({
    children,
}: {
    children: React.ReactNode,
}) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}