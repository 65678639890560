import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./NavBarButton.module.css"

interface INavBarButtonProps {
    onClick: () => void;
    icon: IconProp;
    title: string;
}

export const NavBarButton : React.FC<INavBarButtonProps> = ({
    onClick,
    icon,
    title,
}) => {
    return (
        <button className={styles.button} onClick={onClick} title={title}>
            <FontAwesomeIcon 
                icon={icon}
                className={styles.icon}
                size="2x" 
                title={title}
            />   
        </button>
    )
}