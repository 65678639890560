import { Dates } from "../../../../utils/dates";
import { useLayoutEffect, useState } from "react";
import { Month } from "./Month";
import { SelectedDateDisplay } from "./SelectedDateDisplay";

import styles from "./DatePicker.module.css";
import { useEscapeKey } from "../../../../hooks";

type LocationType = {
    top: number,
    left: number,
}

/**
 * Component displays a dialog which can be used to select a date.
 * @param param0 
 * @returns 
 */
export const DatePicker = ({
    minimumDateMillis,
    maximumDateMillis,
    suppliedDateMillis,
    anchorRef,
    onSelected,
    onCancel,
}: {
    // minimum date that the user can select
    minimumDateMillis: number | null;
    // maximum date that the user can select
    maximumDateMillis: number | null;
    // an initial date value that represents the currently selected date
    suppliedDateMillis: number;
    // reference to an <div> to which the dialog's location will be anchored
    anchorRef: React.MutableRefObject<null | HTMLDivElement>,
    // raised when a date is selected
    onSelected: (dateMillis: number) => void,
    onCancel: () => void,
}) => {

    // position at which the date picker will be displayed
    // const [componentPosition, setComponentPosition] = useState<LocationType>({top: 0, left: 0});

    // Effect that will track window resizing and allow us to 
    // position the date picker relative to the anchor element that
    // has been passed to us
    useLayoutEffect(() => {

        function updatePosition(){
            if (anchorRef.current){
                // determine the location where we will display the date picker
                const DIALOG_HEIGHT = 360;
                const pos: LocationType = {top: 0, left: 0};

                const windowVP = window.visualViewport;
                const anchorCr = anchorRef.current.getBoundingClientRect();
                
                if (windowVP && ((windowVP.height - anchorCr.bottom) < DIALOG_HEIGHT)) 
                { 
                    pos.top = anchorCr.top - DIALOG_HEIGHT - 5
                } else {
                    pos.top = anchorCr.bottom + 5;
                }

                pos.left = anchorCr.left;
            }
        }
        
        window.addEventListener('resize', updatePosition);
        
        updatePosition();
        
        return () => window.removeEventListener('resize', updatePosition);

    },[anchorRef])

    useEscapeKey(onCancel);

    const todayMillis = Dates.today().millis();

    // Date used to determine what month is initially shown, based on any supplied date.
    // Defaults to the month of the current date
    const [startOfMonthMillis, setStartOfMonthMillis] = useState<number>(suppliedDateMillis ? Dates.fromMillis(suppliedDateMillis).startOfMonth().millis() : Dates.today().startOfMonth().millis());
    
    const [potentialDateMillis, setPotentialDateMillis] = useState<number | null>();

    function handleDecreaseMonth(){
        setStartOfMonthMillis(prev => Dates.fromMillis(prev).subtractMonths(1).millis());
    }

    function handleIncreaseMonth(){
        setStartOfMonthMillis(prev => Dates.fromMillis(prev).addMonths(1).millis());
    }
    
    function handleDayClick(dateMillis: number){
        onSelected(dateMillis);
    }

    function handleDayMouseEnter(dateMillis: number){
        setPotentialDateMillis(dateMillis);
    }

    function handleDayMouseLeave(){
        setPotentialDateMillis(undefined);
    }

    // don't allow the user to move to a month that is earler than any supplied minimum date
    const showMonthDecrease = minimumDateMillis ? startOfMonthMillis > minimumDateMillis : true;

    // don't allow a user to move to a month that is later than any supplied maximum date
    const showMonthIncrease = maximumDateMillis ? startOfMonthMillis < Dates.fromMillis(maximumDateMillis).startOfMonth().millis() : true;

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.monthContainer}>
                    <Month
                        firstDateOfMonthMillis={startOfMonthMillis}
                        todayMillis={todayMillis}
                        minimumDateMillis={minimumDateMillis}
                        maximumDateMillis={maximumDateMillis}
                        selectedDateMillis={suppliedDateMillis}
                        showMonthDecrease={showMonthDecrease}
                        onDecrease={handleDecreaseMonth}
                        showMonthIncrease={showMonthIncrease}
                        onIncrease={handleIncreaseMonth}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseEnter}
                        onDayMouseLeave={handleDayMouseLeave}
                    />
                </div>
                <SelectedDateDisplay
                    dateMillis={potentialDateMillis ? potentialDateMillis : suppliedDateMillis}
                />
                {false &&
                    <DebugInfo
                        potentialDateMillis={potentialDateMillis }
                        startOfMonthMillis={startOfMonthMillis}
                        todayMillis={todayMillis}
                        minimumDateMillis={minimumDateMillis}
                        maximumDateMillis={maximumDateMillis}
                        suppliedDateMillis={suppliedDateMillis}
                        showMonthDecrease={showMonthDecrease}
                        showMonthIncrease={showMonthIncrease}
                    />
                }
            </div>
        </div>
    );
}

/**
 * Shows debug information
 */
const DebugInfo = ({
    startOfMonthMillis,
    todayMillis,
    minimumDateMillis,
    maximumDateMillis,
    suppliedDateMillis,
    showMonthDecrease,
    showMonthIncrease,
    potentialDateMillis,
}: {
    startOfMonthMillis: number,
    todayMillis: number,
    minimumDateMillis: number | null,
    maximumDateMillis: number | null,
    suppliedDateMillis: number,
    showMonthDecrease: boolean,
    showMonthIncrease: boolean,
    potentialDateMillis: number | null | undefined,
}) => {

    if (!import.meta.env.DEV) return null;

    function formatDate(dateMillis: number | null | undefined) {
        if (dateMillis === null) return "null";
        if (typeof dateMillis === "undefined") return "null";
        return Dates.fromMillis(dateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS);
    }

    return (
        <div>
            <div style={{ fontFamily: "Courier New" }}>
                <div>Selected Date --: {formatDate(potentialDateMillis)}</div>
                <div>Start of Month -: {formatDate(startOfMonthMillis)}</div>
                <div>Today ----------: {formatDate(todayMillis)}</div>
                <div>Minimum Date ---: {formatDate(minimumDateMillis)}</div>
                <div>Maximum Date ---: {formatDate(maximumDateMillis)}</div>
                <div>Supplied Date --: {formatDate(suppliedDateMillis)}</div>
                <div>Show Month Decrease : {showMonthDecrease ? 'yes' : 'no'}</div>
                <div>Show Month Increase : {showMonthIncrease ? 'yes' : 'no'}</div>
            </div>
        </div>
    )
}