import { useRef } from "react";
import { Header } from "./Header";
import { Spinner } from "../spinners/Spinner";

import styles from "./Dialog.module.css";

export const Dialog = ({
    title,
    children,
    disabled,
    inactive = false,
}: {
    title: string,
    children: React.ReactNode,
    disabled?: boolean,
    inactive?: boolean,
}) => {

    const dialogRef = useRef<HTMLDivElement>(null);
    
    const handleDrag = (movementX: number, movementY: number) => {
        const dialog = dialogRef.current;

        if (dialog === null) return;

        const {x, y} = dialog.getBoundingClientRect();

        dialog.style.left = `${x + movementX}px`;
        dialog.style.top = `${y + movementY}px`;
    }

    return (
        <div className={styles.root} ref={dialogRef}>
            <div className={styles.container}>
                
                <Header title={title} inactive={inactive} onDrag={handleDrag} />

                <div className={styles.content}>
                    {
                        disabled &&  <div className={styles.disabledOverlay}><Spinner /></div>
                    }
                    {children}
                </div>
            </div>
        </div>
    )
}