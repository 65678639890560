import { useDrag } from "react-dnd";
import { IInstallationDnd } from "../../dnd/Installation";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IClient, IInstallation } from "../../data/Models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { ActionTypes } from "../../data/State";
import { moveInstallation } from "../../data/UpdateActions";
import { notifyError } from "../../../common/errors/Errors";
import classnames from "classnames";
import styles from "./Installation.module.css";

interface IInstallationProps {
    clientGroupId: number;
    clientId: number;
    installationGroupId: number;
    installation: IInstallation;
    holdingClient: IClient | undefined;
    dispatch: React.Dispatch<ActionTypes>;
}

export const Installation : React.FC<IInstallationProps> = ({
    clientGroupId,
    clientId,
    installationGroupId,
    installation,
    holdingClient,
    dispatch,
}) => {

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.INSTALLATION,
            item: {
                clientGroupId: clientGroupId,
                clientId: clientId,
                installationId: installation.id,
                installationGroupId: installationGroupId,
            } as IInstallationDnd,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    )

    const handleUnassign = () => {
        if (holdingClient === undefined || holdingClient.installationGroups.length !== 1){
            console.error("no holding client provided");
            notifyError();
            return;
        }

        const moveOptions = 
        {
            source: {
                clientGroupId: clientGroupId,
                clientId: clientId,
                installationId: installation.id,
                installationGroupId: installationGroupId
                },
            target: {
                clientGroupId: clientGroupId,
                clientId: holdingClient?.id,
                // bit of a fudge!  assumes the first installation group
                // holds all the unassigned vessels
                installationGroupId: holdingClient.installationGroups[0].id 
                },
            dispatch
        };

        void moveInstallation(moveOptions);
    }

    return (
        <li>
            <div className={styles.root} title="Drag and drop to move Fleets" >
                <div className={styles.inner} ref={drag}>
                    <div className={`${classnames(styles.text, isDragging && styles.isDragging)}`}>
                        {installation.name}
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.buttonContainer}>
                            <button 
                                className={styles.button}
                                type="button"
                                title="Unassign Vessel"
                                onClick={handleUnassign}
                                disabled={holdingClient === undefined}
                            >
                                <FontAwesomeIcon
                                    icon={faDeleteLeft}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}