export const api = {
    urls: {
        auth: {
            get : {
                authenticate() : string {
                    return "/api/auth/authenticate";
                },    
            },
            post : {
                forgotPassword() : string {
                    return "/api/auth/forgotPassword";
                },
                register() : string {
                    return "/api/auth/register";
                },
                resetPassword() : string {
                    return "/api/auth/resetpassword";
                },     
                signIn() : string {
                    return "/api/auth/signIn";
                },
                signOut() : string {
                    return "/api/auth/signOut";
                },
            },
            put : {
                changePassword() : string {
                    return "/api/auth/changepassword";
                },    
                confirmEmail() : string {
                    return "/api/auth/confirmemail";
                },
            },
        },
        billing: {
            get : {
                invoiceDownload(installationId: number, invoiceId: number) : string {
                    return `/api/billing/invoicedownload?installationId=${installationId}&invoiceId=${invoiceId}`;
                },
                invoiceHistory(installationId: number, fromMillis: number, toMillis: number) : string {
                    return `/api/billing/invoicehistory?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}`;
                },    
            }
        },
        installations: {
            get : {
                installations() : string {
                    return `/api/installations/installations`;
                },
                latestStatus(installationId: number) : string {
                    return `/api/installations/latestStatus?installationId=${installationId}`;
                }
            }
        },
        metrics : {
            get : {
                beamUsage(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/beamusage?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                dataVolumes(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/datavolume?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                downloadSpeeds(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/downloadspeed?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                installationLocations(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/installationLocation?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                rfPerformance(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/rfperformance?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                uploadSpeeds(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/uploadspeed?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                },
                wanInterfaceStatus(installationId: number, fromMillis: number, toMillis: number, intervalMillis: number) : string {
                    return `/api/metrics/waninterfacestatus?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}&intervalMillis=${intervalMillis}`;
                }
            }
        },
        packages: {
            get : {
                editScheduledChanges(wanInterfaceId: number) : string {
                    return `/api/packages/editscheduledchanges?wanInterfaceId=${wanInterfaceId}`;
                },
                immediateUpgradeMetaData(wanInterfaceId: number) : string {
                    return `/api/packages/immediateUpgrade?wanInterfaceId=${wanInterfaceId}`;
                },
                notificationEmails() : string {
                    return `/api/packages/notificationEmails`;
                },
                packageItems(installationId: number) : string {
                    return `/api/packages/packageItems?installationId=${installationId}`;
                },
                scheduledChangesStatus(wanInterfaceId: number) : string {
                    return `/api/packages/scheduledChangesStatus?&wanInterfaceId=${wanInterfaceId}`;
                },
                activeScheduledChanges(wanInterfaceId: number) : string {
                    return `/api/packages/activeScheduledChanges?wanInterfaceId=${wanInterfaceId}`;
                },
                historicScheduledChanges(wanInterfaceId: number) : string {
                    return `/api/packages/historicScheduledChanges?wanInterfaceId=${wanInterfaceId}`;
                }
            },
            post : {
                editScheduledChanges() : string {
                    return `/api/packages/editScheduledChanges`;
                },
                immediateUpgrade() : string {
                    return `/api/packages/immediateUpgrade`;
                },
                notificationEmails() : string {
                    return `/api/packages/notificationEmails`;
                }
            },
            delete : {
                notificationEmails() : string {
                    return `/api/packages/notificationEmails`;
                }
            }
        },    
        satellites: {
            get : {
                beamContours() : string {
                    return `/api/satellites/beamContours`;
                }    
            }
        },
        starlink: {
            get: {
                performance(installationId: number, fromMillis: number, toMillis: number): string {
                    return `/api/starlink/performance?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}`;
                },
                dataVolumes(installationId: number, fromMillis: number, toMillis: number): string {
                    return `/api/starlink/datavolume?installationId=${installationId}&fromMillis=${fromMillis}&toMillis=${toMillis}`;
                },
                status(installationId: number): string {
                    return `/api/starlink/status?installationId=${installationId}`;
                },
                obstructionImage(installationId: number): string {
                    return `/api/starlink/obstructionimage?installationId=${installationId}`;
                },
            }
        }
    },
}