import React from "react";
import { PackageItemModel } from "../../../../../../../../../dataServices/dataPackages/packageItems";
import { BasePackageDisplay } from "./BasePackageDisplay";
import { ImmediateUpgrade } from "../../immediateUpgrade";
import { EditChanges } from "../../editChanges";
import { ScheduledChanges } from "../../scheduledChanges/ScheduledChanges";

export const PlanDetails = ({
    packageItem,
    hidePricing,
}: {
    packageItem: PackageItemModel,
    hidePricing: boolean,
}) => {

    const [showEditChanges, setShowEditChanges] = React.useState<boolean>(false);
    const [showImmediateUpgrade, setShowImmediateUpgrade] = React.useState<boolean>(false);
    const [lastRefreshTime, setLastRefreshTime] = React.useState<number>((new Date()).getTime());

    // handle request to show the Edit Scheduled Changes dialog
    function handleShowEditChanges() {
        setShowEditChanges(true);
    }

    // handle the Edit Changes dialog being closed
    function handleEditChangesClose(updated: boolean){

        // hide the dialog
        setShowEditChanges(false);

        // if the dialog action resulted in data being
        // updated, we want to refresh teh list of scheduled changes
        if (updated){
            setLastRefreshTime((new Date()).getTime());
        }
    }

    // handle request to show the Immediate Upgrade dialog
    function handleShowImmediateUpgrade() {
        setShowImmediateUpgrade(true);
    }

    // handle the Immediate Upgrade Dialog being closed
    function handleImmediateUpgradeClose(updated: boolean) {
    
        // hide the dialog
        setShowImmediateUpgrade(false);
    
        // if the dialog action resulted in data being
        // updated, we want to refresh the list of scheduled changes
        if (updated){
            setLastRefreshTime((new Date()).getTime());
        }        
    }

    // can't show anything useful if we don't have a rate plan name
    if (typeof packageItem.ratePlanName === "undefined" || packageItem.ratePlanName === null) {
        return null;
    }

    return (
        <div>            
            <BasePackageDisplay
                packageName={packageItem.ratePlanName}
            />

            {/* 
                Display the list of on-going, up-coming and historic changes
                together with a mechanism for initiating edits
            */}
            <ScheduledChanges
                wanInterfaceId={packageItem.wanInterfaceId}
                lastRefresh={lastRefreshTime}
                hidePricing={hidePricing}
                onEditChanges={handleShowEditChanges}
                onImmediateUpgrade={handleShowImmediateUpgrade}
            />

            {/*
                Display a dialog the allows the user to add/edit/delete on-going
                and future dated scheduled changes.  This is a modal dialog that
                overlays the lists of existing and historic changes
            */}
            {showEditChanges && packageItem.wanInterfaceId && 

                <EditChanges
                    wanInterfaceId={packageItem.wanInterfaceId}
                    basePackageName={packageItem.ratePlanName}
                    hidePricing={hidePricing}
                    onClose={handleEditChangesClose}
                />
            }

            {/*
                Display a dialog that allows the user to initiate an 
                Immediate scheduled change.  This modal dialog overlays
                the lists of existing and historic changes
            */}
            {showImmediateUpgrade && packageItem.wanInterfaceId &&

                <ImmediateUpgrade
                    wanInterfaceId={packageItem.wanInterfaceId}
                    hidePricing={hidePricing}
                    onClose={handleImmediateUpgradeClose} 
                />
             }
        
        </div>
    )
}