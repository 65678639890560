import { NoActionsView } from "../actions"
import { Content, ContentContainer, Heading, TextValue } from "../common"

export const NothingSelectedView = () => {
    return (
        <ContentContainer>
            <Content>
                <Heading text="Nothing Selected" />
                <TextValue text="Please select an item to view" />
            </Content>
            <NoActionsView/>
        </ContentContainer>
    )
}