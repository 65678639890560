import { useContext, useEffect, useReducer } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContext } from "../../../../contexts/components/AuthContext";
import { Content, ErrorMessage, Footer, Header, LoadingIndicator, Main } from "../common/page";
import { GetAllResultModel, IPriceListRepository } from "./data/Repository";
import { Actions, initialState, reducer } from "./data/State";
import { PriceListDetailViewModel, PriceListViewModel, SavePriceRowModel } from "./views/ViewModels";
import { PageContent } from "./views/page/PageContent";
import { calculateMargin } from "./views/utils/Utils";

// TODO: tidy this up.
const fetchData = async (
    priceListRepository: IPriceListRepository,
    preFetch: () => void,
    postFetch: (data: GetAllResultModel | null) => void
) => {

    let data: GetAllResultModel | null = null;

    preFetch();

    // TODO: how do we handle unauthenticated users response?

    try {
        await priceListRepository.getAll()
            .then(result => {
                data = result
            })
            .catch(() => {
                console.error("need to log this correctly");
            })
    }
    catch {
        console.error("need to handle this correctly")
    }

    postFetch(data);
}

/**
 * Page component to display the End User Prices
 */
export const VnoAdminPricesPage = (props: {
    priceListRepository: IPriceListRepository
}) => {

    const {priceListRepository} = props;

    // state holds the users tree data accessible via a reducer
    const [state, dispatch] = useReducer(reducer, initialState);
    const {signOut} = useContext(AuthContext);

    function preFetch() {
        // signal that the data is loading
        dispatch({type: Actions.LOADING})
    }

    function postFetch(data: GetAllResultModel | null){
        // add a small UI experience delay
        // before handling the retrieved data
        setTimeout(() => {
            if (data){
                dispatch({
                    type: Actions.LOADED,
                    data: data
                });
            } else {
                dispatch({
                    type: Actions.LOADING_FAILED,
                    data: "An error occurred"
                })
            }
        }, 750)
    }

    // load data when the component loads
    useEffect(() => {

        // TODO: how should we be calling this really?
        // how do we handle authentication failures
        fetchData(priceListRepository, preFetch, postFetch).then(() => { }).catch(() => { signOut(() => { }) });

    }, [priceListRepository, signOut])
    

    async function addPriceList(clientId: number, reference: string, sabaQuoteId: number) : Promise<PriceListViewModel> {
        try {
            const result = await priceListRepository.create(clientId, sabaQuoteId, reference);

            return {
                id: result.id,
                clientGroupId: result.clientGroupId,
                clientId: result.clientId,
                reference: result.reference,
                savedDate: result.savedDate,
                createdDate: result.createdDate,
                selected: false,
            };    
        } catch (error) {
            console.error("Error adding Price List:", error);
            return Promise.reject("Error adding Price List");
        }
    }

    async function getPriceList(priceListId: number) : Promise<PriceListDetailViewModel> {
        try {
            const result = await priceListRepository.get(priceListId);
            
            return {
                ...result, 
                selected: false, 
                prices: result.prices.map(price => {
                    return {
                        ...price, 
                        margin: calculateMargin(price.costMonthly, price.saleMonthly)
                    }
                })}    

        } catch(error) {
            console.error("Error getting Price List:", error);
            return Promise.reject("Error getting Price List");
        }    
    }

    async function deletePriceList(priceListId: number) : Promise<void> {
        try {

            await priceListRepository.delete(priceListId);

        } catch(error) {
            console.error("Error deleting Price List:", error);
            return Promise.reject("An error occurred");
        }
    }

    async function savePriceList(priceListId: number, reference: string, prices: Array<SavePriceRowModel>) : Promise<PriceListViewModel> {
        const result = await priceListRepository.save(priceListId, reference, prices);

        return {...result, selected: true};
    }

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Main>
                <Header title="Manage Prices" />
                <LoadingIndicator isLoading={state.loading} />
                <ErrorMessage isError={!!state.error} />
                <Content>
                    <PageContent 
                        data={state.data ? state.data : null} 
                        onAddPriceList={addPriceList} 
                        onDeletePriceList={deletePriceList} 
                        onGetPriceList={getPriceList} 
                        onSavePriceList={savePriceList}                    
                />
                </Content>
                <Footer />
            </Main>
        </ErrorBoundary>
    )
}