import React, { useContext } from "react";

import {
    ChartContainer,
    ChartContent,
    ChartTitle,
    ContentAndKeyContainer,
    ErrorIndicator,
    LoadingIndicator,
} from "../components";

import { froundToIntlString } from "../../../../../../../../../utils/rounding";
import { RfPerformanceChartContent } from "./RfPerformanceChartContent";
import { RfPerformanceChartProps } from "./RfPerformanceChartProps";
import { RfPerformanceChartSeries } from "./RfPerformanceChartSeries";

import { MapDatePointContext } from "../../../../../../../../../contexts/components/MapDatePointContext";
import styles from "../ChartKey.module.css";

type KeyAreaProps = {
    cnoSeries: RfPerformanceChartSeries,
    snrSeries: RfPerformanceChartSeries,
}

const KeyArea : React.FC<KeyAreaProps> = ({cnoSeries, snrSeries}) => {

    const { dateMillis } = useContext(MapDatePointContext);

    return (
        <div className={styles.root}>
            <table>
                <tbody>
                    <tr>
                        <td><div className={styles.itemIndicator} style={{backgroundColor: cnoSeries.color}} /> Average C/N at date point</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(cnoSeries.keyItems?.get(dateMillis) ?? 0, 2)}</span>dB</td>
                    </tr>
                    <tr>
                        <td><div className={styles.itemIndicator} style={{backgroundColor: snrSeries.color}} /> Average SNR at date point</td>
                        <td className={styles.right}><span className={styles.itemValue}>{froundToIntlString(snrSeries.keyItems?.get(dateMillis) ?? 0, 2)}</span>dB</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export const RfPerformanceChart: React.FC<RfPerformanceChartProps> = ({chartState, overallHeight, overallWidth, margins}) => {

    return (
         <ChartContainer>
            <ChartTitle title="Radio Frequency Performance" uom="(Decibels)" />
            <ContentAndKeyContainer>
                <ChartContent overallHeight={overallHeight}>
                    {
                        chartState.isLoading ? 
                            <LoadingIndicator/> : 
                                chartState.isError ? 
                                    <ErrorIndicator/> : 
                                        <RfPerformanceChartContent 
                                            chartData={chartState.data} 
                                            overallHeight={overallHeight} 
                                            overallWidth={overallWidth} 
                                            margins={margins} 
                                        />
                    }
                </ChartContent>
                {chartState.data.series.length 
                    ? <KeyArea cnoSeries={chartState.data.series[0]} snrSeries={chartState.data.series[1]} /> 
                    : null 
                }
            </ContentAndKeyContainer>
         </ChartContainer>
    );
};
