import { PriceListViewModel } from "../ViewModels";
import { ActionsContainer } from "../actions";
import { ContentContainer, Content, Heading } from "../common";
import { Buttons } from "../actions/Buttons";
import { Button } from "../actions/Button";
import { PriceListTableRow } from "./PriceListTableRow";
import { PriceListTableHeader } from "./PriceListTableHeader";
import { Table } from "../tables";

export const PriceListCollectionView = ({
    clientId,
    priceLists,
    onAdd,
    onSelected,
} : {
    clientId: number,
    priceLists: Array<PriceListViewModel>,
    onAdd: (clientId: number) => void,
    onSelected: (id: number) => void,  
}) => {

    const handleAddButtonOnClick = () => {
        onAdd(clientId)
    }

    const items = priceLists.map(priceList => {
        return (
            <PriceListTableRow 
                key={priceList.id} 
                priceList={priceList} 
                onSelected={() => onSelected(priceList.id)} 
            />
        )
    })

    return (
        <ContentContainer>
            <Content>
                <Heading text="Price Lists" />
                <Table>
                    <PriceListTableHeader />
                    <tbody>
                        {items}
                    </tbody>
                </Table>
            </Content>
            <ActionsContainer>
                <Buttons>
                    <Button onClick={handleAddButtonOnClick} text="Add A Price List" />
                </Buttons>
            </ActionsContainer>
        </ContentContainer>
    )
}

