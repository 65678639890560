import { useRef, useState } from "react";
import { Dates } from "../../../../../../../../../utils/dates";
import { DatePicker, DateSelectorButton } from "../../../../../../../../dates";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import styles from "./StartDate.module.css";

interface IStartDateProps {
    startDateMillis: number;
    minStartDateMillis: number;
    maxStartDateMillis: number | null;
    onChange: (value: number) => void;
}

export const StartDate : React.FC<IStartDateProps> = ({
    startDateMillis,
    minStartDateMillis,
    maxStartDateMillis,
    onChange
}) => {

    const [showDatePicker, setShowDatePicker] = useState(false);

    const anchorRef = useRef<HTMLDivElement | null>(null);

    function handleOnSelected(dateMillis: number){

        setShowDatePicker(false);

        onChange(dateMillis);
    }

    return (
        <div>
            <div ref={anchorRef}>
                <DateSelectorButton
                    onClick={() => setShowDatePicker(true)}
                    className={styles.dateSelectorButton}
                >
                    {Dates.fromMillis(startDateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}
                </DateSelectorButton>
            </div>
            {showDatePicker &&
                <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
                    <div>
                        <DatePicker 
                            minimumDateMillis={minStartDateMillis}
                            maximumDateMillis={maxStartDateMillis}
                            suppliedDateMillis={startDateMillis}
                            anchorRef={anchorRef}
                            onSelected={handleOnSelected}
                            onCancel={() => setShowDatePicker(false)}
                        />
                    </div>
                </ClickAwayListener>
            }
        </div>
    )

}