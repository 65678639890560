import styles from "./ErrorMessage.module.css";

interface IErrorMessageProps {
    isError: boolean;
}

export const ErrorMessage : React.FC<IErrorMessageProps> = ({
    isError,
}) => {

    if (!isError) return null;

    return (
        <div className={styles.root}>
           <div>Sorry - something went wrong</div>
        </div>
    )
}