import classNames from "classnames";
import { useDrop } from "react-dnd";
import { notifyError } from "../../../common/errors/Errors";
import { DataApi } from "../../data/DataApi";
import { Actions, ActionTypes } from "../../data/State";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";
import styles from "./Header.module.css";

interface IHeaderProps {
    dispatch: React.Dispatch<ActionTypes>;
}

export const Header : React.FC<IHeaderProps> = ({
    dispatch
}) => {

    const [{isOver, canDrop}, drop] = useDrop(
        () => ({
            accept: ItemTypes.USER,
            canDrop: (item) => item.type !== "unassigned",
            drop: (item: IUserDnd) => handleDrop({
                source: item,
                dispatch: dispatch
            }),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            })
        }),
        []
    )

    return (
        <li ref={drop} className={styles.li}>
            <div className={styles.root}>
                <div className={styles.inner}>
                    <div className={`${classNames(styles.title, canDrop && styles.canDrop, (isOver && canDrop) && styles.isOver)}`}>
                        Unassigned Users
                    </div>
                </div>
            </div>
        </li>
    )
}

interface IhandleDropProps {
    source: IUserDnd;
    dispatch: React.Dispatch<ActionTypes>
}

const handleDrop = (props: IhandleDropProps) => {

    const {source} = props;

    if (source.type === "client"){
        void handleUnassignClientUser(props);
    }

    if (source.type === "client-group") {
        void handleUnassignClientGroupUser(props);
    }
}

const handleUnassignClientUser = async (props : IhandleDropProps) => {

    const {source, dispatch} = props;

    let ok = false;

    try {
        const response = await DataApi.removeUserFromClient(source.userId, source.clientId);

        ok = response.ok;
    }
    catch (error) {
        console.error('There was an error', error);
    }

    if (ok){
        dispatch({
            type: Actions.CLIENT_USER_UNASSIGNED,
            data: {
                userId: source.userId,
                userName: source.userName,
                clientId: source.clientId,
                clientGroupId: source.clientGroupId,
            }
        })
    } else {
        notifyError();
    }
}

const handleUnassignClientGroupUser = async (props : IhandleDropProps) => {
    
    const {source, dispatch} = props;

    let ok = false;

    try {
        const response = await DataApi.removeUserFromClientGroup(source.userId, source.clientGroupId);

        ok = response.ok;
    }
    catch (error) {
        console.error('There was an error', error);
    }

    if (ok) {
        dispatch({
            type: Actions.CLIENT_GROUP_USER_UNASSIGNED,
            data: {
                userId: source.userId,
                userName: source.userName,
                clientGroupId: source.clientGroupId,
            }
        })
    } else {
        notifyError();
    }
}