import React, { RefObject } from "react";
import { useEffect, createRef } from "react";
import { select as d3select } from "d3-selection";
import { axisLeft as d3axisLeft } from "d3-axis";

import { LeftAxisProps } from "./LeftAxisProps";

export const LeftAxis: React.FC<LeftAxisProps> = ({width, scale, showTicks}) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            const axis = d3axisLeft(scale);
            if (!showTicks){
                axis.ticks(0);
            }
            d3select(elRef.current).call(axis);
        }
    }, [elRef, scale, showTicks]);

    return (
        <g ref={elRef} transform={`translate(${width}, 0)`}></g>
    );

}