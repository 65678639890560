import React from "react";
import styles from "./ChartContent.module.css";
import { ChartContentProps } from "./ChartContentProps";

export const ChartContent: React.FC<ChartContentProps> = ({ overallHeight, children }) => {
    return (
        <svg height={overallHeight} className={styles.root}>
            {children}
        </svg>
    );
};
