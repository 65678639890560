import { useEffect, useState } from "react";
import { api, useGetData } from "../../components";
import { ImmediateUpgradeMetaModel } from "./Models";

interface IApiRatePlan {
    id: number;
    name: string;
    dailyAdjustment: number;
}

interface IApiModel {
    earliestEndDateMillis: number;
    ratePlans: IApiRatePlan[];
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel) : ImmediateUpgradeMetaModel {
    return {
        earliestEndDateMillis: apiData.earliestEndDateMillis,
        ratePlans: apiData.ratePlans.map((plan) => {
            return {
                id: plan.id,
                dailyAdjustment: plan.dailyAdjustment,
                name: plan.name
            }
        })
    }
}

/**
 * Hook to retrieve Immediate Upgrade Meta data for a given installation
 * and SABA Equipment Id and WAN Interface ID
 * @param installationId Id of the Installation whose data is to be retrieved
 * 
 * @returns An object which defines the state of the data fetch.
 */
 export const useFetchImmediateUpgradeMetaData = (
    wanInterfaceId: number | undefined,
 ) => {

    const [url, setUrl] = useState<string>('');
    const {state} = useGetData(url, mapper);

    useEffect(() => {

        if (typeof wanInterfaceId === "undefined") return;

        const url = api.urls.packages.get.immediateUpgradeMetaData(wanInterfaceId);

        setUrl(url);
        
    }, [wanInterfaceId]);

    return {state};
}