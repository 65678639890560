import { AuthLayout, AuthForm, SectionHeader } from "./components";

function ForgotPasswordConfirmationPage() {

    return (
        <AuthLayout>
            <AuthForm onSubmit={()=> {}}>
                <SectionHeader text="We have sent you an email with a link to reset your password." />
            </AuthForm>
        </AuthLayout>
    )
}

export default ForgotPasswordConfirmationPage;