import { ActionTypes } from "../../data/State";
import { IHoldingGroupModel } from "../Models";
import { Header } from "./Header";
import { UnassignedUser } from "./UnassignedUser";

interface IHoldingGroupProps {
    holdingGroup: IHoldingGroupModel;
    dispatch: React.Dispatch<ActionTypes>;
}

export const HoldingGroup : React.FC<IHoldingGroupProps> = ({
    holdingGroup,
    dispatch,
}) => {

    return (
        <ul>
            <Header dispatch={dispatch}/>
            <ul>
                {holdingGroup.users.map(user => 
                    <UnassignedUser
                        key={user.userId}
                        clientGroupId={holdingGroup.clientGroupId}
                        userId={user.userId}
                        name={user.name}
                        canBeMoved={user.canBeMoved}
                    />
                )}
            </ul>
        </ul>
    )
}