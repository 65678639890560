// TODO : see if we can get rid of these by creating a set of APIs for various data types
// as per the VNO Client management

export type FetchApiOptions = {
    includeCredentials: boolean
}

export async function fetchApiGet(url = '', options: FetchApiOptions = {includeCredentials: true}) : Promise<Response>{

    return await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: options.includeCredentials ? 'include' : 'omit'
    })
}

export async function fetchApiPost(url = '', data = {}, options: FetchApiOptions = {includeCredentials: true}) : Promise<Response>{

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: options.includeCredentials ? 'include' : 'omit',
        body: JSON.stringify(data)
    });
}

export async function fetchApiPut(url = '', data = {}, options: FetchApiOptions = {includeCredentials: true}) : Promise<Response>{
    
    return await fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: options.includeCredentials ? 'include' : 'omit',
        body: JSON.stringify(data)
    });
}

export async function fetchApiDelete(url = '', data = {}) : Promise<Response>{
    return await fetch(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include', // must be authenticated to delete from the remote api
        body: JSON.stringify(data)
    });
}

interface IFetchHandlerResult {
    succeeded: boolean,
    json: any,
    errors: string[],
    response: Response
}

export async function fetchHandler(response:Response) : Promise<IFetchHandlerResult>{
    if (response.ok){
        return response.json().then(json => {
            // the status is ok and there is a json body
            return Promise.resolve({succeeded: true, json: json, response: response, errors: []});
        }).catch(() => {
            // the status was ok but there is no json body
            return Promise.resolve({succeeded: true, json: undefined, response: response, errors: []});
        });
    } else {
        return response.json().catch(() => {
            // the status was not ok and there is no json body
            return Promise.resolve({succeeded: false, json: undefined, response: response, errors: []})
        }).then(json => {
            // the status was not ok but there is a json body
            return Promise.resolve({succeeded: false, json: json, response: response, errors: json})
        })
    }
}
