import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { VnoAdminMenu } from "./VnoAdminMenu";
import { NavBarButton } from "./NavBarButton";

export const ShowVnoAdminMenu = () => {

    const [open, setOpen] = React.useState<boolean>(false);
    
    const handleClick = () => {
        setOpen((prev) => !prev);
    }

    const closeMenu = () => {
        // don't prevent default on this, because it prevents other
        // links etc on the UI from being actioned.
        setOpen(false);
    }

    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <div>
                <NavBarButton
                    icon={faBars}
                    onClick={handleClick}
                    title="Admin"
                />
                {
                    open ? <VnoAdminMenu onNavigated={closeMenu} onCancel={closeMenu}/> : null
                }
            </div>
        </ClickAwayListener>
    )
}