import React from "react";
import { scaleUtc as d3scaleUtc, scaleLinear as d3scaleLinear } from "d3-scale";
import { max as d3max } from "d3-array";
import { line as d3line, curveBasis as d3curveBasis } from "d3-shape";
import {
    BottomAxis, 
    LeftAxis,
    TimeLine,
    ChartLine
} from "../components";
import { RfPerformanceChartContentProps } from "./RfPerformanceChartContentProps";
import { RfPerformanceChartItem } from "./RfPerformanceChartItem";

export const RfPerformanceChartContent: React.FC<RfPerformanceChartContentProps> = ({ chartData, margins, overallWidth, overallHeight}) => {

    if (chartData === null || typeof chartData === "undefined" || !chartData.series.length) return null;

    // the lowest value of the lower Time Stamp in the collection of data
    const minTimeStamp = chartData.fromMillis;

    // the highest value of the upper Time Stamp in the collection of data
    const maxTimeStamp = chartData.toMillis + chartData.intervalMillis;
    
    // a time based scale for the x axis
    const xScale = d3scaleUtc().domain([minTimeStamp, maxTimeStamp]).range([margins.marginLeft, overallWidth - margins.marginRight]);

    const timeLineWidth = xScale(new Date(chartData.fromMillis + chartData.intervalMillis)) - xScale(new Date(chartData.fromMillis));

    const contentHeight = overallHeight - margins.xAxisHeight;

    const maxSeries0Value = chartData.series[0].chartItems.length ? d3max(chartData.series[0].chartItems.map(d => { return d.value??0; })) ?? 0 : 0;
    const maxSeries1Value = chartData.series[1].chartItems.length ? d3max(chartData.series[1].chartItems.map(d => { return d.value??0; })) ?? 0 : 0;
    const maxValue = d3max([maxSeries0Value, maxSeries1Value]) ?? 0;

    // a number scale for the y axis
    const yScale = d3scaleLinear().domain([0, maxValue]).range([contentHeight, 5]).nice();

    const lineFn = d3line<RfPerformanceChartItem>().defined(d => d.value !== null).curve(d3curveBasis).x(d => xScale(d.tsLowerMillis)).y(d => yScale(d.value??0));

    return (
        <>
            <ChartLine color={chartData.series[0].color} fn={lineFn(chartData.series[0].chartItems)} />
            <ChartLine color={chartData.series[1].color} fn={lineFn(chartData.series[1].chartItems)} />
            <LeftAxis
                width={margins.marginLeft}
                scale={yScale} 
                showTicks={true} />
            <BottomAxis
                width={overallWidth - margins.marginLeft - margins.marginRight}
                top={overallHeight - margins.xAxisHeight}
                scale={xScale} />
            <TimeLine
                height={contentHeight}
                width={timeLineWidth}
                scale={xScale}
                />
        </>
    );
};
