import React from "react";

export type MapDateRangeContextType = {
    fromMillis: number;
    toMillis: number;
    intervalMillis: number;
    setDates: (fromMillis: number, toMillis: number) => void;
    calculateIntervalMillis: (fromMillis: number, toMillis: number) => number;
}

export const MapDateRangeContext = React.createContext<MapDateRangeContextType>(null!);
