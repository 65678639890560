import React from "react";
import { Dates } from "../../../../utils/dates";
import styles from "./SelectedDatesDisplay.module.css";

interface SelectedDatesDisplayProps{
    fromDateMillis: number | null,
    toDateMillis: number | null,
}

export const SelectedDatesDisplay : React.FC<SelectedDatesDisplayProps> = ({
    fromDateMillis,
    toDateMillis,
}) => {

    return (
        <div className={styles.root}>
            <div>From {fromDateMillis ? Dates.fromMillis(fromDateMillis).toString(Dates.DATE_FORMAT_SHORT) : '-'} To {toDateMillis ? Dates.fromMillis(toDateMillis).toString(Dates.DATE_FORMAT_SHORT) : '-'}</div>
        </div>
    )
}