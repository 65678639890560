import { RefObject, createRef, useEffect } from "react";
import { select as d3select } from "d3-selection";
import { axisRight as d3axisRight, AxisScale as d3AxisScale } from "d3-axis";

interface RightAxisProps {
    x: number;
    scale: d3AxisScale<number>;
    showTicks: boolean;
}

export const RightAxis = ({x, scale, showTicks } : RightAxisProps) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            const axis = d3axisRight(scale);
            if (!showTicks) {
                axis.ticks(0);
            }
            d3select(elRef.current).call(axis);
        }
    }, [elRef, scale, showTicks])

    return (
        <g ref={elRef} transform={`translate(${x}, 0)`}></g>
    )
}