import { PackageItemModel } from "../../../../../../../../../dataServices/dataPackages/packageItems";
import { PlanDetails } from "./PlanDetails";

import styles from "./PackageItem.module.css";
import { MonthlyCostDisplay } from "./MonthlyCostDisplay";
import { Tab } from "./Tab";

export const PackageItem = ({
    packageItem,
    hidePricing,
}: {
    packageItem: PackageItemModel,
    hidePricing: boolean,
}) => {

    return (
        <div className={styles.root}>
            <Tab text={packageItem.description} >
                {/* don't show the costs if pricing is being hidden from the user */ }
                {hidePricing
                    ? <></>
                    : <MonthlyCostDisplay cost={packageItem.total }/>
                }
                {/* only show plan details if the plan can be changed */ }
                {packageItem.planCanBeChanged
                    ? <PlanDetails
                        packageItem={packageItem}
                        hidePricing={hidePricing}
                    />
                    : <></>
                }
            </Tab>
        </div>
    )
}
