import { useCallback } from "react";
import { ActionTypes, IApiData } from "../data/State";
import { HoldingGroup } from "./components/HoldingGroup";
import { IHoldingGroupModel } from "./Models";
import { Tree } from "../../common/tree/Tree";

interface IHoldingGroupProps {
    data: IApiData;
    dispatch: React.Dispatch<ActionTypes>;
}

const getHoldingGroups = (data: IApiData) : IHoldingGroupModel[] => {
        
    const items : IHoldingGroupModel[] = [];

    data.clientGroups.forEach(clientGroup => {
        clientGroup.clients.filter(client => client.isVnoHoldingClient).forEach(client => {
            client.installationGroups.forEach(ig => {
                const hg : IHoldingGroupModel = {
                    clientGroupId: clientGroup.id,
                    clientGroupName: clientGroup.name,
                    clientId: client.id,
                    installationGroupId: ig.id,
                    installations: []
                }
                ig.installations.forEach(installation => 
                    hg.installations.push(
                        {
                            installationId: installation.id,
                            name: installation.name
                        }
                    ));
                items.push(hg);
            })
        })
    });

    return items;
}

export const HoldingGroups : React.FC<IHoldingGroupProps> = ({
    data,
    dispatch
}) => {

    const holdingGroups = useCallback(() => {return getHoldingGroups(data)}, [data])

    return (
        <Tree>
            {holdingGroups().map(holdingGroup => 
                <HoldingGroup
                    key={holdingGroup.installationGroupId}
                    holdingGroup={holdingGroup}
                    dispatch={dispatch}
                />
                )}
        </Tree>
    )
}