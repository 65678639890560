import { DateTime } from "luxon";

// converts a string to a number.  Invalid values are converted to zero.
export function stringToNumber(value: string){
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

// returns a value for a margin between cost and salve value
export function calculateMargin(cost:number, sale: number) : number{
    return Math.round((((1.0 * sale) / (1.0 * cost)) - 1.0) * 100);
}

// returns the result of applying a margin expressed as x%
// to a value
export function applyMargin(value:number, margin: number) : number{
    return Math.round(value * ((100.0 + margin) / 100.0));
}

// converts a number to a date and time
export function toDateAndTime(millis: number): string{
    if (isNaN(millis)) return "";

    return DateTime.fromMillis(millis).toFormat('yyyy-LL-dd HH:mm:ss') + ' UTC';
}