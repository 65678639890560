import React from "react";
import styles from "./CloseButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface ICloseButtonProps{
    onClick: () => void;
}

export const CloseButton : React.FC<ICloseButtonProps> = ({
    onClick
}) => {
    return (
        <button className={styles.button} onClick={onClick}>
            <FontAwesomeIcon
                icon={faClose}
                size="lg"
                title="Close"
            />
        </button>
    )
}