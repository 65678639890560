import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useDrag } from "react-dnd";
import { DataApi } from "../../data/DataApi";
import { IUser } from "../../data/Models";
import { Actions, ActionTypes } from "../../data/State";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";

import styles from "./ClientUser.module.css";

interface IRemoveUserApiResult {
    userId: number;
    isUnassigned: boolean;
}

interface IClientUserProps {
    clientGroupId: number;
    clientId: number;
    user: IUser,
    dispatch: React.Dispatch<ActionTypes>,
}

export const ClientUser : React.FC<IClientUserProps> = ({
    clientGroupId,
    clientId,
    user,
    dispatch,
}) => {

    const [{ isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.USER,
            item: {
                clientGroupId: clientGroupId,
                clientId: clientId,
                userId: user.id,
                userName: user.name,
                type: "client"
            } as IUserDnd,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }), 
        []
    );

    const handleRemoveUser = () => {

        const handler = async () => {
            let ok = false;
            let apiResult: IRemoveUserApiResult | null = null;

            const response = await DataApi.removeUserFromClient(user.id, clientId);

            ok = response.ok;

            if (ok) {
                apiResult = await response.json() as IRemoveUserApiResult;

                dispatch({
                    type: Actions.CLIENT_USER_REMOVED_FROM_CLIENT,
                    data: {
                        userId: user.id,
                        userName: user.name,
                        clientId: clientId,
                        clientGroupId: clientGroupId,
                        isUnassigned: apiResult ? apiResult.isUnassigned : false
                    }
                });
            }
        }

        handler().catch(() => console.log("There was an error"));
    }

    return (
        <li>
            <div className={styles.root}>
                <div className={styles.inner} ref={user.canBeMoved ? drag : null} style={{opacity: isDragging ? 1 : 1} }>
                    <div className={`${classNames(styles.title, user.canBeMoved && styles.canDrag)}`}>
                        {user.name}
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.buttonContainer}>
                            <button 
                                className={styles.button} 
                                type="button" 
                                onClick={handleRemoveUser}
                                title="Remove User from Customer"
                            >
                                <FontAwesomeIcon icon={faDeleteLeft} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

