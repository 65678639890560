import { useContext } from "react";
import styles from "./ContentHeader.module.css";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";

const ContentHeader = ({ title }: {title: string}) => {

    const { ship } = useContext(SelectedShipContext);

    const debug = false;

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.subTitle}>
                {"[ "}{ship?.name ?? "Nothing Selected"}{" ]"}
                {debug && ship?.installationId}
            </div>
        </div>
    );
}

export { ContentHeader };