import React from "react";
import { 
    AuthLinks, 
    AuthLayout, 
    AuthForm, 
    Input, 
    SectionHeader, 
    FieldSpacer,
} from "./components";
import {useNavigate} from "react-router-dom";

import { api, fetchHandler, fetchApiPost} from "../../../dataServices/components";
import { application } from "../../../utils/environment";
import { Button } from "../../buttons";
import { toast } from "react-toastify";

function ForgotPasswordPage() {
    
    const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
    const [processing, setProcessing] = React.useState<boolean>(false);
    
    const navigate = useNavigate();

    const redirectPage = '/forgotpasswordconfirmation';

    /**
     * Handle the user pressing the submit button
     */
     function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        setEmailErrorMessage('');

        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;

        let errors = false;

        if (email === null || typeof email === "undefined" || email.trim() === '') {
            setEmailErrorMessage("Email is required");
            errors = true;
        }

        if (errors){
            return;
        }

        setProcessing(true);

        const data = {
            email: email,
            applicationName: application.name,
        };

        fetchApiPost(api.urls.auth.post.forgotPassword(), data, {includeCredentials: false})
        .then((response) => {
            setProcessing(false);
            void fetchHandler(response)
            .then((result) => {
                if (result.succeeded){
                    navigate(redirectPage, {replace: true});
                } else {
                    toast.error("Oops.  Something went wrong processing your request");
                }
            })
        })
        .catch(() => {
            setProcessing(false);
            toast.error("Oops.  Something went wrong processing your request");
        })
    }

    return (        
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit}>
                <SectionHeader text="Forgot your password?" />
                <Input 
                    name="email" 
                    placeholder="Email" 
                    type="text" 
                    maxLength={50} 
                    disabled={processing} 
                    required={true} 
                    errorMessage={emailErrorMessage}
                    autoFocus={true}
                />
                <Button text="Submit" disabled={processing}/>
                <FieldSpacer/>
            </AuthForm>
            <AuthLinks forgotPassword={false} register={true} signIn={true}/>
        </AuthLayout>
    )

}

export default ForgotPasswordPage;
