import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Pager.module.css";

interface IPagerProps {
    canPreviousPage: boolean,
    canNextPage: boolean,
    pageCount: number,
    pageIndex: number,
    pageOptions: number[],
    pageSize: number,
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void,
    previousPage: () => void,
    nextPage: () => void,
    setPageSize: (pageSize: number) => void
}

/**
 * A component that can be used to page and adjust the page size
 * for a react-table implementation
 */
export const Pager : React.FC<IPagerProps> = ({
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize
}) => {

    return (
        <div className={styles.root}>
            <div className={styles.buttonsContainer}>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} title="go to first page">
                    <FontAwesomeIcon icon={faAnglesLeft}/>
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} title="go to previous page">
                    <FontAwesomeIcon icon={faAngleLeft}/>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage} title="go to next page">
                    <FontAwesomeIcon icon={faAngleRight}/>
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} title="go to last page">
                    <FontAwesomeIcon icon={faAnglesRight}/>
                </button>
            </div>
            <div className={styles.divider}>
                {'|'}
            </div>
            <span>
                Page{' '}
                {pageIndex + 1} of {pageOptions.length}
            </span>
            <div className={styles.divider}>
                {'|'}
            </div>
            <div>
                <span>Items per page : </span>
                <select
                    className={styles.pageSizeSelect}
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 15, 20].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}