import { TextValue } from ".";
import styles from "./Reference.module.css";

export const Reference = ({
    reference,
    editing,
    onChange,
}:{
    reference: string,
    editing: boolean,
    onChange: (value: string) => void,
}) => {

    if (editing ){
        return (
            <input 
                type="text" 
                value={reference} 
                onChange={(e) => {onChange(e.target.value)}}
                className={styles.input}
            />
        )
    }

    return (
        <TextValue text={reference} />
    )
}
