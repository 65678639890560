import { NoActionsView } from "../actions"
import { Content, ContentContainer, Heading, TextValue } from "../common"

// just a test - may not work
export const LoadingView = (props: {headingText: string}) => {
    return (
        <ContentContainer>
            <Content>
                <Heading text={props.headingText} />
                <TextValue text="Loading..." />
            </Content>
            <NoActionsView/>
        </ContentContainer>
    )
}