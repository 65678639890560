import styles from "./ContentAndKeyContainer.module.css";

interface ContentAndKeyContainerProps {
    children?: React.ReactNode;
}

export const ContentAndKeyContainer = ({children}: ContentAndKeyContainerProps) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}