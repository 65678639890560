import styles from "./Button.module.css";
import classNames from "classnames";

interface IButtonProps {
    // text to display in the button
    text?: string,
    className?: string,
    // true if the button should be disabled
    disabled?: boolean,
    // raised when the button is clicked
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    // button size
    size?: "small" | "medium" | "large"
    // color
    color?: "primary" | "secondary" | "success" | "danger"
}

interface IBaseButtonProps extends IButtonProps {
    userClassName?: string;
}

const BaseButton : React.FC<IBaseButtonProps> = ({
    text, 
    disabled = false,
    onClick = () => {},
    userClassName,
    size = "medium",
    color = "secondary"
}) => {

    const sizeStyle = size === "large" ? styles.large : size === "medium" ? styles.medium : styles.small;
    const colorStyle = 
        color === "primary" 
        ? styles.primary 
        : color === "secondary" 
            ? styles.secondary 
            : color === "success" 
                ? styles.success 
                : styles.danger;

    return (
        <button 
            className={`${classNames(styles.baseButton, sizeStyle, colorStyle, disabled && styles.disabled, userClassName)}`}
            onClick={onClick} 
            disabled={disabled}
        >
            {text}
        </button>
    )
}

export const Button : React.FC<IButtonProps> = ({
    text,
    className,
    disabled,
    onClick,
    size,
    color,
}) => {
    return (
        <BaseButton
            userClassName={className}
            size={size}
            color={color}
            text={text}
            onClick={onClick}
            disabled={disabled}
        />
    )
}