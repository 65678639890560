import React, {useEffect, useState} from "react";
import { Dates } from "../../../../utils/dates";
import { Layer, Source } from "react-map-gl";
import { ariesKeys } from "../../../../utils/environment";

/*
Returns a random integer between a min and max value inclusive
*/
function getRandomInt(min: number, max: number){
    let minToUse: number = Math.ceil(min);
    let maxToUse: number = Math.floor(max);
    return Math.floor(Math.random() * (maxToUse - minToUse + 1)) + minToUse;
}

/* 
Returns a string that represents a Unix Epoch milliseconds value
as a valid date point for Aeris Weather tiles
*/
function makeDateOffset(millis: number) : string {
    return Dates.fromMillis(millis).toString(Dates.DATE_FORMAT_AERIS_WEATHER_TILES);
}


/*
Returns map tiles for a given date point and weather types
 */
function getWeatherTiles(showRain: boolean, showCloud: boolean, showWaves: boolean, datePointMillis: number) : string[] {
    let weatherType = "";
    if (showWaves){
        weatherType += ",wave-heights";
    }
    if (showRain){
        weatherType += ",radar-global";
    }
    if (showCloud){
        weatherType += ",satellite";
    }
    if (weatherType.length > 0){
        weatherType = weatherType.slice(1);
    }

    const serverNumber = getRandomInt(1, 4);

    const dateOffset = makeDateOffset(datePointMillis);

    return [`https://maps${serverNumber}.aerisapi.com/${ariesKeys.clientId}_${ariesKeys.secret}/${weatherType}/{z}/{x}/{y}/${dateOffset}.png`];    
}

interface WeatherOverlaysProps{
    showRain: boolean,
    showCloud: boolean,
    showWaves: boolean,
    datePoint: number,
}

/**
 * Component returns MapGL Sources and Layers that show Weather information
 */
export const WeatherOverlays : React.FC<WeatherOverlaysProps> = ({
    showRain,
    showCloud,
    showWaves,
    datePoint
}) => {

    const [weatherTiles, setWeatherTiles] = useState(getWeatherTiles(showRain, showCloud, showWaves,datePoint));

    useEffect(() => {
        setWeatherTiles(getWeatherTiles(showRain, showCloud, showWaves,datePoint));
    }, [showRain, showCloud, showWaves, datePoint])

    return (
        <Source 
            key={`${datePoint}-${showRain}-${showCloud}-${showWaves}`} 
            id="weather-source" 
            type="raster" 
            tiles={weatherTiles}>
            <Layer 
                key={`${datePoint}-${showRain}-${showCloud}-${showWaves}`} 
                id="weather-layer"
                type="raster"
                minzoom={0}
                maxzoom={22}
                layout={{visibility: showRain || showCloud || showWaves ? "visible" : "none"}}
                beforeId="ship-route-layer-id"
            />
        </Source>
    )
}
