import React, { useLayoutEffect, useState } from "react";
import { Months} from "./Months";
import { IYearAndMonth, yearAndMonthAsNumber } from "./YearAndMonth";
import { SelectedMonthsDisplay } from "./SelectedMonthsDisplay";

import styles from "./MonthRangePicker.module.css";
import { Year } from "./Year";
import { Buttons } from "./Buttons";

interface IMonthRangePickerProps {
    fromDate: IYearAndMonth;
    toDate: IYearAndMonth;
    minDate: IYearAndMonth | null;
    maxDate: IYearAndMonth | null;
    anchorRef: React.MutableRefObject<HTMLElement | null>;
    onOk : (from: IYearAndMonth, to: IYearAndMonth) => void;
    onCancel: () => void;
}

interface IScreenLocation {
    top: number;
    left: number;
}

export const MonthRangePicker : React.FC<IMonthRangePickerProps> = ({
    fromDate,
    toDate,
    minDate,
    maxDate,
    anchorRef,
    onOk,
    onCancel,
}) => {

    // screen location of the date picker
    const [componentPosition, setComponentPosition] = React.useState<IScreenLocation>({top: 0, left: 0});

    const [selectedYear, setSelectedYear] = useState<number>(fromDate.year);

    const [selectedFromDate, setSelectedFromDate] = useState<IYearAndMonth | null>(fromDate);
    const [selectedToDate, setSelectedToDate] = useState<IYearAndMonth | null>(toDate);
    const [potentialToDate, setPotentialToDate] = useState<IYearAndMonth | null>(null);

    useLayoutEffect(() => {
        function updatePosition(){
            if (anchorRef.current){
                const anchorClientRect = anchorRef.current.getBoundingClientRect();    

                const pos : IScreenLocation = {
                    top: anchorClientRect.bottom + 5,
                    left: anchorClientRect.left
                };

                setComponentPosition(pos);
            }
        }

        window.addEventListener('resize', updatePosition);

        updatePosition();

        return () => window.removeEventListener('resize', updatePosition);
    }, [anchorRef]);

    const handleOnMonthSelected = (yearAndMonth: IYearAndMonth) => {
        
        const selectedDate = yearAndMonthAsNumber(yearAndMonth);

        if (selectedFromDate === null || (selectedFromDate && selectedToDate)){
            setSelectedFromDate(yearAndMonth);
            setSelectedToDate(null);
        }
        else {
            // validate that we are not earlier than the currently selected lower date
            // by comparing the year and month as composite numbers
            if ( selectedDate >= yearAndMonthAsNumber(selectedFromDate)){
                setSelectedToDate(yearAndMonth);
            }
        }
        setPotentialToDate(null);
    }

    const handleOnMonthMouseEnter = (yearAndMonth: IYearAndMonth) => {
        const yearAndMonthNum = yearAndMonthAsNumber(yearAndMonth);
        if (selectedFromDate !== null && selectedToDate === null && yearAndMonthNum > yearAndMonthAsNumber(selectedFromDate)) {
            setPotentialToDate(yearAndMonth)
        } else {
            setPotentialToDate(null);
        }
    }

    const handleOnYearChanged = (year: number) => {
        setSelectedYear(year);
    }

    const handleOnOk = () => {
        if (selectedFromDate !== null && selectedToDate !== null){
            onOk(selectedFromDate, selectedToDate);
        }
    }

    const handleOnCancel = () => {
        onCancel();
    }

    return (
        <div className={styles.root} style={{top: componentPosition.top, left: componentPosition.left}}>
            <div className={styles.content}>
                <div className={styles.section}>
                    <Year 
                        year={selectedYear} 
                        minYear={minDate ? minDate.year : null} 
                        maxYear={maxDate ? maxDate.year : null} 
                        onChange={handleOnYearChanged} 
                    />
                </div>
                <div className={styles.section}>
                    <Months 
                        year={selectedYear} 
                        minDate={minDate}
                        maxDate={maxDate}
                        fromDate={selectedFromDate} 
                        toDate={selectedToDate} 
                        potentialToDate={potentialToDate}
                        onMonthSelected={handleOnMonthSelected}
                        onMonthMouseEnter={handleOnMonthMouseEnter}
                    />
                </div>
                <div className={styles.section}>
                    <SelectedMonthsDisplay
                        from={selectedFromDate}
                        to={
                            selectedToDate
                                ? selectedToDate
                                : potentialToDate
                                    ? potentialToDate
                                    : null
                        }
                    />
                </div>
                <Buttons 
                    disabled={selectedFromDate === null || selectedToDate === null} 
                    onCancel={handleOnCancel} 
                    onOk={handleOnOk} 
                />
            </div>
        </div>
    )
}