import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api, fetchApiPut, fetchHandler } from "../../../dataServices/components";
import { application } from "../../../utils/environment";
import { AuthForm, AuthLayout, SectionHeader, FieldSpacer } from "./components";
import { Button } from "../../buttons";
import { toast } from "react-toastify";

const redirectPage = "/confirmemailconfirmation";

function ConfirmEmailPage() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // pick up the User ID from the URL segment via the router params
    const {userId} = useParams();
    // pick up the Email token code from the Query string (too big to include in a URL segment)
    const code = searchParams.get('c');

    const [processing, setProcessing] = React.useState<boolean>(false);


    /**
     * Handle the user pressing the submit button
     */
     function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        setProcessing(true);

        const data = {
            userId: userId,
            code: code,
            applicationName: application.name,
        };

        console.log("handling submit", data);

        fetchApiPut(api.urls.auth.put.confirmEmail(), data, {includeCredentials: false})
        .then((response) => {
            setProcessing(false);
            void fetchHandler(response)
            .then((result) => {
                if (result.succeeded){
                    navigate(redirectPage, {replace: true});
                } else {
                    toast.error("Ooops. Something went wrong when trying to confirm your email")
                }
            })
        })
        .catch(() => {
            setProcessing(false);
            toast.error("Ooops. Something went wrong when trying to confirm your email")
        })
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit}>
                <SectionHeader text="Confirm your email"/>
                <FieldSpacer/>
                <Button text="Click here to confirm your email" disabled={processing} />
                <FieldSpacer/>
            </AuthForm>
        </AuthLayout>
    )
}

export default ConfirmEmailPage;