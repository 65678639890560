import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag } from "react-dnd";
import { DataApi } from "../../data/DataApi";
import { IUser } from "../../data/Models";
import { Actions, ActionTypes } from "../../data/State";
import { ItemTypes } from "../../dnd/ItemTypes";
import { IUserDnd } from "../../dnd/User";
import classNames from "classnames";

import styles from "./ClientGroupUser.module.css";

interface IClientGroupUserProps {
    clientGroupId: number;
    user: IUser;
    dispatch: React.Dispatch<ActionTypes>
}

export const ClientGroupUser : React.FC<IClientGroupUserProps> = ({
    clientGroupId,
    user,
    dispatch,
}) => {

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.USER,
            item: {
                clientGroupId: clientGroupId,
                userId: user.id,
                userName: user.name,
                type: "client-group"
            } as IUserDnd,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }), 
        []
    );

    const handleRemoveUser = async () => {

        let ok = false;

        try {
            const response = await DataApi.removeUserFromClientGroup(user.id, clientGroupId);
            
            ok = response.ok;
        }
        catch (error) {
            console.error('There was an error', error);
        }

        if (ok) {
            dispatch({
                type: Actions.CLIENT_GROUP_USER_UNASSIGNED,
                data: {
                    userId: user.id,
                    userName: user.name,
                    clientGroupId: clientGroupId
                }
            })
        }
    }

    return (
        <li>
            <div className={styles.root}>
                <div className={styles.inner} ref={user.canBeMoved ? drag : null}>
                    <div className={`${classNames(styles.title, user.canBeMoved && styles.canDrag, isDragging && styles.isDragging)}`}>{user.name} xx</div>
                    <div className={styles.buttons}>
                        <div className={`${classNames(styles.buttonContainer, !user.canBeMoved && styles.disabled)}`} title="Remove User from Admin Users">
                            <button className={`${classNames(styles.button, !user.canBeMoved && styles.disabled)}`} type="button" onClick={handleRemoveUser} disabled={!user.canBeMoved}>
                                <FontAwesomeIcon icon={faDeleteLeft} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}