import { useRef, useState } from "react";
import { DatePicker, DateSelectorButton } from "../../../../../../../../dates";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Dates } from "../../../../../../../../../utils/dates";
import styles from "./EndDate.module.css";

export interface IEndDateProps {
    endDateMillis: number,
    earliestEndDateMillis: number,
    onChange: (value: number) => void,
}

export const EndDate : React.FC<IEndDateProps> = ({
    endDateMillis,
    earliestEndDateMillis,
    onChange,
}) => {

    const [showDatePicker, setShowDatePicker] = useState(false);
    
    const anchorRef = useRef<HTMLDivElement | null>(null);

    // date is the curently selected date which will be displayed in full with time element
    //const date = DateTime.fromMillis(endDate).toUTC();

    // date picker works on start of day, so we need to strip the time element
    // from the earliest date before passing it to the date picker
    const minimumDate = Dates.fromMillis(earliestEndDateMillis).startOfDay().millis();

    function handleOnSelected(dateMillis: number){
        setShowDatePicker(false);
        // need to make the selected date the end of the day
        onChange(Dates.fromMillis(dateMillis).endOfDay().millis());
    }

    return (
        <div className={styles.root}>
            <div ref={anchorRef}>
                <div className={styles.title}>Date the Upgrade will end</div>
                <DateSelectorButton
                    onClick={() => setShowDatePicker(true)}
                >
                    {Dates.fromMillis(endDateMillis).toString(Dates.DATE_FORMAT_LONG_WITHOUT_MILLISECONDS)}
                </DateSelectorButton>
            </div>
            {showDatePicker &&
                <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
                    <div>
                        <DatePicker 
                            minimumDateMillis={minimumDate}
                            maximumDateMillis={null}
                            suppliedDateMillis={endDateMillis}
                            anchorRef={anchorRef}
                            onSelected={handleOnSelected}
                            onCancel={() => setShowDatePicker(false)}
                        />
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}