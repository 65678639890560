import React from "react";
import { ChartTitleProps } from "./ChartTitleProps";

import styles from "./ChartTitle.module.css";

export const ChartTitle: React.FC<ChartTitleProps> = ({ title, uom }) => {

    return (
        <div>
            <div className={styles.title}>
                <span>{title}</span><span className={styles.uom}>{uom}</span>
            </div>
        </div>
    );
};
