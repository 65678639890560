import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { application } from "../../../../utils/environment";
import styles from "./AuthLayout.module.css";

function AppVersion(){
    return (
        <div className={styles.appVersion}>Version {application.version}</div>
    );
}

// inline css so that we can pick up the image from a .env file
const logoStyle = {
    marginTop: "1rem",
    marginBottom: "1rem",
    backgroundImage: `url(/assets/${import.meta.env.VITE_BRANDING_LOGO_NAME})`,
    backgroundPosition: 'left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    minHeight: import.meta.env.VITE_BRANDING_LOGO_HEIGHT as string,
    minWidth: import.meta.env.VITE_BRANDING_LOGO_WIDTH as string,
}

function Logo(){
    return (
        <Link to="/">
            <div style={logoStyle} />
        </Link>
    );
}

// inline css so that we can pick up the image from a .env file
const loginImgStyle = {
    backgroundImage: `url(/assets/${import.meta.env.VITE_BRANDING_LOGIN_IMAGE_NAME})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flexGrow: 1
}

function AuthLayoutLeft(){
    return (
        <div style={loginImgStyle}/>
    );
}

function AuthLayoutRight({children} : {children: Array<JSX.Element> | JSX.Element}){
    return (
        <div className={styles.authLayoutRight}>
            <div className={styles.authLayoutRightInner}>
                <Logo />
                <AppVersion />
                {children}
            </div>
        </div>
    )
}

function AuthLayout({children} : {children: Array<JSX.Element> | JSX.Element}){

    return (
        <div className={styles.authLayout} >
            <AuthLayoutLeft />
            <AuthLayoutRight>
                {children}
            </AuthLayoutRight>
            <ToastContainer />
        </div>
    );
}

export { AuthLayout };

