import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import styles from "./Password.module.css";
import classNames from "classnames";
interface IPasswordProps {
    name: string;
    placeholder: string;
    disabled: boolean;
    errorMessage?: string;
    autofocus?: boolean;
    onChange: (value: string) => void;
}

const Password : React.FC<IPasswordProps> = ({
    name,
    placeholder,
    disabled,
    errorMessage,
    autofocus = false,
    onChange
}) => {

    const handleOnChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ?? '';
        onChange(value);
    }

    return (
        <div className={styles.root}>
            <div className={styles.inputOuterContainer}>
                <div className={styles.inputContainer}>
                    <input 
                        className={classNames(styles.input, errorMessage && styles.error)}
                        name={name} 
                        type="password" 
                        placeholder={placeholder} 
                        disabled={disabled}
                        onChange={handleOnChange}
                        autoFocus={autofocus}
                        required={true}
                        />
                </div>
            </div>
            <div className={styles.errorMessage}>
                {errorMessage 
                    ? <span><FontAwesomeIcon icon={faExclamationCircle}/> {errorMessage}</span>
                    : <span>{' '}</span>
                }                
            </div>
        </div>
    )
}

export {Password}