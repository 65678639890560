import produce from "immer";
import { IApiData } from "./Models";

// shape of the data held in the State 
type State = {
    loading: boolean,
    data?: IApiData | null,
    error?: string | null
}

// action types that can be applied to the State Reducer
export enum Actions {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    LOADING_FAILED = 'LOADING_FAILED',
    UNASSIGNED_USER_ADDED_TO_CLIENT = 'UNASSIGNED_USER_ADDED_TO_CLIENT',
    USER_ADDED_TO_CLIENT_GROUP = 'USER_ADDED_TO_CLIENT_GROUP',
    CLIENT_USER_UNASSIGNED = 'CLIENT_USER_UNASSIGNED',
    CLIENT_USER_MOVED_TO_CLIENT = 'CLIENT_USER_MOVED_TO_CLIENT',
    CLIENT_USER_COPIED_TO_CLIENT = 'CLIENT_USER_COPIED_TO_CLIENT',
    CLIENT_USER_REMOVED_FROM_CLIENT = 'CLIENT_USER_REMOVED_FROM_CLIENT',
    CLIENT_GROUP_USER_UNASSIGNED = 'CLIENT_GROUP_USER_UNASSIGNED',
    CLIENT_GROUP_USER_MOVED_TO_CLIENT = 'CLIENT_GROUP_USER_MOVED_TO_CLIENT',
}

interface IUnassignedUserAddedToClient {
    userId: number;
    userName: string;
    clientId: number;
    clientGroupId: number;
}

interface IUserAddedToClientGroup {
    userId: number;
    userName: string;
    clientGroupId: number;
}

interface IClientUserUnassigned {
    userId: number;
    userName: string;
    clientId: number;
    clientGroupId: number;
}

interface IClientUserMovedToClient {
    userId: number;
    userName: string;
    sourceClientId: number;
    targetClientId: number;
    clientGroupId: number;
}

interface IClientUserRemovedFromClient {
    userId: number;
    userName: string;
    clientId: number;
    clientGroupId: number;
    isUnassigned: boolean;
}

interface IClientUserCopiedToClient {
    userId: number;
    userName: string;
    clientId: number;
    clientGroupId: number;
}

interface IClientGroupUserUnassigned {
    userId: number;
    userName: string;
    clientGroupId: number;
}

interface IClientGroupUserMovedToClient {
    userId: number;
    userName: string;
    clientId: number;
    clientGroupId: number;
}

// initial state
export const initialState: State = {
    loading: false,
}

type LoadingAction = {
    type: typeof Actions.LOADING
}

type LoadedAction = {
    type: typeof Actions.LOADED,
    data: IApiData
}

type LoadingFailedAction = {
    type: typeof Actions.LOADING_FAILED,
    data: string
}

type UnassignedUserAddedToClientAction = {
    type: typeof Actions.UNASSIGNED_USER_ADDED_TO_CLIENT,
    data: IUnassignedUserAddedToClient
}

type UserAddedToClientGroupAction = {
    type: typeof Actions.USER_ADDED_TO_CLIENT_GROUP,
    data: IUserAddedToClientGroup
}

type ClientUserUnassignedAction = {
    type: typeof Actions.CLIENT_USER_UNASSIGNED,
    data: IClientUserUnassigned
}

type ClientUserMovedToClientAction = {
    type: typeof Actions.CLIENT_USER_MOVED_TO_CLIENT,
    data: IClientUserMovedToClient
}

type ClientUserRemovedFromClientAction = {
    type: typeof Actions.CLIENT_USER_REMOVED_FROM_CLIENT,
    data: IClientUserRemovedFromClient
}

type ClientUserCopiedToClientAction = {
    type: typeof Actions.CLIENT_USER_COPIED_TO_CLIENT,
    data: IClientUserCopiedToClient
}

type ClientGroupUserUnassignedAction = {
    type: typeof Actions.CLIENT_GROUP_USER_UNASSIGNED,
    data: IClientGroupUserUnassigned
}

type ClientGroupUserMovedToClientAction = {
    type: typeof Actions.CLIENT_GROUP_USER_MOVED_TO_CLIENT,
    data: IClientGroupUserMovedToClient
}

export type ActionTypes = 
    LoadingAction | LoadedAction | LoadingFailedAction |
    UnassignedUserAddedToClientAction | UserAddedToClientGroupAction |
    ClientUserUnassignedAction | ClientUserMovedToClientAction | 
    ClientUserCopiedToClientAction | ClientUserRemovedFromClientAction |
    ClientGroupUserUnassignedAction | ClientGroupUserMovedToClientAction
    ;

interface IName {
    name: string
}
    
const sortByNameAsc = (a: IName, b: IName) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1; 
    if (nameA > nameB) return 1; 
    return 0;
}
 
// use immer to do a 'mutable' update of the state
export const reducer = produce((draft: State, action: ActionTypes) => {
    switch (action.type) {
        case Actions.LOADING:
            draft.loading = true;
            break;
        case Actions.LOADED:
            draft.loading = false;
            draft.data = action.data;
            break;
        case Actions.LOADING_FAILED:
            draft.loading = false;
            draft.data = null;
            draft.error = action.data;
            break;
        case Actions.UNASSIGNED_USER_ADDED_TO_CLIENT:
            unassignedUserAddedToClient(draft, action.data)
            break;
        case Actions.USER_ADDED_TO_CLIENT_GROUP:
            userAddedToClientGroup(draft, action.data);
            break;
        case Actions.CLIENT_USER_UNASSIGNED:
            clientUserUnassigned(draft, action.data);
            break;
        case Actions.CLIENT_USER_MOVED_TO_CLIENT:
            clientUserMovedToClient(draft, action.data);
            break;
        case Actions.CLIENT_USER_REMOVED_FROM_CLIENT:
            clientUserRemovedFromClient(draft, action.data);
            break;
        case Actions.CLIENT_USER_COPIED_TO_CLIENT:
            clientUserCopiedToClient(draft, action.data);
            break;
        case Actions.CLIENT_GROUP_USER_UNASSIGNED:
            clientGroupUserUnassigned(draft, action.data);
            break;
        case Actions.CLIENT_GROUP_USER_MOVED_TO_CLIENT:
            clientGroupUserMovedToClient(draft, action.data);
            break;
        default:
            break;
    }
});

const unassignedUserAddedToClient = (draft: State, data: IUnassignedUserAddedToClient) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        const client = clientGroup.clients.find(c => c.id === data.clientId);
        if (typeof client !== "undefined"){
            clientGroup.unassignedUsers = clientGroup.unassignedUsers.filter(uu => uu.id !== data.userId);
            const users = client.users.slice();
            users.push({
                id: data.userId,
                name: data.userName,
                canBeMoved: true,
            })
            users.sort(sortByNameAsc);
            client.users = users;
        }
    }
}

const userAddedToClientGroup = (draft: State, data: IUserAddedToClientGroup) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        // remove the user from the unassigned users
        clientGroup.unassignedUsers = clientGroup.unassignedUsers.filter(uu => uu.id !== data.userId);
        // add the user to the client group's users
        const users = clientGroup.users.slice();
        users.push({
            id: data.userId,
            name: data.userName,
            canBeMoved: true
        })
        users.sort(sortByNameAsc);
        clientGroup.users = users;
        // remove the user from all the client group's clients
        clientGroup.clients.forEach(client => {
            client.users = client.users.filter(user => user.id !== data.userId);
        })
    }
}

const clientUserUnassigned = (draft: State, data: IClientUserUnassigned) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        // remove the user from all clients in the client group
        clientGroup.clients.forEach(client => {
            client.users = client.users.filter(user => user.id !== data.userId);
        })
        // add the user to the client group's unassigned collection
        const users = clientGroup.unassignedUsers.slice();
        users.push({
            id: data.userId,
            name: data.userName,
            canBeMoved: true
        })
        users.sort(sortByNameAsc);
        clientGroup.unassignedUsers = users;
    }
}

const clientUserMovedToClient = (draft: State, data: IClientUserMovedToClient) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        const sourceClient = clientGroup.clients.find(client => client.id === data.sourceClientId);
        const targetClient = clientGroup.clients.find(client => client.id === data.targetClientId);
        if (typeof sourceClient !== "undefined" && typeof targetClient !== "undefined"){
            // remove the user from the source client
            sourceClient.users = sourceClient.users.filter(user => user.id !== data.userId);
            // add the user to the target client, only if it isn't already there
            const user = targetClient.users.find(user => user.id === data.userId);
            if (typeof user === "undefined"){
                const users = targetClient.users.slice();
                users.push({
                    id: data.userId,
                    name: data.userName,
                    canBeMoved: true,
                })
                users.sort(sortByNameAsc);
                targetClient.users = users;
            }
        }
    }
}

const clientUserRemovedFromClient = (draft: State, data: IClientUserRemovedFromClient) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        const client = clientGroup.clients.find(client => client.id === data.clientId);
        if (typeof client !== "undefined"){
            const user = client.users.find(user => user.id === data.userId);
            if (typeof user !== "undefined"){
                client.users = client.users.filter(user => user.id !== data.userId);
                if (data.isUnassigned){
                    const users = clientGroup.unassignedUsers.slice();
                    users.push({
                        id: data.userId,
                        name: data.userName,
                        canBeMoved: true,
                    })
                    users.sort(sortByNameAsc);
                    clientGroup.unassignedUsers = users;
                }
            }
        }
    }
}

const clientUserCopiedToClient = (draft: State, data: IClientUserCopiedToClient) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        const client = clientGroup.clients.find(client => client.id === data.clientId);
        if (typeof client !== "undefined"){
            const user = client.users.find(user => user.id === data.userId);
            if (typeof user === "undefined"){
                // add the user to the client's users
                const users = client.users.slice();
                users.push({
                    id: data.userId,
                    name: data.userName,
                    canBeMoved: true,
                })
                users.sort(sortByNameAsc);
                client.users = users;
            }
        }
    }
}

const clientGroupUserUnassigned = (draft: State, data: IClientGroupUserUnassigned) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        // remove the user from the client group
        clientGroup.users = clientGroup.users.filter(user => user.id !== data.userId);
        // add the user to the client group's unassigned collection
        const users = clientGroup.unassignedUsers.slice();
        users.push({
            id: data.userId,
            name: data.userName,
            canBeMoved: true
        })
        users.sort(sortByNameAsc);
        clientGroup.unassignedUsers = users;
    }
}

const clientGroupUserMovedToClient = (draft: State, data: IClientGroupUserMovedToClient) => {
    const clientGroup = draft.data?.clientGroups.find(cg => cg.id === data.clientGroupId);
    if (typeof clientGroup !== "undefined"){
        const client = clientGroup.clients.find(client => client.id === data.clientId);
        if (typeof client !== "undefined"){
            // remove the user from the client group
            clientGroup.users = clientGroup.users.filter(user => user.id !== data.userId);
            // add the user to the client
            const users = client.users.slice();
            users.push({
                id: data.userId,
                name: data.userName,
                canBeMoved: true
            })
            users.sort(sortByNameAsc);
            client.users = users;
        }    
    }
}