import produce from "immer"
import { GetAllResultModel } from "./Repository"

// shape of the data held in the State
type State = {
    loading: boolean,
    data?: GetAllResultModel | null,
    error?: string | null
}

// action types that can be applied to the State Reducer
export enum Actions {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    LOADING_FAILED = 'LOADING_FAILED'
}

// initial state
export const initialState: State = {
    loading: false,
}

type LoadingAction = {
    type: typeof Actions.LOADING
}

type LoadedAction = {
    type: typeof Actions.LOADED,
    data: GetAllResultModel
}

type LoadingFailedAction = {
    type: typeof Actions.LOADING_FAILED,
    data: string
}

export type ActionTypes =
    LoadingAction | LoadedAction | LoadingFailedAction

// use immer to do a 'mutable' update of the state
export const reducer = produce((draft: State, action: ActionTypes) => {
    switch (action.type){
        case Actions.LOADING:
            draft.loading = true;
            break;
        case Actions.LOADED:
            draft.loading = false;
            draft.data = action.data;
            break;
        case Actions.LOADING_FAILED:
            draft.loading = false;
            draft.data = undefined;
            draft.error = action.data;
            break;
        default:
            break;
    }
})
