import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./Year.module.css";

interface IYearProps {
    year: number;
    minYear: number | null;
    maxYear: number | null;
    onChange: (year: number) => void;
}

export const Year : React.FC<IYearProps> = ({
    year,
    minYear,
    maxYear,
    onChange,
}) => {

    const handlePreviousClick = () => {
        onChange(year - 1);
    }

    const handleNextClick = () => {
        onChange(year + 1);
    }

    return (
        <div className={styles.root}>
            <button title="previous year" disabled={minYear ? (year === minYear) : false} onClick={handlePreviousClick} className={styles.button}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </button>
            {year}
            <button title="next year" disabled={maxYear ? (year === maxYear) : false} onClick={handleNextClick} className={styles.button}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </button>
        </div>
    )
}
