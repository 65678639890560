import { Dates } from "../../../../utils/dates";
import { IYearAndMonth } from "./YearAndMonth";
import styles from "./SelectedMonthsDisplay.module.css";

interface ISelectedMonthsDisplayProps {
    from: IYearAndMonth | null;
    to: IYearAndMonth | null;
}

export const SelectedMonthsDisplay : React.FC<ISelectedMonthsDisplayProps> = ({
    from,
    to,
}) => {
    return (
        <div role="display" className={styles.root}>
            {from ? `${from.year}-${Dates.monthNameShort(from.month)}` : '-'}
            {' to '}
            {to ? `${to.year}-${Dates.monthNameShort(to.month)}` : '-'}
        </div>
    )
}