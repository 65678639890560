import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/components/AuthContext";
import { useEscapeKey } from "../../../hooks";
import styles from "./VnoAdminMenu.module.css";

export const VnoAdminMenu = (
    {
        onNavigated,
        onCancel,
    }:
    {
        onNavigated: () => void;
        onCancel: () => void;
    }
) => {

    const {user} = useContext(AuthContext);
    
    useEscapeKey(onCancel);
    
    return (
        <div className={styles.root}>
            <div>
            {user && user.canPerformVnoAdmin &&
                <div>
                    <NavLink to="/users" onClick={onNavigated} text="Manage Users"/>
                    <NavLink to="/clients" onClick={onNavigated} text="Manage Customers and Vessels" />
                    {/*<NavLink to="/prices" onClick={onNavigated} text="Manage End User Pricing"/>*/}
                </div>
            }
                <NavLink to="/home" onClick={onNavigated} text="Home"/>
            </div>
        </div>
    )
}

const NavLink = (
    {
        to,
        text,
        onClick
    }:
    {
        to: string;
        text: string;
        onClick: () => void;
    }
) => {
    
    return (
        <div className={styles.linkContainer}>
            <Link to={to} className={styles.navLink} onClick={onClick}>
                {text}
            </Link>
        </div>
    )
}
