import { useContext } from "react";

import { Map as MapGL } from 'react-map-gl';

import { ErrorBoundary } from "react-error-boundary";
import { MapDatePointContext } from "../../../../contexts/components/MapDatePointContext";
import { MapLocationsContext } from "../../../../contexts/components/MapLocationsContext";
import { MapViewStateContext } from "../../../../contexts/components/MapViewStateContext";
import { SelectedShipContext } from "../../../../contexts/components/SelectedShipContext";
import { BeamContours } from "./BeamContours";
import { FlyTo } from "./FlyTo";
import { LoadingIndicator } from "./LoadingIndicator";
import { NoRoutesIndicator } from "./NoRoutesIndicator";
import { ShipPoint } from "./ShipPoint";
import { ShipRoute } from "./ShipRoute";
import { WeatherLegends } from "./WeatherLegends";
import { WeatherOverlays } from "./WeatherOverlays";

const Content = (
    {
        showSatelliteBeams,
        showServiceAreas,
        showCloud,
        showRain,
        showWaves,
        showLegend,
        onHideLegend,
    }:
    {
            showSatelliteBeams: boolean,
            showServiceAreas: boolean,
            showCloud: boolean, 
            showRain: boolean,
            showWaves: boolean,
            showLegend: boolean,
            onHideLegend: () => void,
    }
) => {

    const { isLoading, routes } = useContext(MapLocationsContext);
    const datePoint = useContext(MapDatePointContext).dateMillis;
    const {ship} = useContext(SelectedShipContext);

    return (
        <>
            <LoadingIndicator visible={isLoading} vesselName={ship?.name} />
            <NoRoutesIndicator visible={(ship && !isLoading && routes && routes.length === 0) || !ship} vesselName={ship?.name} />
            <ShipPoint visible={!isLoading && routes && routes.length > 0}/>
            <ShipRoute />
            < FlyTo />
           
            <BeamContours
                showSatelliteBeams={showSatelliteBeams}
                showServiceAreas={showServiceAreas}
            />

            <WeatherOverlays
                showRain={showRain}
                showCloud={showCloud}
                showWaves={showWaves}
                datePoint={datePoint}
            />
            <WeatherLegends 
                visible={showLegend}
                onClose={onHideLegend}
            />
        </>
    )
}

export const ShipMap = ({
    showSatelliteBeams,
    showServiceAreas,
    showCloud,
    showRain,
    showWaves,
    showLegend,
    onHideLegend,
}: {
        showSatelliteBeams: boolean,
        showServiceAreas: boolean,
        showCloud: boolean,
        showRain: boolean,
        showWaves: boolean,
        showLegend: boolean,
        onHideLegend: () => void,
}) => {

    const { viewState, setViewState } = useContext(MapViewStateContext);

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <MapGL
                {...viewState}
                mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN as string}
                style={{ width: "100%", height: "100%" }}
                onMove={evt => setViewState(evt.viewState)}
                mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <Content 
                        showSatelliteBeams={showSatelliteBeams}
                        showServiceAreas={showServiceAreas}
                        showCloud={showCloud} 
                        showRain={showRain} 
                        showWaves={showWaves}
                        showLegend={showLegend}
                        onHideLegend={onHideLegend}
                    />
                </ErrorBoundary>
            </MapGL>
        </ErrorBoundary>
    );
}