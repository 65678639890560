import classNames from "classnames";
import { useDrag } from "react-dnd";
import { IInstallationDnd } from "../../dnd/Installation";
import { ItemTypes } from "../../dnd/ItemTypes";
import styles from "./UnassignedInstallation.module.css";

interface IUnassignedInstallationProps {
    clientGroupId: number;
    clientId: number;
    installationGroupId: number;
    installationId: number;
    name: string;
}

export const UnassignedInstallation : React.FC<IUnassignedInstallationProps> = ({
    clientGroupId,
    clientId,
    installationGroupId,
    installationId,
    name,
}) => {

    const [{isDragging}, drag] = useDrag(
        () => ({
            type: ItemTypes.INSTALLATION,
            item: {
                clientGroupId: clientGroupId,
                clientId: clientId,
                installationId: installationId,
                installationGroupId: installationGroupId,
            } as IInstallationDnd,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    )

    return (
        <li>
            <div className={styles.root}>
                <div className={`${classNames(styles.inner, isDragging && styles.isDragging)}`} ref={drag}>
                    <div>{name}</div>
                </div>
            </div>
        </li>
    )
}