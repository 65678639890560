import { useEffect, useState } from "react";
import { api, useGetData } from "../../components";
import { IScheduledChangesStatusModel } from "./Models";

/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel{
    immediateUpgradeIsAllowed: boolean;
    immediateUpgradeEarliestStartDateMillis: number;
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData API data
 * @returns UI model
 */
function mapper(apiData: IApiModel) : IScheduledChangesStatusModel {
    return {
        immediateUpgradeIsAllowed: apiData.immediateUpgradeIsAllowed,
        earliestImmediateUpgradeStartDateMillis: apiData.immediateUpgradeEarliestStartDateMillis
    }
}

/**
 * Hook to retrieve Status information for Scheduled Changes for a given Installation
 * and SABA Equipment Id and WAN Interface ID
 * 
 * @returns An object which defines the state of the data fetch.
 */
 export const useFetchScheduledChangesStatus = (
    wanInterfaceId: number | undefined,
    requestId: number,
) => {

    const [url, setUrl] = useState<string>('');
    const {state} = useGetData(url, mapper, requestId);

    useEffect(() => {

         if (typeof wanInterfaceId === "undefined") return;

         const url = api.urls.packages.get.scheduledChangesStatus(wanInterfaceId);

         setUrl(url);
        
    }, [wanInterfaceId]);

    return {state};
}