import { useState } from "react";
import { DataApi } from "../../data/DataApi";
import { Actions, ActionTypes } from "../../data/State";
import { AddEditForm } from "../common/AddEditForm";
import { notifyError } from "../../../common/errors/Errors";

interface IAddInstallationGroupProps {
    clientGroupId: number;
    clientId: number;
    onClose: () => void;
    dispatch: React.Dispatch<ActionTypes>;
}

export const AddInstallationGroup : React.FC<IAddInstallationGroupProps> = ({
    clientGroupId,
    clientId,
    onClose,
    dispatch,
}) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleOk = async (name: string) => {

        setIsSaving(true);

        let json;

        try {
            const response = await DataApi.createInstallationGroup(clientId, name);

            if (response.ok){
                json = await response.json();
            } 
        } 
        catch (error) 
        {
            console.error('There was an error', error);
        }

        if (json){
            dispatch({
                type: Actions.INSTALLATION_GROUP_ADDED,
                data: {
                    clientGroupId: clientGroupId,
                    clientId: clientId,
                    id: json.id,
                    name: name
                }
            });
            onClose();
        } else {
            notifyError();
        }        

        setIsSaving(false);
    }

    return (
        <li>
            <AddEditForm
                title="Add a Fleet"
                label="Fleet Name:"
                placeHolder="Fleet name"
                isProcessing={isSaving}
                onOk={handleOk}
                onCancel={onClose}
            />
        </li>
    )
}