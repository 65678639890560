import { useState } from "react";
import { ActionsContainer } from "../actions"
import { ContentContainer, Content, Heading, Reference, SubHeading, SelectList } from "../common"
import { PriceListViewModel, SabaQuoteViewModel } from "../ViewModels";
import { Buttons } from "../actions/Buttons";
import { Button } from "../actions/Button";

export const AddPriceListView = ({
    clientId,
    sabaQuotes,
    onCancel,
    onSave,
    onSaved,
} : {
    clientId: number,
    sabaQuotes: Array<SabaQuoteViewModel>,
    onCancel: (clientId: number) => void,
    onSave: (clientId: number, reference: string, sabaQuoteId: number) => Promise<PriceListViewModel | null>,
    onSaved: (priceListId: number) => void,
}) => {

    const defaultSabaQuoteId = sabaQuotes && sabaQuotes.length ? sabaQuotes[0].id : undefined;

    const [reference, setReference] = useState<string>('');
    const [sabaQuoteId, setSabaQuoteId] = useState<number | undefined>(defaultSabaQuoteId);
    const [isValid, setIsValid] = useState<boolean>(false);

    const handleCancelOnClick = () => {
        setReference('');
        onCancel(clientId);
    }

    const handleAppylyOnClick = async () => {

        // TODO: validation checks and error handling
        // TODO: this should be asyncd properly

        if (!sabaQuoteId) return;

        const result = await onSave(clientId, reference, sabaQuoteId);

        if (result !== null){
            onSaved(result.id);
        }
        
    }

    const handleSabaQuoteOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const value = parseInt(e.target.value, 10);

        if (isNaN(value)) return;

        setSabaQuoteId(value);

        setIsValid(validate(reference, value));
    }

    const handleReferenceOnChange = (value: string) => {
        setReference(value);

        setIsValid(validate(value, sabaQuoteId));
    }

    const validate = (reference: string, sabaQuoteId: number | undefined) : boolean => {
        return (typeof sabaQuoteId !== 'undefined' && reference.length > 10);
    }

    const selectOptions: Array<{value: number, text: string}> = sabaQuotes.map(quote => {
        return {
            value: quote.id,
            text: quote.description
        }
    }) 

    return (
        <ContentContainer>
            <Content>
                <Heading text="Add a Price List" />
                <SubHeading text="Reference"/>
                <Reference editing={true} reference={reference} onChange={handleReferenceOnChange}/>
                <SubHeading text="Quote to base the Price List on" />
                <SelectList options={selectOptions} onChange={handleSabaQuoteOnChange} defaultValue={defaultSabaQuoteId}/>
            </Content>
            <ActionsContainer>
                <Buttons>
                    <Button onClick={() => void handleAppylyOnClick()} text="Save Changes" disabled={!isValid} />
                    <Button onClick={handleCancelOnClick} text="Cancel Changes" />
                </Buttons>
            </ActionsContainer>
        </ContentContainer>
    )
}