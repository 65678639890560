import { ActionTypes } from "../../data/State";
import { UnassignedInstallation } from "./UnassignedInstallation";
import { IHoldingGroupModel } from "../Models";
import { Header } from "./Header";

interface IHoldingGroupProps {
    holdingGroup: IHoldingGroupModel;
    dispatch: React.Dispatch<ActionTypes>;
}

export const HoldingGroup : React.FC<IHoldingGroupProps> = ({
    holdingGroup,
    dispatch,
}) => {

    return (
        <ul>
            <Header 
                clientGroupId={holdingGroup.clientGroupId}
                clientId={holdingGroup.clientId}
                installationGroupId={holdingGroup.installationGroupId}
                dispatch={dispatch}
            />
            <ul>
            {holdingGroup.installations.map(installation => 
                <UnassignedInstallation
                    key={installation.installationId}
                    clientGroupId={holdingGroup.clientGroupId}
                    clientId={holdingGroup.clientId}
                    installationGroupId={holdingGroup.installationGroupId}
                    installationId={installation.installationId}
                    name={installation.name}
                />
            )}
            </ul>
        </ul>
    )
}