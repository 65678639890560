import SearchBox from "../searchBox/SearchBox";
import styles from "./Header.module.css";

interface IHeaderProps{
    initialFilter: string,
    onFilterChange: (filter: string) => void,
}

const Header: React.FC<IHeaderProps> = ({initialFilter, onFilterChange}) => {
    return (
        <header className={styles.root}>
            <SearchBox initialFilter={initialFilter} onFilterChange={onFilterChange}/>
        </header>
    )
}

export default Header;