/**
* provides methods to Register, Sign In and Sign Out from a server
*/
import React, { useCallback, useMemo } from "react";
import { api } from "../../dataServices/components";
import { useLocation } from "react-router-dom";
import { authenticatedGetOptions, postOptions } from "../../dataServices/components/fetchOptions";
import { AuthContext, UserDetailsType } from "./AuthContext";


function AuthProvider({children} : {children: React.ReactNode}){

    const [user, setUser] = React.useState<UserDetailsType | null>(null);

    const state = useLocation();

    // the following version without navigation appears to work to some extent.
    // it appears to avoid the odd redirection to signin but can cause 'secondary' browser tabs
    // to get out of sync.  If you sign out of one tab, the other tab doesn't know.
    React.useEffect(() => {
        const getUserAuth = async () => {
            try{
                const response = await fetch(api.urls.auth.get.authenticate(), authenticatedGetOptions);

                if (response.ok){
                    const userDetails = await response.json() as UserDetailsType;

                    //logger.log("authenticated as ", [userDetails]);

                    setUser(userDetails);
                } else {
                    //logger.log("not authenticated or other issue");
                    setUser(null);
                }
            }
            catch {
                //logger.error(`failed to determine authentication status`);
                setUser(null);
            }
        };

        getUserAuth().then(() => { }).catch(() => { });

    }, [setUser, state]);

    // call this function to authenticate the user
    const signIn = useCallback(async (email: string, password: string, onSuccess: () => void, onError: (error: string) => void ) => {

        // construct a post to the server
        const data = JSON.stringify({email, password});

        const options = {...postOptions, body: data};

        await fetch(api.urls.auth.post.signIn(), options)
        .then(response => {
            if (response.ok){
                response.json()
                    .then(json => {

                        const userDetails = json as UserDetailsType;

                        setUser(userDetails);
                        onSuccess();
                    })
                    .catch(() => { onError("an error occurred") });
            } else {
                onError("Invalid login");
            }
        })
        .catch(() => {
            onError("Sorry - an error occurred");
        })
    }, [setUser]);

    const signOut = useCallback(async (onSuccess: () => void) => {
        // signout from the server
        // and set local storage to null
        await fetch(api.urls.auth.post.signOut(), postOptions)
        .finally(() => {
            setUser(null);
            onSuccess();
        });
    },[setUser]);

    const value = useMemo(    
        () => ({
            user,
            signIn,
            signOut,
        }),
        [user, signIn, signOut]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthProvider};
